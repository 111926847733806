interface ITab {
  id: number;
  tab: string;
}

export const tabs: ITab[] = [
  {
    id: Date.now(),

    tab: 'view all',
  },
  {
    id: Date.now(),
    tab: 'active',
  },
  {
    id: Date.now(),
    tab: 'draft',
  },
  {
    id: Date.now(),
    tab: 'unlisted',
  },
  {
    id: Date.now(),
    tab: 'out of stock',
  },
  {
    id: Date.now(),
    tab: 'sold',
  },

  // {
  //   id: Date.now(),
  //   tab: 'deleted',
  // },
  {
    id: Date.now(),
    tab: 'issues',
  },
];

export const simpleBusinessModeTabs: ITab[] = [
  {
    id: Date.now(),

    tab: 'view all',
  },
  {
    id: Date.now(),
    tab: 'sold',
  },
  {
    id: Date.now(),
    tab: 'issues',
  },
];
