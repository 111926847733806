import React, {useCallback, useState} from 'react';
import styles from './TwoLevelPieChart.module.scss'

import {PieChart, Pie, Cell, Sector, Legend} from 'recharts';
import accounting from 'accounting';

const renderActiveShape = (props: any) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
	 <g>
		 <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
			 {payload.name}
		 </text>
		 <Sector
		  style={{cursor: 'pointer'}}
		  cx={cx}
		  cy={cy}
		  innerRadius={innerRadius}
		  outerRadius={outerRadius}
		  startAngle={startAngle}
		  endAngle={endAngle}
		  fill={fill}
		 />
		 <Sector
		  cx={cx}
		  cy={cy}
		  startAngle={startAngle}
		  endAngle={endAngle}
		  innerRadius={outerRadius + 6}
		  outerRadius={outerRadius + 10}
		  fill={fill}
		 />
		 <path
		  d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
		  stroke={fill}
		  fill="none"
		 />
		 <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
		 <text
		  x={ex + (cos >= 0 ? 1 : -1) * 12}
		  y={ey}
		  textAnchor={textAnchor}
		  fill="#333"
		 >
			 {accounting.formatMoney(value, '$', 2)}
		 </text>
		 <text
		  x={ex + (cos >= 0 ? 1 : -1) * 12}
		  y={ey}
		  dy={18}
		  textAnchor={textAnchor}
		  fill="#999"
		 >
			 {`(${(percent * 100).toFixed(2)}%) of sales`}
		 </text>
	 </g>
	);
};

const CustomLegend = ({payload, activeIndex, setActiveIndex}: any) => {
	return (
	 <ul>
		 {payload.map((entry: any, index: any) => (
		  <li
		   onMouseEnter={() => setActiveIndex(index)}
		   key={`legend-${index}`}
		   style={{
			   display: 'flex',
			   alignItems: 'center',
			   marginBottom:'8px',
			   cursor: 'pointer',
		   }}
		  >
			  <svg width={20} height={20} style={{borderRadius: '2px'}}>
				  <rect width={20} height={20} fill={entry.color}/>
			  </svg>
			  <span
			   style={{
				   marginLeft: '8px',
				   display: 'flex',
				   justifyContent: 'center',
				   alignItems: 'center',
				   gap: '10px',
			   }}
			  >
            <p>{entry.name}</p>
            <p style={{color: '#4DB89E', fontWeight: 600}}>
              {accounting.formatMoney(entry?.value, '$', 2)}
            </p>
          </span>
		  </li>
		 ))}
	 </ul>
	);
};

interface ITwoLevelPieChart {
	graphData: any;
	dataForLegend: any;
}

const TwoLevelPieChart = (props: ITwoLevelPieChart) => {
	const {graphData, dataForLegend} = props;

	const [activeIndex, setActiveIndex] = useState(0);


	const onPieEnter = useCallback(
	 (_: any, index: any) => {
		 setActiveIndex(index);
	 },
	 [setActiveIndex]
	);

	return (
	 <div className={styles.twoLevelPieChart}>
		 <div

		  style={{
			  background: '#fff',
			  width: '900px' ,
			  borderRadius: 8,
			  border: '1px solid #CBCBCB',
			  marginBottom:10,
			  position: 'relative',
		  }}
		 >

             {graphData?.length ? (
              <PieChart width={870} height={450}>
                  <Pie
                   activeIndex={activeIndex}
                   activeShape={renderActiveShape}
                   data={graphData}
                   cx={320}
                   cy={230}
                   innerRadius={80}
                   outerRadius={120}
                   fill="#8884d8"
                   dataKey="value"
                   onMouseEnter={onPieEnter}
                  >
                      {graphData.map((entry: any, index: any) => (
                       <Cell key={`cell-${index}`} fill={entry.color}/>
                      ))}
                  </Pie>
                  <Legend
                   width={240}
                   layout="vertical"
                   align="right"
                   verticalAlign="middle"
                   payload={dataForLegend.map((entry: any) => ({
                       id: entry.name,
                       type: 'rect',
                       name: entry.name,
                       value: `$${entry.value}`,
                       color: entry.color,
                   }))}
                   wrapperStyle={{whiteSpace: 'nowrap', height:'300', top:'10%'}}
                   content={
                       <CustomLegend
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                       />
                   }
                  />
              </PieChart>
             ) : (
              <div
               style={{
                   width: 900,
                   height: 450,
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   fontSize: '20px',
               }}
              >
                  No results
              </div>
             )}
		 </div>
	 </div>
	);
};

export default TwoLevelPieChart;
