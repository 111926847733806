import { useSelector } from 'react-redux';
import styles from './RedirectModal.module.scss';

interface NavigationState {
  showNotificationModal: boolean;
  active: boolean;
}

interface RootState {
  navigation: NavigationState;
}

const RedirectModal = () => {
  const navigation = useSelector((state: RootState) => state.navigation.active);
  return (
    <div className={styles.redirectContainer}>
      <div
        className={styles.redirectModal}
        style={{ marginLeft: navigation ? '220px' : '100px' }}
      >
        <p>
          “You are being directed to the CSV Download page. Once loaded,
          download your CSV file there.”
        </p>
      </div>
    </div>
  );
};

export default RedirectModal;
