export const skuSortListings = (orders: any[]) => {
    let listings: any[] = [];

    orders?.forEach(order => {
        order?.listings.forEach((listing: any) => {
            listings.push({
                date_sold:order.date_sold,...listing
            });
        });
    });

    listings?.sort((a, b) => a?.sku?.localeCompare(b?.sku));

    return listings;
};