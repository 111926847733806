import React, {Fragment, useState, useEffect} from 'react';
import {UploaderFile} from "../interfaces";
import {getUniqueId, loadImageToBase64, replaceSuffixInFilename, whenElDisappear,isElVisible} from "../common";
import LazyBackgroundImage from "./LazyBackgroundImage";
import {useGlobalUploaderState} from "../hooks/hooks";

function MobileImageActionsModal({showModal,files,setMobileImageActionsVisibility, setMobileImageActionsActiveIndex,imageIndex}: any) {
    const [allowClose, setAllowClose] = useState(false);
    const {  dispatch} = useGlobalUploaderState();
    //in some cases onclick fires on component render
    const [allowAction, setAllowAction] = useState(false);
    const [imageUploaded, setImageUploaded] = useState(false);


    const addLoadedImageToFile =(file: UploaderFile, image: any, index: any) => {
        dispatch({type: "SETNEWPROPERTYINFILE", data: { index: index, properties: {backgroundLoading: false, preview: false, imagePreviewUrl: image }}});
        dispatch({type: "MODALLOADERACTION", data: {status: false, text: ''}})
        file.imagePreviewUrl = image;
        file.preview = false;
        file.backgroundLoading = false
        if (isElVisible('.image-uploader-modal')){
            dispatch({type:'SETCACHEDFILE', data: file})
            dispatch({type:'SETACTIVEFILE', data: file})
            setMobileImageActionsVisibility(false)
        }
    }
    useEffect(() => {
        let file = files.find((file: UploaderFile, i: any) => i === imageIndex) || {};
        const {fileS3Link, imagePreviewUrl, index, backgroundLoading} = file
        const controller = new AbortController();
        const { signal } = controller
        whenElDisappear('.mobile-image-actions-modal-overlay').then(() => {
            document.body.classList.remove('no-scroll');
        })
        if (fileS3Link && !imageUploaded) setImageUploaded(true);
        if (backgroundLoading || imagePreviewUrl.startsWith('data:image'))return;
            //if starts with url not base64
        dispatch({type: "SETNEWPROPERTYINFILE", data: { index: index, properties: {backgroundLoading: true }}});
        loadImageToBase64(imagePreviewUrl).then((image:string) => {
            if (!signal.aborted) {
                addLoadedImageToFile(file, image, index);
            }
        }).catch((e) => {
            if (!signal.aborted) {
                dispatch({type: "SETNEWPROPERTYINFILE", data: { index: index, properties: {backgroundLoading: false, preview: true, imagePreviewUrl }}});
                alert(e)
            }
        });
       
    }, [files, imageIndex, imageUploaded]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAllowClose(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setAllowAction(true);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAllowAction(true);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const actionsClickListener = (type: String) => {
        const element = document.querySelector(`[data-index="${imageIndex}"][data-image_index]`)
        if (!element || !allowAction) return;
       switch (type) {
           case 'view':
               // @ts-ignore
               element.querySelector('.view-image-modal-button').click();
               break;
           case 'openS3':
               // @ts-ignore
               element.querySelector('.s3-image-button').click();
               break;
           case 'lens':
               // @ts-ignore
               element.querySelector('.google-lens-button').click();
               break;
           case 'remove':
               // @ts-ignore
               element.querySelector('.remove-image-button').click();
               break;
       }
        handleClose(false)
    }

    const handleClose = (ignoreCloseTimeout = false) => {
        if(ignoreCloseTimeout || allowClose) {
            showModal(false);
        }
    }

    const goToImage = (prev = false) => {
        let newImageIndex = imageIndex;
        if (prev)  newImageIndex--;
        else newImageIndex++
        if (newImageIndex > files.length - 1) return
        setMobileImageActionsActiveIndex(newImageIndex)
    }

    return (
        <Fragment>
        <div className='mobile-image-actions-modal-overlay' onTouchStart={() => handleClose(false)} onClick={() => handleClose(false)}>
        </div>
            <div className="mobile-image-actions-modal">
                <div className="mobile-image-actions-modal-header">
                    {/*<div onClick={() => goToImage(true)} className={imageIndex <= 0 ? 'mobile-image-actions-image-left disabled' : 'mobile-image-actions-image-left'}></div>*/}
                    {/* /!*<LazyBackgroundImage imageUrl={itemImage} elClassName="mobile-image-actions-image" />*!/*/}
                    {/*<div onClick={() => goToImage(false)} className={imageIndex + 1 === files.length ? 'mobile-image-actions-image-right disabled' : 'mobile-image-actions-image-right'}></div>*/}
                    {/*<div className='mobile-image-actions-image-counter'>{imageIndex + 1} of {files.length}</div>*/}
                </div>
                <div className="mobile-image-actions-modal-actions">
                    <div onClick={() => actionsClickListener('view')}
                         className="mobile-image-actions-modal-actions-item">
                        <div className="mobile-image-action-icon-edit"></div>
                        Edit Image
                    </div>
                    <div onClick={() => actionsClickListener('openS3')}
                         className={imageUploaded ? "mobile-image-actions-modal-actions-item" : "mobile-image-actions-modal-actions-item disabled"}>
                        <div className="mobile-image-action-icon-view"></div>
                        View image
                    </div>
                    <div onClick={() => actionsClickListener('lens')}
                         className={imageUploaded ? "mobile-image-actions-modal-actions-item" : "mobile-image-actions-modal-actions-item disabled"}>
                        <div className="mobile-image-action-icon-search"></div>
                       Google Lens
                    </div>
                    <div onClick={() => actionsClickListener('remove')}
                         className="mobile-image-actions-modal-actions-item">
                        <div className="mobile-image-action-icon-remove"></div>

                        Delete Image
                    </div>
                    <div onClick={() => handleClose(true)}
                         className='mobile-image-actions-modal-actions-item'>
                        Cancel
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default MobileImageActionsModal;