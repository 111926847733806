import React, {  useMemo, useRef } from 'react';
import DragSort from '@yaireo/dragsort';
import '@yaireo/dragsort/dist/dragsort.css';

import Tags from '@yaireo/tagify/dist/react.tagify';

interface ITagifyProps {
  keywordTags: string;
  index: number;
  tableData: any;
  setTableData: (tableData: any) => void;
  saveActive?:any;
  setSaveActive?:any;
  setRetryActive?:any;
  retryActive?:any;
}

const Tagify = (props: ITagifyProps) => {
  const { keywordTags, index, tableData, setTableData, saveActive,  setSaveActive, setRetryActive,  retryActive } = props;
  const tagifyRefDragSort = useRef<any>(null);

  useMemo(() => {
    if (tagifyRefDragSort.current)
      new DragSort(tagifyRefDragSort.current.DOM.scope, {
        selector: '.tagify__tag',
        callbacks: {
          dragEnd: onDragEnd,
        },
      });
  }, [tagifyRefDragSort.current]);

  // must update Tagify's value according to the re-ordered nodes in the DOM
  function onDragEnd(elm: any) {
    tagifyRefDragSort.current.updateValueByDOMTags();
  }

  const handleInputChange = (e: any) => {
    try {
      if (setSaveActive && !saveActive) {
        setSaveActive(true);
      }
      if (retryActive && setRetryActive) {
        setRetryActive(false);
      }

      let parseDetailValues:any;

      if (e.detail.value === "") {
        parseDetailValues = [];
      } else {
        try {
          parseDetailValues = JSON.parse(e.detail.value);
        } catch (jsonError) {
          throw new Error('Failed to parse JSON');
        }
      }

      setTableData((prevTableData:any) => {
        const updatedItems = [...prevTableData];

        updatedItems[index] = {
          ...updatedItems[index],
          changed: true,
          newKeywords: parseDetailValues
        };

        return updatedItems;
      });
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <div style={{ width: '100%' }}>
      <Tags
        tagifyRef={tagifyRefDragSort}
        defaultValue={keywordTags}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default Tagify;
