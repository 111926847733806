import { createSlice } from '@reduxjs/toolkit';

interface IRoleSliceState {
    isSave: boolean;
    isCancel: boolean;
}

const initialState: IRoleSliceState = {
    isSave: false,
    isCancel: false,
};

const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        setIsCancelSales: (state, action) => {
            state.isCancel = action.payload;
        },
        setIsSaveSales: (state, action) => {
            state.isSave = action.payload;
        },
    },
});

export const { setIsCancelSales, setIsSaveSales } = salesSlice.actions;
export default salesSlice.reducer;