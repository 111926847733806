
export
    const facebookLogo =
        'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/facebook.svg';
export
    const depopLogo =
        'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/depop.svg';
export
    const etsyLogo =
        'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/etsy.svg';
export
    const grailedLogo =
        'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/grailed.svg';
export
    const kidizenLogo =
        'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/kidizen.svg';
export
    const listingPartyLogo =
        'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/listing_party.svg';
export
    const instagramLogo =
        'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/instagram.svg';
export
    const tradesyLogo =
        'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/tradesy.svg';

export const shopifyLogo = 'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/shopify.svg'



export const vestiaireLogo = 'https://s3.us-east-1.amazonaws.com/cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/vestiaire.svg'

