export interface IPlatform {
  id: number;
  platform: {
    title: string;
    isChecked: boolean;
    code?: string[];
    platformId?: string;
  };
  notOnPlatform: {
    code?:any;
    title: string;
    isChecked: boolean;
  };
}

export const platforms: IPlatform[] = [
  {
    id: Date.now(),
    platform: {
      title: 'SOLD',
      isChecked: false,
      code: ['539.1'],
    },
    notOnPlatform: {
      title: 'Not SOLD',
      isChecked: false,
      code: ['539.1not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'eBay',
      isChecked: false,
      code: ['539.2'],
      platformId: '618',
    },
    notOnPlatform: {
      title: 'Not on eBay',
      isChecked: false,
      code: ['539.2not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Etsy',
      isChecked: false,
      code: ['539.3'],
      platformId: '619',
    },
    notOnPlatform: {
      title: 'Not on Etsy',
      isChecked: false,
      code: ['539.3not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Poshmark',
      isChecked: false,
      code: ['539.4'],
      platformId: '620',
    },
    notOnPlatform: {
      title: 'Not on Poshmark',
      isChecked: false,
      code: ['539.4not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Mercari',
      isChecked: false,
      code: ['539.5'],
      platformId: '621',
    },
    notOnPlatform: {
      title: 'Not on Mercari',
      isChecked: false,
      code: ['539.5not'],
    },
  },
  // {
  //   id: Date.now(),
  //   platform: {
  //     title: 'Tradesy',
  //     isChecked: false,
  //     code: ['539.6'],
  //     platformId: '833',
  //   },
  //   notOnPlatform: {
  //     title: 'Not on Tradesy',
  //     isChecked: false,
  //   },
  // },
  {
    id: Date.now(),
    platform: {
      title: 'Amazon',
      isChecked: false,
      code: ['539.7'],
      platformId: '836',
    },
    notOnPlatform: {
      title: 'Not on Amazon',
      isChecked: false,
      code: ['539.7not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Grailed',
      isChecked: false,
      code: ['539.8'],
      platformId: '839',
    },
    notOnPlatform: {
      title: 'Not on Grailed',
      isChecked: false,
      code: ['539.8not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Facebook',
      isChecked: false,
      code: ['539.9'],
      platformId: '840',
    },
    notOnPlatform: {
      title: 'Not on Facebook',
      isChecked: false,
      code: ['539.9not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Depop',
      isChecked: false,
      code: ['539.11'],
      platformId: '841',
    },
    notOnPlatform: {
      title: 'Not on Depop',
      isChecked: false,
      code: ['539.11not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Kidizen',
      isChecked: false,
      code: ['539.12'],
      platformId: '853',
    },
    notOnPlatform: {
      title: 'Not on Kidizen',
      isChecked: false,
      code: ['539.12not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Shopify',
      isChecked: false,
      code: ['539.13'],
      platformId: '855',
    },
    notOnPlatform: {
      title: 'Not on Shopify',
      isChecked: false,
      code: ['539.13not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Instagram',
      isChecked: false,
      code: ['539.14'],
      platformId: '856',
    },
    notOnPlatform: {
      title: 'Not on Instagram',
      isChecked: false,
      code: ['539.14not'],
    },
  },
  // {
  //   id: Date.now(),
  //   platform: {
  //     title: 'Heroine',
  //     code: ['539.15'],
  //     isChecked: false,
  //   },
  //   notOnPlatform: {
  //     title: 'Not on Heroine',
  //     isChecked: false,
  //   },
  // },
  {
    id: Date.now(),
    platform: {
      title: 'Listing Party',
      isChecked: false,
      code: ['539.16'],
    },
    notOnPlatform: {
      title: 'Not on Listing Party',
      isChecked: false,
      code: ['539.16not'],
    },
  },
  {
    id: Date.now(),
    platform: {
      title: 'Vestiaire',
      isChecked: false,
      code: ['539.17'],
      platformId: '885',
    },
    notOnPlatform: {
      title: 'Not on Vestiaire',
      isChecked: false,
      code: ['539.17not'],
    },
  },
];
