import styles from './ShippingAndVariationsComponent.module.scss';
import Tippy from "@tippyjs/react";
import {AddVariationIcon, PopoverIcon, RemoveVariationIcon} from "../../../../assets/icons";
import {InputCount} from "../../InputCount";
import {useEffect, useState} from "react";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import getNameGenerator from "../../../../hooks/useNameGenerator";
interface Variation {
    Size: string;
    Color: string;
    Custom: string;
    Quantity: string;
    SKU: string;
}

export const ShippingAndVariationsComponent = ({variations, setVariations, formInfo, unescapedRowMetadata , handleStandartInputChange, itemData, setItemData, editIndex}:any) => {
    const [variationChanger, setVariationChanger] = useState(false)

    const handleAddVariation = () => {
        setVariations([...variations, {} as Variation]);
        setVariationChanger(true);
    };

    const handleRemoveVariation = (index: any) => {
        const updatedVariations = [...variations];
        updatedVariations?.splice(index, 1);
        setVariations(updatedVariations);
        setVariationChanger(true);
    };

    const handleVariationsChange = (
        index: number,
        field: keyof Variation,
        value: string
    ) => {
        console.log(index, field, value, 'test3')
        setVariationChanger(true);
        setVariations((prevVariations:any) => {
            const updatedVariations = [...prevVariations];
            updatedVariations[index][field] = value;
            return updatedVariations;
        });
    };

    useEffect(() => {
        if(variationChanger) {
            let updatedItems: any = [...itemData];
            const generatedName = getNameGenerator('input_857');
            updatedItems[editIndex] = {
                ...updatedItems[editIndex],
                [generatedName]: variations,
                changed: true,
            };
            setItemData(updatedItems);
        }
    }, [variations, variationChanger]);

    const { bdecode } = useBasisFuncs();

    return(
        <div>
            <div className={styles.accordionContent}>
                <div className={styles.accordionContent3}>
                    <p className={styles.title}>
                        Variations
                        <Tippy
                            interactive={true}
                            zIndex={5}
                            trigger="click"
                            arrow={false}
                            content={
                                <div
                                    className={`popover ${styles.popoverContent}`}
                                >
                                    <ul>
                                        <li>
                                            Support for crossposting to marketplaces
                                            with variations coming soon!
                                        </li>
                                        <li>
                                            Click the Plus + symbol to add additional
                                            rows
                                        </li>
                                    </ul>
                                </div>
                            }
                        >
                            <PopoverIcon
                                className={styles.popoverIcon}
                                color={'#fff'}
                            ></PopoverIcon>
                        </Tippy>
                    </p>
                    <div className={styles.inputsContainer}>
                        {variations?.length < 1 ? (
                            <div key={53252389} className={styles.inputsItem}>
                                <div className={styles.inpLeftItem}>
                                    <p className={styles.heading}>Size</p>
                                    <InputCount
                                        index={0}
                                        placeholder=""
                                        subName={'Size'}
                                        name="input_857_size"
                                        value={variations[0]?.Size}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                        handleVariationsChange={handleVariationsChange}
                                    />
                                </div>
                                <div className={styles.inpRightItem}>
                                    <p className={styles.heading}>Color</p>
                                    <InputCount
                                        index={0}
                                        placeholder=""
                                        subName={'Color'}
                                        name="input_857_color"
                                        value={variations[0]?.Color}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                        handleVariationsChange={handleVariationsChange}
                                    />
                                </div>
                                <div className={styles.inpRightItem}>
                                    <p className={styles.heading}>Custom</p>
                                    <InputCount
                                        index={0}
                                        subName={'Custom'}
                                        placeholder=""
                                        name="input_857_custom"
                                        value={variations[0]?.Custom}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                        handleVariationsChange={handleVariationsChange}
                                    />
                                </div>
                                <div className={styles.inpRightItem}>
                                    <p className={styles.heading}>Quantity</p>
                                    <InputCount
                                        index={0}
                                        subName={'Quantity'}
                                        placeholder=""
                                        name="input_857_quantity"
                                        value={variations[0]?.Quantity}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                        handleVariationsChange={handleVariationsChange}
                                    />
                                </div>
                                <div className={styles.inpRightItem}>
                                    <p className={styles.heading}>SKU</p>
                                    <InputCount
                                        index={0}
                                        placeholder=""
                                        name="input_857_SKU"
                                        subName={'SKU'}
                                        value={variations[0]?.SKU}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                        handleVariationsChange={handleVariationsChange}
                                    />
                                </div>
                                <AddVariationIcon
                                    width="20px"
                                    height="20px"
                                    onClick={handleAddVariation}
                                />
                                {variations?.length > 1 && (
                                    <RemoveVariationIcon
                                        width="20px"
                                        height="20px"
                                        onClick={() => handleRemoveVariation(0)}
                                    />
                                )}
                            </div>
                        ) : (
                            variations?.map((variation:any, index:any) => (
                                <div key={index} className={styles.inputsItem}>
                                    <div className={styles.inpLeftItem}>
                                        <p className={styles.heading}>Size</p>
                                        <InputCount
                                            index={index}
                                            placeholder=""
                                            subName={'Size'}
                                            name="input_857_size"
                                            value={variations[index]?.Size}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                            handleVariationsChange={handleVariationsChange}
                                        />
                                    </div>
                                    <div className={styles.inpRightItem}>
                                        <p className={styles.heading}>Color</p>
                                        <InputCount
                                            index={index}
                                            placeholder=""
                                            subName={'Color'}
                                            name="input_857_color"
                                            value={variations[index]?.Color}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                            handleVariationsChange={handleVariationsChange}
                                        />
                                    </div>
                                    <div className={styles.inpRightItem}>
                                        <p className={styles.heading}>Custom</p>
                                        <InputCount
                                            index={index}
                                            subName={'Custom'}
                                            placeholder=""
                                            name="input_857_custom"
                                            value={variations[index]?.Custom}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                            handleVariationsChange={handleVariationsChange}
                                        />
                                    </div>
                                    <div className={styles.inpRightItem}>
                                        <p className={styles.heading}>Quantity</p>
                                        <InputCount
                                            index={index}
                                            subName={'Quantity'}
                                            placeholder=""
                                            name="input_857_quantity"
                                            value={variations[index]?.Quantity}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                            handleVariationsChange={handleVariationsChange}
                                        />
                                    </div>
                                    <div className={styles.inpRightItem}>
                                        <p className={styles.heading}>SKU</p>
                                        <InputCount
                                            index={index}
                                            placeholder=""
                                            subName={'SKU'}
                                            name="input_857_SKU"
                                            value={variations[index]?.SKU}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                            handleVariationsChange={handleVariationsChange}
                                        />
                                    </div>
                                    <AddVariationIcon
                                        width="20px"
                                        height="20px"
                                        onClick={handleAddVariation}
                                    />
                                    {variations?.length > 1 && (
                                        <RemoveVariationIcon
                                            width="20px"
                                            height="20px"
                                            onClick={() => handleRemoveVariation(index)}
                                        />
                                    )}
                                </div>
                            ))
                        )}
                        <div className={styles.line}></div>
                    </div>
                    <div className={styles.inputsContainerDown}>
                        <div className={styles.inputsItem}>
                            <div className={styles.inpLeftItem}>
                                <p className={styles.heading}>
                                    Shipping Weight lb / kg
                                    <Tippy
                                        interactive={true}
                                        zIndex={5}
                                        trigger="click"
                                        arrow={false}
                                        content={
                                            <div
                                                className={`popover ${styles.popoverContent}`}
                                            >
                                                <ul>
                                                    <li>Enter the weight.</li>
                                                    <div className={styles.line}></div>
                                                    <p>
                                                        ★ Crossposts to supported 'weight'
                                                        fields.
                                                    </p>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <PopoverIcon
                                            className={styles.popoverIcon}
                                            color={'#fff'}
                                        ></PopoverIcon>
                                    </Tippy>
                                </p>
                                <InputCount
                                    placeholder="lb / kg"
                                    name="input_2"
                                    type="number"
                                    value={
                                        formInfo.newShippingWeightlbkg.changed
                                            ? bdecode(
                                                formInfo.newShippingWeightlbkg.text
                                            )
                                            : unescapedRowMetadata[2]
                                                ? bdecode(unescapedRowMetadata[2])
                                                : ''
                                    }
                                    limit={250}
                                    height={40}
                                    hideCount={true}
                                    handleChange={handleStandartInputChange}
                                />
                            </div>
                            <div className={styles.inpRightItem}>
                                <p className={styles.heading}>
                                    Shipping Weight oz / g
                                    <Tippy
                                        interactive={true}
                                        zIndex={5}
                                        trigger="click"
                                        arrow={false}
                                        content={
                                            <div
                                                className={`popover ${styles.popoverContent}`}
                                            >
                                                <ul>
                                                    <li>Enter the weight.</li>
                                                    <div className={styles.line}></div>
                                                    <p>
                                                        ★ Crossposts to supported 'weight'
                                                        fields.
                                                    </p>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <PopoverIcon
                                            className={styles.popoverIcon}
                                            color={'#fff'}
                                        ></PopoverIcon>
                                    </Tippy>
                                </p>
                                <InputCount
                                    placeholder="oz / g"
                                    name="input_3"
                                    type="number"
                                    value={
                                        formInfo.newShippingWeightozg.changed
                                            ? bdecode(
                                                formInfo.newShippingWeightozg.text
                                            )
                                            : unescapedRowMetadata[3]
                                                ? bdecode(unescapedRowMetadata[3])
                                                : ''
                                    }
                                    limit={250}
                                    height={40}
                                    hideCount={true}
                                    handleChange={handleStandartInputChange}
                                />
                            </div>
                            <div className={styles.inpRightItem}>
                                <p className={styles.heading}>
                                    Shipping Zip or City Code
                                    <Tippy
                                        interactive={true}
                                        zIndex={5}
                                        trigger="click"
                                        arrow={false}
                                        content={
                                            <div
                                                className={`popover ${styles.popoverContent}`}
                                            >
                                                <ul>
                                                    <li>
                                                        Enter the zip code for where the
                                                        product is shipping from.
                                                    </li>
                                                    <div className={styles.line}></div>
                                                    <p>
                                                        ★ Crossposts to supported 'zip code'
                                                        fields.
                                                    </p>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <PopoverIcon
                                            className={styles.popoverIcon}
                                            color={'#fff'}
                                        ></PopoverIcon>
                                    </Tippy>
                                </p>
                                <InputCount
                                    placeholder=""
                                    name="input_837"
                                    value={
                                        formInfo.newShippingZipOrCityCode.changed
                                            ? bdecode(
                                                formInfo.newShippingZipOrCityCode
                                                    .text
                                            )
                                            : unescapedRowMetadata[837]
                                                ? bdecode(unescapedRowMetadata[837])
                                                : ''
                                    }
                                    limit={250}
                                    height={40}
                                    hideCount={true}
                                    handleChange={handleStandartInputChange}

                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.inputsContainerDown}>
                        <div className={styles.inputsItem}>
                            <div className={styles.inpLeftItem}>
                                <p className={styles.heading}>
                                    Shipping Package Length
                                    <Tippy
                                        interactive={true}
                                        zIndex={5}
                                        trigger="click"
                                        arrow={false}
                                        content={
                                            <div
                                                className={`popover ${styles.popoverContent}`}
                                            >
                                                <ul>
                                                    <li>Enter the package dimensions.</li>
                                                    <div className={styles.line}></div>
                                                    <p>
                                                        ★ Crossposts to supported 'shipping
                                                        dimension' fields.
                                                    </p>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <PopoverIcon
                                            className={styles.popoverIcon}
                                            color={'#fff'}
                                        ></PopoverIcon>
                                    </Tippy>
                                </p>
                                <InputCount
                                    placeholder=""
                                    name="input_531"
                                    type="number"
                                    value={
                                        formInfo.newShippingPackageLength.changed
                                            ? bdecode(
                                                formInfo.newShippingPackageLength
                                                    .text
                                            )
                                            : unescapedRowMetadata[531]
                                                ? bdecode(unescapedRowMetadata[531])
                                                : ''
                                    }
                                    limit={250}
                                    height={40}
                                    hideCount={true}
                                    handleChange={handleStandartInputChange}
                                />
                            </div>
                            <div className={styles.inpRightItem}>
                                <p className={styles.heading}>
                                    Shipping Package Width
                                    <Tippy
                                        interactive={true}
                                        zIndex={5}
                                        trigger="click"
                                        arrow={false}
                                        content={
                                            <div
                                                className={`popover ${styles.popoverContent}`}
                                            >
                                                <ul>
                                                    <li>Enter the package dimensions.</li>
                                                    <div className={styles.line}></div>
                                                    <p>
                                                        ★ Crossposts to supported 'shipping
                                                        dimension' fields.
                                                    </p>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <PopoverIcon
                                            className={styles.popoverIcon}
                                            color={'#fff'}
                                        ></PopoverIcon>
                                    </Tippy>
                                </p>
                                <InputCount
                                    placeholder=""
                                    name="input_532"
                                    type="number"
                                    value={
                                        formInfo.newShippingPackageWidth.changed
                                            ? bdecode(
                                                formInfo.newShippingPackageWidth
                                                    .text
                                            )
                                            : unescapedRowMetadata[532]
                                                ? bdecode(unescapedRowMetadata[532])
                                                : ''
                                    }
                                    limit={250}
                                    height={40}
                                    hideCount={true}
                                    handleChange={handleStandartInputChange}
                                />
                            </div>
                            <div className={styles.inpRightItem}>
                                <p className={styles.heading}>
                                    Shipping Package Height
                                    <Tippy
                                        interactive={true}
                                        zIndex={5}
                                        trigger="click"
                                        arrow={false}
                                        content={
                                            <div
                                                className={`popover ${styles.popoverContent}`}
                                            >
                                                <ul>
                                                    <li>Enter the package dimensions.</li>
                                                    <div className={styles.line}></div>
                                                    <p>
                                                        ★ Crossposts to supported 'shipping
                                                        dimension' fields.
                                                    </p>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <PopoverIcon
                                            className={styles.popoverIcon}
                                            color={'#fff'}
                                        ></PopoverIcon>
                                    </Tippy>
                                </p>
                                <InputCount
                                    placeholder=""
                                    name="input_533"
                                    type="number"
                                    value={
                                        formInfo.newShippingPackageHeight.changed
                                            ? bdecode(
                                                formInfo.newShippingPackageHeight
                                                    .text
                                            )
                                            : unescapedRowMetadata[533]
                                                ? bdecode(unescapedRowMetadata[533])
                                                : ''
                                    }
                                    limit={250}
                                    height={40}
                                    hideCount={true}
                                    handleChange={handleStandartInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}