import _ from "lodash";

// Import config
import config from "../config";

// Import API client
import APIClient from './APIClient';


class Orders {

    list(filter_by : any, metadata : any, options: any, sort_by: any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/orders/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);
            if(metadata) _.set(params, 'data.metadata', metadata);
            if(options) _.set(params, 'data.options', options);
            if(sort_by) _.set(params, 'data.sort_by', sort_by);

            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    update(token : string, filter_by:any, payload: any){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'PUT',
                url: `${config.base_url_x}/v1/orders/update`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);
            if(payload) _.set(params, 'data.payload', payload);


            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    listings_update(token : string, filter_by:any, payload: any){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'PUT',
                url: `${config.base_url_x}/v1/orders/listings/update`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);
            if(payload) _.set(params, 'data.payload', payload);


            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    metrics_total_price_of_orders_per_marketplace_list(filter_by : any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/orders/metrics/total-price-of-orders-per-marketplace/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);

            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    metrics_total_sales_tax_of_orders_per_marketplace_list(filter_by : any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/orders/metrics/total-sales-tax-of-orders-per-marketplace/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);

            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    metrics_total_fees_of_orders_per_marketplace_list(filter_by : any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/orders/metrics/total-fees-of-orders-per-marketplace/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);

            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    metrics_total_shipping_costs_of_orders_per_marketplace_list(filter_by : any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/orders/metrics/total-shipping-costs-of-orders-per-marketplace/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);

            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    metrics_total_cogs_of_order_listings_per_marketplace_list(filter_by : any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/orders/metrics/total-cogs-of-order-listings-per-marketplace/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);

            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

}

export default new Orders();
