import React from 'react'
import styles from './QuickLinks.module.scss'

import jwt_decode from "jwt-decode";

import quickLinks from "@assets/constants/quickLinks";
import QuickLinkItem from "./components/QuickLinkItem";
import getToken from "../../../../utils/getToken";


interface IQuickLinksProps {
}

const QuickLinks = (props: IQuickLinksProps) => {
	const token: any = getToken();
	const decodedToken: any = jwt_decode(token);
	const roles = decodedToken?.data?.user?.roles;
	const is_role_allowed_master = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'corporate master'
		);
	});

	const filteredQuickLinks = is_role_allowed_master
	 ? quickLinks.filter(link => link.id !== 1)
	 : quickLinks;

	return (
	 <div className={styles.quickLinks}>
		 <div className={styles.quickLinksWrapper}>
			 {filteredQuickLinks?.map((item: any) => (
			  <QuickLinkItem
			   key={item.id}
			   icon={item.icon}
			   title={item.title}
			   link={item.link}/>
			 ))}
		 </div>

	 </div>
	)
}

export default QuickLinks