import styles from './ExpressHeader.module.scss';
import {ListingSvg, NewListingLister, PopoverIcon, Print, ProgressSaved, SavingIcon} from "../../../../assets/icons";
import React from "react";
import {useNavigate} from "react-router";
import TimeAgo from 'react-timeago';
import Tippy from "@tippyjs/react";


const ExpressHeader = ({isRoleAllowed, hasPro, createdListingId, createActive, navigation, handleAddNewListing, expressListerSaved,expressListerCreated, setServerTime, serverTime,  setAddNewChanger}:any) => {
    const navigate = useNavigate();

    const leftMenuExpanded = Boolean(localStorage.getItem('leftMenuExpanded'));


    return (
        <div className={styles.expressHeader}
             style={navigation && leftMenuExpanded ? {width: 'calc(100% - 210px)'} : {width: 'calc(100% - 80px)'}}
        >
            <div className={styles.headerLeftContainer}>
                <div className={styles.editSubmitButtons}>
                    <button
                     onClick={() => {
                         if (createActive) {
                             setAddNewChanger((prevState: boolean) => !prevState);
                             setServerTime(null);
                             handleAddNewListing();
                         }
                     }}
                     className={styles.saveAndPreview}
                     disabled={!createActive}
                     style={!createActive ? {opacity: 0.7} : {opacity: 1}}
                    >
                        <NewListingLister/>
                        New Listing
                    </button>
                    <button
                     onClick={() => {
                         if (createActive) {
                             navigate('/listings')
                         }
                     }}
                     className={`${styles.saveAndPreview} ${styles.myListings}`}
                     disabled={!createActive}
                     style={!createActive ? {opacity: 0.7} : {opacity: 1}}
                    >
                        <ListingSvg/>
                        My Listings
                    </button>
                    {
                        (hasPro && isRoleAllowed) ? <button
                         className={`${styles.buttonPrint} ${(!createdListingId || !createActive) ? styles.printDisabled : ''}`}
                         onClick={() => {
                             if (createdListingId && createActive) {
                                 window.open(`${origin}/print-qr-page/${createdListingId}`);
                             }
                         }}
                         disabled={!createActive}
                        >
                            <Print/>
                            Inventory Label
                        </button> :

                         <Tippy
                          interactive={true}
                          arrow={false}
                          zIndex={9999}
                          trigger="click"
                          content={
                              <div className={`popover ${styles.popoverContent}`}>
                                  <p>
                                      Available for Pro Plus users. Upgrade to access
                                      this feature and many more.
                                      <a target="_blank"
                                         href={'https://listperfectly.com/pricing'}> Upgrade
                                          Now </a>
                                  </p>

                              </div>
                          }
                         >
                             <button
                              className={`${styles.buttonPrint} ${styles.printDisabled}`}
                             >
                                 <Print/>
                                 Inventory Label
                             </button>
                         </Tippy>

                    }
                    {
                     (serverTime && !expressListerSaved) &&
                     <>
                         <div className={styles.progressSavedContainer}>
                             <ProgressSaved/>
                             <div style={{color: '#767676', fontWeight: '600'}}>Progress Saved</div>
                             <TimeAgo className={styles.timeAgo} date={serverTime}/>
                         </div>
                     </>
                    }
                    {expressListerCreated &&
                     <div style={{color: '#767676', fontWeight: '600'}}><SavingIcon/> Creating listing...</div>}
                    {expressListerSaved &&
                     <div style={{color: '#767676', fontWeight: '600'}}><SavingIcon/> Saving...</div>}
                </div>
            </div>

        </div>
    );
};

export default ExpressHeader;