import React, { useEffect, useState } from 'react';
import styles from './TextAreaGrowHeight.module.scss';
import TextareaAutosize from "react-textarea-autosize";

interface TextAreaInterface {
    placeholder: string;
    name: string;
    value: string;
    limit: number;
    handleChange?: any;
    textareaRef?: any;
    hasLimit?:any;
    title?:any;
}

export const TextareaWithGrowHeight = ({
                                           placeholder,
                                           name,
                                           value,
                                           limit,
                                           handleChange,
                                           textareaRef,
                                           hasLimit,
                                           title

                                       }: TextAreaInterface) => {
    const [characterCount, setCharacterCount] = useState(0);

    useEffect(() => {
        if (value) {
            const countNewlinesAsChars = value?.replace(/\n/g, "xx"); // Replace newlines with 2 characters
            setCharacterCount(countNewlinesAsChars.length);
        }
    }, [value]);


    return (
        <div style={{ width: '100%' }} id="textArea">
            <TextareaAutosize
                className={styles.brandInfoTextarea}
                maxLength={hasLimit ? limit : undefined} // Apply limit only if hasLimit is true
                name={name}
                value={value}
                ref={textareaRef}
                placeholder={placeholder}
                onChange={(e) => {
                    const newText = e.target.value;
                    const countNewlinesAsChars = newText?.replace(/\n/g, "xx");
                    // Prevent updating the text if the limit is exceeded and hasLimit is true
                    if (!hasLimit || countNewlinesAsChars.length <= limit) {
                        handleChange(title, e.target.value)
                        setCharacterCount(countNewlinesAsChars.length);
                    }
                }}
            />
            {hasLimit && (
                <p className={styles.charactersQuantity}>
                    Character Count: {characterCount} / {limit}
                </p>
            )}
        </div>
    );
};