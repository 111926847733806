import React, {useEffect, useState} from 'react'
import styles from './SendToSalesModal.module.scss'
import moment from 'moment'
import _ from 'lodash'
import {useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";

import {ImportSales, ModalCloseSvg} from "../../../../assets/icons";
import CombineItemsModal from "./components/CombineItemsModal";

import ModalItemListing from "./components/ModalItemListing";
import transformString from "../../../../utils/transformString";
import {instanceAxios2} from "../../../../services";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import {platforms} from "../Table/components/ColumnSearch/data/platforms";
import userConfigs from "../../../../services/UserConfigs";
import getToken from "../../../../utils/getToken";
import Loader from "../../../../components/Templates/Loader";
const isSmallScreenLapTop = window.innerWidth < 1440
const modalFields = [
	{
		id: 1,
		value: 'date_sold',
		title: 'Date Sold',
		width: isSmallScreenLapTop ? "90px" : "132px"
	},
	{
		id: 2,
		value: 'shipping_status',
		title: 'Shipping Status',
		width: isSmallScreenLapTop ? "140px" : "180px"

	},
	{
		id: 3,
		value: 'sku',
		title: 'SKU',
		width: isSmallScreenLapTop ? "96px" : "120px"
	},
	{
		id: 4,
		value: 'sold_on',
		title: 'Sold on',
		width: isSmallScreenLapTop ? "127px" : "159px"
	},
	{
		id: 5,
		value: 'shop',
		title: 'Shop',
		width: isSmallScreenLapTop ? "112px" : "140px"
	},
	{
		id: 6,
		value: 'sold_price',
		title: 'Sold Price',
		width: isSmallScreenLapTop ? "80px" : "100px"
	},
	{
		id: 7,
		value: 'sales_tax',
		title: 'Sales Tax',
		width: isSmallScreenLapTop ? "80px" : "100px"

	},
	{
		id: 8,
		value: 'fees',
		title: 'Fees',
		width: isSmallScreenLapTop ? "80px" : "100px"

	},
	{
		id: 9,
		value: 'shipping_cost_buyer',
		title: 'Shipping Cost Buyer',
		width: isSmallScreenLapTop ? "80px" : "100px"

	},
	{
		id: 10,
		value: 'shipping_cost_seller',
		title: 'Shipping Cost Seller',
		width: isSmallScreenLapTop ? "80px" : "100px"

	},
	{
		id: 11,
		value: 'cogs',
		title: 'COGS',
		width: isSmallScreenLapTop ? "80px" : "100px"

	},
	{
		id: 12,
		value: 'quantity_sold',
		title: 'Qty Sold',
		width: isSmallScreenLapTop ? "64px" : "80px"
	},
	{
		id: 13,
		value: 'quantity_available',
		title: 'Qty Available',
		width: isSmallScreenLapTop ? "64px" : "80px"
	},
	{
		id: 13,
		value: 'listing_id',
		title: 'Listing ID',
		width: isSmallScreenLapTop ? "125px" : "100px"
	},
]

interface ISendToSalesModalProps {
	handleToggleShowSalesModal: () => void;
	tableData: any;
	listingItem: any
	handleChangeSuccess: () => void;
	setTableData: (tableData: any) => void;
	selectedListingsData: any;
	setSelectedListingsData:(selectedListingsData: any) => void;
}

const SendToSalesModal = (props: ISendToSalesModalProps) => {
	const {
		handleToggleShowSalesModal,
		tableData,
		listingItem,
		handleChangeSuccess,
		setTableData,
		selectedListingsData,
		setSelectedListingsData
	} = props;

	const {bdecode} = useBasisFuncs()

	const [isVisibleCombineModal, setIsVisibleCombineModal] = useState(false)
	const [shopIds, setShopIds] = useState<any>({})
	const [shopLoading, setShopLoading] = useState(true)
	const [isLoading,setIsLoading] = useState(false)

	const handleToggleCombineModalShow = () => {
		setIsVisibleCombineModal(!isVisibleCombineModal)

		function removeKeysFromModalListings() {
			const deletedKeys = [
				'newDateSold',
				'newShippingStatus',
				'newSku',
				'newSoldOn',
				'newShop',
				'newSoldPrice',
				'newSalesTax',
				'newFees',
				'newShippingCostBuyer',
				'newShippingCostSeller',
				'newCogs',
				'newQuantitySold',
				'newQuantityAvailable',
				'newItemSoldPrice'
			];

			const updatedModalListings = modalListings.map((item: any) => {
				const updatedItem = {...item};

				deletedKeys.forEach((key) => {
					delete updatedItem[key];
				});

				return updatedItem;
			});

			setModalListings(updatedModalListings);
		}

		removeKeysFromModalListings()
	}

	useEffect(() => {
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	}, []);

	useEffect(() => {
		const getShopId = async () => {
			try {
				const type = 'chrome_extension'
				const token = getToken() || ""
				const response: any = await userConfigs.get(type, token)

				const {config} = response;

				if (config) {
					setShopLoading(false)
					setShopIds(config)
					modalListings.forEach((modalListing: any) => {
						const unescapedMetadata = _.get(modalListing, 'metadata')
						const platformName = checkPlatformInMetadata(unescapedMetadata)?.toLowerCase()
						Object.keys(config)?.forEach((c: any) => {
							if (c === `${platformName}ShopId1`) {
								modalListing['newShop'] = config[c]
							}
						})
					})
				}
			} catch (error) {
				console.error(error)
			}
		}
		getShopId()
	}, []);

	const mappedModalListings = selectedListingsData?.length > 1 ? selectedListingsData : [listingItem]

	const [modalListings, setModalListings] = useState<any>(mappedModalListings)

	const globalCustomMarketplaces = useSelector((state: any) => state.customMarketplaces.customMarketplaces)

	const checkPlatformInMetadata = (unescapedRowMetadata: any) => {
		let platformName = null;

		const allPlatforms = platforms.concat(globalCustomMarketplaces)
		for (let i = 0; i < allPlatforms.length; i++) {
			const platform: any = allPlatforms[i];
			const platformCode = platform.platform.code;

			if (unescapedRowMetadata.hasOwnProperty(platformCode)) {
				if (platform.platform.title === 'SOLD') {
					continue;
				}

				if (!platform?.isCustom) {
					platformName = platform.platform.title
				} else {
					platformName = platform.platform.code[0];
				}
				break;
			}
		}

		return platformName;
	};

	const checkPlatformListingId = (unescapedRowMetadata: any) => {
		if(!unescapedRowMetadata) return
		const keyPairs = [
			["539.2", "618"],
			["539.3", "619"],
			["539.4", "620"],
			["539.5", "621"],
			["539.6", "833"],
			["539.8", "839"],
			["539.9", "840"],
			["539.11", "841"],
			["539.12", "853"],
			["539.13", "855"],
			["539.17", "885"],
		];

		globalCustomMarketplaces.forEach((item:any) => {
			if (item.platform && item.platform.code  && item.platform.platformId) {
				keyPairs.push([item.platform.code[0], item.platform.platformId]);
			}
		});

		for (const pair of keyPairs) {
			const [firstKey, secondKey] = pair;
			if (unescapedRowMetadata[firstKey] !== undefined && unescapedRowMetadata[secondKey] !== undefined) {
				return unescapedRowMetadata[secondKey];
			}
		}
		return "";
	}

	const handleSend = async () => {
		setIsLoading(true)
		const updates: any = [];
		const payload: any = {};
		const today = moment().toISOString();

		const updatedModalListings = modalListings.map((item: any, index: number) => {


			 const keys = [
				 'newDateSold', 'newShippingStatus', 'newSku', 'newSoldOn', 'newShop',
				 'newSoldPrice', 'newSalesTax', 'newFees', 'newShippingCostBuyer',
				 'newShippingCostSeller', 'newCogs', 'newQuantitySold', 'newQuantityAvailable', 'newListingId'
			 ];

				const mappedModalForRequest = [mappedModalListings.find((t: any) => t.id === item.id)].map((mpItem) => {
					const listingId = _.has(item, 'newListingId') ? item.newListingId :  checkPlatformListingId(mpItem?.metadata);

					return {
						listing_id: listingId,
						catalog_id: _.get(mpItem,'id'),
						title: bdecode(_.get(mpItem,'metadata[133]')),
						thumbnail: _.get(mpItem,'thumbnail'),
						sku: _.get(item,'newSku') || _.get(mpItem,'metadata[1]'),
						quantity_available: +_.get(item,'newQuantityAvailable') || +_.get(mpItem,'metadata[851]'),
						quantity_sold: +_.get(item,'newQuantitySold') || 1,
						cogs: +_.get(item,'newCogs') || + _.get(mpItem,'metadata[847]')
					};
				});

			 	let hasUpdates = false
				keys.forEach((key) => {
					let valueByKey = item[key];

					if (valueByKey === '') {
						return;
					}

					if (key === 'newSoldOn') {
						valueByKey = item[key]?.toLowerCase();
					}

					if (['newSku', 'newQuantityAvailable', 'newCogs', 'newQuantitySold', 'newListingId'].includes(key)) {
						valueByKey = mappedModalForRequest;
					}

					if (valueByKey !== undefined) {
						hasUpdates = true;
						updates.push({
							key,
							index,
							value: ['newShippingCostBuyer', 'newShippingCostSeller', 'newFees', 'newSalesTax', 'newSoldPrice'].includes(key) ? +valueByKey : valueByKey,
						});
					}
				});
			 if (!hasUpdates) {
				 updates.push({ index });
			 }
			return item;
		}
		);

		setModalListings(updatedModalListings)
		updates.forEach((update: any) => {
			const {key, index, value} = update;
			const transformedKey =
			 key === 'newShop'
			  ? 'shop_id'
			  : key === 'newSoldOn'
			   ? 'marketplace'
			   : (key === 'newSku' || key === 'newQuantityAvailable' || key === 'newCogs'
				|| key === 'newQuantitySold'
				|| key === 'newQuantityAvailable' || key === 'newListingId'
			   ) ? 'listings' : key === 'newSoldPrice' ? 'total_price' : transformString(key)
			if (!payload[index]) {
				payload[index] = {};
			}
			payload[index][transformedKey] = value;
		})
		  await Promise.all(Object.keys(payload).map(async (id) => {

			const unescapedRowMetadata: any = mappedModalListings[id]?.metadata
			const platformName: any = checkPlatformInMetadata(unescapedRowMetadata)
			const listingİd: string | null = mappedModalListings[id]?.newListingId ||  checkPlatformListingId(unescapedRowMetadata)
			return new Promise(async (resolve, reject) => {
				const listingItem: any = {
					listing_id: listingİd,
					catalog_id: mappedModalListings[id]?.id,
					title: unescapedRowMetadata[133],
					thumbnail: mappedModalListings[id]?.thumbnail,
					sku: unescapedRowMetadata[1],
					quantity_available: +unescapedRowMetadata[851],
					cogs: +unescapedRowMetadata[847]
				}
				const uuid_order_id = uuidv4();

				const requestData = {
					...payload[id],
					source: 'website',
					user_id_marketplace: 'user_id_marketplace',
					...(!payload[id]?.date_sold) && {date_sold:  (unescapedRowMetadata[846] && moment(unescapedRowMetadata[846]).toDate()) ||  today},
					...(!payload[id]?.fees && unescapedRowMetadata[844]) && {fees: +unescapedRowMetadata[844]},
					...(!payload[id]?.shipping_cost_seller && unescapedRowMetadata[852]) && {shipping_cost_seller: +unescapedRowMetadata[852]},
					...(!payload[id]?.total_price && unescapedRowMetadata[842] ) && {total_price: +unescapedRowMetadata[842]},
					...(!payload[id]?.marketplace) && {marketplace: (platformName?.toLowerCase() !== 'sold' || platformName?.toLowerCase() !== 'listing party')  && platformName?.toLowerCase()},
					...(!payload[id]?.listings) && {listings: [listingItem]},
					order_id:uuid_order_id
				}
				try {
					const response = await instanceAxios2.post(
					 '/v2/orders/add',
					 {payload: requestData}
					);

					if (response.status === 200) {
						setIsLoading(false)
						const data = _.get(response, 'data.data');
						const orderId  = _.get(data, 'listings[0][order_id]');
						handleChangeSuccess()
						handleToggleShowSalesModal()
						resolve(data)

						const updatedTableData = tableData.map((item: any) => {
							const listing = data.listings.find((listing: any) => item.id === listing.catalog_id);
							if (listing) {
								item.isSendToSales = true
								if (Array.isArray(item.metadata[902])) {
									item.metadata[902] = [...item.metadata[902],orderId]
								} else {
									item.metadata[902] = [orderId]
								}
							}
							return item;
						});

						setTableData(updatedTableData);

					}

				} catch (error: any) {
					setIsLoading(false)
					console.error(error.message)
				}
			})
		}))
	}


	const handleDeleteListingInModal = (id: number) => {
		if (modalListings && modalListings?.length === 1) {
			handleToggleShowSalesModal()
		}

		const tempModalListings = [...modalListings];
		const tempSelectedListingsData = [...selectedListingsData];

		const filteredModalListings = tempModalListings.filter((item) => item.id !== id);
		const transformedSelectedListingsData = tempSelectedListingsData.map((listingsData:any) => {
			if(listingsData.id === id) {
				return {
					...listingsData, removedFromSendToSales:true
				}
			} else {
				return {...listingsData}
			}

		})

		setModalListings(filteredModalListings);
		setSelectedListingsData(transformedSelectedListingsData)
	};

	const isButtonDisabled = () => {
		const requiredKeys = ['newSoldOn'];
		return !modalListings.every((listing: any) => {
			return requiredKeys.every((key: string) => {
				return key in listing && listing[key] !== '';
			}) || (checkPlatformInMetadata(listing.metadata) !== null )
		});
	};

	const checkShopId = (platformName: string, index: number) => {
		const updatedModalListings = modalListings.map((modalListing: any, modalListingIndex: number) => {
			if (index === modalListingIndex) {
				const tempShopId = `${platformName}ShopId1`;
				modalListing['newShop'] = shopIds[tempShopId] !== undefined ? shopIds[tempShopId] : '';
				return modalListing;
			}
		});
	};

	const handleChangeListingIdByPlatforms = (platformCode: string, index: number | number[]) => {
		const keys:any = {
			'539.2' : '618',
			'539.3' : '619',
			'539.4' : '620',
			'539.5' : '621',
			'539.6' : '833',
			'539.7' : '836',
			'539.8' : '839',
			'539.9' : '840',
			'539.11' : '841',
			'539.12' : '853',
			'539.13' : '855',
			'539.14' : '856',
			'539.15' : '',
			'539.16' : '',
			'539.17' : '885',
			'891.1' : "892",
			'891.2' : "893",
			'891.3' : "894",
			'891.4' : "895",
			'891.5' : "896",
			'891.6' : "897",
			'891.7' : "898",
			'891.8' : "899",
			'891.9' : "900",
			'891.10' : "901",
		}


		const updatedModalListings = modalListings.map((modalListing: any, modalListingIndex: number ) => {
			if (index === modalListingIndex) {

				if (modalListings[index]?.metadata && platformCode in keys) {
					const keyForMetadata = keys[platformCode];
					if(keyForMetadata) {
						// modalListing['newListingId'] = metadata[keyForMetadata] || ''
						modalListing['newListingId'] = modalListings[index].metadata[keyForMetadata] || ''

					}
				} else {
					modalListing['newListingId'] = ""

				}
				return modalListing;
			}
			return modalListing
		});
	}



	return (
	 <>
		 <div className={styles.sendToSalesModalOverlay}>
			 <div
			  className={styles.modal}>

				 {
					 isLoading ? <Loader maxHeight={isLoading && '100vh'} /> : <>
						 <div className={styles.newHeaderModal}>
							 <div className={styles.modalBtns}>
								 <button
								  className={"myBtn saveBulkBtn "}
								  onClick={handleToggleCombineModalShow}
								  disabled={modalListings && modalListings?.length === 1}
								 >
									 Create Multi item Order
								 </button>
								 <button
								  className={"myBtn bulkBtn"}
								  disabled={isButtonDisabled()}
								  onClick={handleSend}
								 >
									 Send to Sales & Analytics
								 </button>
							 </div>
							 <div
							  className={styles.closeBtn}
							  onClick={handleToggleShowSalesModal}
							 >
								 <ModalCloseSvg/>
							 </div>
						 </div>
						 {shopLoading  ? <Loader/> : <div className={styles.outerModalWrapper}>
							 <div
							 >
								 <div className={styles.outerModalTitle}>
									 <div>
										 <ImportSales/>
										 <span className={styles.spanTitle}>
		   Send to Sales and Analytics
			 </span>
										 <span>
			  ({modalListings?.length})
			 </span>
									 </div>

								 </div>
								 <p className={styles.modalParagpraph}>Enter and confirm your listing details, then click to
									 send to
									 the
									 Sales and
									 Analytics page.</p>
							 </div>

							 <div className={styles.modalWrapper}>
								 {modalListings?.map((mpItem: any, index: number) => (

								  <ModalItemListing
								   key={mpItem.id}
								   index={index}
								   thumbnail={_.get(mpItem, 'thumbnail')}
								   metadata={_.get(mpItem, 'metadata')}
								   modalFields={modalFields}
								   modalListings={modalListings}
								   setModalListings={setModalListings}
								   customMarketplaces={globalCustomMarketplaces}
								   handleDeleteListingInModal={handleDeleteListingInModal}
								   id={_.get(mpItem, 'id')}
								   mpItem={mpItem}
								   checkShopId={checkShopId}
								   checkPlatformListingId={checkPlatformListingId}
								   handleChangeListingIdByPlatforms={handleChangeListingIdByPlatforms}

								  />
								 ))}
							 </div>
						 </div>}
					 </>
				 }

			 </div>
		 </div>
		 {isVisibleCombineModal &&
		  <CombineItemsModal
		   modalListings={modalListings}
		   setModalListings={setModalListings}
		   handleToggleCombineModalShow={handleToggleCombineModalShow}
		   customMarketplaces={globalCustomMarketplaces}
		   handleToggleShowSalesModal={handleToggleShowSalesModal}
		   checkPlatformInMetadata={checkPlatformInMetadata}
		   checkPlatformListingId={checkPlatformListingId}
		   handleChangeSuccess={handleChangeSuccess}
		   tableData={tableData}
		   setTableData={setTableData}
		   checkShopId={checkShopId}
		   handleDeleteListingInModal={handleDeleteListingInModal}
		   handleChangeListingIdByPlatforms={handleChangeListingIdByPlatforms}
		  />}
	 </>
	)
}

export default SendToSalesModal