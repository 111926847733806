import { useMemo } from 'react';

const useRandomNumber = (): number => {
  const randomNumber = useMemo(() => {
    return Math.floor(Math.random() * (77777 - 13 + 1)) + 13;
  }, []);

  return randomNumber;
};

export default useRandomNumber;
