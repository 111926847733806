import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import {
	DragIcon,
	FakeImageLister,
	PopoverIcon,
	TrashIcon,
} from "../../../../../assets/icons";
import styles from '../ExpressListerCustomize.module.scss';


export function SortableItemRight({skuGenerator, field,setMainListDataLister, setListFromMainLister}: any) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: field?.id });

	const style = {
		transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'none',
		transition,
	};
	const handleRemoveItem = (id:any) => {
		setMainListDataLister((prevList: any) =>
		 prevList.map((item:any) =>
		  item.id === id ? { ...item, checked: false } : item
		 )
		);
		setListFromMainLister((prevList: any) =>
		 prevList.map((item:any) =>
		  item.id === id ? { ...item, checked: false } : item
		 )
		);
	};

	return (
	 <div ref={setNodeRef} style={style} className={skuGenerator ? styles.skuGeneratorFieldSKURight : ''}>
		 {field?.checked && (
		  <div className={styles.selectedFieldRightSide}>
			  {skuGenerator && (
			   <div className={styles.fieldItemContainer}>
				   <div className={styles.header}>
					   <p>SKU Generator</p>
					   <div className={styles.dragAndRemove}>
						   {skuGenerator?.title !== 'Listing Title' && (
							<div className={styles.removeBtnRight}
								 onClick={() => handleRemoveItem(skuGenerator?.id)}>
								<TrashIcon/>
							</div>
						   )}
					   </div>
				   </div>
				   <div className={styles.fakeInput} style={{height: '40px'}}></div>
			   </div>
			  )}
			  <div className={styles.fieldItemContainer}>
				  <div className={styles.header}>
					  <p>{field?.title}
						  <PopoverIcon className={styles.popoverIcon} color={'#fff'}></PopoverIcon>
					  </p>
					  <div className={styles.dragAndRemove}>
						  <div className={styles.dragBtnRight} {...attributes} {...listeners}><DragIcon/></div>
						  {field?.title !== 'Listing Title' && (
						   <div className={styles.removeBtnRight} onClick={() => handleRemoveItem(field?.id)}>
							   <TrashIcon/>
						   </div>
						  )}
					  </div>
				  </div>
				  <div className={styles.fakeInput} style={{height: '40px'}}></div>
			  </div>

		  </div>
		 )}
	 </div>
	);
}