import styles from './ConnectedMarketplacesModal.module.scss';
import React, { useEffect, useState, useMemo } from 'react';
import ConnectionWizardModal from '../ConnectionWizardModal';
import MarketplacesModal from '../MarketplacesModal';
import SettingsModal from '../SettingsModal';
import _, {debounce} from "lodash";
import {instanceAxios} from "../../services";
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from "jwt-decode";
import getToken from "../../utils/getToken";
import {useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';
import useExtensionVersion from "../../hooks/useExtensionVersion";

interface IConnectedMarketsModalProps {
	includesError:boolean;
	setConnectedMarketplacesModal: any;
	getMarketsFromExtension: any;
}

const available = [
	{
		id: 0,
		marketName: 'eBay',
		account: 'ClubRed971',
	},
	{
		id: 1,
		marketName: 'Etsy',
		account: 'ClubRed972',
	},
	{
		id: 2,
		marketName: 'Poshmark',
		account: 'ClubRed973',
	},
	{
		id: 3,
		marketName: 'Mercari',
		account: 'ClubRed974',
	},
	{
		id: 4,
		marketName: 'Grailed',
		account: 'ClubRed975',
	},
	{
		id: 5,
		marketName: 'Facebook',
		account: 'ClubRed976',
	},
	{
		id: 6,
		marketName: 'Depop',
		account: 'ClubRed977',
	},
	{
		id: 7,
		marketName: 'Kidizen',
		account: 'ClubRed978',
	},
	{
		id: 8,
		marketName: 'Shopify',
		account: 'ClubRed979',
	},
	{
		id: 9,
		marketName: 'Vestiaire',
		account: 'ClubRed979',
	},
	{
		id: 10,
		marketName: 'Instagram',
		account: 'ClubRed979',
	},
];

const marketList = [
	{
		marketplace: 'eBay',
		shop_id: 'test1',
		connection_status_extension: true,
		connection_status_extension_error: 'error',
	},
	{
		marketplace: 'Etsy',
		shop_id: 'test2',
		connection_status_extension: false,
		connection_status_extension_error: 'test',
	},
	{
		marketplace: 'Poshmark',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
	{
		marketplace: 'Mercari',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
	{
		marketplace: 'Grailed',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
	{
		marketplace: 'Facebook',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
	{
		marketplace: 'Depop',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
	{
		marketplace: 'Vestiaire',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
	{
		marketplace: 'Shopify',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
	{
		marketplace: 'Kidizen',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
	{
		marketplace: 'Instagram',
		shop_id: 'test3',
		connection_status_extension: true,
		connection_status_extension_error: '',
	},
];

const ConnectedMarketplacesModal = (props: IConnectedMarketsModalProps) => {
	const {
		setConnectedMarketplacesModal,
		getMarketsFromExtension,
		includesError
	} = props;
	const token: any = getToken();
	const decodedToken: any = jwt_decode(token);
	const roles = decodedToken?.data?.user?.roles;
	const is_role_allowed = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'corporate master' ||
		 role.toLowerCase() === 'corporate sub'
		);
	});
	const hasPro = roles
	.map((role: string) => role.toLowerCase())
	.includes('pro'.toLowerCase()) && !is_role_allowed;
	const hasSimple = roles
	.map((role: string) => role.toLowerCase())
	.includes('simple'.toLowerCase());

	const hasBusiness = roles
	.map((role: string) => role.toLowerCase())
	.includes('business'.toLowerCase());

	const isShopifySupported =  useExtensionVersion('0.20.0');

	const [marketsFromExtension, setMarketsFromExtension] = useState([])
	const [data, setData] = useState<any>(null)
	const [connectionWizardActivate, setConnectionWizardActivate] =
	 useState(false);
	const [bodyAtribute, setBodyAtribute] = useState<any>(null);
	const [openSettingsModal, setOpenSettingsModal] = useState(false);
	const [settingsMarketInfo, setSettingsMarketInfo] = useState<any>('');
	const [configIDExtension, setConfigIDExtension] = useState(false);
	const [availableMarketplacesList, setAvailableMarketplacesList] =
	 useState<any>([]);
	const [showGeneralSettings, setShowGeneralSettings] = useState(false);
	const isMobile = useSelector((state: any) => state.navigation.isMobile);
	const isGridView =  useSelector((state: any) => state.catalog.isGridView);
	const location = useLocation();
	const isSalesPage = location.pathname === '/sales';
	const[tabsData , setTabsData] = useState<any>(
	 [
		 {
			 name: "General",
			 tabChosen: true,
			 properties : {
				 checkboxes:[
					 {
						 propId: 'dontSaveOnExportToLP',
						 title: "Automatically save listing when imported to List Perfectly",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Requires manual saving of listings after import, allowing for edits before finalization.',
						 popoverDisabled: 'Disabled: Automatically saves listings to your catalog upon import.'
					 },
					 {
						 propId: 'keepTabsOnCopyTo',
						 title: "Leave edit tabs open when copying listings",
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Keeps the edit window open for immediate adjustments.',
						 popoverDisabled: 'Disabled: Closes the edit tab automatically during cross-posting.'
					 },
					 {
						 propId: 'showCopiedStatus',
						 title: "Show link tool and copied status",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Displays the options on active listings. ',
						 popoverDisabled: 'Disabled: Hides link tool and copied status.'
					 },
					 // {
					 //  propId: 'useNewListingIdsAPI',
					 //  propId: 'useNewListingIdsAPI',
					 //  title: "Support for LP Catalog",
					 //  checked: false,
					 //  changed: false,
					 //  disabled: false
					 // },
					 // {
					 //  propId: 'copyToExistingTab',
					 //  title: "Copy to open marketplace tab",
					 //  checked: false,
					 //  changed: false,
					 //  disabled: false
					 // },
					 {
						 propId: 'modifyImagesBeforeUpload',
						 title: "Modify images before upload",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Assists in modifying images during upload to meet marketplace requirements and prevent unwanted cropping.  Keep this setting on to ensure images are appropriately adjusted for each marketplace\'s format, especially beneficial for images with unique dimensions like tall photographs.',
						 popoverDisabled: ''
					 },
					 {
						 propId: 'showCopyFromToButtons',
						 title: "Show crosspost icons on marketplace",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Displays cross-posting icons for easy access.',
						 popoverDisabled: 'Disabled: Hides the icons if preferred.'
					 },
					 {
						 propId: 'confirmationMessages',
						 title: "Confirmation Messages",
						 checked:true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Displays confirmation messages post-cross-posting and listing completions, alerting users to success or issues.',
						 popoverDisabled: ''
					 },
					 {
						 propId: 'prohibitedWordsDetector',
						 title: "Word alert",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Allows for personalized alerts based on specified keywords to prevent potential issues across different marketplaces.',
						 popoverDisabled: ''
					 }
				 ],
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false
				 },
			 }
		 },
		 {
			 name: "Poshmark",
			 tabChosen: false,
			 properties: {
				 checkboxes:[
					 {
						 propId: 'poshmarkSetSoldInsteadOfDeleting',
						 title: 'Set as “Not For Sale” when ending listings on Poshmark',
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: When marking sold or ending listings on Poshmark, they will be set as "not for sale" vs deleting on Poshmark',
						 popoverDisabled: 'Disabled: Linked listings will be deleted off Poshmark when ending.'
					 },
					 {
						 propId: 'poshmarkAddToBoutique',
						 title: 'Add to your boutique (for new items only)',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: During the crosspost, "Add to boutique" will always be selected',
						 popoverDisabled: 'Disabled: During the crosspost no selection is made.'
					 }
				 ],
				 discount: {
					 propId: 'poshmarkSetDiscountedShipping',
					 value: '',
					 changed: false,
					 options: [
						 {label:'', value: 'null'},
						 {label:'No Discount', value: 'No Discount'},
						 {label:'$5.95', value: '$5.95'},
						 {label:'$4.99', value: '$4.99'},
						 {label:'FREE', value: 'FREE'},
					 ],
					 disabled: false,
					 popoverEnabled: 'When selection is made here it will result in every crosspost applying that selection to discounted shipping.',
					 popoverDisabled: ''
				 },
				 shopId1: {
					 propId: 'poshmarkShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'poshmarkShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: 'poshmarkSetProhibitedWords',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Poshmark only.',
					 popoverDisabled: ''
				 }
			 },
		 },
		 {
			 name: "Shopify",
			 tabChosen: false,
			 properties: {
				 checkboxes: [
					 {
						 propId: 'shopifySetGetTags',
						 title: 'Crosspost / Import Shopify tags',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: During a crosspost tags will be gathered and copied to Shopify.  During an import, tags will be gathered and copied to List Perfectly.',
						 popoverDisabled: 'Disabled: No tags will be gathered or copied to Shopify duing a crosspost or imported to List Perfeclty.'
					 },
					 {
						 propId: 'shopifySetSoldInsteadOfDeleting',
						 title: 'Set “archived” on Shopify when marking sold',
						 checked: false,
						 changed: false,
						 disabled: false
					 }
				 ],
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Shopify only.',
					 popoverDisabled: ''
				 },
				 shopName: {
					 propId: 'shopifyName',
					 value: '',
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Add your Shopify shop name to link your Shopify shop to your List Perfectly extension for crossposting.',
					 popoverDisabled: ''
				 },
			 },

		 },
		 {
			 name: "Instagram",
			 tabChosen: false,
			 properties: {
				 checkboxes: [
					 {
						 propId: 'instagramNotIgnoreOnDelete',
						 title: 'Remove linked Instagram post when marking SOLD / End listing',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Linked Instagram posts will be removed from your IG account\n',
						 popoverDisabled: 'Disabled: Linked IG posts will remain even when ending / marking sold from your catalog.'
					 },
				 ],
				 instaDescSeparatorValue: {
					 propId: 'instagramDescriptionSeparator',
					 value: '',
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'When you Crosspost to Instagram, List Perfect converts your tags or keywords into #hashtags and separates these #hashtags from your description with the format of 4 • symbols. These • symbols help to optimize your Instagram post by “hiding” your #hashtags away from the description which is optimal to the Instagram viewer.',
					 popoverDisabled: ''
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Instagram only.',
					 popoverDisabled: ''
				 },

			 },

		 },
		 {
			 name: "Etsy",
			 tabChosen: false,
			 properties: {
				 checkboxes:[
					 {
						 title: 'Alternate copy to',
						 propId: 'etsyAlternativeCopy',
						 checked: false,
						 tippyContent: 'Tippy content Etsy Alternate copy to',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled:Activated as a troubleshooting step if you are experiencing an issue with gathering item IDs after copying to Etsy. Enable if instructed to from LP support. ',
						 popoverDisabled: ''
					 },
					 {
						 title: 'Use new listing form',
						 propId:'etsyUseNewLinks',
						 checked: true,
						 tippyContent: 'Tippy content Etsy Use new listing form',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: During the crosspost the new Etsy listing form will be utilized',
						 popoverDisabled: 'Disabled: During the crosspost the original Etsy listing form will be used'

					 },
					 {
						 title: 'Add core details',
						 propId: 'etsyCore',
						 checked: false,
						 tippyContent: 'Tippy content Etsy Add core details',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'These are core details that Etsy requires on every listing. If you are selling similar products then set these ONE time and they will ALWAYS be set when crossposting to Etsy.',
						 popoverDisabled: ''
					 }
				 ],
				 shopId1: {
					 propId: 'etsyShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'etsyShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 itemType: {
					 propId: 'etsyCoreDetailsType',
					 value: {label:'', value: ''},
					 changed: false,
					 options: [
						 {label:'', value: ''},
						 {label:'Physical item', value: 'Physical item'},
						 {label:'Digital files', value: 'Digital files'},
					 ],
					 disabled: false
				 },
				 whoMaid: {
					 propId: 'etsyCoreWhoMade',
					 value: {label:'', value: ''},
					 changed: false,
					 options: [
						 {label:'', value: ''},
						 {label:'I did', value: 'I did'},
						 {label:'A member of my shop', value: 'A member of my shop'},
						 {label:'Another company or person', value: 'Another company or person'},
					 ],
					 disabled: false
				 },
				 whatIsIt: {
					 propId: 'etsyCoreWhatIsIt',
					 value: {label:'', value: ''},
					 changed: false,
					 options: [
						 {label:'', value: ''},
						 {label:'A Finished product', value: 'A finished product'},
						 {label:'A Supply or tool to make things', value: 'A supply or tool to make things'},
					 ],
					 disabled: false
				 },
				 whenWasItMade: {
					 propId: 'etsyCoreWhenItMade',
					 value: {label:'', value: ''},
					 changed: false,
					 options: [
						 {label:'', value: ''},
						 {label:'Made to order', value: 'Made to order'},
						 {label:'2020-2024', value: '2020-2024'},
						 {label:'2010-2019', value: '2010-2019'},
						 {label:'2005-2009', value: '2005-2009'},
						 {label:'Before 2005', value: 'Before 2005'},
						 {label:'2000-2004', value: '2000-2004'},
						 {label:'1990s', value: '1990s'},
						 {label:'1980s', value: '1980s'},
						 {label:'1970s', value: '1970s'},
						 {label:'1960s', value: '1960s'},
						 {label:'1950s', value: '1950s'},
						 {label:'1940s', value: '1940s'},
						 {label:'1930s', value: '1930s'},
						 {label:'1920s', value: '1920s'},
						 {label:'1910s', value: '1910s'},
						 {label:'1900-1909', value: '1900-1909'},
					 ],
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Etsy only.',
					 popoverDisabled: ''
				 },

			 },
		 },
		 {
			 name: "eBay",
			 tabChosen: false,
			 properties: {
				 checkboxes:[
					 {
						 title: 'Enable Sell Similar on eBay',
						 propId: 'ebayPauseAtDraftSection',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: When crossposting to eBay from the crosspost will pause during the process to allow you to review and select a similar listing from the eBay catalog so you can take advantage these listings’ item specifics and other details that are already saved to speed up listing. ',
						 popoverDisabled: 'Disabled: No change to standard crossposting process to eBay.'
					 },
					 {
						 title: 'Alternate Image Upload',
						 propId:'ebayAlternativeImageUpload',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enable to utilize eBay’s “Upload from web” option when uploading photos.',
						 popoverDisabled: ''
					 },
					 {
						 title: 'Alternate ebay fill form flow',
						 propId:'ebayAlternativeFlow',
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Enable only if instructed to by support. This is used to troubleshoot unique issues copying to eBay.',
						 popoverDisabled: 'Disabled: No change to standard crossposting process to eBay.'
					 },
					 {
						 title: 'Fast Image Upload',
						 propId:'ebayImportFromWeb',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled : 'Enabled:  Enable only if instructed to by support. This is used to troubleshoot unique issues copying to eBay.',
						 popoverDisabled: 'Disabled: No change to standard crossposting process to eBay.'
					 },
				 ],
				 shopId1: {
					 propId: 'ebayShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'ebayShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to eBay only.',
					 popoverDisabled: ''
				 },
			 },

		 },
		 {
			 name: "Mercari",
			 tabChosen: false,
			 properties: {
				 checkboxes:[
					 {
						 propId: 'mercariSetProhibitedWords',
						 title: 'Override Mercari Automatic Suggestions',
						 checked: true,
						 tippyContent: 'Tippy content Override Mercari Automatic Suggestions',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled (recommended): LP will copy your details from your listing and override the suggestions made by Mercari based on uploaded photos.',
						 popoverDisabled: 'Disabled: Mercari’s suggestions and added details will remain added to your new listing based on uploaded photos. '
					 },
					 {
						 propId: 'mercariSmartPriceOff',
						 title: 'Turn off Smart Pricing feature on Mercari',
						 checked: true,
						 tippyContent: 'Tippy content Turn off Smart Pricing feature on Mercari',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: During the crosspost the smart pricing option will be turned off.',
						 popoverDisabled: 'Disabled: Disabled will result in the default Mercari suggestion being applied for the smart pricing option'
					 }
				 ],
				 shopId1: {
					 propId: 'mercariShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'mercariShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Mercari only.',
					 popoverDisabled: ''
				 },
			 },

		 },
		 {
			 name: "Facebook",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'facebookShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'facebookShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Facebook only.',
					 popoverDisabled: ''
				 },
			 }
		 },
		 {
			 name: "Grailed",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'grailedShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'grailedShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Grailed only.',
					 popoverDisabled: ''
				 },
			 }
		 },
		 {
			 name: "Depop",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'depopShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'depopShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Depop only.',
					 popoverDisabled: ''
				 },
			 }
		 },
		 {
			 name: "Kidizen",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'kidizenShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'kidizenShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false
				 },
			 }
		 },
		 {
			 name: "Vestiaire",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'vestiaireShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'vestiaireShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
			 }
		 },
	 ].map((tab: any) => {
		 if (hasSimple) {
			 // if (tab.properties.wordAlertValue) {
			 //  delete tab.properties.wordAlertValue;
			 // }
			 if (tab.name === "Poshmark") {
				 // tab.properties.checkboxes = tab.properties.checkboxes?.filter((checkbox: any) =>
				 //  checkbox.propId !== 'poshmarkAddToBoutique'
				 // );
				 tab.properties.checkboxes?.forEach((checkbox:any) => {
					 if(checkbox.propId === 'poshmarkAddToBoutique') {
						 checkbox.disabled = true;
					 }
				 });
				 if(tab.properties.discount?.propId === 'poshmarkSetDiscountedShipping') {
					 tab.properties.discount.disabled = true;
				 }
				 if(tab.properties.wordAlertValue?.propId === 'poshmarkSetProhibitedWords') {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
			 if(tab.name === 'Shopify') {
				 if(tab.properties.shopName?.propId === 'shopifyName') {
					 tab.properties.shopName.disabled = true;
				 }
				 tab.properties.checkboxes?.forEach((checkbox:any) => {
					 if(checkbox.propId === 'shopifySetGetTags') {
						 checkbox.disabled = true;
					 }
				 });
				 if(tab.properties.wordAlertValue?.propId === '') {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
			 if(tab.name === 'General'){
				 tab.properties.checkboxes?.forEach((checkbox:any) => {
					 if(checkbox.propId === 'prohibitedWordsDetector') {
						 checkbox.disabled = true;
					 }
				 });
			 }
			 if(tab.name === 'Instagram') {
				 if(tab.properties.instaDescSeparatorValue?.propId === 'instagramDescriptionSeparator') {
					 tab.properties.instaDescSeparatorValue.disabled = true;
				 }
				 if(tab.properties.wordAlertValue?.propId === '') {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
			 if(tab.name === 'Etsy' || tab.name === 'eBay' || tab.name === 'Mercari' || tab.name === 'Facebook' || tab.name === 'Grailed' || tab.name === 'Depop' || tab.name === 'Kidizen') {
				 if(tab.properties.wordAlertValue) {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
		 }
		 else if(hasBusiness){
			 if (tab.name === "Poshmark") {
				 // tab.properties.checkboxes = tab.properties.checkboxes?.filter((checkbox: any) =>
				 //  checkbox.propId !== 'poshmarkAddToBoutique'
				 // );
				 tab.properties.checkboxes.forEach((checkbox:any) => {
					 if(checkbox.propId === 'poshmarkAddToBoutique') {
						 checkbox.disabled = true;
					 }
				 });
				 if(tab.properties.discount?.propId === 'poshmarkSetDiscountedShipping') {
					 tab.properties.discount.disabled = true;
				 }
				 if(tab.properties.wordAlertValue?.propId === 'poshmarkSetProhibitedWords') {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
			 if(tab.name === 'General'){
				 tab.properties.checkboxes?.forEach((checkbox:any) => {
					 if(checkbox.propId === 'prohibitedWordsDetector') {
						 checkbox.disabled = true;
					 }
				 });
			 }
			 if(tab.name === 'Etsy' || tab.name === 'eBay' || tab.name === 'Instagram' || tab.name === 'Mercari' || tab.name === 'Facebook' || tab.name === 'Grailed' || tab.name === 'Depop' || tab.name === 'Kidizen') {
				 if(tab.properties.wordAlertValue) {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }

		 }
		 return tab;
	 })
	)

	const [descriptionChanged, setDescriptionChanged] = useState(false)
	const [websiteSettings, setWebsiteSettings] = useState<any>({
		descriptions: [
			{propdId: 'mepr_ebay_description', name: 'eBay Description', value: '', marketplace:'eBay', changed: false},
			{propdId: 'mepr_poshmark_description', name: 'Poshmark Description', value: '', marketplace:'Poshmark', changed: false},
			{propdId: 'mepr_mercari_description', name: 'Mercari Description', value: '', marketplace:'Mercari', changed: false},
			{propdId: 'mepr_etsy_description', name: 'Etsy Description', value: '', marketplace: 'Etsy', changed: false},
			{propdId: 'mepr_facebookmarketplace_description', name: 'Facebook Marketplace Description', value: '', marketplace:'Facebook', changed: false},
			{propdId: 'mepr_shopify_description', name: 'Shopify Description', value: '', marketplace:'Shopify', changed: false},
			{propdId: 'mepr_depop_description', name: 'Depop Description', value: '', marketplace:'Depop', changed: false},
			{propdId: 'mepr_kidizen_description', name: 'Kidizen Description', value: '', marketplace:'Kidizen', changed: false},
			{propdId: 'mepr_grailed_description', name: 'Grailed Description', value: '', marketplace:'Grailed', changed: false},
			{propdId: 'mepr_instagram_description', name: 'Instagram Description', value: '', marketplace:'Instagram', changed: false},
			{propdId: 'mepr_vestiaire_description', name: 'Vestiaire Description', value: '', marketplace:'Vestiaire', changed: false},
		],
	})

	useEffect(() => {
		const filteredAvailable = available.filter((item) => {
			return !getMarketsFromExtension?.some(
			 (market: any) => market?.marketplace === item?.marketName?.toLowerCase(),
			);
		});
		setAvailableMarketplacesList(filteredAvailable)
	},[getMarketsFromExtension])


	useEffect(() => {
		setMarketsFromExtension(getMarketsFromExtension)
	},[getMarketsFromExtension])

	const handleGetSettingsValue = (newSettingsMarket: any) => {
		setSettingsMarketInfo(newSettingsMarket);
	};
	const unescapedRowMetadata = useMemo(() => {
		const result: any = {};
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				result[key] = _.get(data, key);
			}
		}
		return result;
	}, [data]);

	const updateTabsData = (receivedData: any[]) => {
		try {
			// Check if the receivedData array is not empty and if it contains objects with a 'date' property
			if (receivedData.length > 0 && 'date' in receivedData[0]) {
				// Sort receivedData by date
				receivedData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
			} else {
				receivedData.sort((a, b) => a.word_or_phrase.localeCompare(b.word_or_phrase));
			}

			const updatedTabsData = [...tabsData];
			const generalValues: { id: string, value: string }[] = [];

			receivedData.forEach(item => {
				const marketplace = item.marketplace.toLowerCase();
				if (marketplace === 'all_marketplaces') {
					generalValues.push({ id: item.id, value: item.word_or_phrase });
				} else {
					const tabToUpdate = updatedTabsData.find(tab => tab.name.toLowerCase() === marketplace);
					if (tabToUpdate) {
						if (!tabToUpdate.properties.wordAlertValue.value) {
							tabToUpdate.properties.wordAlertValue.value = [];
						}
						tabToUpdate.properties.wordAlertValue.value.push({ id: item.id, value: item.word_or_phrase });
						tabToUpdate.properties.wordAlertValue.changed = true;
					}
				}
			});

			const generalTab = updatedTabsData.find(tab => tab.name.toLowerCase() === 'general');
			if (generalTab) {
				generalTab.properties.wordAlertValue.value = generalValues;
				generalTab.properties.wordAlertValue.changed = true;
			}
			setTabsData(updatedTabsData);
		} catch (e) {
			console.log(e, 'error');
		}
	};


	const getWordsAlertInfo = async() => {
		try {
			const response = await instanceAxios.post('/prohibited-words-and-phrases/list',  { metadata: { offset: 0, limit: 300 }});
			if(response.status === 200) {
				const data = response.data.data
				updateTabsData(data);
			}
		}
		catch (e) {
			console.log('error', e)
		}
	}
	const getTypeExtension = async() => {
		try {
			const payload: any = { type: 'chrome_extension' };
			const response = await instanceAxios.post('user-configs/get', payload);
			if(response.status === 200) {
				const { config, id } = response.data;
				setConfigIDExtension(id)
				setData(config)
			}
		}
		catch (e) {
			console.log('error', e)
		}
	}
	async function fetchGlobalFooters(): Promise<void> {
		try {
			const response = await instanceAxios.post('/users/legacy/metadata/list', {
				metadata: { offset: 0, limit: 10 },
				filter_by: {},
			});
			if (response.status === 200) {
				const serverData = response.data.data;

				const updatedDescriptions = websiteSettings.descriptions.map((description:any) => {
					let serverKey = `mepr_${description.name.toLowerCase().replace(/\s+/g, '_')}`;
					if(serverKey === 'mepr_facebook_marketplace_description') {
						serverKey = 'mepr_facebookmarketplace_description'
					}
					return {
						...description,
						value: _.get(serverData, serverKey, ''),
					};
				});

				setWebsiteSettings((prevSettings:any) => ({
					...prevSettings,
					descriptions: updatedDescriptions,
				}));
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}
	const sendRequestForGlobalDescriptions = async () => {
		try {
			const changedDescription = websiteSettings.descriptions.find((item:any) => item.changed);
			if (!changedDescription) {
				return;
			}

			const payload: any = {
				payload: {
					[changedDescription.propdId]: changedDescription.value
				}
			};

			const response = await instanceAxios.put(
			 '/users/legacy/metadata/update',
			 payload
			);
			if (response.status === 200) {
				setDescriptionChanged(false);

			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		let debouncedSendRequest : any;
		if (descriptionChanged) {
			try {
				debouncedSendRequest = debounce(() => {
					sendRequestForGlobalDescriptions();
				}, 500);

				debouncedSendRequest();
			} catch (e) {
				console.log('error', e);
			}
		}

		return () => {
			if (debouncedSendRequest) {
				debouncedSendRequest.cancel();
			}
		};
	}, [descriptionChanged, sendRequestForGlobalDescriptions]);

	useEffect(() => {
		const bodyElement = document.body;

		const  handleAttributeChange = () => {
			const getRefactor = bodyElement.getAttribute(
			 'lp_custom_chrome_extension_version'
			);
			try {
				if(getRefactor) {
					const versions =  [0.114, 0.115]
					const parts = getRefactor?.split('.');
					const result = parts?.slice(0, 2)?.join('.');
					if (versions.includes(parseFloat(result))) {
						setBodyAtribute(true);
					}
					else {
						setBodyAtribute(false);
					}
				}
			}
			catch (e) {
				console.log('error', e)
			}

		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_version'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		if (bodyAtribute) {
			setBodyAtribute(false)
			setTabsData((prevTabsData:any) => {
				// Clone the previous state to avoid mutating it directly
				const updatedTabsData = [...prevTabsData];

				// Find the General tab and add checkboxes conditionally
				const generalTab = updatedTabsData.find(tab => tab.name === "General");
				if (generalTab) {
					// Add checkboxes conditionally based on bodyAttribute
					if(hasSimple || hasPro || hasBusiness){
						generalTab.properties.checkboxes.push(
						 {
							 propId: 'turnOffBackgroundEnd',
							 title: "End listings - tabs will open in active window",
							 checked: false,
							 changed: false,
							 disabled: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? true : false,
						 }
						);
					}
					else {
						generalTab.properties.checkboxes.push(
						 {
							 propId: 'turnOffBackgroundUpdate',
							 title: "Update listings - tabs will open in active window",
							 checked: false,
							 changed: false,
							 disabled: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? true : false,
							 popoverEnabled: 'Enabled: Updates open in a new tab, providing visibility to changes and requires you to complete the updates',
							 popoverDisabled:'Disabled: Updates occur in the background, streamlining the process'
						 },
						 {
							 propId: 'turnOffBackgroundEnd',
							 title: "End listings - tabs will open in active window",
							 checked: false,
							 changed: false,
							 disabled: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? true : false,
							 popoverEnabled: 'Enabled: Ending lisitngs open in a new tab, providing visibility to confirmation messages.',
							 popoverDisabled: 'Disabled: Ending listings occur in the background, streamlining the process.'
						 },
						 {
							 propId: 'turnOffAutoEnd',
							 title: "Auto delist enabled",
							 checked: false,
							 changed: false,
							 disabled: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? true : false,
							 popoverEnabled: 'Automatically deactivates linked marketplace listings when a sale is detected on a List Perfectly listing.',
							 popoverDisabled: ''
						 }
						);
					}

				}

				return updatedTabsData;
			});
		}
	}, [bodyAtribute]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await getWordsAlertInfo();
				await getTypeExtension();
				await fetchGlobalFooters();
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);


	return (
	 <div className={styles.connectedMarketplacesModalContainer}>
		 {connectionWizardActivate ? (
		  <ConnectionWizardModal
		   connectionWizardActivate={connectionWizardActivate}
		   setConnectionWizardActivate={setConnectionWizardActivate}
		   availableMarketplacesList={availableMarketplacesList}
		   setAvailableMarketplacesList={setAvailableMarketplacesList}
		   hasSimple={hasSimple}
		   isShopifySupported={isShopifySupported}
		  />
		 ) : openSettingsModal ? (
		  <SettingsModal
		   setOpenSettingsModal={setOpenSettingsModal}
		   setConnectionWizardActivate={setConnectionWizardActivate}
		   settingsMarketInfo={settingsMarketInfo}
		   tabsData={tabsData}
		   setTabsData={setTabsData}
		   unescapedRowMetadata={unescapedRowMetadata}
		   websiteSettings={websiteSettings}
		   setWebsiteSettings={setWebsiteSettings}
		   setDescriptionChanged={setDescriptionChanged}
		   configIDExtension={configIDExtension}
		   showGeneralSettings={showGeneralSettings}
		  />
		 ) : (
		  <MarketplacesModal
		   includesError={includesError}
		   marketplacesList={marketsFromExtension}
		   availableMarketplacesList={availableMarketplacesList}
		   setConnectedMarketplacesModal={setConnectedMarketplacesModal}
		   setConnectionWizardActivate={setConnectionWizardActivate}
		   setOpenSettingsModal={setOpenSettingsModal}
		   handleGetSettingsValue={handleGetSettingsValue}
		   setShowGeneralSettings={setShowGeneralSettings}
		   isShopifySupported={isShopifySupported}
		  />
		 )}
	 </div>
	);
};

export default ConnectedMarketplacesModal;