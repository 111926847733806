import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {FaSearch, SVGClearSort, SVGNewAsc, SVGNewDesc} from "../../../../assets/icons";
import _ from "lodash";
import {useLocation} from "react-router-dom";
import styles from "../../../CatalogPage/components/Table/Table.module.scss";
import {Popover} from "react-tiny-popover";

interface ISortData {
    id: number;
    title: string;
}

const sortData: ISortData[] = [
    {
        id: Date.now(),
        title: 'ascending',
    },
    {
        id: Date.now(),
        title: 'descending',
    },
    {
        id: Date.now(),
        title: 'clear Sort',
    },
];


const TableResizeColumn = ({searchResult, directions, handleChangeDirection, key, col, i, mouseDown, id, innerRef, activeIndex, handleSearch}:any) => {
    const [isFocused, setIsFocused] = useState(false);
    const [cacher, setCacher] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sortRef = useRef<HTMLDivElement | null>(null);
    const [sortTollTipShow, setSortToolTipShow] = useState<boolean>(false);
    const direction = directions[id] || 'none';
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleSearchChange = (
     e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCacher(false);
        handleSearch(e.target.name, e.target.value);
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFocused(false);
        if (!event.target.value) {
            setIsFocused(false);
        }
    };

    const handleToggleSortToolTipShow = () => {
        setSortToolTipShow(!sortTollTipShow);
    };

    let decodedFilterBy: any;
    const functionGetValueFromUrl = (columnValue: string) => {
        const filterByParam = queryParams.get('filter_by');
        if (filterByParam) {
            decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));
        }
        if (columnValue === 'title') {
            return _.get(decodedFilterBy, '133');
        } else if (columnValue === 'SKU') {
            return _.get(decodedFilterBy, '1');
        } else if (columnValue === 'price') {
            return _.get(decodedFilterBy, '22');
        } else if (columnValue === 'quantity') {
            return _.get(decodedFilterBy, '851');
        } else if (columnValue === 'notes') {
            return _.get(decodedFilterBy, '726');
        } else if (columnValue === 'platforms') {
            return _.get(decodedFilterBy, 'platforms');
        } else {
            return '';
        }
    };

    useEffect(() => {
        functionGetValueFromUrl(col.value);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (sortRef.current && !sortRef.current?.contains(event.target as Node)) {
                setSortToolTipShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sortRef, setSortToolTipShow]);


  return (
    <>
        <th
            ref={innerRef}
            className="sticky-element"
            style={{
                position: 'sticky',
                top: '0',
                background: '#F4F7FF',
            }}
        >
            <div style={{display: 'flex', alignItems:"flex-start", gap:'10px'}}>
                {
                    (col.value === 'title' || col.value === 'updated') ?
                     <Popover
                      isOpen={sortTollTipShow}
                      positions={['bottom']}
                      reposition={true}
                      padding={15}
                      containerStyle={{zIndex: '999'}}
                      content={
                          sortTollTipShow ? (
                           <div className="popover popoverCatalog" ref={sortRef}>
                               {sortData.map((item) => (
                                <div className={styles.popOverWrapper}>
                                    <div
                                     style={{
                                         gap: item.title === 'clear Sort' ? 4 : 10,
                                         marginBottom:
                                          item.title === 'clear Sort' ? 0 : 10,
                                     }}
                                     className={styles.popoverSortItem}
                                     key={item.id}
                                     onClick={() => {
                                         handleChangeDirection(
                                          col.value,
                                          item.title === 'clear Sort'
                                           ? 'none'
                                           : item.title === 'ascending'
                                            ? 'top'
                                            : 'bottom'
                                         );

                                         handleToggleSortToolTipShow();
                                     }}
                                    >
                                        <div>
                                            {item.title === 'clear Sort' ? (
                                             <SVGClearSort/>
                                            ) : item.title === 'descending' ? (
                                             <SVGNewDesc/>
                                            ) : (
                                             <SVGNewAsc/>
                                            )}
                                        </div>
                                        <div>{item.title}</div>
                                    </div>
                                </div>
                               ))}
                           </div>
                          ) : (
                           <div></div>
                          )
                      }
                     >
                <span
                 onClick={handleToggleSortToolTipShow}
                 style={{display: 'flex', alignItems: "flex-start", gap: '10px', cursor: 'pointer'}}
                >
                  <p>{col.heading}</p>

                    {(col.value === 'title' || col.value === 'updated') && direction !== 'none' && (
                     <div className={styles.selectedSortView}>
                         {direction === 'bottom' ? (
                          <div className={styles.caretUp}>
                              <SVGNewDesc/>
                          </div>
                         ) : (
                          <SVGNewAsc/>
                         )}
                     </div>
                    )}
                </span>
                     </Popover>
                     :
                     <p>{col.heading}</p>
                }
            </div>
            <div
                style={{
                    height: '100%',
                    position: 'absolute',
                    zIndex: 534534,
                }}
                onMouseDown={() => mouseDown(i)}
                className={`resize-handle ${
                    activeIndex === i ? 'active' : 'idle'
                }`}
            />
            {
             col.value === 'title' &&
             <div style={{position: 'relative', width:'100%'}}>
                 <input className="myInputCatalog"
                        onChange={handleSearchChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        name={col.value}
                        value={
                         (searchResult?.find((item:any) => item?.name?.toLowerCase() === 'title')?.value) ||
                         (cacher && functionGetValueFromUrl(col.value)) ||
                         ''
                        }
                        />
                 {!isFocused &&
                  !(searchResult?.find((item:any) => item?.name?.toLowerCase() === 'title')?.value) &&
                  !functionGetValueFromUrl(col.value)  && (
                   <div className="myInputSearchIcon">
                       <FaSearch/>
                   </div>
                  )}
             </div>
            }
        </th>
    </>
  )
}

export default TableResizeColumn;