import React from 'react';
import accounting from 'accounting';

import styles from '../../Stats.module.scss';

const StatsItem = (props: any) => {
	const { title, price, isActive,handleStatsClick, value,index, data } = props;

	// TODO absolutely terrible approach to use H tags for styling purposes. Replace with divs + css.
	return (
	 <div
	  className={isActive ? `${styles.activeStatsItem}` : `${styles.statsItem}`}
	  onClick={() => handleStatsClick({ title, price, value, index, data })}>
		 <h3>{title}</h3>
		 <h4>{accounting.formatMoney(price, '$', 2)}</h4>
	 </div>
	);
};

export default StatsItem;
