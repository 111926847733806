// @flow
import * as React from 'react';
import {useGlobalUploaderState} from "../hooks/hooks";
import {Fragment} from "react";

export function UploaderMainLoader() {
    const { state } = useGlobalUploaderState();

    return state.showUploaderMainLoader ? (
        <div id='react_image_uploader_loader_container'>
            <svg className="circular-loader react_image_uploader_loader" viewBox="25 25 50 50">
                <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#234165"
                        strokeWidth="2"/>
            </svg>
            <span className='disable-select local-loading-text'>
                        Image loading...
                    </span>
        </div>
    ): <Fragment />
};