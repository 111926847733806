import styles from './ListingAssistant.module.scss';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import ScanComponent from '../ScanComponent';
import {CloseModal, KeyboardSvg, Lightbulb, UploadSvg} from "../../../assets/icons";
import useBasisFuncs from "@hooks/useBasisFuncs";

function ListingAssistant({
                              changeListingAssistance,
                              availableTitle,
                              scanLoader,
                              sendRequestForScan,
                              assistantTitle,
                              setAssistantTitle,
                              totalTitle,
                              localAvalaibleCount,
                              barCodeLimits,
                              sendRequestForSuggestDescription,
                              handleChangeEditInput,
                              loaderForSuggest,
                              setNewTableInfo,
                              newTableInfo,
                              itemData,
                              setItemData,
                              editIndex,
                              setCreateFromImage,
                              hideAssistantModal,
                              setHideAssistantModal,
                              imagesFromUploader,
                              mainTitle,
                              isModal,
                              setAssistantFromTitle,
                              unescapedRowMetadata
                          }: any) {
    const [assistantScanTitle, setAssistantScanTitle] = useState('');
    const [scanShow, setScanShow] = useState(false);
    const [localTitle, setLocalTitle] = useState('');
    const [hideDefaultModalItems, setHideDefaultModalItems] = useState(false);
    const [createFromTitle, setCreateFromTitle] = useState(false);
    const [showNotifyImagesModal, setShowNotifyImagesModal] = useState(false);
    const {bdecode} = useBasisFuncs();

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setHideAssistantModal(!isChecked);

        if (isChecked) {
            changeListingAssistance(true, 'show');
        } else {
            changeListingAssistance(true, 'hide');
        }

    };

    const handleCreateFromImage = () =>{
        setCreateFromImage(true);
        changeListingAssistance(false);
    }
    const handleTitleChange = (e: any) => {
        const { value } = e.target;
        setLocalTitle(value)
        setAssistantTitle(value);
    };
    const handleScanTitleChange = (e: any) => {
        const { value } = e.target;
        setAssistantScanTitle(value);
    };

    const toggleCreateFromTitle = ()=> {
        setCreateFromTitle(true);
        setHideDefaultModalItems(true);
        if(mainTitle){
            setAssistantTitle(mainTitle)
            setLocalTitle(mainTitle)
        }
    }
    function limitToMaxCharacters(input: string, maxChars: number): any {
      try{
          let charCount = 0;
          let realIndex = 0;

          for (let i = 0; i < input.length; i++) {
              const char = input[i];

              // Check if adding this character exceeds the limit
              if (char === '\n') {
                  if (charCount + 2 > maxChars) break; // Exceeds limit, stop
                  charCount += 2;
              } else {
                  if (charCount + 1 > maxChars) break; // Exceeds limit, stop
                  charCount += 1;
              }

              // Only increase the realIndex if we are still within the character limit
              realIndex++;
          }

          // Return the substring up to the valid character index
          return input.substring(0, realIndex);
      }catch(e){
          console.log(e, 'error')
      }
    }

    const titleSubmit = (e: any) => {
       try{
           if (localTitle.length < 40) {
               toast.error(
                   'Min chars for title field "Suggest description" button to become active is 40 characters.',
                   {
                       position: 'top-right', // You can customize the notification position
                   }
               );
           } else {
               // setNewTableInfo({
               //     ...newTableInfo,
               //     ['newTitle']: { text: localTitle, changed: true },
               // });
               setNewTableInfo({
                   ...newTableInfo,
                   ['newTitle']: { text: localTitle, changed: true },
                   ...(
                       (newTableInfo.newMercaryTitle.changed ||
                           (unescapedRowMetadata[789] )) &&

                       newTableInfo.newMercaryTitle.text !==  unescapedRowMetadata[133] &&

                       (newTableInfo.newMercaryTitle.text !== newTableInfo.newTitle.text.substring(0, 80))
                           ? {}
                           : {
                               newMercaryTitle: {
                                   text: limitToMaxCharacters(localTitle, 80), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),
                   ...(
                       (newTableInfo.newPoshmarkTitle.changed ||
                           (unescapedRowMetadata[786] )) &&

                       newTableInfo.newPoshmarkTitle.text !==  unescapedRowMetadata[133] &&

                       ( newTableInfo.newPoshmarkTitle.text !== newTableInfo.newTitle.text.substring(0, 80))
                           ? {}
                           : {
                               newPoshmarkTitle: {
                                   text: limitToMaxCharacters(localTitle, 80), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),

                   ...(
                       (newTableInfo.newGrailedTitle.changed ||
                           (unescapedRowMetadata[788])) &&

                       newTableInfo.newGrailedTitle.text !==  unescapedRowMetadata[133] &&

                       ( newTableInfo.newGrailedTitle.text !== newTableInfo.newTitle.text.substring(0, 60))
                           ? {}
                           : {
                               newGrailedTitle: {
                                   text:  limitToMaxCharacters(localTitle, 60), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),

                   ...(
                       (newTableInfo.newAmazonEbayTitle.changed ||
                           (unescapedRowMetadata[791])) &&

                       newTableInfo.newAmazonEbayTitle.text !==  unescapedRowMetadata[133] &&

                       ( newTableInfo.newAmazonEbayTitle.text !== newTableInfo.newTitle.text.substring(0, 80))
                           ? {}
                           : {
                               newAmazonEbayTitle: {
                                   text: limitToMaxCharacters(localTitle, 80), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),

                   ...(
                       (newTableInfo.newFacebookTitle.changed ||
                           (unescapedRowMetadata[869])) &&

                       newTableInfo.newFacebookTitle.text !==  unescapedRowMetadata[133] &&

                       ( newTableInfo.newFacebookTitle.text !== newTableInfo.newTitle.text.substring(0, 99))
                           ? {}
                           : {
                               newFacebookTitle: {
                                   text:  limitToMaxCharacters(localTitle, 99), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),

               });
               const updatedItem = [...itemData];
               updatedItem[editIndex] = {
                   ...updatedItem[editIndex],
                   ['newTitle']: localTitle,
                   changed: true,
               };
               setItemData(updatedItem)
               sendRequestForSuggestDescription('assistance');
               setAssistantTitle('');
               setAssistantFromTitle(true);
               setLocalTitle('');
               setAssistantScanTitle('');
           }
       }
       catch(e){
           console.log(e, 'error')
       }
    };

    const barcodeClick = () => {
        sendRequestForScan(assistantScanTitle);
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <div className={`${styles.listingAssistantMain} ${isModal ? styles.isModal : ''}`}>
            <div className={styles.listingAssistantContainer}>
                {scanShow ? (
                    <ScanComponent
                        setScanShow={setScanShow}
                        sendRequestForScan={sendRequestForScan}
                    />
                ) : loaderForSuggest || scanLoader ? (
                    <div className={styles.assistantLoader}>
                        {' '}
                        <MoonLoader color="#1e3166" loading={true} size={48} />
                    </div>
                ) : (
                    <>
                        <div className={styles.closeListingAssistant} onClick={() => changeListingAssistance(false)}>
                            <CloseModal/>
                        </div>
                        <h2 className={styles.assistHeading}><Lightbulb/> Listing Assistant </h2>
                        {
                            showNotifyImagesModal ?
                                <div>
                                    <p className={styles.notifyModalDesc}>Please upload a few images into the uploader to activate this feature and generate the listing details. </p>
                                </div>
                                :
                                <>
                                    {
                                        !hideDefaultModalItems && <p className={styles.assistDesc}>
                                            Use one of the options below and let LP create the listing for
                                            you!
                                        </p>
                                    }
                                    {
                                        !hideDefaultModalItems && <div className={styles.buttonsContainerForAssistant}>
                                            <button className={styles.assistantButtonChoice}
                                                    disabled={(availableTitle - localAvalaibleCount) === 0}
                                                    onClick={() => {
                                                        if (imagesFromUploader?.length) {
                                                            handleCreateFromImage()
                                                        } else {
                                                            setShowNotifyImagesModal(true)
                                                        }

                                            }}><UploadSvg/> Create from Images
                                            </button>
                                            <button disabled={(availableTitle - localAvalaibleCount) === 0} className={styles.assistantButtonChoice} onClick={toggleCreateFromTitle}>
                                                <KeyboardSvg/> Create from Title
                                            </button>
                                        </div>
                                    }
                                    {
                                        createFromTitle && <div className={styles.detailedDate}>
                                            <div className={styles.detailedTitleContainer}>
                                                <input
                                                    name="input_133"
                                                    value={bdecode(assistantTitle)}
                                                    maxLength={250} // Set the maximum character limit
                                                    className={styles.detailedDateInputTitle}
                                                    placeholder="Add a detailed title"
                                                    onChange={handleTitleChange}
                                                />
                                                <input
                                                    name="input_133"
                                                    type="button"
                                                    value="Submit"
                                                    className={styles.showSubmitBtn}
                                                    onClick={(e) => titleSubmit(e)}
                                                />
                                            </div>

                                        </div>
                                    }

                                    {
                                        !hideDefaultModalItems && <>
                                            {!localTitle.length && (
                                                <>
                                                    <p className={styles.charactersQuantity}>
                                                        {availableTitle - localAvalaibleCount} of {totalTitle} available
                                                    </p>
                                                    <div className={styles.or}>OR</div>

                                                    <div className={styles.scanInput}>
                                                        <div className={styles.scanInputContainer}>
                                                            {' '}
                                                            <input
                                                                className={styles.detailedDateInput}
                                                                style={{height: '40px'}}
                                                                placeholder="Scan or enter barcode"
                                                                onChange={handleScanTitleChange}
                                                            />
                                                            <button
                                                                className={styles.scanBtn}
                                                                onClick={() => setScanShow(true)}
                                                            >
                                                                Scan
                                                            </button>
                                                        </div>
                                                        {assistantScanTitle.length > 0 && (
                                                            <button
                                                                onClick={() => barcodeClick()}
                                                                className={styles.showSubmitBtn}
                                                            >
                                                                Submit
                                                            </button>
                                                        )}
                                                        <p className={styles.charactersQuantity}>
                                                            {barCodeLimits.available - localAvalaibleCount} of{' '}
                                                            {barCodeLimits.total} available
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    }

                                    {
                                        !hideDefaultModalItems && <div className={styles.submitButtons}>
                                            <button onClick={() => changeListingAssistance(false)}>
                                                List on my own
                                            </button>
                                            {
                                                <div className={styles.autoModalSwitcher}>
                                                    <span>Always Show: </span>
                                                    <input
                                                        type="checkbox"
                                                        style={{visibility: 'visible'}}
                                                        className={styles.myCheckbox}
                                                        name={'showAutoModal'}
                                                        checked={!hideAssistantModal}
                                                        onChange={handleChangeCheckbox}
                                                    />
                                                </div>
                                            }

                                        </div>
                                    }
                                </>
                        }


                    </>
                )}
            </div>
        </div>
    );
}

export default ListingAssistant;