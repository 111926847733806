import React from 'react';

import styles from './Loader.module.scss';

import { MoonLoader } from 'react-spinners';

interface ILoaderProps {
    maxHeight?:string;
}

const Loader = (props:ILoaderProps) => {
    const {maxHeight} = props ;
    const isSmallScreenLapTop = window.innerWidth < 1440 ;
    return (
     <div
      style={{
          width: '100%',
          height: maxHeight ? maxHeight : '50vh',
          // height: maxHeight ? (isSmallScreenLapTop ? Number(maxHeight) / 0.8 : maxHeight) : Number('50vh') / 0.8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
      }}
      className={styles.wrapper}
     >
         <div className={styles.loader}>
             <MoonLoader color="#1e3166" loading={true} />
             <p>Loading...</p>
         </div>
     </div>
    );
};

export default Loader;