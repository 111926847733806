import {PopoverIconWhite} from "../../../../assets/icons";
import Tippy from "@tippyjs/react";
import styles from './EditTemplatesContainer.module.scss';
import Select from 'react-select';
import React, {useEffect, useState} from "react";
import _ from "lodash";
import useBasisFuncs from "@hooks/useBasisFuncs";

const EditTemplatesContainer = ({selectedOptionTemplate, setSelectedOptionTemplate,  templates, setActiveTemplateId, setItemData, setFormInfo, editIndex, itemData, formInfo, isShowInDropDown, setIsShowInDropDown }:any) => {
    const [options, setOptions] = useState([{ value: 'Select Template', label: 'Select Template', id: null }]);
    const {bdecode} = useBasisFuncs();

    useEffect(() => {
        if(templates.length > 0){
            const filteredTemplates = _.filter(templates, (template: any) => {
                return _.get(template, 'metadata["133"]', null) !== null;
            });

            const newOptions = filteredTemplates.map((template: any) => {
                const value = template.metadata["133"]; // Direct access since we know it exists

                return {
                    value: bdecode(value),
                    label: bdecode(value),
                    id: template.id,
                };
            });

            setOptions([{ value: 'Select Template', label: 'Select Template', id: null }, ...newOptions]);
        }
    }, [templates]);


    const handleSelectChangeTemplate = (selectedOption: any) => {
        setSelectedOptionTemplate(selectedOption);
        setActiveTemplateId(selectedOption?.id);
    };

    const customStylesTemplates = {
        control: (provided: any) => ({
            ...provided,
            width: '100%',
            cursor: 'pointer'
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex: 9991
        }),
        option: (provided: any) => ({
            ...provided,
            wordBreak: 'break-word', // Ensure long words break within options
            whiteSpace: 'normal',    // Allow normal white space behavior for wrapping
        }),
    };

    return(
        <div className={styles.templatesHeader}>
            <div className={styles.header}>
                <div className={styles.selection}>
                    <p className={styles.selectionTitle}>
                        {' '}
                        Select a template for faster listing - (optional)
                        <Tippy
                            interactive={true}
                            zIndex={9991}
                            arrow={false}
                            trigger="click"
                            content={
                                <div className={`popover ${styles.popoverContent}`}>
                                    <ul>
                                        <li>
                                            Templates help to save time listing. Choose a saved
                                            template below.
                                        </li>
                                        <li>
                                            You can create your own from a listing you already
                                            have or from scratch.
                                        </li>
                                        <li>
                                            More info in our{' '}
                                            <a
                                                href="https:/app.listperfectly.com/docs/topics/lp-catalog-templates/"
                                                target="_blank"
                                            >
                                                Template Guide.
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            }
                        >
                            <PopoverIconWhite
                                className={styles.popoverIcon}
                                color={'#fff'}
                            ></PopoverIconWhite>
                        </Tippy>
                    </p>

                    <div className={styles.selectInput}>
                        <Select
                            name="input_673"
                            inputId="input_673"
                            value={selectedOptionTemplate}
                            onChange={handleSelectChangeTemplate}
                            options={options}
                            getOptionValue={(option:any) => option?.id?.toString()} // Use id as the option value
                            getOptionLabel={(option:any) => option?.label}
                            styles={customStylesTemplates}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditTemplatesContainer;