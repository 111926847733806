import {AccArrowInactive, AccordionArrow, PopoverIcon, PopoverIconWhite} from "../../../../../assets/icons";
import styles from './AccordionHeaderComponent.module.scss';
import Tippy from "@tippyjs/react";

const AccordionHeaderComponent = ({accIndex, accTitle, setActiveIndices, activeIndices} :any) => {

    const handleAccordionClick = (index: number) => {
        if (activeIndices.includes(index)) {
            setActiveIndices(activeIndices.filter((i:any) => i !== index));
        } else {
            setActiveIndices([...activeIndices, index]);
        }
    };

    return(
        <div>
            <div
                className={`${styles.acordionHeader} ${
                    activeIndices.includes(accIndex) ? styles.accordionActive : ''
                }`}
                onClick={() => handleAccordionClick(accIndex)}
            >
                <p>
                    {accTitle}
                    {
                        accIndex === 1 ? (
                            <Tippy
                                interactive={true}
                                zIndex={5}
                                trigger="mouseenter"
                                arrow={false}
                                content={
                                    <div className={`popover ${styles.popoverContent}`}>
                                        <>
                                            <ul>
                                                <li>
                                                    The Pro plan offers the most extensive{' '}
                                                    <a
                                                        href="https://app.listperfectly.com/docs/topics/lp-catalog-templates/"
                                                        target="_blank"
                                                    >
                                                        templating
                                                    </a>{' '}
                                                    in List Perfectly. Fill in as much or as little
                                                    detail as you'd like, and we will format your
                                                    descriptions for you with customizable bullets,
                                                    measurements automatically converted for your
                                                    international buyers, and more.
                                                    <ul>
                                                        <li>
                                                            Check our{' '}
                                                            <a
                                                                href="https://app.listperfectly.com/docs/topics/pro-plan/"
                                                                target="_blank"
                                                            >
                                                                Pro Plan guide
                                                            </a>{' '}
                                                            for more information and instructions
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <div className={styles.line}></div>
                                            <p>
                                                ♦ Pro{' '}
                                                <a
                                                    href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
                                                    target="_blank"
                                                >
                                                    {' '}
                                                    Description Builder
                                                </a>{' '}
                                                section
                                            </p>
                                        </>
                                    </div>
                                }
                            >
                                {activeIndices.includes(1) ? (
                                    <PopoverIconWhite
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIconWhite>
                                ) : (
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                )}
                            </Tippy>
                        ) : accIndex === 5 ? (
                            <Tippy
                                interactive={true}
                                zIndex={5}
                                trigger="mouseenter"
                                arrow={false}
                                content={
                                    <div className={`popover ${styles.popoverContent}`}>
                                        <ul>
                                            <li>
                                                Customize your listing and Pro Description
                                                builder settings.
                                            </li>
                                            <li>
                                                All customizations can be
                                                <a
                                                    href="https://app.listperfectly.com/docs/topics/lp-catalog-templates/"
                                                    target="_blank"
                                                > templated.
                                                </a>
                                            </li>
                                        </ul>
                                        <div className={styles.line}></div>
                                        <p className={styles.popoverCustomizations}>
                                            <span>♦Pro</span>  <a
                                            href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
                                            target="_blank"
                                        > <span>Description Builder</span></a>
                                            customizations
                                        </p>
                                    </div>
                                }
                            >
                                {activeIndices.includes(5) ? (
                                    <PopoverIconWhite
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIconWhite>
                                ) : (
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                )}
                            </Tippy>
                        ) : ''

                    }

                </p>
                {activeIndices.includes(accIndex) ? (
                    <AccordionArrow className={styles.accordionArrow} />
                ) : (
                    <AccArrowInactive className={styles.accordionArrow} />
                )}
            </div>
        </div>
    )
}

export default AccordionHeaderComponent;