import styles from './Mobileslider.module.scss';
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slickstyles.css';
import {
  ArrowLeftSlick,
  ArrowRightSlick,
  SVGIssuesIcon,
} from '../../../../assets/icons';
import { MoonLoader } from 'react-spinners';
import { useSelector } from 'react-redux';

const MobileSlider: React.FC<any> = ({
  handleChangeActiveTab,
  handleTabsClick,
  tab,
  tabCounts2,
  tabs,
  completedRequestsCount,
}) => {
  const [activeItem, setActiveItem] = useState('item1');
  function handleItemClick(item: any) {
    setActiveItem(item);
  }
  const hasSimple = useSelector((state: any) => state.roles.hasSimple);
  const hasBusiness = useSelector((state: any) => state.roles.hasBusiness);

  let sliderRef: any = React.createRef();
  const settings = {
    dots: true, // Enable dots navigation
    infinite: false, // Loop the slider
    speed: 500,
    spacing: 10,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false, // Enable navigation arrows
  };
  const handlePrevClick = () => {
    sliderRef.current.slickPrev(); // Call the slickPrev method to navigate to the previous slide
  };

  const filteredTabs: any = tabs.filter(
    (tab: any) =>
      tab.tab === 'view all' || tab.tab === 'issues' || tab.tab === 'sold'
  );
  const localTabs = hasSimple || hasBusiness ? filteredTabs : tabs;
  const handleNextClick = () => {
    sliderRef.current.slickNext(); // Call the slickNext method to navigate to the next slide
  };
  return (
    <div className={styles.MobileSliderContainer}>
      <div
        className={`slider-container slider-custom-container ${styles.SliderContainer}`}
      >
        <Slider {...settings} ref={sliderRef}>
          {localTabs?.map((item: any) =>
            item.tab === 'issues' ? (
              <li
                key={item.tab}
                style={{ flexDirection: 'row' }}
                onClick={() => {
                  handleChangeActiveTab(item.tab);
                  handleTabsClick(item.tab);
                }}
                className={
                  item.tab === tab ? styles.activeTab : styles.sliderItem
                }
              >
                <div className={styles.Issues}>
                  <SVGIssuesIcon
                    className={item.tab === tab ? 'issueTabActive' : ''}
                  />
                  <span className={styles.sliderItemTitle}>Issues</span>
                  {completedRequestsCount > 0 && (
                    <div className="issueTabDot"></div>
                  )}
                </div>
                <span
                  className={
                    tab === item.tab ? styles.activeTabSpan : undefined
                  }
                ></span>
              </li>
            ) : item.tab === 'out of stock' ? (
              <li
                onClick={() => {
                  handleChangeActiveTab(item.tab);
                  handleTabsClick(item.tab);
                }}
                className={
                  item.tab === tab ? styles.activeTab : styles.sliderItem
                }
              >
                <span
                  className={`${
                    tab === item.tab
                      ? `${styles.activeTabSpan} ${styles.removeCapitalize}`
                      : styles.removeCapitalize
                  } ${styles.sliderItemTitle}`}
                >
                  Out of Stock
                </span>
                <span
                  style={{
                    color: tab === 'out of stock' ? '#fff' : '',
                  }}
                  className={styles.sliderItemQuantity}
                >
                  {tabCounts2[item.tab]?.loader ||
                  tabCounts2['out of stock']?.loader ||
                  tabCounts2['all']?.loader ? (
                    <MoonLoader color="#1e3166" loading={true} size={18} />
                  ) : (
                    tabCounts2[item.tab]?.count
                  )}
                </span>
              </li>
            ) : (
              <li
                onClick={() => {
                  handleChangeActiveTab(item.tab);
                  handleTabsClick(item.tab);
                }}
                className={
                  item.tab === tab ? styles.activeTab : styles.sliderItem
                }
              >
                <span
                  className={`${
                    tab === item.tab ? styles.activeTabSpan : undefined
                  }  ${styles.sliderItemTitle}`}
                >
                  {item.tab === 'view all' ? 'All' : item.tab}
                </span>
                <span
                  style={{
                    color: item.tab === tab ? '#fff' : '',
                  }}
                  className={styles.sliderItemQuantity}
                >
                  {tabCounts2[item.tab]?.loader ||
                  tabCounts2['out of stock']?.loader ||
                  tabCounts2['all']?.loader ? (
                    <MoonLoader
                      color={item.tab === tab ? '#fff' : '#1e3166'}
                      loading={true}
                      size={18}
                    />
                  ) : (
                    tabCounts2[item.tab]?.count
                  )}
                </span>
              </li>
            )
          )}
        </Slider>
      </div>
      <div className={styles.customArrows}>
        {!hasSimple && !hasBusiness && (
          <div className={styles.leftArrow} onClick={handlePrevClick}>
            <ArrowLeftSlick />
          </div>
        )}
        {!hasSimple && !hasBusiness && (
          <div className={styles.rightArrow} onClick={handleNextClick}>
            <ArrowRightSlick />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSlider;
