import EditAndAddListingModal from "../../components/EditAddListingModal";
import Iframe from "../../components/UI/Iframe";

const AddListingPage = () => {
    return(
        <>
            <Iframe />
            <EditAndAddListingModal /></>
    )
}

export default AddListingPage;