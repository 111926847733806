import React, {useLayoutEffect, useState} from 'react';
import _ from "lodash";
import {MoonLoader} from "react-spinners";
import {useDispatch, useSelector} from "react-redux";
import styles from "../../../../Table.module.scss";

// Import config
import config from "@config";

// Import hooks
import useRandomNumber from "@hooks/UseRandomNumber";
import useBasicFuncs from "@hooks/useBasisFuncs";

// Import icons
import { SoldTagSvg, AmazonLogo, EbayLogo, FaImage, LinkedSVG, ListingPerfectlyNotActiveLogo, MarkSoldSVG, MercaryLogo, NotLinkedSvg, PoshmarkLogo } from "@assets/icons";
import { depopLogo, etsyLogo, facebookLogo, grailedLogo, instagramLogo, kidizenLogo, listingPartyLogo, shopifyLogo, vestiaireLogo } from "@assets/constants/platformsLogo";

// TODO looks like this one isn't in the right folder
import PlatformBlockItem from "../../../../../../../CatalogPage/components/Table/components/PlatformBlockItem";

// Import interfaces
import {INestedTableRowProps} from "@interfaces/index";

// Something related to redux
import { iframeLoaderChanger, setCandidantLinkPure, setIframeLink, setIframeName, setShowIframe, setShowQuestionModal } from "../../../../../../../../redux/slices/NavigationSlice";



const NestedTableRow = (props: INestedTableRowProps) => {

	const {
		itemTabledata,
		columns,
		trClass,
		handleToogleShowCatalogModalShow,
		buttonRef,
		isBulkEdit,
		newTableInfo,
		setNewTableInfo,
		tableData,
		setTableData,
		index,
		nestedIndex,
		setSelectedListing,
		retryActive,
		setRetryActive,
		saveActive,
		setSaveActive,
	 	hasBusiness,
		hasPro,
	 	is_role_allowed_master
	} = props ;
	const screenWidth = window.innerWidth ;
	const iframeLink = useSelector(
	 (state: any) => state.navigation.iframeLink
	);
	const dispatch = useDispatch()

	const [dimensions, setDimensions] = useState<string | null>(null);
	const [loaderForImage, setLoaderForImage] = useState(true);

	const randomNumber = useRandomNumber()
	const { bdecode } = useBasicFuncs()

	const handleSendClickToMarkButton = () => {
		if (buttonRef?.current) {
			buttonRef?.current.click();
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const charCode = event.charCode;

		if ((charCode < 48 || charCode > 57) && charCode !== 46) {
			event.preventDefault();
		}
	};


	useLayoutEffect(() => {
		const getImageDimensions = (src: string | undefined) => {
			try {
				if (src) {
					const img = new Image();
					img.src = `${config.orders_image_prefix}/${src}`;

					img.onload = () => {
						if (img.width >= img.height) {
							setDimensions('width');
						} else {
							setDimensions('height');
						}
						setLoaderForImage(false);
					};
				}
			} catch (e) {
				console.log('e');
				setLoaderForImage(false);
			}
		};
		getImageDimensions(_.get(itemTabledata,'thumbnail'));
	}, []);

	// TODO. This one looks like a copy of the similar function in parent component.
	const handleChangeEditInputOrdersListings = (e: | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>, listingIndex: number) => {
		const {name, value} = e.target;
		const updatedItems = [...tableData];
		if (retryActive) {
			setRetryActive(false)
		}
		if (!saveActive) {
			setSaveActive(true)
		}
		const updatedItem = {
			...updatedItems[index],
			listings: updatedItems[index].listings.map((listing: any, i: number) => {
				if (i === listingIndex) {
					return {
						...listing,
						[name]: value,
						multiItem: true, // Устанавливаем multiItem в true только при изменении данных в textarea внутри listings
					};
				}
				return listing;
			}),
		};

		const updatedTableData = updatedItems.map((item, idx) => {
			if (idx === index) {
				return updatedItem;
			}
			return item;
		});
		setNewTableInfo({
			...newTableInfo,
			[name]: {text: value, changed: true},
		});

		setTableData(updatedTableData);
	};

	const unescapedRowMetadataInnerListingFunc = (itemTableData: any) => {
		const innerListings: Record<string, any> = {};
		if (itemTableData?.catalog_metadata) {
			for (const key in itemTableData.catalog_metadata) {
				if (itemTableData.catalog_metadata.hasOwnProperty(key)) {
					innerListings[key] = _.get(itemTableData.catalog_metadata, key);
				}
			}
		}
		return innerListings;
	};

	// TODO. Temporary solution for rendering table cells(tds). Start
	const render_table_td = (column_item: any) => {


		// Init
		let TD_Element_Content;
		let TD_Element_Props : any = {};
		const order_listing_property_code = column_item.value;
		// const new_order_listing_property_code = `new__${order_listing_property_code}`;

		// If bulk edit is NOT ACTIVATED.
		if(!isBulkEdit){

			if(order_listing_property_code === 'cogs'){
				TD_Element_Content = (itemTabledata[`newMultiItemCogs${itemTabledata.id}`]
					?.length ? (
					`$${Number(
						itemTabledata[`newMultiItemCogs${itemTabledata.id}`]
					).toFixed(2)}`
				) : itemTabledata[order_listing_property_code] ? (
					`$${Number(itemTabledata[order_listing_property_code])}`
				) : (
					'-'
				));
			}

			if(order_listing_property_code === 'quantity_sold'){
				TD_Element_Content = (
					itemTabledata[
						`newMultiItemQuantitySold${itemTabledata.id}`
						]?.length ? (
						itemTabledata[
							`newMultiItemQuantitySold${itemTabledata.id}`
							]
					) : itemTabledata[order_listing_property_code] ? (
						itemTabledata[order_listing_property_code]
					) : (
						'-'
					)
				);
			}


			if(order_listing_property_code === 'sku'){
				TD_Element_Content = (
					itemTabledata[`newMultiItemSku${itemTabledata.id}`]
						?.length >= 0 ? (
						itemTabledata[`newMultiItemSku${itemTabledata.id}`]
					) : itemTabledata[order_listing_property_code] ? (
						itemTabledata[order_listing_property_code]
					) : (
						'-'
					)
				);
			}

		}

		// If bulk edit is ACTIVATED.
		if(isBulkEdit){

			if(order_listing_property_code === 'cogs'){
				TD_Element_Content = (
					<input
						className="myInput  no-spinner"
						type="text"
						name={`newMultiItemCogs${itemTabledata.id}`}
						value={
							newTableInfo[`newMultiItemCogs${itemTabledata.id}`]
								?.changed
								? newTableInfo[
									`newMultiItemCogs${itemTabledata.id}`
									]?.text
								: newTableInfo[
								`newMultiItemCogs${itemTabledata.id}`
								]?.text || itemTabledata[order_listing_property_code]
						}
						onChange={(e) =>
							handleChangeEditInputOrdersListings(e, nestedIndex)
						}
					/>
				);

			}

			if(order_listing_property_code === 'quantity_sold'){
				TD_Element_Content = (

					<input
						className="myInput  no-spinner"
						type="text"
						name={`newMultiItemQuantitySold${itemTabledata.id}`}
						onKeyPress={handleKeyPress}
						value={
							newTableInfo[
								`newMultiItemQuantitySold${itemTabledata.id}`
								]?.changed
								? newTableInfo[
									`newMultiItemQuantitySold${itemTabledata.id}`
									]?.text
								: newTableInfo[
								`newMultiItemQuantitySold${itemTabledata.id}`
								]?.text || itemTabledata[order_listing_property_code]
						}
						onChange={(e) =>
							handleChangeEditInputOrdersListings(e, nestedIndex)
						}
					/>

				);
			}

			if(order_listing_property_code === 'sku'){
				TD_Element_Content = (
					<textarea
						className="myInputTextArea"
						name={`newMultiItemSku${itemTabledata.id}`}
						value={
							newTableInfo[`newMultiItemSku${itemTabledata.id}`]
								?.changed
								? newTableInfo[
									`newMultiItemSku${itemTabledata.id}`
									]?.text
								: newTableInfo[
								`newMultiItemSku${itemTabledata.id}`
								]?.text || itemTabledata[order_listing_property_code]
						}
						onChange={(e) =>
							handleChangeEditInputOrdersListings(e, nestedIndex)
						}
					/>
				);
			}

		}

		// Exceptions:
		if(order_listing_property_code === 'title'){
			TD_Element_Content = (<div>{bdecode(itemTabledata[order_listing_property_code])}</div>);
		}
		if(order_listing_property_code === 'quantity_available'){

			let quantity_available = _.get(itemTabledata, order_listing_property_code);
			if(quantity_available) quantity_available = _.toNumber(quantity_available);
			quantity_available = _.isFinite(quantity_available) ? quantity_available : '-';
			TD_Element_Content = (<div style={{cursor: 'text'}}>{quantity_available}</div>);

		}



		// Return generated cell(td)
		return (<td key={order_listing_property_code} {...TD_Element_Props}>{TD_Element_Content}</td>);

	};
	// TODO. Temporary solution for rendering table cells(tds). End

	return (
	 <tr
	  className={trClass}
	 >
		 <td></td>
		 {columns?.map((nestedColumnItem: any) => (
			 // TODO. that's just temporary workaround to keep code readable, later it needs to replaced:
			 (nestedColumnItem.value === 'title' ? (render_table_td(nestedColumnItem)) : null) ||
			 (nestedColumnItem.value === 'cogs' ? (render_table_td(nestedColumnItem)) : null) ||
			 (nestedColumnItem.value === 'quantity_sold' ? (render_table_td(nestedColumnItem)) : null) ||
			 (nestedColumnItem.value === 'quantity_available' ? (render_table_td(nestedColumnItem)) : null) ||
			 (nestedColumnItem.value === 'sku' ? (render_table_td(nestedColumnItem)) : null) ||
			 (<td key={nestedColumnItem.value}>
				 {nestedColumnItem.value === 'actions' ? (
					 <div className={styles.actionButtons}>
						 {itemTabledata.catalog_id ? (
							 <div
								 style={{border: '1px solid var(--Mint, #4DB89E)'}}
								 className={styles.actionColumnSvg}
								 onClick={() => {
									 setSelectedListing(itemTabledata);
									 handleToogleShowCatalogModalShow();
								 }}
							 >
								 <LinkedSVG/>
							 </div>
						 ) : (
							 <div
								 style={{border: ' 1px solid var(--Red, #CA1010)'}}
								 className={styles.actionColumnSvg}
								 onClick={() => {
									 setSelectedListing(itemTabledata);
									 handleToogleShowCatalogModalShow();
								 }}
							 >
								 <NotLinkedSvg/>
							 </div>
						 )}
						 {_.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.1'
							 ) !== 'SOLD' &&
							 _.get(itemTabledata, 'catalog_id') && (
								 <div
									 style={{
										 border: '1px solid var(--Red, #CA1010)',
									 }}
									 className={`${
										 styles.actionColumnSvg
									 } markSoldButton ${
									  !_.isNil(_.get(itemTabledata, 'catalog_id')) && ((hasPro && is_role_allowed_master) || hasPro || hasBusiness)
									   ? 'linked'
											 : ''
									 }`}
									 onClick={handleSendClickToMarkButton}
								 >
									 <MarkSoldSVG/>
								 </div>
							 )}
						 {!_.isNil(_.get(itemTabledata, 'catalog_id')) && (
							 <button
								 ref={buttonRef}
								 style={{
									 visibility: 'hidden',
									 height: 0,
									 width: 0,
									 padding: 0,
									 margin: 0,
									 border: 0,
									 marginRight: -10,
								 }}
								 className="markSoldIcon"
							 >
							 </button>
						 )}
					 </div>
				 ) : nestedColumnItem.value === 'image' ? (
					 <div
						 key={nestedColumnItem.value}
						 className={styles.thumbnail}
						 style={{
							 display: 'flex',
							 alignItems: 'center',
							 justifyContent: 'center',
						 }}
					 >
						 {_.get(itemTabledata,'thumbnail') ? (
							 loaderForImage ? (
								 <MoonLoader
									 color={'#1e3166'}
									 loading={true}
									 size={18}
								 />
							 ) : (
								 <img
									 src={`${config.orders_image_prefix}/${_.get(itemTabledata,'thumbnail')}?rnd=${randomNumber}`}
									 alt={dimensions ? dimensions : ""}
									 style={{
										 width: dimensions === 'width' ? (screenWidth > 1440 ? '100px' : '80px') : 'auto',
										 height:
											 dimensions === 'height' ? (screenWidth > 1440 ? '100px' : '80px'): 'auto',
									 }}
								 />
							 )
						 ) : (
							 <FaImage/>
						 )}
					 </div>
				 ) : nestedColumnItem.value === 'linked' ? (
					 <div style={{cursor: 'text'}}>

						 {itemTabledata.catalog_id ? (
							 <div
								 className={styles.linked}
								 onClick={(e: any) => {
									 e.preventDefault();
									 if (!iframeLink.length) {
										 dispatch(setIframeName('title'));
										 dispatch(setShowIframe());
										 dispatch(iframeLoaderChanger(true));
										 dispatch(
											 setIframeLink(
												 `listperfectly.com/listings/single/view/${itemTabledata.catalog_id}`
											 )
										 );
									 } else {
										 dispatch(
											 setShowQuestionModal(
												 `https://app.listperfectly.com/products/view/${itemTabledata.catalog_id}`
											 )
										 );
										 dispatch(
											 setCandidantLinkPure(
												 `listperfectly.com/listings/single/view/${itemTabledata.catalog_id}`
											 )
										 );
									 }
								 }}
							 >
								 <ListingPerfectlyNotActiveLogo/>
							 </div>
						 ) : (
							 '-'
						 )}
					 </div>
				 ) : nestedColumnItem.value === 'platforms' ? (
					 <div className={styles.platformsWrapper}>
						 {(itemTabledata?.newSOLD === 'SOLD' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.1'
							 ) === 'SOLD') && (
							 <PlatformBlockItem
								 platformName={'SOLD'}
								 sync={itemTabledata?.sync}
								 href=""
								 platformIcon={SoldTagSvg}
								 isLink={false}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata.newListing_party === 'Listing Party' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.16'
							 ) === 'Listing Party') && (
							 <PlatformBlockItem
								 platformName={'listing_party'}
								 href={`https://listingparty.com/listings?id=${itemTabledata.id}`}
								 platformIcon={listingPartyLogo}
								 isLink={true}
								 IsSold={_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '539.1'
								 )}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.neweBay === 'eBay' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.2'
							 ) === 'eBay') && (
							 <PlatformBlockItem
								 platformName={'eBay'}
								 href={`https://ebay.com/itm/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '618'
								 )}`}
								 platformIcon={EbayLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '618'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.2'
									 )
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newEtsy === 'Etsy' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.3'
							 )) && (
							 <PlatformBlockItem
								 platformName={'Etsy'}
								 href={`https://www.etsy.com/listing/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '619'
								 )}}`}
								 platformIcon={etsyLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '619'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '[539.3'
									 ) === 'Etsy'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newPoshmark === 'Poshmark' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.4'
							 ) === 'Poshmark') && (
							 <PlatformBlockItem
								 platformName={'Poshmark'}
								 href={`https://poshmark.com/listing/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '620'
								 )}`}
								 platformIcon={PoshmarkLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '620'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.4'
									 ) === 'Poshmark'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newMercari === 'Mercari' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.5'
							 ) === 'Mercari') && (
							 <PlatformBlockItem
								 platformName={'Mercari'}
								 href={`https://www.mercari.com/us/item/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '621'
								 )}`}
								 platformIcon={MercaryLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '621'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.5'
									 ) === 'Mercari'
								 }
								 fromPlatform={true}
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newAmazon === 'Amazon' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.7'
							 ) === 'Amazon') && (
							 <PlatformBlockItem
								 platformName={'Amazon'}
								 href={`https://www.amazon.com/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '836'
								 )}`}
								 platformIcon={AmazonLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '836'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.7'
									 ) === 'Amazon'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newGrailed === 'Grailed' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.8'
							 ) === 'Grailed') && (
							 <PlatformBlockItem
								 platformName={'Grailed'}
								 href={`https://www.grailed.com/listings/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '839'
								 )}`}
								 platformIcon={grailedLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '839'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.8'
									 ) === 'Grailed'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newFacebook === 'Facebook' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.9'
							 ) === 'Facebook') && (
							 <PlatformBlockItem
								 platformName={'Facebook'}
								 href={`https://www.facebook.com/marketplace/category/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '840'
								 )}/`}
								 platformIcon={facebookLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '840'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.9'
									 ) === 'Facebook'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newDepop === 'Depop' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.11'
							 ) === 'Depop') && (
							 <PlatformBlockItem
								 platformName={'Depop'}
								 href={`https://www.depop.com/products/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '841'
								 )}`}
								 platformIcon={depopLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '841'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.11'
									 ) === 'Depop'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newKidizen === 'Kidizen' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.12'
							 ) === 'Kidizen') && (
							 <PlatformBlockItem
								 platformName={'Kidizen'}
								 href={`https://www.kidizen.com/items/${

									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '853'
									 )
								 }`}
								 platformIcon={kidizenLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '853'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.12'
									 ) === 'Kidizen'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newShopify === 'Shopify' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.13'
							 ) === 'Shopify') && (
							 <PlatformBlockItem
								 platformName={'Shopify'}
								 href={`https://accounts.shopify.com/lookup?rid=70f0f0e2-3f19-4767-849c-f8e779c0005a`}
								 platformIcon={shopifyLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '855'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.13'
									 ) === 'Shopify'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newInstagram === 'Instagram' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc(itemTabledata),
								 '539.14'
							 ) === 'Instagram') && (
							 <PlatformBlockItem
								 platformName={'Instagram'}
								 href={`https://www.instagram.com/p/${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '856'
								 )}/`}
								 platformIcon={instagramLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '856'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.14'
									 ) === 'Instagram'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
						 {(itemTabledata?.newVestiaire === 'Vestiaire' ||
							 _.get(
								 unescapedRowMetadataInnerListingFunc('539.17')
							 ) === 'Vestiaire') && (
							 <PlatformBlockItem
								 platformName={'Vestiaire'}
								 href={`https://vestiairecollective.com/'${_.get(
									 unescapedRowMetadataInnerListingFunc(itemTabledata),
									 '885'
								 )}.shtml`}
								 platformIcon={vestiaireLogo}
								 isLink={
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '885'
									 ) &&
									 _.get(
										 unescapedRowMetadataInnerListingFunc(
											 itemTabledata
										 ),
										 '539.17'
									 ) === 'Vestiaire'
								 }
								 fromPlatform={true}
								 type="sales"
								 hasTitle={true}
							 />
						 )}
					 </div>
				 ) : (
						 <div>{itemTabledata[nestedColumnItem.value]}</div>
					 )}
			 </td>)


		 ))}
	 </tr>
	)
}

export default NestedTableRow
