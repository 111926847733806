import React from 'react';
import styles from './SettingsHeader.module.scss';
import { DownArrow } from '../../../../assets/icons';
import { GreenUpSvg, WhiteDownSvg } from '../../../../assets/icons';
interface ITitleProps {
	text: string;
	activeClass: boolean;
	titleClick: () => void;
	isMobile?:boolean;
	isExtensionTab?:boolean;
}

const SettingsHeader = (props: ITitleProps) => {
	const { text, activeClass, titleClick, isMobile,isExtensionTab } = props;
	return (
	 <div
	  style={{
		  background: activeClass ? '#4DB89E' : '#EDEDED',
		  color: activeClass ? '#fff' : '#393838',
	  }}
	  className={`${styles.titleBlock} ${isMobile && isExtensionTab ? styles.mobileExtensionSettings : null} `}
	  onClick={titleClick}
	 >
		 <div>
			 <h3>{text}</h3>
			 {
			  (isMobile && isExtensionTab) && <p>Desktop only, unavailable on mobile.</p>
			 }</div>
		 <span>{!activeClass || (isMobile && isExtensionTab) ?  <GreenUpSvg /> :<WhiteDownSvg />}</span>
	 </div>
	);
};

export default SettingsHeader;