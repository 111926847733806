import { useState, useEffect } from 'react';
import axios from 'axios';
import getToken from '../../utils/getToken';

interface QuotasData {
    available: number;
    total: number;
    used: number;
}
const useQuotasTotal = () => {
    const token = getToken();
    const [quotasData, setQuotasData] = useState<QuotasData | null>(null);

    useEffect(() => {
        const axiosConfig = {
            method: 'POST',
            url: 'https://api.listperfectly.com/v1/listings/tools/quotas/list',
            data: { filter_by: { name: 'generator' } },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };

        axios(axiosConfig)
            .then((response) => {
                const { available, total, used } = response?.data?.data[0];
                setQuotasData({ available, total, used });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return quotasData;
};

export default useQuotasTotal;