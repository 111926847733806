export function getPlatformLink(title: string, value: string): string | null {
  let link: string | null = null;

  if (title === 'eBay') {
    if (value) {
      link = `https://ebay.com/itm/${value}`;
    } else {
      link = 'https://ebay.com';
    }
  } else if (title === 'Etsy') {
    if (value) {
      link = `https://www.etsy.com/listing/${value}`;
    } else {
      link = 'https://www.etsy.com';
    }
  } else if (title === 'Poshmark') {
    if (value) {
      link = `https://poshmark.com/listing/${value}`;
    } else {
      link = 'https://poshmark.com';
    }
  } else if (title === 'Mercari') {
    if (value) {
      link = `https://www.mercari.com/us/item/${value}`;
    } else {
      link = 'https://www.mercari.com';
    }
  } else if (title === 'Amazon') {
    if (value) {
      link = `https://www.amazon.com/${value}`;
    } else {
      link = 'https://www.amazon';
    }
  } else if (title === 'Grailed') {
    if (value) {
      link = `https://www.grailed.com/listings/${value}`;
    } else {
      link = 'https://www.grailed.com';
    }
  } else if (title === 'Facebook') {
    if (value) {
      link = `https://www.facebook.com/marketplace/${value}`;
    } else {
      link = 'https://www.facebook.com';
    }
  } else if (title === 'Depop') {
    if (value) {
      link = `https://www.depop.com/products/${value}`;
    } else {
      link = `https://www.depop.com`;
    }
  } else if (title === 'Kidizen') {
    if (value) {
      link = `https://www.kidizen.com/items/${value}`;
    } else {
      link = 'https://www.kidizen';
    }
  } else if (title === 'Instagram') {
    if (value) {
      link = `https://www.instagram.com/p/${value}`;
    } else {
      link = 'https://www.instagram';
    }
  } else if (title === 'Vestiaire') {
    if (value) {
      link = `https://vestiairecollective.com/${value}`;
    } else {
      link = 'https://vestiairecollective.com';
    }
  } else if (title === 'Listing Party') {
    link = 'https://listingparty.com/';
  } else if (title === 'Shopify') {
    if (value) {
      link = `https://admin.shopify.com/store/listperfectly/products/${value}`;
    } else {
      link = 'https://admin.shopify.com';
    }
  }

  return link;
}
