import styles from './AvalaiblePlatformItem.module.scss';
import { ReactComponent as EbayLogo } from '../../../../assets/icons/svg/EbayIconUpdated.svg';
import { ReactComponent as AmazonLogo } from '../../../../assets/icons/svg/AmazonIconUpdated.svg';
import { ReactComponent as GrailedLogo } from '../../../../assets/icons/svg/GreiledIconUpdated.svg';
import { ReactComponent as ShopifyLogo } from '../../../../assets/icons/svg/ShopifyUpdatedICon.svg';
import { ReactComponent as EtsyLogo } from '../../../../assets/icons/svg/EtsyIconUpdated.svg';
import { ReactComponent as DepopLogo } from '../../../../assets/icons/svg/DepopIconUpdated.svg';
import { ReactComponent as PoshmarkLogo } from '../../../../assets/icons/svg/PoshmarkIconUpdated.svg';
import { ReactComponent as KitidzenLogo } from '../../../../assets/icons/svg/KitidzenIconUpdated.svg';
import { ReactComponent as FacebookLogo } from '../../../../assets/icons/svg/FacebookIconUpdated.svg';
import { ReactComponent as VestiaireLogo } from '../../../../assets/icons/svg/VestiaireIconUpdated.svg';
import { ReactComponent as InstagramLogo } from '../../../../assets/icons/svg/InstagramIconUpdated.svg';
import { ReactComponent as MercaryLogo } from '../../../../assets/icons/svg/MercaryIconUpdated.svg';
import React from 'react';
const AvalaiblePlatformItem = (props: any) => {
	const { marketName } = props;
	return (
	 <div className={styles.listItemContainer}>
		 <div className={styles.image}>
			 {marketName.toLowerCase() === 'ebay' ? (
			  <EbayLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'etsy' ? (
			  <EtsyLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'poshmark' ? (
			  <PoshmarkLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'mercari' ? (
			  <MercaryLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'grailed' ? (
			  <GrailedLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'facebook' ? (
			  <FacebookLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'depop' ? (
			  <DepopLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'kidizen' ? (
			  <KitidzenLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'shopify' ? (
			  <ShopifyLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'instagram' ? (
			  <InstagramLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'vestiaire' ? (
			  <VestiaireLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'amazon' ? (
			  <AmazonLogo height={50} width={50} />
			 ) : null}
		 </div>
	 </div>
	);
};

export default AvalaiblePlatformItem;