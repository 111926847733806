import React, {useState} from 'react' ;
import styles from './CustomSkuPrefix.module.scss' ;
import Tippy from "@tippyjs/react";
import {QuestionIcon} from "../../../../assets/icons";
import {useSelector} from "react-redux";

interface ICustomSkuPrefix {
	customSkuPrefix:string;
	handleChangeCustomSkuPrefix:(e:any) => void;
}

const CustomSkuPrefix = (props:ICustomSkuPrefix)  => {
	const {customSkuPrefix,handleChangeCustomSkuPrefix} = props ;
	const isMobile = useSelector((state: any) => state.navigation.isMobile);

	const [isClickedQuestionIcon,setIsClickedQuestionIcon] = useState(false)

	const [isFocused, setIsFocuced] = useState(false)

	const limitExceeded = (customSkuPrefix || '').length > 3

	return (
	 <div className={styles.customSkuPrefix}>
		 <div className={styles.tippyBlock}>
			 <div className={styles.title}>custom SKU prefix</div>
			 <Tippy
			  interactive={true}
			  zIndex={5}
			  arrow={false}
			  trigger="focus click"
			  content={
				  <p>Enter a 3-character identifier to customize your SKU generator. This prefix can represent a specific location, category, or other identifiers to help organize your inventory.</p>
			  }

			  placement={isMobile ? 'bottom-end' : 'right-end'}
			  className={styles.toolTip}
			  onClickOutside={() => setIsClickedQuestionIcon(false)}
			 >
				 <div>
					 <QuestionIcon
					  // className={`${styles.questionSvg} ${isClickedQuestionIcon ? styles.isClickedQuestionClass : ""}`}
					  onClick={() => setIsClickedQuestionIcon(!isClickedQuestionIcon)}
					  style={{
						  cursor:'pointer'
					  }}
					 />
				 </div>
			 </Tippy>
		 </div>
		 <div className={styles.skuPrefixBlock}>
			 <div className={styles.inputBlock}>
				 <input
				  value={customSkuPrefix?.toUpperCase() || ''}
				  onChange={(e) => {
					  setIsFocuced(true)
					  handleChangeCustomSkuPrefix(e)
				  }}
				  onFocus={()=>setIsFocuced(true)}
				  onBlur={() =>setIsFocuced(false)}
				 />
				 {isFocused && <p className={limitExceeded ? styles.limitTextDanger : ""}>{customSkuPrefix?.length}/3
					 {limitExceeded ? ' - Limit Exceeded' : ""}
				 </p>}
			 </div>
		 </div>
	 </div>
	)
}

export default CustomSkuPrefix