import React, { useEffect, useState, useRef } from 'react';
import styles from './AutoGrowHeightTextarea.module.scss';

export const AutoGrowHeightTextarea = ({
                                      placeholder,
                                      name,
                                      value,
                                      limit,
                                      height,
                                      handleChange,
                                      title,
                                      hasLimit
                                  }: any) => {
    const [characterCount, setCharacterCount] = useState(0);
    const id = Math.random().toString();
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (value) {
            // Replace newlines with two characters to count them properly
            const countNewlinesAsChars = value.replace(/\n/g, "xx");
            setCharacterCount(countNewlinesAsChars.length);
        }

        if (textareaRef.current) {
            // Adjust height based on scrollHeight
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [value]);

    return (
        <div style={{ width: '100%' }}>
            <textarea
                ref={textareaRef}
                className={styles.brandInfoTextarea}
                id={id}
                value={value}
                name={name}
                style={{
                    height: height,
                    minHeight: '150px', // Set your desired minHeight directly here
                    overflow: 'hidden',
                    resize: 'none'
            }} // Disable scroll and add hardcoded minHeight
                placeholder={placeholder}
                maxLength={limit}
                onChange={(e) => {
                    const newText = e.target.value;
                    if (newText.length <= limit) {
                        handleChange(title, e.target.value)
                        setCharacterCount(newText.length);
                    }
            }}
      />
            {
                hasLimit && <p className={styles.charactersQuantity}>
                    Character Count: {characterCount} / {limit}
                </p>
            }

        </div>
    );
};