import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Layout from '../components/UI/Layout';
import DataSetTable from "../pages/DataSetTable";
import DataSetLayout from "../components/UI/DataSetLayout";
import {PrintQRPage} from "../pages/PrintQrPage";
import NotFoundPage from '../pages/NotFound';
import ErrorBoundary from '../pages/ErrorBoundary';
import CatalogPage from '../pages/CatalogPage';
import SalesPage from '../pages/SalesPage';
import ExpressPage from '../pages/ExpressListPage';
import MainPage from '../pages/MainPage';
import SettingsPage from "../pages/SettingsPage";
import ListingViewPage from '../pages/ListingViewPage';
import CreateTemplatePage from '../pages/CreateTemplatesPage';
import EditTemplate from '../pages/EditTemplatePage';
import TemplatesPage from "../pages/Templates";
import AddListingPage from "../pages/AddListingPage";
import EditListingPage from "../pages/EditListingPage";
// const NotFoundPage = lazy(() => import('../pages/NotFound'));
// const ErrorBoundary = lazy(() => import('../pages/ErrorBoundary'));
// const CatalogPage= lazy(() => import('../pages/CatalogPage'));
// const SalesPage = lazy(() => import('../pages/SalesPage'));
// const ExpressPage = lazy(() => import('../pages/ExpressListPage'));
// const MainPage = lazy(() => import('../pages/MainPage'));
// const SettingsPage = lazy(() => import("../pages/SettingsPage"));
// const ListingViewPage = lazy(() => import('../pages/ListingViewPage'));
// const CreateTemplatePage = lazy(() => import('../pages/CreateTemplatesPage'));
// const EditTemplate = lazy(() => import('../pages/EditTemplatePage'));
// const TemplatesPage = lazy(() => import("../pages/Templates"));

export const router = createBrowserRouter([
 {
	 path: '/',
	 element: (
	   <Layout
		  routerLink="main">
			 <MainPage/>
		 </Layout>
		),
		errorElement: <ErrorBoundary />,
 },
  {
    path: '/listings',
    element: (
        <Layout routerLink="listings">
          <CatalogPage />
        </Layout>
    ),
    errorElement: <ErrorBoundary />,
  },
    {
        path: '/templates',
        element: (
            <Layout routerLink="templates">
                <TemplatesPage />
            </Layout>
        ),
        errorElement: <ErrorBoundary />,
    },
  {
        path: '/settings',
        element: (
         <Layout routerLink="settings">
             <SettingsPage />
         </Layout>
        ),
        errorElement: <ErrorBoundary />,
  },
  {
    path: '/sales',
    element: (
        <Layout routerLink="sales">
          <SalesPage />
        </Layout>
    ),
    errorElement: <ErrorBoundary />,
  },
    {
        path: '/print-qr-page/:id',
        element: (
            <PrintQRPage />
        ),
        errorElement: <ErrorBoundary />,
    },
  {
        path: '/listings/express',
        element: (
         <Layout routerLink="expressLister">
             <ExpressPage />
         </Layout>
        ),
        errorElement: <ErrorBoundary />,
  },
  {
    path: '/print',
    element: (
        <DataSetLayout>
          <DataSetTable />
        </DataSetLayout>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
        path: '/templates/add',
        element: (
         <Layout routerLink="createTemplates">
             <CreateTemplatePage />
         </Layout>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: '/listings/add',
        element: (
            <Layout routerLink="AddListing">
                <AddListingPage />
            </Layout>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: '/listings/edit/:id',
        element: (
            <Layout routerLink="editListing">
                <EditListingPage />
            </Layout>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: '/create-templates/:id',
        element: (
         <Layout routerLink="editTemplate">
             <EditTemplate />
         </Layout>
        ),
        errorElement: <ErrorBoundary />,
    },
    {
        path: '/listings/view/:id',
        element: (
            <Layout routerLink="listView">
                <ListingViewPage />
            </Layout>
        ),
        errorElement: <ErrorBoundary />,
    },
  {
    path: '*',
    element: (
        <Layout routerLink="error">
          <NotFoundPage />
        </Layout>
    ),
    errorElement: <ErrorBoundary />,
  },
]);