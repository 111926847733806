export default function formatPrice(price: number | string): string {
  const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

  if (numericPrice === null) {
    return '';
  }

  if (numericPrice === 0) {
    return '0.00';
  }

  const formattedPrice = numericPrice?.toFixed(2);

  return formattedPrice;
}
