import { useSelector } from 'react-redux';
import { useRef, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './Notification.module.scss';
import { useDispatch } from 'react-redux';
import {
  setCloseNotificationModal,
  setDeletedNotification,
} from '../../../redux/slices/NavigationSlice';
import {
  InfoSVG,
  NotificationClose,
  NotificationVector,
  SuccessSVG,
  WarningSVG,
} from '../../../assets/icons';
import axios from 'axios';
import getToken from '../../../utils/getToken';

interface NavigationState {
  showNotificationModal: boolean;
  active: boolean;
}

interface RootState {
  navigation: NavigationState;
}

const NotificationsModal = () => {
  const [deletedCount, setDeletedCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [displayedItems, setDisplayedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const showNotificationModal = useSelector(
    (state: RootState) => state.navigation.showNotificationModal
  );
  const dispatch = useDispatch();
  const navigation = useSelector((state: RootState) => state.navigation.active);
  const divRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (showNotificationModal && divRef.current) {
      if (!divRef.current.contains(event.target as Node)) {
        dispatch(setCloseNotificationModal(false));
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotificationModal]);

  const fetchMoreData = () => {
    const token = getToken();

    axios
      .post(
        'https://api.listperfectly.com/v2/notifications/list',
        {
          metadata: { offset: page, limit: 10 },
          filter_by: {
            state: 'public',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const newItems = response?.data.data;
        const deletedNotifications = JSON.parse(
          localStorage.getItem('deletedNotifications') || '[]'
        );
        const filteredItems = newItems.filter(
          (item: any) => !deletedNotifications.includes(item.id)
        );
        if (newItems.length === 0) {
          setHasMore(false);
        } else {
          setItems((prevItems): any => [...prevItems, ...filteredItems]);
          setPage((prevPage) => prevPage + 10);
          filterDisplayedItems(searchQuery, [
            ...displayedItems,
            ...filteredItems,
          ]); // Update displayedItems directly
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    filterDisplayedItems(query, items); // Use the current items array
  };

  const formatDate = (inputDate: any) => {
    const options: any = {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'UTC',
    };

    const date = new Date(inputDate);
    return date.toLocaleString('en-US', options);
  };

  const handleDeleteListing = (id: string, category: string) => {
    const token = getToken();
    if (category === 'announcement') {
      const deletedNotifications = JSON.parse(
        localStorage.getItem('deletedNotifications') || '[]'
      );
      localStorage.setItem(
        'deletedNotifications',
        JSON.stringify([...deletedNotifications, id])
      );
      setDisplayedItems((prevItems) =>
        prevItems.filter((item: any) => item?.id !== id)
      );
      setDeletedCount((prevCount) => prevCount + 1);
      dispatch(setDeletedNotification(deletedCount + 1));
    } else {
      axios
        .post(
          'https://api.listperfectly.com/v1/notifications/dismiss',
          {
            notification_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setDisplayedItems((prevItems) =>
            prevItems.filter((item: any) => item?.id !== id)
          );
          setDeletedCount((prevCount) => prevCount + 1);
          dispatch(setDeletedNotification(deletedCount + 1));
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  };

  const filterDisplayedItems = (query: string, itemsToFilter: any) => {
    const filteredItems = itemsToFilter.filter(
      (item: any) =>
        item?.title?.toLowerCase().includes(query.toLowerCase()) ||
        item?.message?.toLowerCase().includes(query.toLowerCase())
    );
    setDisplayedItems(filteredItems); // Update displayed items based on search query
    setHasMore(false); // Reset hasMore to true when applying a search query
  };

  return (
    <div className={styles.modalContainer}>
      <div
        ref={divRef}
        className={styles.modal}
        style={{ marginLeft: navigation ? '220px' : '100px' }}
      >
        <div className={styles.notificationHeader}>
          <div className={styles.firstSectionFlex}>
            <div className={styles.notificationsTitle}>
              <NotificationVector width={21} height={24} />
              Notifications
            </div>
            <div className={styles.notificationInput}>
              <input
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSearchQuery('');
              dispatch(setCloseNotificationModal(false));
            }}
          >
            <NotificationClose />
          </div>
        </div>
        <div
          className={styles.notificationBody}
          style={{ height: '100%', overflow: 'auto' }}
        >
          <InfiniteScroll
            height={'80%'}
            dataLength={displayedItems.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={hasMore ? <h4>Loading...</h4> : null}
          >
            {displayedItems?.map((item: any, index) => (
              <div
                key={index}
                style={{
                  position: 'relative',
                  border:
                    item?.type === 'info'
                      ? '1px solid #2185D0'
                      : item?.type === 'success'
                      ? '1px solid #4DB89E'
                      : item?.type === 'warning'
                      ? '1px solid #FBBE0A'
                      : item?.type === 'danger'
                      ? '1px solid #CA1010'
                      : '1px solid black',
                  borderRadius: '8px',
                  padding: '20px',
                  margin: '10px 0',
                }}
              >
                {item?.type === 'info' && (
                  <div
                    className={styles.typeChecker}
                    style={{ background: '#2185D0' }}
                  >
                    <InfoSVG />
                  </div>
                )}
                {item?.type === 'success' && (
                  <div
                    className={styles.typeChecker}
                    style={{ background: '#4DB89E' }}
                  >
                    <SuccessSVG />
                  </div>
                )}
                {item?.type === 'warning' && (
                  <div
                    className={styles.typeChecker}
                    style={{ background: '#FBBE0A' }}
                  >
                    <WarningSVG />
                  </div>
                )}
                {item?.type === 'danger' && (
                  <div
                    className={styles.typeChecker}
                    style={{ background: '#CA1010' }}
                  >
                    <WarningSVG />
                  </div>
                )}
                <div style={{ marginLeft: '35px', maxWidth: '80%' }}>
                  <div dangerouslySetInnerHTML={{ __html: item?.title }} />
                  <div dangerouslySetInnerHTML={{ __html: item?.message }} />
                  {/* <div className={styles.date}>
                    {formatDate(item?.date_show)}
                  </div> */}
                  {/* <p className={styles.notificationTitle}>{item?.title}</p>
                  <p>{item?.message}</p> */}
                </div>

                <NotificationClose
                  className="notificationAbsoluteCloseButton"
                  onClick={() => {
                    handleDeleteListing(item?.id, item?.category);
                  }}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default NotificationsModal;
