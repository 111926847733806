import _ from 'lodash';
import axios from 'axios';

// Setup Axios client instance
const APIClient = axios.create();

// Add a response interceptor
APIClient.interceptors.response.use((response) => response, (error) => {

    // Init
    const status_code = _.get(error, 'response.status');

    // Handle Auth errors on LP addresses (domains, subdomains)
    const url = _.defaultTo(_.get(error, 'config.url'), '');
    const lp_addresses = ['api.listperfectly.com'];
    const is_lp_address = lp_addresses.some((lp_address) => (url.includes(lp_address)));

    // If Auth error received redirect to log in page
    if([401, 403].includes(status_code) && is_lp_address){
        window.location.href = 'https://app.listperfectly.com'; // TODO add UTM source later
    }

    // Return error
    return Promise.reject(error);

});

// Export client instance
export default APIClient;
