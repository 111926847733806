import styles from './ExtensionContent.module.scss';
import ReactSelect from 'react-select';
import Tippy from '@tippyjs/react';
import { PopoverIcon} from '../../../../assets/icons';
import Tags from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css';
import './tippy.css'
import React from "react";
import {instanceAxios} from "../../../../services";
import {toast} from "react-toastify";
import {debounce} from "lodash";

const sendRequestToServerInput = debounce(async(propId:any, name:any, configIDExtension:any) => {
	try {
		const requestData = {
			filter_by: {
				id: configIDExtension,
			},
			data: {
				config: {
					[propId]: name,
				}
			}
		};

		const response = await instanceAxios.put(
		 '/user-configs/extension/update',
		 requestData
		);
	}catch (error) {
		toast.error(`Oops! We encountered an error while attempting to save your changes.  Please try again.`, {
			position: 'bottom-right',
		});
		console.error(error);
	}
}, 500);

const ExtensionContent = ({tabsData, setTabsData, unescapedRowMetadata, configIDExtension} : any) => {
	const customStyles = {
		// Style the input element
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isDisabled ? 'rgba(182, 182, 182, 0.2)' : '#fff',
			color: '#1E3166',
			padding: 1,
			borderRadius: '8px',
			borderColor: '#B6B6B6',
			minWidth: '88px',
			cursor: 'pointer',
			width: '200px',
			minHeight: '39px',
			height: '39px',
		}),
		valueContainer: (provided: any) => ({
			...provided,
			height: '39px',
		}),

		input: (provided: any) => ({
			...provided,
			margin: '0px',
		}),
		indicatorSeparator: (state:any) => ({
			display: 'none',
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			height: '39px',
		}),
		menu: (provided: any, state: any) => ({
			...provided,
			zIndex: 999, // Adjust the z-index as needed
		}),
		option: (provided: any, state: any) => ({
			...provided,
			minHeight: '40px',
			cursor: 'pointer',
		}),
	};
	const sendCheckboxesRequest = async (propId:any, checked:any) => {
		try {
			const requestData = {
				filter_by: {
					id: configIDExtension,
				},
				data: {
					config: {
						[propId]: checked,
					}
				}
			};

			const response = await instanceAxios.put(
			 '/user-configs/extension/update',
			 requestData
			);
		}catch (error) {
			toast.error(`Oops! We encountered an error while attempting to save your changes.  Please try again.`, {
				position: 'bottom-right',
			});
			console.error(error);
		}

	};
	const tagsSettings:any = {
		duplicates : true,
		editTags: false,
	}
	const handleCheckboxChange = (e: any, tabName: any, propertyTitle: any, propId:any) => {
		const checked = e.target.checked;
		setTabsData((prevTabsData: any) => {
			const updatedTabsData = prevTabsData.map((tab: any) => {
				if (tab.name === tabName) {
					const updatedProperties = tab.properties.checkboxes.map((property: any) => {
						if (property.title === propertyTitle) {
							return { ...property, checked: checked, changed: true };
						}
						return property;
					});

					return { ...tab, properties: { ...tab.properties, checkboxes: updatedProperties } };
				}
				return tab;
			});

			return updatedTabsData;
		});
		sendCheckboxesRequest(propId, checked);
	};


	// const handleShopNameChange = (tabName:any, newShopName:any, propId:any) => {
	// 	const tabIndex = tabsData.findIndex((tab:any) => tab.name === tabName);
	// 	if (tabIndex !== -1) {
	// 		setTabsData((prevTabsData:any) => {
	// 			const updatedTabsData = [...prevTabsData];
	// 			updatedTabsData[tabIndex] = {
	// 				...updatedTabsData[tabIndex],
	// 				properties: {
	// 					...updatedTabsData[tabIndex].properties,
	// 					shopName: {
	// 						...updatedTabsData[tabIndex].properties.shopName,
	// 						value: newShopName,
	// 						changed: true
	// 					}
	// 				}
	// 			};
	// 			return updatedTabsData;
	// 		});
	// 	}
	// 	// Cancel any previously scheduled debounced function
	// 	sendRequestToServerInput.cancel();
	//
	// 	// Call the debounced function to send request to the server
	// 	sendRequestToServerInput(propId, newShopName, configIDExtension);
	// };

	const handleInstaDescSeparatorChange = (tabName: any, newSeparatorValue: any, propId:any) => {
		const tabIndex = tabsData.findIndex((tab: any) => tab.name === tabName);
		if (tabIndex !== -1) {
			setTabsData((prevTabsData: any) => {
				const updatedTabsData = [...prevTabsData];
				updatedTabsData[tabIndex] = {
					...updatedTabsData[tabIndex],
					properties: {
						...updatedTabsData[tabIndex].properties,
						instaDescSeparatorValue: {
							...updatedTabsData[tabIndex].properties.instaDescSeparatorValue,
							value: newSeparatorValue,
							changed: true
						}
					}
				};
				return updatedTabsData;
			});
		}
		sendRequestToServerInput.cancel();

		// Call the debounced function to send request to the server
		sendRequestToServerInput(propId, newSeparatorValue, configIDExtension);
	};

	// const handleShopIdChange = (tabName: any, inputType: string, newValue: any, propId:any) => {
	// 	const tabIndex = tabsData.findIndex((tab: any) => tab.name === tabName);
	//
	// 	if (tabIndex !== -1) {
	// 		setTabsData((prevTabsData: any) => {
	// 			const updatedTabsData = [...prevTabsData];
	// 			updatedTabsData[tabIndex] = {
	// 				...updatedTabsData[tabIndex],
	// 				properties: {
	// 					...updatedTabsData[tabIndex].properties,
	// 					[inputType]: {
	// 						...updatedTabsData[tabIndex].properties[inputType],
	// 						value: newValue,
	// 						changed: true
	// 					},
	// 				},
	// 			};
	// 			return updatedTabsData;
	// 		});
	// 	}
	// 	sendRequestToServerInput.cancel();
	//
	// 	// Call the debounced function to send request to the server
	// 	sendRequestToServerInput(propId, newValue, configIDExtension);
	// };
	let ids:any = [];
	const handleAddTag = async (tabName:string, wordOrPhrase:string) => {
		try {
			let marketName = tabName;
			if (marketName === 'General') {
				marketName = 'all_marketplaces'
			}
			const payload: any = { marketplace: marketName.toLowerCase(), word_or_phrase: wordOrPhrase };
			const response = await instanceAxios.post('prohibited-words-and-phrases/add', payload);

			if (response.status === 200) {
				const tagId = response.data.data.id;

				// Add the new tag to the set of added tags
				addedTagsSet.add(wordOrPhrase);

				toast.success(`Saved`, {
					position: 'bottom-right',
				});

				return tagId;
			}
		}
		catch (e) {
			toast.error(`Oops! We encountered an error while attempting to save your changes.  Please try again.`, {
				position: 'bottom-right', // You can customize the notification position
			});
			console.log(e, 'error');
			throw e;
		}
	};


	const handleRemoveTag = async(id: any) => {
		try {
			const payload = { id };
			const response = await instanceAxios.delete('prohibited-words-and-phrases/delete', { data: payload });
			if(response.status === 200) {
				toast.success(`Saved`, {
					position: 'bottom-right',
				});
			}
		}
		catch (e) {
			toast.error(`Oops! We encountered an error while attempting to save your changes.  Please try again.`, {
				position: 'bottom-right', // You can customize the notification position
			});
			console.log(e, 'error')
		}
	}



	const addedTagsSet = new Set();

	// const handleTagChange = async (tabName:string, event:any) => {
	// 	try {
	// 		const parsedValue = event.detail.value ? JSON.parse(event.detail.value) : [];
	// 		const tagObjects = parsedValue.map((tag:any) => ({ id: tag.id, value: tag.value }));
	//
	// 		setTabsData((prevTabsData:any) => {
	// 			return prevTabsData.map((tab:any) => {
	// 				if (tab.name === tabName) {
	// 					// Get the existing tags for the tab
	// 					const prevTags = tab?.properties?.wordAlertValue?.value || [];
	//
	// 					// Find newly added tags
	// 					const newTagObjects = tagObjects.filter((tag:any) => !prevTags.some((prevTag:any) => prevTag.value === tag.value));
	//
	// 					// Find removed tags
	// 					const removedTags = prevTags.filter((prevTag:any) => !tagObjects.some((tag:any) => tag.value === prevTag.value));
	//
	// 					// Remove each removed tag
	// 					removedTags.forEach(async (removedTag:any) => {
	// 						try {
	// 							await handleRemoveTag(removedTag.id);
	// 						} catch (error) {
	// 							console.error("Error occurred while removing tag:", error);
	// 						}
	// 					});
	//
	// 					// Add each new tag
	// 					newTagObjects.forEach(async (newTag:any) => {
	// 						try {
	// 							const addedTagId = await handleAddTag(tabName, newTag.value);
	// 							newTag.id = addedTagId;
	// 						} catch (error) {
	// 							console.error("Error occurred while adding tag:", error);
	// 						}
	// 					});
	//
	// 					// Combine existing tags with newly added tags (excluding removed tags)
	// 					const updatedTags = [...prevTags.filter((prevTag:any) => !removedTags.some((removedTag :any)=> removedTag.value === prevTag.value)), ...newTagObjects];
	//
	// 					// Update state with the new tags
	// 					return {
	// 						...tab,
	// 						properties: {
	// 							...tab.properties,
	// 							wordAlertValue: {
	// 								...tab.properties.wordAlertValue,
	// 								value: updatedTags,
	// 							},
	// 						},
	// 					};
	// 				}
	// 				return tab;
	// 			});
	// 		});
	//
	//
	// 	} catch (e) {
	// 		console.log('error', e);
	// 	}
	// };

	const sendDropdownsRequest = async (propId:any, selectedValue:any, tabName:any) => {
		try {
			const emptyValue = tabName === 'Poshmark' ? null : " ";
			const valueToSend = selectedValue.length > 0 ? selectedValue : emptyValue;
			const requestData = {
				filter_by: {
					id: configIDExtension,
				},
				data: {
					config: {
						[propId]: valueToSend === 'null' ? null : valueToSend,
					}
				}
			};

			const response = await instanceAxios.put(
			 '/user-configs/extension/update',
			 requestData
			);

		}catch (error) {
			console.error(error);
		}
	};
	const handleDropDownChange = (selectedOption:any, tabName:any, propertyName:any, propId: any) => {
		const selectedValue = selectedOption.value;
		const updatedTabsData = tabsData.map((tab:any) => {
			if (tab.name === tabName) {
				return {
					...tab,
					properties: {
						...tab.properties,
						[propertyName]: {
							...tab.properties[propertyName],
							value: selectedOption,
							changed: true
						}
					}
				};
			}
			return tab;
		});
		setTabsData(updatedTabsData);

		const changedProperty = updatedTabsData.find((tab:any) => tab.name === tabName)?.properties[propertyName];
		if (changedProperty && changedProperty.changed) {
			sendDropdownsRequest(changedProperty.propId, selectedValue, tabName);
		}

	};
	const handleAddTagEvent = async(tag :any, tabName:string) => {
		try {

			const value = tag?.detail?.data?.value;
			const idFromCreated = await handleAddTag(tabName, value);
			let newObj = {
				id: idFromCreated,
				tagId: tag?.detail?.data?.__tagId
			}
			ids.push(newObj);

			// requestSent = false;
			if(idFromCreated) {
				setTabsData((prevTabsData: any) => {
					return prevTabsData.map((tab: any) => {
						if (tab.name === tabName) {
							const newTagObjects = {id: idFromCreated, value: value}
							const prevTags = tab?.properties?.wordAlertValue?.value || [];
							const updatedTags = [...prevTags, newTagObjects]
							return {
								...tab,
								properties: {
									...tab.properties,
									wordAlertValue: {
										...tab.properties.wordAlertValue,
										value: updatedTags,
									},
								},
							};
						}
						return tab;
					})
				})
			}
		}

		catch (e) {
			console.log('error' , e)
		}
	}
	const handleRemoveTagEvent = async(tag: any, tabName:string)=> {
		try {
			const value = tag?.detail?.data?.value
			const id = tag?.detail?.data?.id;
			const localAddedTagId = ids.find((item:any) => item.tagId === tag?.detail?.data?.__tagId)
			setTabsData((prevTabsData: any) => {
				return prevTabsData.map((tab: any) => {
					if (tab.name === tabName) {
						const removedTags = [{id: id ? id : localAddedTagId.id, value: value}]
						const prevTags = tab?.properties?.wordAlertValue?.value || [];
						const updatedTags =  [...prevTags.filter((prevTag: any) => !removedTags.some((removedTag: any) => removedTag.id === prevTag.id))];
						return {
							...tab,
							properties: {
								...tab.properties,
								wordAlertValue: {
									...tab.properties.wordAlertValue,
									value: updatedTags,
								},
							},
						};
					}
					return tab;
				})
			})
			await handleRemoveTag(id ? id : localAddedTagId.id);
		}catch (e) {
			console.log(e, 'error')
		}
	}
	return <div className={styles.tabsContent} id="tabsContent">
		{
			tabsData.map((tab: any, index: number) => {
				return(
				 <div key={index} className={styles.tabContentInner} >
					 { tab?.tabChosen && tab?.name === 'General' ? (
					  <div>
						  <div className={styles.checkBoxesExtension} >
							  {
								  tab.properties.checkboxes.map((item: any, index: number) => {
									  return (
									   <div className={styles.extSettingsCheckboxesItem}>
										   <input id={item.title} type="checkbox" className={styles.myCheckbox} checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false} onChange={(e) => handleCheckboxChange(e, tab.name, item.title,item.propId)} disabled={item.disabled ? true : false} />
										   <label className={`${item?.disabled ? styles.disabledText : ''}`} htmlFor={item.title}>{item.title}</label>
										   {
											   (item.popoverEnabled || item.popoverDisabled) &&
											   <Tippy
												interactive={true}
												arrow={false}
												zIndex={9999}
												trigger="click"
												content={
													<div className={styles.popoverContent}>
														<p>{`${item?.popoverEnabled}`}</p>
														<p>{`${item?.popoverDisabled}`}</p>
													</div>
												}
											   >
												   <PopoverIcon
													className={styles.popoverIcon}
													color={'#fff'}
												   ></PopoverIcon>
											   </Tippy>
										   }
									   </div>
									  )
								  }) }

						  </div>
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for all marketplaces:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
								   }
							   >
								   <PopoverIcon
									className={styles.popoverIcon}
									color={'#fff'}
								   ></PopoverIcon>
							   </Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>


					 ) : tab?.tabChosen && tab?.name === 'Poshmark' ? (
					  <div className={styles.contentInner}>
						  {/*<div className={styles.poshmarkShopIDBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value, tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}
						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value, tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
						  <div className={styles.checkBoxesExtensionPoshmark} >
							  {
								  tab.properties.checkboxes.map((item: any, index: number) => {
									  return (
									   <div className={styles.extSettingsCheckboxesItem}>
										   <input id={item.title} type="checkbox" className={styles.myCheckbox}  checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false} onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)} disabled={item.disabled ? true : false} />
										   <label className={`${item?.disabled ? styles.disabledText : ''}`} htmlFor={item.title}>{item.title}</label>
										   {
											(item.popoverEnabled || item.popoverDisabled) &&
											<Tippy
											 interactive={true}
											 arrow={false}
											 zIndex={9999}
											 trigger="click"
											 content={
												 <div className={styles.popoverContent}>
													 <p>{`${item?.popoverEnabled}`}</p>
													 <p>{`${item?.popoverDisabled}`}</p>
												 </div>
											 }
											>
												<PopoverIcon
												 className={styles.popoverIcon}
												 color={'#fff'}
												></PopoverIcon>
											</Tippy>
										   }
									   </div>
									  )
								  }) }
						  </div>
						  <div className={styles.poshmarkNewTitle}>
							  <p>Poshmark sharing settings are setup directly on the extension.</p>
						  </div>
						  {
						   tab.properties?.discount &&  <div className={styles.dropdownPoshmark}>
							   <div>
								   <label className={`${tab.properties?.discount.disabled ? styles.disabledText : ''}`}>Set discounted shipping on Poshmark
									   {
										(tab.properties?.discount?.popoverEnabled || tab.properties?.discount?.popoverDisabled) &&
										<Tippy
										 interactive={true}
										 arrow={false}
										 zIndex={9999}
										 trigger="click"
										 content={
											 <div className={styles.popoverContent}>
												 <p>{`${tab.properties?.discount?.popoverEnabled}`}</p>
												 <p>{`${tab.properties?.discount?.popoverDisabled}`}</p>
											 </div>

									   }
								   >
									   <PopoverIcon
										className={styles.popoverIcon}
										color={'#fff'}
									   ></PopoverIcon>
										</Tippy>
									   }
								   </label>
							   </div>
							  <div className={styles.reactSelectZoomWrapper}>
								  <ReactSelect
								   key={tab.name}
								   defaultValue={tab?.properties?.discount?.changed ? tab.properties?.discount?.value : {
									   label: unescapedRowMetadata[tab?.properties?.discount?.propId],
									   value: unescapedRowMetadata[tab?.properties?.discount?.propId]
								   }}
								   styles={customStyles}
								   placeholder=""
								   options={tab.properties.discount?.options}
								   onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'discount', tab?.properties?.discount?.propId)}
								   isDisabled={tab.properties.discount?.disabled}
								  />
							  </div>

						   </div>
						  }
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
								   }
							   >
								   <PopoverIcon
									className={styles.popoverIcon}
									color={'#fff'}
								   ></PopoverIcon>
							   </Tippy>
							   }
						   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Shopify' ? (
					  <div>
						  {/*<div className={styles.shopifyTabBlock}>*/}
							{/*  <label className={`platformLabel ${ tab?.properties?.shopName?.disabled ? styles.disabledText : ''}`} htmlFor="shopifyId3">*/}
							{/*	  Shopify shop name*/}
							{/*	  {*/}
							{/*	   (tab?.properties?.shopName?.popoverEnabled || tab?.properties?.shopName?.popoverDisabled) &&*/}
							{/*	   <Tippy*/}
							{/*		interactive={true}*/}
							{/*		arrow={false}*/}
							{/*		zIndex={9999}*/}
							{/*		trigger="click"*/}
							{/*		content={*/}
							{/*			<div className={styles.popoverContent}>*/}
							{/*				<p>{`${tab?.properties?.shopName?.popoverEnabled}`}</p>*/}
							{/*				<p>{`${tab?.properties?.shopName?.popoverDisabled}`}</p>*/}
							{/*			</div>*/}

							{/*	  }*/}
							{/*  >*/}
							{/*	  <PopoverIcon*/}
							{/*	   className={styles.popoverIcon}*/}
							{/*	   color={'#fff'}*/}
							{/*	  ></PopoverIcon>*/}
							{/*  </Tippy>*/}
							{/*  }*/}
						  {/*</label>*/}
							{/*  <input*/}
							{/*   id="shopifyId3"*/}
							{/*   type="text"*/}
							{/*   className="platformTabInput"*/}
							{/*   value={tab?.properties?.shopName.changed ? tab?.properties?.shopName.value : unescapedRowMetadata[tab?.properties?.shopName.propId] || ''}*/}
							{/*   onChange={(e) => handleShopNameChange(tab.name, e.target.value, tab?.properties?.shopName.propId)}*/}
							{/*   disabled={tab?.properties?.shopName?.disabled ? true : false}*/}
							{/*  />*/}
						  {/*</div>*/}
						  <div className={styles.shopifyCheckboxBlock}>
							  {
								  tab.properties.checkboxes.map((item: any, index: number) => {
									  return (
									   <div className={styles.extSettingsCheckboxesItem}>
										   <input id={item.title} type="checkbox" className={styles.myCheckbox}  checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false} onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)} disabled={item.disabled ? true : false} />
										   <label className={`${item?.disabled ? styles.disabledText : ''}`} htmlFor={item.title} >{item.title}</label>
										   {
											(item.popoverEnabled || item.popoverDisabled) &&
											<Tippy
											 interactive={true}
											 arrow={false}
											 zIndex={9999}
											 trigger="click"
											 content={
												 <div className={styles.popoverContent}>
													 <p>{`${item?.popoverEnabled}`}</p>
													 <p>{`${item?.popoverDisabled}`}</p>
												 </div>
										   }
									   >
										   <PopoverIcon
											className={styles.popoverIcon}
											color={'#fff'}
										   ></PopoverIcon>
									   </Tippy>
										   }
									   </div>
									  )
								  }) }

						  </div>
						  {
						   tab?.properties?.wordAlertValue &&  <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
								   }
							   >
								   <PopoverIcon
									className={styles.popoverIcon}
									color={'#fff'}
								   ></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags  settings={tagsSettings}  onAdd={(tag) => handleAddTagEvent(tag, tab.name)} onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}  defaultValue={tab?.properties?.wordAlertValue?.value}   className={styles.tags_container}  />
							   <div className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Instagram' ? (
					  <div className={styles.instagramBlock}>
						  <div className={styles.labelTitle}>
							  <label htmlFor="instagramID4" className={`platformLabel ${tab?.properties?.instaDescSeparatorValue?.disabled ? styles.disabledText : ''}`}>
								  Instagram description seperator
								  {
								   (tab?.properties?.instaDescSeparatorValue?.popoverEnabled || tab?.properties?.instaDescSeparatorValue?.popoverDisabled) &&
								   <Tippy
									interactive={true}
									arrow={false}
									zIndex={9999}
									trigger="click"
									content={
										<div className={styles.popoverContent}>
											<p>{`${tab?.properties?.instaDescSeparatorValue?.popoverEnabled}`}</p>
											<p>{`${tab?.properties?.instaDescSeparatorValue?.popoverDisabled}`}</p>
										</div>
								  }
							  >
								  <PopoverIcon
								   className={styles.popoverIcon}
								   color={'#fff'}
								  ></PopoverIcon>
							  </Tippy>
							  }
						  </label>
							  <input
							   type="text"
							   id={`instagramID4`}
							   className="platformTabInput"
							   value={tab?.properties?.instaDescSeparatorValue.changed ? tab?.properties?.instaDescSeparatorValue.value : unescapedRowMetadata[tab?.properties?.instaDescSeparatorValue.propId] || ''}
							   onChange={(e) => handleInstaDescSeparatorChange(tab.name, e.target.value, tab?.properties?.instaDescSeparatorValue.propId)}
							   disabled={tab?.properties?.instaDescSeparatorValue?.disabled ? true : false}
							  />
						  </div>
						  <div className={styles.instagramCheckboxBlock}>
							  {
								  tab.properties.checkboxes.map((item: any, index: number) => {
									  return (
									   <div className={styles.extSettingsCheckboxesItem}>
										   <input id={item.title} type="checkbox" className={styles.myCheckbox}  checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false} onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)} disabled={item.disabled ? true : false} />
										   <label className={`${item?.disabled ? styles.disabledText : ''}`} htmlFor={item.title}>{item.title}</label>
										   {
											(item.popoverEnabled || item.popoverDisabled) &&
											<Tippy
											 interactive={true}
											 arrow={false}
											 zIndex={9999}
											 trigger="click"
											 content={
												 <div className={styles.popoverContent}>
													 <p>{`${item?.popoverEnabled}`}</p>
													 <p>{`${item?.popoverDisabled}`}</p>
												 </div>
										   }
									   >
										   <PopoverIcon
											className={styles.popoverIcon}
											color={'#fff'}
										   ></PopoverIcon>
									   </Tippy>
								  }
								  </div>
									  )
								  }) }
						  </div>
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
									 }
									>
										<PopoverIcon
										 className={styles.popoverIcon}
										 color={'#fff'}
										></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Etsy' ? (
					  <div className={styles.etsyBlock}>
						  {/*<div className={styles.etsyShopIDBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value, tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}
						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value, tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
						  <div className={styles.etsyCheckboxes}>
							  {
								  tab.properties.checkboxes.map((item: any, index: number) => {
									  return (
									   <div className={styles.extSettingsCheckboxesItem}>
										   <input id={item.title} type="checkbox" className={styles.myCheckbox} checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false} onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)} disabled={item.disabled ? true : false} />
										   <label className={`${item?.disabled ? styles.disabledText : ''}`} htmlFor={item.title}>{item.title}</label>
										   {
											(item.popoverEnabled || item.popoverDisabled) &&
											<Tippy
											 interactive={true}
											 arrow={false}
											 zIndex={9999}
											 trigger="click"
											 content={
												 <div className={styles.popoverContent}>
													 <p>{`${item?.popoverEnabled}`}</p>
													 <p>{`${item?.popoverDisabled}`}</p>
												 </div>
											 }
											>
												<PopoverIcon
												 className={styles.popoverIcon}
												 color={'#fff'}
												></PopoverIcon>
											</Tippy>
										   }
									   </div>
									  )
								  }) }
						  </div>
						  <div className={styles.etsyQuestionSelects}>
							  <div>
								  <div>
									  <label>What type of item is it?</label>
								  </div>
								  <div className={styles.reactSelectZoomWrapper}>
									  <ReactSelect
									   key={tab.name}
									   defaultValue={tab.properties.itemType.changed ? tab.properties.itemType.value : {
										   label: unescapedRowMetadata[tab?.properties?.itemType.propId],
										   value: unescapedRowMetadata[tab?.properties?.itemType.propId]
									   }}
									   styles={customStyles}
									   placeholder=""
									   options={tab.properties.itemType.options}
									   onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'itemType', tab?.properties?.itemType.propId)}
									  />
								  </div>
							  </div>
							  <div>
								  <div>
									  <label>Who made it?</label>
								  </div>
								  <div className={styles.reactSelectZoomWrapper}>
									  <ReactSelect
									   key={tab.name}
									   defaultValue={tab.properties.whoMaid.changed ? tab.properties.whoMaid.value : {
										   label: unescapedRowMetadata[tab?.properties?.whoMaid.propId],
										   value: unescapedRowMetadata[tab?.properties?.whoMaid.propId]
									   }}
									   styles={customStyles}
									   placeholder=""
									   options={tab.properties.whoMaid.options}
									   onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'whoMaid', tab?.properties?.itemType.propId)}
									  />
								  </div>
							  </div>
							  <div>
								  <div>
									  <label>What is it?</label>
								  </div>
								  <div className={styles.reactSelectZoomWrapper}>
									  <ReactSelect
									   key={tab.name}
									   defaultValue={tab.properties.whatIsIt.changed ? tab.properties.whatIsIt.value : {
										   label: unescapedRowMetadata[tab?.properties?.whatIsIt.propId],
										   value: unescapedRowMetadata[tab?.properties?.whatIsIt.propId]
									   }}
									   styles={customStyles}
									   placeholder=""
									   options={tab.properties.whatIsIt.options}
									   onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'whatIsIt', tab?.properties?.itemType.propId)}
									  />
								  </div>
							  </div>
							  <div>
								  <div>
									  <label>When was it made?</label>
								  </div>
								  <div className={styles.reactSelectZoomWrapper}>
									  <ReactSelect
									   key={tab.name}
									   defaultValue={tab.properties.whenWasItMade.changed ? tab.properties.whenWasItMade.value : {
										   label: unescapedRowMetadata[tab?.properties?.whenWasItMade.propId],
										   value: unescapedRowMetadata[tab?.properties?.whenWasItMade.propId]
									   }}
									   styles={customStyles}
									   placeholder=""
									   options={tab.properties.whenWasItMade.options}
									   onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'whenWasItMade', tab?.properties?.itemType.propId)}
									  />
								  </div>
							  </div>
						  </div>
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:

								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
									 }
									>
										<PopoverIcon
										 className={styles.popoverIcon}
										 color={'#fff'}
										></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'eBay' ? (
					  <div className={styles.ebayBlock}>
						  {/*<div className={styles.ebayLabelBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value, tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}
						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value, tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
						  <div className={styles.ebayCheckboxes}>
							  {
								  tab.properties.checkboxes.map((item: any, index: number) => {
									  return (
									   <div className={styles.extSettingsCheckboxesItem}>
										   <input id={item.title} type="checkbox" className={styles.myCheckbox} checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false} onChange={(e) => handleCheckboxChange(e, tab.name, item.title,item.propId)} disabled={item.disabled ? true : false} />
										   <label className={`${item?.disabled ? styles.disabledText : ''}`} htmlFor={item.title}>{item.title}</label>
										   {
											(item.popoverEnabled || item.popoverDisabled) &&
											<Tippy
											 interactive={true}
											 arrow={false}
											 zIndex={9999}
											 trigger="click"
											 content={
												 <div className={styles.popoverContent}>
													 <p>{`${item?.popoverEnabled}`}</p>
													 <p>{`${item?.popoverDisabled}`}</p>
												 </div>
											 }
											>
												<PopoverIcon
												 className={styles.popoverIcon}
												 color={'#fff'}
												></PopoverIcon>
											</Tippy>
										   }
									   </div>
									  )
								  }) }
						  </div>
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
									 }
									>
										<PopoverIcon
										 className={styles.popoverIcon}
										 color={'#fff'}
										></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Mercari' ? (
					  <div className={styles.mercariBlock}>
						  {/*<div className={styles.mercariLabelBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}
						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value, tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}
						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value, tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
						  <div className={styles.mercariCheckboxs}>
							  {
								  tab.properties.checkboxes.map((item: any, index: number) => {
									  return (
									   <div className={styles.extSettingsCheckboxesItem}>
										   <input id={item.title} type="checkbox" className={styles.myCheckbox} checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false} onChange={(e) => handleCheckboxChange(e, tab.name, item.title,item.propId)} disabled={item.disabled ? true : false} />
										   <label className={`${item?.disabled ? styles.disabledText : ''}`} htmlFor={item.title}>{item.title}</label>
										   {
											(item.popoverEnabled || item.popoverDisabled) &&
											<Tippy
											 interactive={true}
											 arrow={false}
											 zIndex={9999}
											 trigger="click"
											 content={
												 <div className={styles.popoverContent}>
													 <p>{`${item?.popoverEnabled}`}</p>
													 <p>{`${item?.popoverDisabled}`}</p>
												 </div>
											 }
											>
												<PopoverIcon
												 className={styles.popoverIcon}
												 color={'#fff'}
												></PopoverIcon>
											</Tippy>
										   }
									   </div>
									  )
								  }) }
						  </div>
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
									 }
									>
										<PopoverIcon
										 className={styles.popoverIcon}
										 color={'#fff'}
										></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Facebook' ? (
					  <div className={styles.facebookBlock}>
						  {/*<div className={styles.facebookLabelBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value,tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value, tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
									 }
									>
										<PopoverIcon
										 className={styles.popoverIcon}
										 color={'#fff'}
										></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Grailed' ? (
					  <div className={styles.grailedBlock}>
						  {/*<div className={styles.grailedLabelBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value, tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value,tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
									 }
									>
										<PopoverIcon
										 className={styles.popoverIcon}
										 color={'#fff'}
										></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Depop' ? (
					  <div className={styles.depopBlock}>
						  {/*<div className={styles.depopLabelBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}


						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value,tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value, tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
									 }
									>
										<PopoverIcon
										 className={styles.popoverIcon}
										 color={'#fff'}
										></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Kidizen' ? (
					  <div className={styles.kidizenBlock}>
						  {/*<div className={styles.kidizenLabelBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}

						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value, tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}
						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value, tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
						  {
						   tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
							   <label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
								   {
									(tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
									<Tippy
									 interactive={true}
									 arrow={false}
									 zIndex={9999}
									 trigger="click"
									 content={
										 <div className={styles.popoverContent}>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
											 <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
										 </div>
									 }
									>
										<PopoverIcon
										 className={styles.popoverIcon}
										 color={'#fff'}
										></PopoverIcon>
									</Tippy>
								   }
							   </label>
							   <Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
									 onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
									 defaultValue={tab?.properties?.wordAlertValue?.value}
									 className={styles.tags_container}/>
							   <div
								className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
						   </div>
						  }

					  </div>
					 ) : tab?.tabChosen && tab?.name === 'Vestiaire' ? (
					  <div className={styles.vestiaireBlock}>
						  {/*<div className={styles.vestiaireLabelBlock}>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel5_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 1*/}
						  {/*            </label>*/}
						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel5_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId1.value ? tab?.properties?.shopId1.value : unescapedRowMetadata[tab?.properties?.shopId1.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId1', e.target.value,tab?.properties?.shopId1.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*    <div>*/}
						  {/*        <div>*/}
						  {/*            <label htmlFor={`etsyLabel6_${tab.name}`} className="platformLabel">*/}
						  {/*                Shop ID 2*/}
						  {/*            </label>*/}
						  {/*        </div>*/}

						  {/*        <input*/}
						  {/*            type="text"*/}
						  {/*            id={`etsyLabel6_${tab.name}`}*/}
						  {/*            className="platformTabInput"*/}
						  {/*            value={tab?.properties?.shopId2.value ? tab?.properties?.shopId2.value : unescapedRowMetadata[tab?.properties?.shopId2.propId] || ''}*/}
						  {/*            onChange={(e) => handleShopIdChange(tab.name, 'shopId2', e.target.value, tab?.properties?.shopId2.propId)}*/}
						  {/*        />*/}
						  {/*    </div>*/}
						  {/*</div>*/}
					  </div>
					 ) : null }
				 </div>
				)
			})
		}
	</div>;
};

export default ExtensionContent;