import React, { useState, useEffect } from 'react';

import styles from './ToTopBtn.module.scss';
import { ToTopBtnSvg } from '../../../assets/icons';

const ToTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div

      className={styles.toTopBtn}
      onClick={scrollToTop}
    >

      <ToTopBtnSvg />
    </div>
  );
};

export default ToTopBtn;
