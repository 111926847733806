import React, { useEffect, useRef } from 'react';

import styles from './PlatformsModal.module.scss';
import { CloseSvg } from '../../../../../../assets/icons';
import PlatformModalRow from './PlatformModalRow';

const PlatformsModal = (props: any) => {
  const {
    handleTogglePlatformsModalShow,
    handleClick,
    lpPlatforms,
    main,
    unescapedRowMetadata,
    setLpPlatforms,
    tableData,
    index,
    setTableData,
    setChangedPatforms,
  } = props;
  const plartformModalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        plartformModalRef.current &&
        !plartformModalRef.current.contains(event.target as Node)
      ) {
        handleTogglePlatformsModalShow();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [plartformModalRef, handleTogglePlatformsModalShow]);

  const handleModalScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } =
      plartformModalRef.current!;

    if (
      ((e.nativeEvent as WheelEvent).deltaY &&
        (e.nativeEvent as WheelEvent).deltaY < 0 &&
        scrollTop === 0) ||
      ((e.nativeEvent as WheelEvent).deltaY > 0 &&
        scrollTop + clientHeight >= scrollHeight)
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.platformsModal__overlay}>
      <div
        className={styles.modal}
        onClick={(e) => e.stopPropagation()}
        onScroll={handleModalScroll}
        ref={plartformModalRef}
      >
        <div className={styles.close__block}>
          {main ? (
           <h1>Platforms</h1>
          ) : (
           <div className={styles.platformsTitleWrapper}>
             <h1>Platforms</h1>
             <h1>Platform ID</h1>
           </div>
          )}
          <button onClick={handleTogglePlatformsModalShow}>
            <CloseSvg />
          </button>
        </div>

        <div className={styles.platformsModal__wrapper}>
          {lpPlatforms &&
            lpPlatforms.map((item: any) => (
              <PlatformModalRow
                key={item?.id}
                item={item}
                handleClick={handleClick}
                main={main}
                unescapedRowMetadata={unescapedRowMetadata}
                tableData={tableData}
                setTableData={setTableData}
                index={index}
                setChangedPatforms={setChangedPatforms}
              />
            ))}
          <div
            className={`${styles.platform__modal__button} ${styles.closeButton}`}
            onClick={handleTogglePlatformsModalShow}
          >
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformsModal;
