import moment from 'moment';
import _ from 'lodash';
import convertToFormattedString from '../convertToFormattedString';

export const tableDataTransform = (orders: any) => {

	return orders.map((order: any) => {

		const order_listing_catalog_metadata = _.get(order, 'listings[0][catalog_metadata]');
		const field_order_listings = _.get(order, 'listings') || [];

		// Handle fields
		let field_cogs = _.get(field_order_listings, '[0].cogs');

		// TODO total_sold_price is temporary exception, need to find a way to replace it with just total_price
		let field_total_sold_price = _.get(order, 'total_price');


		const field_catalog_id = _.get(field_order_listings, '[0].catalog_id');

		let field_sku = _.get(field_order_listings, '[0].sku');
		if(!field_sku) field_sku = _.get(order_listing_catalog_metadata, '1');
		if(!field_sku) field_sku = null; // TODO temporary measure to keep it consistent with the old code

		let field_quantity_sold = _.get(field_order_listings, '[0].quantity_sold');
		// if(!_.isNumber(field_quantity_sold)) field_quantity_sold = 1; // Set 1 to be default value

		// Fetches data from the linked listing
		let field_quantity_available = _.get(order_listing_catalog_metadata, '851') || null;


		return {
			id: order.id,
			thumbnail: 'https://s3.amazonaws.com/listperfectly.orders/' + _.get(field_order_listings, '[0].thumbnail'),
			title: field_order_listings.length >= 1 && { long: _.get(field_order_listings, '[0].title') },
			total_price: field_total_sold_price,
			date_sold: moment(order?.date_sold).format('lll'),
			sold_on: `https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/${order.marketplace}.svg`,
			shop_id: _.get(order, 'shop_id'),
			marketplaces: _.get(order, 'marketplace'), // TODO looks like a wrong naming, replace singular form.
			marketplace: _.get(order, 'marketplace'),
			listings: field_order_listings,
			catalog_id: field_catalog_id,
			listing_id: _.get(field_order_listings, '[0].listing_id'),
			shipping_cost_buyer: _.get(order, 'shipping_cost_buyer'),
			shipping_cost_seller: _.get(order, 'shipping_cost_seller'),
			cogs: field_cogs,
			sales_tax: _.get(order, 'sales_tax'),
			shipping_status: convertToFormattedString( _.get(order, 'shipping_status') ),
			sku: field_sku,
			catalog_metadata: order_listing_catalog_metadata,
			order_id: _.get(order, 'order_id'),
			quantity_sold: field_quantity_sold,
			quantity_available: field_quantity_available,
			fees: _.get(order, 'fees'),
			status: _.get(order, 'status')
		};
	});
};
