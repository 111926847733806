import {MoonLoader} from "react-spinners";
import React from "react";
import styles from "./PrintLoader.module.scss";
import {setHandleStopPrint} from "../../../../redux/slices/NavigationSlice";
import {CloseSvg} from "../../../../assets/icons";
import {useDispatch} from "react-redux";

const PrintLoader  = () => {
    const dispatch = useDispatch();

    return (
        <div className={styles.printLoaderContainer}>
            <div className={styles.closeIcon} onClick={() => {
                dispatch(setHandleStopPrint())
            }}><CloseSvg/></div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:'column',
                textAlign: 'center',
                width: '100%',
                position: 'absolute',
                top: '40%', gap:'5px',
            }}>
                <MoonLoader color="#1e3166" loading={true} />
                <div>Generate Table For Print</div>
            </div>
        </div>
    )

}


export  default PrintLoader;