import getToken from "../../../utils/getToken";

const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
}

const getImageFilenameAndExtensionSeparately = (filename, uploadedImageFilename = false) => {


    let filenameWithoutExtension = uploadedImageFilename ? filename.replace('_LP', '').replace('_CI', '').replace(/\.[^/.]+$/, ""): filename.replace('_LP', '').replace('_CI', '').replace(/_/gim, '-').replace(/\.[^/.]+$/, "").replace(/[^\w\s\-]/gi, '');
    let fileExtension;

    //if file extension not found
    try {
        fileExtension = filename.match(/\.[0-9a-z]+$/i, '')[0].toLowerCase();
    } catch (e) {
        return {
            fileExtension: null,
            filenameWithoutExtension
        };
    }
    //console.log(filenameWithoutExtension, uploadedImageFilename, 123812)
    return {
        fileExtension,
        clearFilenameExtension: fileExtension.replace('.', ''),
        filenameWithTypeTag: 'image/' + fileExtension.replace('.', ''),
        filenameWithoutExtension
    };
};

const getUniqueId = () => {
    return "xxxxxxxx-4xxx".replace(/[xy]/g, e => {
        var t = 16 * Math.random() | 0;
        return ("x" === e ? t : 3 & t | 8).toString(16);
    });
};

const getFilenameFromUrl = (url) => {
    let matches = url.match(/\/([^\/?#]+)[^\/]*$/);
    if (matches.length > 1) {
        return matches[1];
    }
    return null;
}

const hasClass = (el, className)  => {
    if (el.classList)
        return el.classList.contains(className)
    else
        return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
}
const addClass = (el, className) => {
    if (el.classList)
        el.classList.add(className)
    else if (!hasClass(el, className)) el.className += " " + className
}

const removeClass = (el, className) => {
    if (el.classList)
        el.classList.remove(className)
    else if (hasClass(el, className)) {
        let reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
        el.className=el.className.replace(reg, ' ')
    }
}
const sleep = ms => new Promise(r => setTimeout(r, ms));

const  moveElementBefore = (array, fromIndex, toIndex) => {
    if (
        fromIndex < 0 ||
        fromIndex >= array.length ||
        toIndex < 0 ||
        toIndex >= array.length ||
        fromIndex === toIndex
    ) {
        console.error('Invalid indices');
        return array; // Return the original array if indices are invalid
    }

    // Remove the element from the array at the fromIndex
    const elementToMove = array.splice(fromIndex, 1)[0];

    // Insert the element at the toIndex
    array.splice(toIndex, 0, elementToMove);
    return array;
}

const  updateFileProperty = (prevFiles,index, propertyName, newValue) => {
    return prevFiles.map(obj => {
        if (obj.index === index) {
            return { ...obj, [propertyName]: newValue };
        }
        return obj;
    });
};

const replaceSuffixInFilename = (filename, oldString, newString) => {
    const dotIndex = filename.lastIndexOf('.');

    if (dotIndex !== -1) {
        const baseName = filename.slice(0, dotIndex);
        const extension = filename.slice(dotIndex);
        const pattern = new RegExp(`${oldString}(?!.*${oldString})`);
        const newBaseName = baseName.replace(pattern, newString);

        return newBaseName + extension;
    }

    const pattern = new RegExp(`${oldString}(?!.*${oldString})`);
    return filename.replace(pattern, newString);
}
const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    console.log(document.cookie,99)
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
const getActualCookie = () => {
    // const isAppTest = window.location.href.indexOf('apptest.listperfectly.com') !== -1
    // const actualCookieName = isAppTest ? 'lp_authorization_token_staging' : 'lp_authorization_token'
    return getToken();
}

const isElVisible = (selector) => {
    const modal = document.querySelector(selector);
    if (!modal) return false;
    const style = window.getComputedStyle(modal);
    return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0';
}

const loadImageToBase64 = async (imageUrl) => {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            return null;
        }
        const blob = await response.blob();
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = () => reject('Error reading blob as Base64');
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        throw new Error(`Error converting image to Base64: ${error.message}`);
    }
};


const generateImageThumbnail = async (base64String, maxWidth = 240, maxHeight = 240) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height);
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL('image/jpeg'))
        };
        img.src = base64String;
    })
};

const updatePropertyInFile = (array, targetIndex, properties) => {
    // Find the object with the specified index
    return array.map(obj => {
        if (obj.index === targetIndex) {
            return { ...obj, ...properties};
        }
        return obj;
    });
}

const  addOrRemoveElementIfItExistArray = (array, elemToAdd) => {
    const index = array.indexOf(elemToAdd);
    if (index !== -1) {
        array.splice(index, 1);
    } else {
        array.push(elemToAdd);
    }
    return array
}

const  whenElDisappear = (selector) => {
    return new Promise((resolve) => {
        // Create a MutationObserver to monitor changes to the DOM
        const intervalId = setInterval(() => {
            if (!document.querySelector(selector)) {
                resolve(true)
                clearInterval(intervalId); // Stop checking
            }
        }, 300);
    })
   
}
export {
    addOrRemoveElementIfItExistArray,
    getImageFilenameAndExtensionSeparately,
    getUniqueId,
    updatePropertyInFile,
    replaceSuffixInFilename,
    moveElementBefore,
    sleep,
    isMobileDevice,
    whenElDisappear,
    isElVisible,
    loadImageToBase64,
    getFilenameFromUrl,
    generateImageThumbnail,
    hasClass,
    removeClass,
    updateFileProperty,
    addClass,
    getCookie,
    getActualCookie
}