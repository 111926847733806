import React from "react";
import styles from './TemplatesHeader.module.scss';
import {CustomizeIcon} from "../../../assets/icons";
import PerPageSelect from "../../shared/PerPageSelect";
import TemplatesPagination from "../TablePagination";
import Tippy from "@tippyjs/react";
import './templatesHeader.css';
import Button, {ModifierType} from "../../../components/UI/Button";

export const TemplatesHeader = ({hasSimple, handleCheckboxChange,  isDefaultTemplate, metadata, filterBy, handleGetDataPerPage, setIsCanceled, handleSaveListings, retryActive, setRetryActive, pageCount, saveActive, setTableData, setSaveActive, handleChangeModal, isBulkEdit, setIsBulkEdit, setMetadata, pagiInputValue, setPagiInputValue, setPageCount, setLoader}:any) =>{

	const handleChangeBulkEdit = () => {
		setRetryActive(false);
		setSaveActive(false);
		setIsCanceled(false);
		setIsBulkEdit(!isBulkEdit);
	}
	const handleCancel = () => {
		setIsCanceled(true);
		setIsBulkEdit(!isBulkEdit);
	}



	return (
	 <div className={styles.templatesHeader} id="templatesHeader">
		 <p className={styles.templatesTitle}>Templates</p>

		 <div className={styles.templatesHeaderRightPart}>
			 <div className={styles.defaultTemplateCheckbox}>
				 <label>
					 <p>Default Template</p>
					 <input
					  className="selectInputDraftSold"
					  type="checkbox"
					  checked={isDefaultTemplate}
					  onChange={handleCheckboxChange}
					 />
				 </label>
			 </div>
			 <TemplatesPagination
			  isBulkEdit={isBulkEdit}
			  pageCount={pageCount}
			  pagiInputValue={pagiInputValue}
			  setPagiInputValue={setPagiInputValue}
			 />
			 <div className={styles.perPageSelectBlock}>
				 <span>Per Page</span>
				 <PerPageSelect
				  handleGetDataPerPage={handleGetDataPerPage}
				  metadata={metadata}
				  type="Templates"
				 />
			 </div>
			 <Button
			  style={{
				  color:'#1E3166',
				  border:'1px solid #1E3166'
			  }}
			  handleClick={handleChangeModal}
			  modifier={ModifierType.Transparent}>
				 <CustomizeIcon className="settingsIconCustomize"/> Customize
			 </Button>
			 {
				 hasSimple ?
				 <div>
					 <Tippy
					  interactive={true}
					  zIndex={999}
					  arrow={false}
					  trigger="focus click"
					  content={
						  <div className={styles.popoverContentDisabledActions}>
							  <p className={styles.desc}>
								  Bulk edit is available for Business, Pro and Pro Plus
								  users. Upgrade to access this feature and many more.
							  </p>
							  <a
							   target={'_blank'}
							   href={'https://listperfectly.com/pricing'}
							  >
								  {' '}
								  Upgrade Now{' '}
							  </a>
						  </div>
					  }>
						 <div>
							 <Button
							  style={{
								  background:'#b4b4b3',
								  color:"#fff"
							  }}
							  modifier={ModifierType.Transparent}>Bulk Edit</Button>
						 </div>
					 </Tippy>
				 </div> :
				  <>
					  {
						  !isBulkEdit ? (
						   <div className={styles.bulkBtnWrapper}>
							   <Button modifier={ModifierType.Secondary} handleClick={handleChangeBulkEdit}>Bulk Edit</Button>
						   </div>
						  ) : (
						   <div className={styles.saveAndCancel} id='saveAndCancel'>
							   <Button
								handleClick={() => handleSaveListings()}
								modifier={ModifierType.Primary}
								disabled={!saveActive}
							   >{retryActive ? 'Retry' : 'Save'}</Button>
							   <Button handleClick={handleCancel} modifier={ModifierType.Error}>Cancel
								   Edits</Button>
						   </div>
						  )
					  }
				  </>
			 }

		 </div>
	 </div>
	)
}