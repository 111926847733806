import React, {useEffect} from 'react'
import styles from './MainPage.module.scss'
import {useDispatch} from "react-redux";

import QuickLinks from "./components/QuickLinks";
import Iframe from "../../components/UI/Iframe";
import getToken from "../../utils/getToken";
import jwt_decode from "jwt-decode";
import {setRoles} from "../../redux/slices/RoleSlice";
import {Helmet} from "react-helmet";


interface IMainPageProps {
}

const MainPage = (props: IMainPageProps) => {
	const token: any = getToken();
	const decodedToken: any = jwt_decode(token);
	const roles = decodedToken?.data?.user?.roles;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setRoles(roles));
	}, [dispatch]);

	return (
	 <>
		 <Helmet>
			 <title>
				 Main Menu | List Perfectly
			 </title>
		 </Helmet>
		 <div
		  className={styles.mainPage}
		 ><QuickLinks/>
			 <Iframe/>
		 </div>
	 </>

	)
}

export default MainPage