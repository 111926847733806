import styles from "./FooterComponent.module.scss";
import Select, {components, SingleValueProps} from "react-select";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";

export const FooterComponent = ({savingProgress, showEditAddModal, disableSaveButtons, editId, handleAddListing, forTabletSize, enabledButtons, handleSaveForm, setSaveAndClose, setSaveAndPreview, setSaveAndNew} :any)=>{
    const FooterButtonsOptions =  [
        { value: 'save_progress', label: 'Save Progress' },
        { value: 'save_and_new', label: 'Save & New' },
        { value: 'save_and_preview', label: 'Save & Preview' },
        { value: 'save_and_close', label: 'Save & Close' }
    ]
    const selectedFromStorage =  localStorage.getItem('selectedOptionSave');
    const [selectedOptionFooter, setSelectedOptionFooter] = useState<any>(selectedFromStorage ? JSON.parse(selectedFromStorage) : FooterButtonsOptions[3]);

    const customStylesFooter = {
        control: (provided:any, state:any) => ({
            ...provided,
            height: '40px',
            borderRadius: '0 8px 8px 0',
            cursor:'pointer',
            maxWidth: '100px',
        }),
        container: (provided: any, state: any) => ({
            ...provided,
            fontSize: '14px'
        }),
        menu: (provided: any) => ({
            ...provided,
            maxWidth: '140px',
            minWidth: '140px',
            fontSize: '14px',
            right: 0,
            left: 'auto',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'nowrap',
            overflow: 'visible'
        }),
    };

    const handleSelectChange = (selectedOptionFooter:any) => {
        setSelectedOptionFooter(selectedOptionFooter);
        localStorage.setItem('selectedOptionSave', JSON.stringify(selectedOptionFooter));
    };

    const handleFooterSaveButtons = (value:any)=>{
        switch (value){
            case 'save_progress':
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_new':
                setSaveAndNew(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_preview':
                setSaveAndPreview(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_close':
                setSaveAndClose(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            default:
        }
    }
    // Custom component to hide the selected value
    const CustomSingleValue = (props: SingleValueProps<any>) => (
        <components.SingleValue {...props}>
            <span style={{ color: 'hsl(0, 0%, 50%)', whiteSpace: 'nowrap' }}>Options</span>
        </components.SingleValue>
    );

    return (
        <div className={`${styles.footerButtonsMobile} ${forTabletSize ? styles.footerButtonsTablet : styles.fixedFooterMobile} ${editId && showEditAddModal ? styles.footerButtonsMobileOnModal : ''}`}>
            <Select
                options={FooterButtonsOptions}
                onChange={handleSelectChange}
                placeholder={'Options'}
                menuPlacement={forTabletSize ? "bottom" : 'top'}
                styles={customStylesFooter}
                isSearchable={false}
                components={{ SingleValue: CustomSingleValue }}
            />
            {selectedOptionFooter && (
                <button
                    onClick={() => {
                        if(enabledButtons && !disableSaveButtons){
                            handleFooterSaveButtons(selectedOptionFooter.value)
                        }else{
                            console.log('happening')
                            toast.warning(`Oops! It looks like no changes were made to your listing. Please edit something for the 'Save' button to become active.`, {
                                position: 'bottom-right', // You can customize the notification position
                            });
                        }
                    }}
                    style={!enabledButtons || disableSaveButtons || savingProgress  ? {opacity: 0.7, cursor: 'not-allowed'} : {opacity: 1}}
                    // disabled={!enabledButtons || disableSaveButtons || savingProgress }
                >
                    {selectedOptionFooter.label}
                </button>
            )}
        </div>
    )
}