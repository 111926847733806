import React, {Dispatch, useEffect, useState} from 'react';
// @ts-ignore
import {ChromePicker, CustomPicker} from 'react-color';
import {useGlobalUploaderState} from "../hooks/hooks";
import UploaderService from "../services"

interface CustomColorPickerInterface {
    displayColorPicker: boolean,
    mainColor: string,
    setDisplayColorPicker: Function,
    colors: Array<string>
    wc_hex_is_light: Function,
    saveColors: any
}

const CustomColorPicker = ({displayColorPicker,setDisplayColorPicker, mainColor,colors,wc_hex_is_light, saveColors}: CustomColorPickerInterface) => {
    const [localColors, setLocalColors]: [Array<string>, Dispatch<any>] = useState([]);
    const [selectedColor, setSelectedColors] = useState("#FFFFFF");
    const [displaySecondColorPicker, setDisplaySecondColorPicker] = useState(false);

    useEffect(() => {
        setLocalColors(colors);
    }, [colors])

    useEffect(() => {
        setSelectedColors(mainColor);
    }, [mainColor])

    const onColorChange = (hex: string, event: Event) => {
        let arrWithNewValue = [...localColors];
        if (event.type !== "click"){
            let found = false;
            arrWithNewValue = localColors.map((value, i) => {
                if (!found && value.toLowerCase() === selectedColor.toLowerCase()) {
                    found = true
                    return hex;
                }
                return value;
            });
        }
        setLocalColors(arrWithNewValue)
        setSelectedColors(hex)
    };

    const resetColors = () => {
        setLocalColors(colors);
        setSelectedColors(mainColor);
        setDisplayColorPicker(false)
    }
    return <div  className={displaySecondColorPicker ? "iu-color-picker opened" : "iu-color-picker"}>
        {displaySecondColorPicker && <ChromePicker color={selectedColor.replace('#', '')} onChangeComplete={({hex}:any, e: any) => {
            setSelectedColors(hex)
            onColorChange(hex, e)
        } } disableAlpha={true} />}
        <div className="iu-color-picker-colors">
            {localColors.map((color) => {
                let colorClass = !wc_hex_is_light(color) ? "iu-color-picker-item" : "iu-color-picker-item black"
                if (selectedColor.toLowerCase() === color.toLowerCase()) colorClass = colorClass + " selected"
                return <div key={color} onClick={(e:any) => {
                    setSelectedColors(color)
                    onColorChange(color, e)
                }} style={{backgroundColor: color}}
                            className={colorClass}></div>
            })}

            <div className="iu-color-picker-input">
                <div className="iu-color-picker-opener" onClick={() => setDisplaySecondColorPicker(!displaySecondColorPicker)} >#</div>
                <input onChange={(e: any) => onColorChange(e.target.value, e)} value={selectedColor.replace('#', '')} type="text"/>
            </div>
        </div>
        <div className="bg-rm-color-picker-actions" style={{display: "flex", flexDirection: "row", justifyContent: "right"}}>
            <div className="lp-discard-small-btn" onClick={resetColors}>Discard</div>
            <div className="lp-submit-small-btn" onClick={() => saveColors(localColors,selectedColor)} style={{marginLeft: 12}}>Save Color</div>
        </div>
    </div>;
}

const ReactColorCustomColorPicker = CustomPicker(CustomColorPicker)

const BackgroundRemoverColorPicker = () => {
    const {state, dispatch} = useGlobalUploaderState();
    const { selected_remove_bg_color, remove_bg_colors, userConfig } = state;
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const wc_hex_is_light = (color: string) => {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    }

    const showPicker = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const colorSubmit = (colors: Array<string>, selected: string) => {
        dispatch({type: "SETBACKGROUNDREMOVECOLORS", data: {remove_bg_colors: colors, selected_remove_bg_color: selected}})
        setDisplayColorPicker(false);
        UploaderService.saveUserConfig({...userConfig, selected_remove_bg_color: selected, remove_bg_colors: colors}).then()
    }

    return <div className="bg-rm-color-picker">
        <div className="bg-rm-color-picker-main-color" onClick={showPicker} style={{color: !wc_hex_is_light(selected_remove_bg_color) ? "#ffffff" : "#000" ,backgroundColor: selected_remove_bg_color}}>
            <p>Color</p>
        </div>
        {displayColorPicker && <div style={{position: "absolute", zIndex:99999}}>
            <div className="bg-rm-color-picker-cover"   onClick={ showPicker }/>
            <ReactColorCustomColorPicker setDisplayColorPicker={setDisplayColorPicker} mainColor={selected_remove_bg_color} saveColors={colorSubmit} wc_hex_is_light={wc_hex_is_light} colors={remove_bg_colors} ></ReactColorCustomColorPicker>
        </div>}
    </div>
}



export default BackgroundRemoverColorPicker;