import React, {  useReducer, ReactNode } from 'react';
import {uploaderGlobalReducer} from "../reducer";
import {UploaderState} from "../interfaces";
import {GlobalUploaderStateContext} from "../context/UploaderContext";
// Define the initial state
const initialState: UploaderState = {
    files: [],
    activeFile: null,
    confirmCrop: false,
    saveImage: false,
    showUploaderCropSquare: false,
    cachedFile: null,
    userConfig: {},
    showModalLoader: false,
    modalLoaderText: "Image is loading for edit...",
    selected_remove_bg_color: '#FFFFFF',
    remove_bg_colors: ["#000000", "#FFFFFF", "#36454F", "#00D084", "#8ED1FC", "#0693E3", "#FF6900", "#FCB900", "#F78DA7", "#9900EF"],
    removeBackgroundStats: {
        total: 0,
        available: 0
    },
    showImageSelection: false,
    selectedImageIndexes: [],
    showUploaderMainLoader: false,
    uploaderMainLoaderText: "",
    uploadedFileCounter: 0,
    showUploaderModal: false,
    imageLoadingThreadsQueue: 0,
    activeStatus: 2,
    showUploaderCropGrid: false,
    showUploaderCropView: false,
    cropButtonDisabled: true
};

export const GlobalUploaderStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(uploaderGlobalReducer, initialState);
    return <GlobalUploaderStateContext.Provider value={{ state, dispatch }}>{children}</GlobalUploaderStateContext.Provider>;
};