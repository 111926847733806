import React, {Fragment, useState} from 'react';
import {useGlobalUploaderState} from "../hooks/hooks";
import {toast} from "react-toastify";
import {imageUploadLimitCount} from "../const/const";
import Tippy from "@tippyjs/react";
import {PopoverIcon} from "../../../assets/icons";

const ImageUploadButton = (props: {uploadFileToUploader: Function,expressListerView: Boolean, setShowImagesUploadingStatusLoader: any }) => {
    const [focused, setFocused] = useState(false);
    const {state} = useGlobalUploaderState();
    const dragOver = () => !focused && setFocused(true)
    const dragEnter = () => setFocused(true)
    const dragLeave = () => setFocused(false)

    const handleImageLoad = async (e: any) => {
        e.preventDefault();
        //TODO filters polyfill
        const {files:eventFiles = []} = e.target;
        if (eventFiles.length === 0) return;
        let files = Array.from(eventFiles);
        if (files.length > imageUploadLimitCount){
            files.splice(imageUploadLimitCount - files.length, files.length);
            toast.error(`Add up to 30 files. Limit reached.`);
        }
        props.setShowImagesUploadingStatusLoader(true);
        props.uploadFileToUploader(files, 0)
    }

    return state.files.length < 30 ?
        props.expressListerView ?
            <label
                className="disable-select imageUploadInputContainerLabel"
                htmlFor="imageUploadInput"
            >
                <div className={state.files.length ? 'uploader-desk-titles' : 'uploader-desk-titles empty'}>
                    <div className={ 'uploader-desk-title-main'}>
                        <div className='drag-image'></div>
                        <div className="imageUploadInputContainer">Drag and drop your images here or
                            <span className={'browse'}>
                                <span className="upload--image-text-desc">
                                     &nbsp;Browse
                                </span>
                                <Tippy
                                      interactive={true}
                                      zIndex={5}
                                      arrow={false}
                                      trigger="mouseenter"
                                      content={
                                  <div className={`popover`}>
                                       <p>- Add up to 30 files</p>
                                       <p>- Max file size: 15MB</p>
                                       <p>
                                         - Supported format: jpg, png, tiff, heic, webp, bmp
                                       </p>
                                 </div>
                             }
                            >
                        <PopoverIcon
                         style={{marginLeft: 6, cursor: "pointer"}}
                         color={'#fff'}
                        ></PopoverIcon>
                    </Tippy>

                           <input
                               id="imageUploadInput"
                               onChange={handleImageLoad}
                               type="file"
                               multiple={true}
                               accept="image/jpeg, image/png, image/tiff, image/bmp, image/heic, image/webp"/>
                        </span>
                        </div>
                    </div>
                <p className="uploader-desk-title-mb">Images</p>
</div>
            </label>
:
    <label
        className={focused ? "imageUploaderContainer imageUploaderContainerMob imageUploaderFocused disable-select" : "imageUploaderContainer disable-select imageUploaderContainerMob"}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                htmlFor="imageUploadInput"
            >
                <div className="upload-image-icon"></div>
                <span className="upload--image-text">
             Images
        </span>
                <input
                    id="imageUploadInput"
                    onChange={handleImageLoad}
                    type="file"
                    multiple={true}
                    accept="image/jpeg, image/png, image/tiff, image/bmp, image/heic, image/webp"/>
            </label> : <Fragment/>
};

export default ImageUploadButton;