import styles from "../InventoryAndSales.module.scss";
import {ChangeEvent, useState} from "react";
import {InputCount} from "../../../InputCount";

const CustomIds = ({
                       title,
                       unescapedRowMetadata,
                       setNewTableInfo,
                       itemData,
                       setItemData,
                       id,
                       editIndex,
                       newTableInfo
                   } : any) => {
    let initialPlatformInputs = {
        [`newPlatformIdByCustom${title}`]: unescapedRowMetadata[id],
    }
    const [platformInputs, setPlatformInputs] = useState<any>(
        initialPlatformInputs,
    );

    const handleChangePlatformId = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let updatedItems: any = [...itemData];
        setPlatformInputs({
            ...platformInputs,
            [name]: value,
        });
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [name]: value,
            changed: true,
        };
        setItemData(updatedItems);
        setNewTableInfo({
            ...newTableInfo,
            [name]: { text: value, changed: true },
        });
    };

    return (
        <div className={styles.inpRightItem}>
            <p className={styles.heading}>{title} item #</p>
            <InputCount
                placeholder=""
                name={`newPlatformIdByCustom${title}`}
                value={platformInputs[`newPlatformIdByCustom${title}`]}
                limit={500}
                height={40}
                hideCount={true}
                handleChange={handleChangePlatformId}
            />
        </div>
    )
}

export default CustomIds;