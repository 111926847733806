import React, { useEffect, useState } from 'react';
import cl from './ProgressiveImg.module.scss';
import FullImageModal from '../FullImageModal';
import styles from './ProgressiveImg.module.scss';
import {FaImage} from "../../../../../assets/icons";

const ProgressiveImg = ({
                            placeholderSrc,
                            src,
                            resultImageUrls,
                            ...props
                        }: any) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
    const [customClass, setcustomClass] = useState(
        placeholderSrc && imgSrc === placeholderSrc ? 'loading' : '',
    );
    const [showFullImage, setShowFullImage] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImgSrc(src);
            setcustomClass('loaded');
        };

        return img.removeEventListener('load', () => {
            setImgSrc(src);
            setcustomClass('loaded');
        });
    }, [src]);

    return (
        <>
            {src ? (
                <div className={styles.progressiveImg}>
                    <p className={styles.imageOrder}>1</p>
                    <img
                        {...{ src: imgSrc, ...props }}
                        className={cl[customClass]}
                        alt={props.alt || ''}
                        style={{
                            objectFit: 'contain',
                            width: '295px',
                            height: '295px',
                            borderRadius: '8px',
                        }}
                        onClick={() => setShowFullImage(true)}
                    />
                    {showFullImage && (
                        <FullImageModal
                            imgSrc={imgSrc}
                            resultImageUrls={resultImageUrls}
                            setShowFullImage={setShowFullImage}
                            imageIndex={0}
                        />
                    )}
                </div>
            ) : (
                <div>
                    <FaImage
                        width={300}
                        height={300}
                        style={{
                            objectFit: 'contain',
                            border: '#4db89e solid 4px',
                            borderRadius: '8px',
                        }}
                    />
                </div>
            )}
        </>
    );
};
export default ProgressiveImg;