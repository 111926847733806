import React, { useEffect, useState } from 'react';
import styles from './inputCount.module.scss';

interface TextAreaInreface {
	placeholder: string;
	name: string;
	value: string;
	limit: number;
	height: number;
	type?: string;
	handleChange?:any;
	hideCount?:any;
	handleVariationsChange?:any;
	index?:any;
	subName?:any;
	showCount?:any;
}

export const InputCount = ({
							   placeholder,
							   name,
							   value,
							   limit,
							   height,
							   type,
							   handleChange,
							   hideCount,
							   handleVariationsChange,
							   index,
							   subName,
							   showCount
						   }: TextAreaInreface) => {
	const [characterCount, setCharacterCount] = useState(0);
	const id = Math.random().toString();
	const [hideCharactersCount, setHideCharactersCount] = useState(false);
	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (type === 'number') {
			const charCode = event.charCode;
			if ((charCode < 48 || charCode > 57) && charCode !== 46) {
				event.preventDefault();
			}
		}
	};
	useEffect(() => {
		if(showCount){
			setHideCharactersCount(true)
		}
	}, [showCount]);

	const handleFocus = () => {
		if(!hideCount && !showCount){
			setHideCharactersCount(true)
		}
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		if (type === 'number') {
			const paste = event.clipboardData.getData('text');
			if (!/^\d*\.?\d*$/.test(paste)) {
				event.preventDefault();
			}
		}
	};

	const handleBlur = () => {
		if(!showCount){
			setHideCharactersCount(false)
		}
	};

	useEffect(() => {
		if (value) {
			setCharacterCount(value.length);
		}
	}, [value]);

	return (
	 <div style={{ width: '100%' }}>
		 <input
		  onKeyPress={handleKeyPress}
		  className={styles.brandInfoTextarea}
		  id={id}
		  value={value}
		  name={name}
		  style={{ height: height }}
		  onFocus={handleFocus}
		  onBlur={handleBlur}
		  placeholder={placeholder}
		  onPaste={handlePaste}
		  maxLength={limit}
		  onChange={(e) => {
			  const newText = e.target.value;
			  if (newText.length <= limit) {
				  if (handleVariationsChange) {
					  handleVariationsChange(index, subName, newText);
				  }else {
					  handleChange(e);
				  }
				  if(!hideCharactersCount){
					  setCharacterCount(newText.length);
				  }
			  }
		  }}
		 />
		 {
		  hideCharactersCount &&  <p className={styles.charactersQuantity}>
			  Character Count: {characterCount} / {limit}
		  </p>
		 }

	 </div>
	);
};