import {useDispatch} from 'react-redux';
import {useIntercom} from 'react-use-intercom'
import Tippy from "@tippyjs/react";

import {
	AddListingM,
	ListingSvg,
	Helper,
	NotificationVector,
	MenuSvg,
	MenuActive,
	ViewTemplates,
	Community,
	Account,
	GuideToLp,
	ExpressListerBlue,
	ExpressListerWhite,
	SettingsIconGearSvg,
	HeaderStarIcon,
	ThreeDots,
	Print,
	EditListing,
	SVGNewSquares,
	OptProPlus,
	EditPencilSvg,
	SalesSvg
} from '../../assets/icons';

import {
	iframeLoaderChanger,
	setActivateNotificationModal,
	setCandidantLinkPure, setEditAddModalId, setEditAddModalShow, setExpressListerNewClicked,
	setIframeLink,
	setIframeName,
	setShowIframe, setShowListingViewModal,
	setShowQuestionModal,
} from '../../redux/slices/NavigationSlice';
import styles from './MobileMenu.module.scss';
import {useSelector} from 'react-redux';
import React, {useEffect, useRef, useState} from 'react';
import getToken from '../../utils/getToken';
import axios from 'axios';
import {useNavigate} from 'react-router';
import { setSettingsActiveIndex } from '../../redux/slices/SettingsSlice';
import './MobileMenu.css'
import {Link} from "react-router-dom";
import jwt_decode from "jwt-decode";
import EditFavoriteLinksModal from "./components/EditFavoriteLinksModal";
import iframe from "../UI/Iframe";

interface NavigationState {
	active: boolean;
	isBulkEdit: boolean;
	iframeLink: string;
	isDeleted: number;
	expressListerNewActive: boolean;
}

interface RootState {
	navigation: NavigationState;
}

const MobileMenuComponent: React.FC<any> = ({ routerLink, quickLinks,setQuickLinks,configID }: any) => {
	const link = routerLink;
	const iframeLink = useSelector(
	 (state: RootState) => state.navigation.iframeLink
	);
	const newExpressEnabled = useSelector(
	 (state: RootState) => state.navigation.expressListerNewActive
	);
	const showListingViewModal = useSelector((state: any) => state.navigation.listingViewModalShow);
	const localRouter = showListingViewModal ? 'listView' : routerLink
	const allowAllTemplatesAccess = useSelector((state: any) => state.navigation.allowAllTemplatesAccess);
	const dispatch = useDispatch();
	const listViewId = useSelector((state: any) => state.navigation.listingViewId);
	const expressListingId = useSelector((state: any) => state.navigation.expressListingId);
	const [notificationsLength, setNotificationsLength] = useState(0);
	const [isChecked, setIsChecked] = useState(false);
	const [isButtonActive, setButtonActive] = useState(false);
	const [editFavoriteModalVisible,  setEditFavoriteModalVisible] = useState(false)
	const navigate = useNavigate();
	const customContainerRefForTippy = useRef<any>(null);
	const toggleButton = () => {
		setButtonActive(!isButtonActive);
	};

	const handleToggleEditFavoriteModal = () => {
		setEditFavoriteModalVisible(!editFavoriteModalVisible)
	}
	const settingsActiveIndex = useSelector(
	 (state: any) => state.settings.settingsActiveIndex
	);
	const handleChangeDropdownToSettingsPage = (index: number) => {
		dispatch(
		 setSettingsActiveIndex(settingsActiveIndex === index ? null : index)
		);
		toggleButton();
	};
	const hideMenu = (): void => {
		setButtonActive(false);
	};
	const [activeIndex, setActiveIndex] = useState<number | null>(1);
	const {  showMessages } = useIntercom();
	const menuOverlay = {
		backgroundColor: isButtonActive ? 'rgba(0, 0, 0, 0.4)' : '',
		height: isButtonActive ? '100vh' : '',
		// You can add more styles here as needed
	};
	const token: any = getToken();
	const decodedToken: any = jwt_decode(token);
	const roles = decodedToken?.data?.user?.roles;
	const hasSimple = roles
	.map((role: string) => role.toLowerCase())
	.includes('simple'.toLowerCase());

	const is_role_allowed = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'corporate master' ||
		 role.toLowerCase() === 'corporate sub'
		);
	});

	const is_role_allowed_master = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'corporate master'
		);
	});

	const hasSubAcc = roles.some((role: any) => {
		return (role.toLowerCase() === 'corporate sub');
	});

	const hasPro = roles
	.map((role: string) => role.toLowerCase())
	.includes('pro'.toLowerCase());

	useEffect(() => {
		const token = getToken();
		if (token) {
			axios
			.post(
			 'https://api.listperfectly.com/v2/notifications/list',
			 {
				 metadata: {offset: 0, limit: 10},
				 filter_by: {
					 state: 'public',
				 },
			 },
			 {
				 headers: {
					 Authorization: `Bearer ${token}`,
				 },
			 }
			)
			.then((response) => {
				const totalNotifications = response?.data?.metadata?.total;

				// Retrieve deleted notifications from localStorage
				const deletedNotifications = JSON.parse(
				 localStorage.getItem('deletedNotifications') || '[]'
				);

				// Calculate the effective notifications count
				const effectiveNotificationsLength =
				 totalNotifications - deletedNotifications.length;
				setNotificationsLength(effectiveNotificationsLength);
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
		}
	}, []);

	const handleClick = (index: number) => {
		if (activeIndex === index) {
			setActiveIndex(null); // Close the clicked accordion if it's already open
		} else {
			setActiveIndex(index);
		}
	};

	const handleOutsideClick = (e: any) => {
		if (e.target === e.currentTarget) {
			setButtonActive(false);
		}
	};

	const HandleshowMessages = () => {
		showMessages();
	}


	useEffect(() => {
		if(link !== 'settings'){
			dispatch(
			 setSettingsActiveIndex(null)
			);
		}
	}, [link]);



	return (
	 <div
	  className={styles.mobileMenuContainer}
	  style={menuOverlay}
	  onClick={handleOutsideClick}
	  ref={customContainerRefForTippy}
	  id="mobileMenuMain"
	 >
		 <div className={styles.mainFooterMenu}>
			 {isButtonActive ? (

			  editFavoriteModalVisible ?
			   <EditFavoriteLinksModal
				quickLinks={quickLinks}
				setQuickLinks={setQuickLinks}
				handleToggleEditFavoriteModal={handleToggleEditFavoriteModal}
				configID={configID}
				hasPro={hasPro}
				isRoleAllowed={is_role_allowed}
			   />

			   :
			   <div
				style={{
					overflowY:'auto',
					background:"#fff",
					height:'79vh',
				}}
			   >
				   <div className={styles.menuOpened}>
					   {
						!is_role_allowed_master && <a
							className={styles.optProPlus}
							target={'_blank'}
							href='https://help.listperfectly.com/en/articles/9512203-access-more-features-with-pro-plus '
						   >
							   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
								   <OptProPlus width={17} height={20}/>
								   <p className="navigationText">Opt Into Pro Plus</p>
							   </div>
						   </a>
					   }

					   {<div
						className={styles.favoritesBlock}>
						   <div className={styles.titleBlock}>
							   <div className={styles.left}>
								   <div className={styles.title}>Favorites</div>
								   <HeaderStarIcon/>
							   </div>
							   <ThreeDots
								className={styles.threeDots}
								onClick={handleToggleEditFavoriteModal}
							   />
						   </div>
						   <div className={`${styles.favoriteLinkWrapper} favoriteLinkWrapper`}>
							   {quickLinks?.map((link: any) => {
								   if (link.value === 'express_lister') {
										   return (
											<Link
											 to={link.href}
											 className={styles.quickLinkItem}
											 onClick={() => {
												 if (link.value === 'website' || link.value === 'extension') {
													 handleChangeDropdownToSettingsPage(link.value === 'website' ? 3 : 1)
												 }
												 hideMenu()
											 }}
											>
												<link.svg/>
												<div>{link.title}</div>
											</Link>
										   )
								   }


								   return (
									link.value === 'website' || link.value === 'extension' || (link.value === 'express_lister') || link.value === 'view_listings' || link.value === 'sales__analytics' ? (
									  <Link
									   to={link.href}
									   className={styles.quickLinkItem}
									   onClick={() => {
										   if (link.value === 'website' || link.value === 'extension') {
											   handleChangeDropdownToSettingsPage(link.value === 'website' ? 3 : 1)
										   }
										   hideMenu()
									   }}
									  >
										  <link.svg/>
										  <div>{link.title}</div>
									  </Link>
									 ) :
									 (
									  <div
									   key={link.id}
									   className={styles.quickLinkItem}
									   onClick={() => {
										   if (link.href) {
											   window.open(`${link.href}`, '_blank', 'noopener,noreferrer')
										   }
										   if (!link.href && link.value === 'contact_us') {
											   hideMenu();
											   HandleshowMessages()
										   }
										   if (!link.href && link.value === 'add_listing') {
											   hideMenu();
											   if (!iframeLink.length) {
												   dispatch(setShowIframe());
												   dispatch(setIframeName('add listing'));
												   dispatch(iframeLoaderChanger(true));
												   dispatch(
													setIframeLink('listperfectly.com/listings/add')
												   );
											   } else if(iframeLink !== 'listperfectly.com/listings/add') {
												   dispatch(
													setShowQuestionModal(
													 'https://app.listperfectly.com/listings/add/'
													)
												   );
												   dispatch(
													setCandidantLinkPure(
													 'listperfectly.com/listings/add'
													)
												   );
											   }
										   }
										   if (!link.href && link.value === 'add_template') {
											   hideMenu();
											   navigate('/templates/add')
										   }
										   if (!link.href && link.value === 'view_templates') {
											   hideMenu();
											   navigate('/templates')
										   }
									   }}
									  >
										  <link.svg/>
										  <div>{link.title}</div>
									  </div>
									 )
								   )
							   })}

						   </div>
					   </div>
					   }
					   <div className={styles.NavigateTo}>Navigate to</div>
					   <div className={styles.list}>
						   <div className={
							   link === 'listings'
								? 'acordionHeederActive'
								: 'acordionHeeder'
						   }>
							   <div
								className="acordionFlex"
								onClick={() => handleClick(1)}
								style={{
									justifyContent: 'space-between',
								}}
							   >
								   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
									   <ListingSvg/>
									   <div>Listings</div>
								   </div>


							   </div>
							   {activeIndex === 1 && (
								<>
									<div className={'downSection'}>
										<a>
											<div
											 className={styles.acrodionItemListing}
											 style={{
												 fontSize: 16
											 }}
											 onClick={() => {
												 hideMenu();
												 navigate('/listings/express')
											 }}
											>
												Express Lister
											</div>
										</a>
										<a>
											<div
											 className={styles.acrodionItemListing}
											 style={{
												 fontSize: 16
											 }}
											 onClick={() => {
												 hideMenu();
												 if (!iframeLink.length) {
													 dispatch(setShowIframe());
													 dispatch(setIframeName('add listing'));
													 dispatch(iframeLoaderChanger(true));
													 dispatch(
													  setIframeLink('listperfectly.com/listings/add')
													 );
												 } else if (iframeLink !== 'listperfectly.com/listings/add') {
													 dispatch(
													  setShowQuestionModal(
													   'https://app.listperfectly.com/listings/add/'
													  )
													 );
													 dispatch(
													  setCandidantLinkPure(
													   'listperfectly.com/listings/add'
													  )
													 );
												 }
											 }}
											>
												{'Add Listings'}
											</div>
										</a>
										<a
										 href={"https://app.listperfectly.com/listed-items/"}
										 target="_blank"
										 rel="noreferrer">
											<div
											 className={styles.acrodionItemListing}
											 style={{
												 padding: '10px',
												 fontSize: 16
											 }}
											>
												{'Old Catalog'}
											</div>
										</a>

									</div>
									{
										<div className={'downSection'}>
											<a
											 href={`https://my.listperfectly.com/listings`}

											 onClick={(e) => {
												 e.preventDefault()
												 navigate('/listings');
												 toggleButton();
											 }}
											 target="_blank"
											 rel="noreferrer">
												<div
												 className={styles.acrodionItemListing}
												 style={{
													 padding: '10px',
													 fontSize: 16
												 }}
												>
													{'View Listings'}
												</div>
											</a>
										</div>
									}
								</>

							   )}

						   </div>
						   <div className={'acordionHeeder'}>
							   <div
								className="acordionFlex"
								onClick={() => handleClick(2)}
								style={{
									justifyContent: 'space-between',
								}}
							   >
								   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
									   <ViewTemplates/>
									   <div>Templates</div>
								   </div>

							   </div>
							   {activeIndex === 2 && (
								<div
								 className="downSection"
								 style={{alignItems: ''}}
								>
									{
									 ((hasSubAcc && allowAllTemplatesAccess) || !hasSubAcc) && <div
									  className={styles.acrodionItemListing}
									  style={{
										  padding: '10px',
										  fontSize: 16
									  }}
									  onClick={() => {
										  hideMenu();
										  navigate('/templates/add')
									  }}
									 >
										 {'Add Template'}
									 </div>
									}
									<div
									 className="acrodionItemListingAddTemplate"
									 style={{
										 padding: '10px',
										 fontSize: 16
									 }}
									 onClick={() => {
										 hideMenu();
										 navigate('/templates')
									 }}
									>
										{'View templates'}
									</div>
								</div>
							   )}
						   </div>


						   <div className={'acordionHeeder'}>
							   <div
								className="acordionFlex"
								onClick={() => handleClick(99)}
								style={{
									justifyContent: 'space-between',
								}}
							   >
								   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
									   <SalesSvg/>
									   <div>Sales</div>
								   </div>

							   </div>
							   {activeIndex === 99 && (
								<div
								 className="downSection"
								 style={{alignItems: ''}}
								>
									<div

									 onClick={(e) => {
										 e.preventDefault()
										 navigate('/sales');
									 }}
									>
										<div
										 className={styles.acrodionItemListing}
										 style={{
											 padding: '10px',
											 fontSize: 16
										 }}
										>
											{'Sales & Analytics'}
										</div>
									</div>

								</div>
							   )}
						   </div>


						   <div className={'acordionHeeder'}>
							   <div
								className="acordionFlex"
								onClick={() => handleClick(3)}
								style={{
									justifyContent: 'space-between',
								}}
							   >
								   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
									   <Helper/>
									   <div>Help & Support</div>
								   </div>

							   </div>
							   {activeIndex === 3 && (
								<div
								 className="downSection"
								 style={{alignItems: ''}}
								>
									<a
									 onClick={HandleshowMessages}
									>
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											Contact Us
										</div>
									</a>
									<a
									 target="_blank"
									 href="https://help.listperfectly.com/en/"
									>
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											{'Help Center'}
										</div>
									</a>

									<a
									 target="_blank"
									 href="https://help.listperfectly.com/en/articl­es/8913965-install-list-perfectly"
									>
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											{'Latest Version'}
										</div>
									</a>
									<a
									 target="_blank"
									 href="https://help.listperfectly.com/en/articles/9039723-current-technical-issue"
									>
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											{'Current  Issues'}
										</div>
									</a>
									<a target="_blank" href="https://listperfectly.com/faq/">
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											{'FAQ'}
										</div>
									</a>
								</div>
							   )}
						   </div>
						   <div className={'acordionHeeder'}>
							   <div
								className="acordionFlex"
								onClick={() => handleClick(4)}
								style={{
									justifyContent: 'space-between',
								}}
							   >
								   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
									   <Community/>
									   <div>Community</div>
								   </div>

							   </div>
							   {activeIndex === 4 && (
								<div
								 className={'downSection'}
								 style={{alignItems: ''}}
								>
									<a target="_blank" href="https://listingparty.com/">
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											<p
											 style={{
												 display: 'flex',
												 alignItems: 'center',
												 gap: '10px',
											 }}
											>
												{'Listing Party'}{' '}
											</p>
										</div>
									</a>
									<a target="_blank" href="https://listingparty.com/events">
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											<p
											 style={{
												 display: 'flex',
												 alignItems: 'center',
												 gap: '10px',
											 }}
											>
												{'Events'}{' '}
											</p>
										</div>
									</a>
									<a
									 target="_blank"
									 href="https://app.listperfectly.com/referral-area/?tab=resources"
									>
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											<p
											 style={{
												 display: 'flex',
												 alignItems: 'center',
												 gap: '10px',
											 }}
											>
												{'Referral'}
											</p>
										</div>
									</a>
									<a
									 target="_blank"
									 href="https://listperfectly.com/community/"
									>
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											<p
											 style={{
												 display: 'flex',
												 alignItems: 'center',
												 gap: '10px',
											 }}
											>
												{'Community'}
											</p>
										</div>
									</a>
								</div>
							   )}
						   </div>
						   <div className={'acordionHeeder'}>
							   <div
								className="acordionFlex"
								onClick={() => handleClick(5)}
								style={{
									justifyContent: 'space-between',
								}}
							   >
								   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
									   <Account/>
									   <div>Account</div>
								   </div>

							   </div>
							   {activeIndex === 5 && (
								<div
								 className="downSection"
								 style={{alignItems: ''}}
								>
									<a
									 target="_blank"
									 href="https://app.listperfectly.com/account/?action=subscriptions"
									>
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											{'My Account'}
										</div>
									</a>
									<a
									 target="_blank"
									 href="https://app.listperfectly.com/my-requests/"
									>
										<div
										 className={styles.acrodionItem}
										 style={{whiteSpace: 'nowrap', fontSize: 16}}
										>
											{'Help Requests'}
										</div>
									</a>
									<a
									 target="_blank"
									 href="https://app.listperfectly.com/wp-login.php?action=logout&redirect_to=%2Flogin&_wpnonce=09317016fc"
									>
										<div
										 style={{fontSize: 16}}
										 className={styles.acrodionItem}>
											{'Log Out'}
										</div>
									</a>
								</div>
							   )}
						   </div>

						   <div
							className={
								link === 'settings'
								 ? 'acordionHeederActive'
								 : 'acordionHeeder'
							}
						   >
							   <div
								className="acordionFlex"
								onClick={() => {
									handleClick(6);
								}}
								style={{
									justifyContent: 'space-between',
								}}
							   >
								   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
									   <SettingsIconGearSvg/>
									   <div className={styles.settingsNew}>Settings <span>NEW!</span></div>
								   </div>

							   </div>
							   {activeIndex === 6 && (
								<div
								 className="downSection"
								 style={{alignItems: ''}}
								>
									<Link to="/settings">
										<div
										 className={styles.acrodionItem}
										 style={{
											 whiteSpace: 'nowrap',
											 background:
											  settingsActiveIndex === 1 ? '#f5f5f5' : '',
											 color: settingsActiveIndex === 1 ? '#2B2B2B' : '',
											 fontWeight: settingsActiveIndex === 1 ? 600 : 400,
											 fontSize: 16,
										 }}
										 onClick={() => handleChangeDropdownToSettingsPage(1)}
										>
											Extension
										</div>
									</Link>

									{
									 !hasSimple &&
									 <Link to="/settings">
										 <div
										  style={{
											  background:
											   settingsActiveIndex === 3 ? '#f5f5f5' : '',
											  color: settingsActiveIndex === 3 ? '#2B2B2B' : '',
											  fontWeight: settingsActiveIndex === 3 ? 600 : 400,
											  fontSize: 16
										  }}
										  className={styles.acrodionItem}
										  onClick={() => handleChangeDropdownToSettingsPage(3)}
										 >
											 {'Website'}
										 </div>
									 </Link>
									}

								</div>
							   )}
						   </div>


					   </div>
				   </div>
			   </div>

			 ) : (
			  ''
			 )}
			 <div className={styles.mobileMenuMain}>
				 {
				  (localRouter !== 'listView' && localRouter !== 'expressLister') && <div
				   className={styles.menuItem}
				   onClick={() => {
					   navigate('/');
				   }}
				  >
					  <div className={styles.itemLogo}>
						  <GuideToLp/>
					  </div>
					  <div className={styles.itemTitle}>Home</div>
				  </div>
				 }
				 {
				  (localRouter === 'expressLister' || localRouter === 'listView') &&
				  <a
				   className={styles.menuItem}
				   onClick={() => {
					   hideMenu()
					   dispatch(setShowListingViewModal(false));
					   navigate('/listings');
				   }}
				  >
					  <div className={styles.itemLogo}>
						  <ListingSvg/>
					  </div>
					  <div className={styles.itemTitle}>My Listings</div>
				  </a>
				 }
				 {
				  (localRouter === 'listView' && is_role_allowed && hasPro) &&
				  <a
				   className={styles.menuItem}
				   onClick={() => window.open(`${origin}/print-qr-page/${listViewId}`)}
				  >
					  <div className={styles.itemLogo}>
						  <Print/>
					  </div>
					  <div className={styles.itemTitle}>Inventory Label</div>
				  </a>
				 }
				 {
					 (localRouter === 'expressLister') &&
					 (hasPro && is_role_allowed) ?
					  <a
					   className={styles.menuItem}
					   onClick={() => {
						   if (expressListingId && newExpressEnabled && (hasPro && is_role_allowed)) {
							   window.open(`${origin}/print-qr-page/${expressListingId}`)
						   }
					   }}
					   style={(!newExpressEnabled || !expressListingId) ? {opacity: 0.7} : {opacity: 1}}
					  >
						  <div className={styles.itemLogo}>
							  <Print/>
						  </div>
						  <div className={styles.itemTitle}>Inventory Label</div>
					  </a> :
					  (localRouter === 'expressLister')  && <Tippy
						  interactive={true}
						  placement={'bottom'}
						  zIndex={5}
						  arrow={false}
						  trigger="focus click"
						  appendTo={customContainerRefForTippy?.current}
						  content={
							  <p>
								  Available for Pro Plus users. Upgrade to access
								  this feature and many more.
								  <a target="_blank"
									 href={'https://listperfectly.com/pricing'}> Upgrade
									  Now </a>
							  </p>
						  }>
							  <a
								  className={styles.menuItem}
								  style={{opacity: 0.7}}
							  >
								  <div className={styles.itemLogo}>
									  <Print/>
								  </div>
								  <div className={styles.itemTitle}>Inventory Label</div>
							  </a>
					  </Tippy>
				 }
				 {
					 localRouter === 'listView' &&
					 <div
						 className={styles.menuItem}
						 onClick={() => {
							 if(showListingViewModal) {
								 dispatch(setEditAddModalShow(true));
								 dispatch(setEditAddModalId(listViewId));
								 dispatch(setShowListingViewModal(false));
							 }else {
								 navigate(`/listings/edit/${listViewId}`);
							 }
						 }}
					 >
						 <div className={`${styles.itemLogo} ${styles.editLogo}`}>
							 <EditPencilSvg/>
						 </div>
						 <div className={styles.itemTitle}>Edit</div>
					 </div>
				 }
				 {
				  localRouter === 'listView' &&
					 <div
						 className={styles.menuItem}
						 onClick={() => {
							 if (!iframeLink.length) {
								 dispatch(setIframeName('duplicate'));
								 dispatch(setShowIframe());
								 dispatch(iframeLoaderChanger(true));
								 dispatch(
									 setIframeLink(
										 'listperfectly.com/listings/add/?mode=duplicate&action=duplicate&duplicate_id=' + listViewId
									 )
								 );
							 } else {
								 dispatch(
									 setShowQuestionModal(
										 'https://app.listperfectly.com/listings/add/?mode=duplicate&action=duplicate&duplicate_id=' + listViewId
									 )
								 );
								 dispatch(
									 setCandidantLinkPure(
										 'listperfectly.com/listings/add/?mode=duplicate&action=duplicate&duplicate_id=' + listViewId
									 )
								 );
							 }
							 dispatch(setShowListingViewModal(false));
						 }}
					 >
						 <div className={`${styles.itemLogo} ${styles.duplicateLogo}`}>
							 <SVGNewSquares/>
						 </div>
						 <div className={styles.itemTitle}>Duplicate</div>
					 </div>
				 }
				 {
					 localRouter !== 'expressLister' && localRouter !== 'listView'  ?
					  <a
					   className={styles.menuItem}
					   onClick={() => {
						   hideMenu()
						   navigate('/listings/express')
					   }}
					  >
						  <div className={styles.itemLogo}>
							  <ExpressListerBlue/>
						  </div>
						  <div className={styles.itemTitle}>Express Lister</div>
					  </a>
					  :
						 (localRouter !== 'listView') && <a
					   style={!newExpressEnabled ? {opacity: 0.7} : {opacity: 1}}
					   className={`${styles.menuItem} ${styles.menuItemExpress}`}
					   onClick={() => {
						   if (newExpressEnabled) {
							   hideMenu()
							   dispatch(setExpressListerNewClicked(true))
						   }
					   }}
					  >
						  <div className={styles.itemLogo}>
							  <ExpressListerWhite/>
						  </div>
						  <div className={styles.itemTitle}>New</div>
					  </a>
				 }
				 {
				  localRouter !== 'listView' &&
					 <div
						 className={styles.menuItem}
						 onClick={() => {
							 if (!iframeLink.length) {
								 dispatch(setShowIframe());
								 dispatch(setIframeName('add listing'));
								 dispatch(iframeLoaderChanger(true));
								 dispatch(setIframeLink('listperfectly.com/listings/add'));
							 } else if(iframeLink !== 'listperfectly.com/listings/add') {
								 dispatch(
									 setShowQuestionModal(
										 'https://app.listperfectly.com/listings/add'
									 )
								 );
								 dispatch(
									 setCandidantLinkPure('listperfectly.com/listings/add')
								 );
							 }
							 hideMenu();
						 }}>
						 <div className={styles.itemLogo}>
							 <AddListingM/>
						 </div>
						 <div
							 className={styles.itemTitle}>Add Listing
						 </div>
					 </div>
				 }

				 {
					 (localRouter !== 'expressLister' && localRouter !== 'listView') &&
					 <div
						 className={styles.menuItem}
						 onClick={() => {
							 setIsChecked(true);
							 dispatch(setActivateNotificationModal(true));
							 hideMenu();
						 }}
					 >
						 <div className={styles.itemLogo}>
							 <NotificationVector width={17} height={20}/>
						 </div>
						 <div className={styles.itemTitle}>Notifications</div>
					 </div>
				 }

				 <div onClick={toggleButton} className={styles.menuItem}>
					 <div className={styles.itemLogo}>
						 {isButtonActive ? <MenuActive/> : <MenuSvg/>}
					 </div>
					 <div
						 className={styles.itemTitle}>Menu
					 </div>
				 </div>
			 </div>
		 </div>
	 </div>
	);
};

export default MobileMenuComponent;