import React, {useState, useEffect, Dispatch} from 'react';
import {Tabs,TabList, TabPanel, Tab} from "react-tabs";
import {useGlobalUploaderState} from "../hooks/hooks";
import {UploaderFile} from "../interfaces";
import {updateFileProperty} from "../common";
// @ts-ignore
import Slider from 'react-rangeslider'
// @ts-ignore
import debounce from 'lodash';


const ModalFooter = ({hasSimple}: any) => {
    const {state, dispatch} = useGlobalUploaderState();
    const [squareAspect, setSquareAspect] = useState(false);
    const {activeStatus,activeFile,cropButtonDisabled, files, showUploaderCropGrid, showUploaderCropSquare} = state;
    const [brightnessValue, setBrightnessValue]: [brightnessValue: number,setBrightnessValue: Dispatch<any>] = useState(0)
    const [contrastValue, setContrastValue]:any = useState(0)

    useEffect(() => {
        if (brightnessValue === 0 && contrastValue === 0) return;
        const delayDebounceFn = setTimeout(() => {
            // @ts-ignore
            dispatch({type: "SETACTIVEFILE", data: {...activeFile, brightness: brightnessValue,contrast: contrastValue}})
        }, 10);
        return () => clearTimeout(delayDebounceFn);
    }, [brightnessValue,contrastValue]);

    if (!activeFile) return <></>
    const {flipVertical, flipHorizontal, brightness, rotate, contrast} = activeFile


    const setRotateValue = (right = false) => {
        let newRotate;
        if (!right){
            newRotate = Math.abs(+rotate) - 1 > 0 ?  Math.abs(+rotate) - 1 : 4
        }else{
            newRotate = Math.abs(+rotate) + 1 < 5 ?  Math.abs(+rotate) + 1 : 1
        }
        dispatch({type: "SETACTIVEFILE", data: {...activeFile, rotate: newRotate}})
    }

    const flipImage = (horizontal = false) => {
        if (horizontal){
            dispatch({type: "SETACTIVEFILE", data: {...activeFile, flipHorizontal: !flipHorizontal}})
        }else{
            dispatch({type: "SETACTIVEFILE", data: {...activeFile, flipVertical: !flipVertical}})
        }
    }





    return  <Tabs
        selectedTabClassName="parameters-desktop-tabs--selected"
        selectedTabPanelClassName="parameters-desktop-tabs-panel--selected"
        className={"image-uploader-sub-panel-desktop"}
        onSelect={(index:any) => dispatch({type: "SETUPLOADERCROPVIEW", data: index === 1})}
    >
        {/*<div className="open-uploader-settings" onClick={() => settingsModalVisibility(true)}>*/}
        {/*</div>*/}
        <TabList className="parameters-desktop-tabs-list">
            <Tab className="parameters-desktop-tabs disable-select" key={"rotate"}>
                Rotate
            </Tab>
            {(activeStatus !== 0 && !hasSimple) && <Tab className="parameters-desktop-tabs" key={"crop"}>
                Crop
            </Tab>}
            {(activeStatus !== 0 && !hasSimple) &&  <Tab className="parameters-desktop-tabs disable-select" key={"brightness"}>
                Brightness
            </Tab>}
            {(activeStatus !== 0 && !hasSimple) &&  <Tab className="parameters-desktop-tabs disable-select" key={"contrast"}>
                Contrast
            </Tab> }
        </TabList>

        <TabPanel className="parameters-desktop-tab-panel rotate-tab" key={"rotate"}>

            <div className="parameters-tab-panel-rotate-left disable-select">
                <div className="disable-select" onClick={() => setRotateValue(false)}>
                    <div className="rotate-left-icon"></div>
                    Rotate left
                </div>
                {(activeStatus !== 0 && !hasSimple) &&  <div className="disable-select" onClick={() => flipImage(true)}>
                    <div className="flip-horizontal-icon icon"></div>
                    Flip horizontal
                </div> }
            </div>
            {/*<div className="rotate-input">*/}
            {/*    <input  id="uploaderImageRotate" onChange={(e) => setStateValue('rotate', e)} type="range" value={rotate} min={0} max={360}/>*/}
            {/*    <span className="rotate-value">{rotate} </span>*/}
            {/*</div>*/}
            <div className="parameters-tab-panel-rotate-right disable-select">
                <div className="disable-select"
                     onClick={() => setRotateValue(true)}>
                    Rotate right
                    <div className="rotate-right-icon"></div>
                </div>
                {(activeStatus !== 0 && !hasSimple) &&   <div className="disable-select" onClick={() => flipImage(false)}>
                    Flip vertical
                    <div className="flip-vertical-icon icon"></div>
                </div> }
            </div>
        </TabPanel>

        <TabPanel className="parameters-desktop-tab-panel" key={"crop"}>
            <div className="parameters-tab-panel-crop disable-select">
                <div className="react-uploader-switch-container">
                    <span>Square </span>
                    <label className="react-uploader-switch">
                        <input onChange={(e: any) => {
                            dispatch({type: "SETUPLOADERCROPSQUARE", data: e.target.checked})
                        }} checked={showUploaderCropSquare} type="checkbox" />
                        <span className="react-uploader-slider react-uploader-round"></span>
                    </label>
                </div>
                <div className="react-uploader-switch-container">
                    <span>Grid </span>
                    <label className="react-uploader-switch">,
                        <input onChange={(e) => {
                            dispatch({type: "SETUPLOADERCROPGRIDVIEW", data: e.target.checked})
                        }} checked={showUploaderCropGrid} type="checkbox" />
                        <span className="react-uploader-slider react-uploader-round"></span>
                    </label>
                </div>
                <button onClick={() => {
                    dispatch({type: "CONFIRMCROP", data: true})
                }} type="button" disabled={cropButtonDisabled}  className="modal-body-btn confirm-crop-btn upload-submit-btn  disable-select">Confirm crop</button>

            </div>

        </TabPanel>
        <TabPanel className="parameters-desktop-tab-panel"
                  style={{minWidth: 300, position: 'relative', top: '-6px'}} key={"brightness"}>
            <Slider id="uploaderImageBrightness"
                    onChange={(e: number) => setBrightnessValue(e)} value={+brightness.toFixed(1)} step={0.1}
                    min={-1} max={1}/>
        </TabPanel>
        <TabPanel className="parameters-desktop-tab-panel"
                  style={{minWidth: 300, position: 'relative', top: '-6px'}} key={"contrast"}>
            <Slider id="uploaderImageRotate"
                    onChange={(e: number) => setContrastValue(e)} value={+contrast.toFixed(1)} step={0.1}
                    min={-1} max={1}/>
        </TabPanel>
    </Tabs>
};
export default ModalFooter;