import React from 'react';
import ColumnSearch from '../ColumnSearch';

interface ITableResizeColumProps {
 col: any;
 i: number;
 innerRef?: any;
 activeIndex?: number | null;
 mouseDown?: (index: number) => void;
 activePlatforms?: any;
 handleTogglePlatformsModalShow?: any;
 handleGetColumnSearchDebounced?: any;
}

const TableResizeColumn = (props: ITableResizeColumProps) => {
 const {
  col,
  innerRef,
  activeIndex,
  i,
  mouseDown,
  activePlatforms,
  handleTogglePlatformsModalShow,
  handleGetColumnSearchDebounced,
 } = props;

  return (
     <>
      <th
          ref={innerRef}
          style={{
           position: 'sticky',
           top: '0',
           maxWidth: col.value === 'title' ? 'none' : '500px !important',
           display: 'flex',
           alignItems: 'baseline',
           justifyContent: 'flex-start',
           flexDirection: 'column',
           gap: '5px',
          }}
          className="sticky-element sticky"
      >
       <div style={{cursor: 'pointer'}}>{col.heading}</div>
       <div
           style={{
            height: '100%',
            position: 'absolute',
            zIndex: 534534,
           }}
           onMouseDown={() => mouseDown && mouseDown(i)}
           className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
       />
       {col.value !== 'image' &&
           col.value !== 'title' &&
           col.value !== 'total_sold_price' &&
           col.value !== 'Shop' &&
           col.value !== 'actions' &&
           col.value !== 'shop_id' &&
           col.value !== 'date_sold' &&
           col.value !== 'sku' &&
           col.value !== 'shipping_cost_buyer' &&
           col.value !== 'shipping_cost_seller' &&
           col.value !== 'cogs' &&
           col.value !== 'linked' &&
           col.value !== 'quantity' && (
               <ColumnSearch
                   activePlatforms={activePlatforms}
                   name={col.value}
                   handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
                   handleGetColumnSearchDebounced={handleGetColumnSearchDebounced}
               />
           )}
      </th>
     </>
 )


};

export default TableResizeColumn;
