import styles from './DescriptionBuilderComponent.module.scss';
import Tippy from "@tippyjs/react";
import {PopoverIcon} from "../../../../assets/icons";
import {useState} from "react";

import getNameGenerator from "../../../../hooks/useNameGenerator";

const DescriptionBuilderComponent = ({ itemData, setItemData, editIndex, descriptionBuildItems, setDescriptionBuildItems}:any) => {

	const toggleDescriptionBuildItemsItems = (id: number, value: any) => {
		let updatedItems: any = [...itemData];
		const generatedName = getNameGenerator('input_166');
		updatedItems[editIndex] = {
			...updatedItems[editIndex],
			[generatedName]: value,
			changed: true,
		};
		setItemData(updatedItems);
		setDescriptionBuildItems((prevItems:any) => {
			const updatedItems = prevItems.map((item:any) => ({
				...item,
				active: item.id === id && !item.active,
			}));

			const clickedItem = updatedItems.find((item:any) => item.id === id);
			if (clickedItem && clickedItem.active) {
				return updatedItems;
			}
			return updatedItems.map((item:any) => ({
				...item,
				active: item.id === id,
			}));
		});
	};


	return (
	 <div>
		 <div className={`${styles.intendedMenu} ${styles.deskDescBuild}`}>
			 <p className={styles.title}>Description Builder Settings</p>
			 <div className={styles.menuItems}>
				 {descriptionBuildItems.map((item:any) => (
				  <div
				   className={item.active ? styles.itemActive : styles.item}
				   key={item.id}
				   onClick={() =>
					toggleDescriptionBuildItemsItems(item.id, item.value)
				   }
				  >
					  <span>{item.text}</span>
				  </div>
				 ))}
			 </div>
		 </div>
		 <div
		  className={`${styles.intendedMenu} ${styles.mobileDescBuild}`}
		 >
			 <p className={styles.title}>
				 Description Builder Settings
				 <Tippy
				  interactive={true}
				  zIndex={9991}
				  trigger="click"
				  arrow={false}
				  content={
					  <div className={`popover ${styles.popoverContent}`}>
						  <ul>
							  <li>
                              <span className={styles.heading}>
                                Normal Listing –
                              </span>{' '}
								  This setting will add and display ALL fields that
								  are included in the pro description builder.
								  Including short description, feature list
								  (bulleted list), size, measurements, condition,
								  condition description, and bottom of description.
							  </li>
							  <li>
								  {' '}
								  <span className={styles.heading}>
                                Shorten Listing –
                              </span>
								  This setting will display ONLY the details added
								  to the specific “Short Description ” box in your
								  LP Listing. *Please note that by default listings
								  that are imported to your LP catalog will have a
								  shorten listing setting applied as all of the
								  details are added to only the “short description”
								  box when importing to LP.
							  </li>
							  <li>
								  Listings imported to List Perfectly will
								  automatically be set to Shorten Listing. This is
								  so that no extra text is added to your
								  description.To use the description builder, set to
								  Normal Listing.
							  </li>
							  <div className={styles.line}></div>
							  <p>★ Crossposts to supported 'condition' fields.</p>
							  <p>
								  ♦ Pro{' '}
								  <a
								   href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
								   target="_blank"
								  >
									  {' '}
									  Description Builder Settings
								  </a>
							  </p>
						  </ul>
					  </div>
				  }
				 >
					 <PopoverIcon
					  className={styles.popoverIcon}
					  color={'#fff'}
					 ></PopoverIcon>
				 </Tippy>
			 </p>
			 <div className={styles.menuItems}>
				 {descriptionBuildItems.map((item:any) => (
				  <div
				   data-name="input_166"
				   className={
					   item.active ? styles.itemActive : styles.item
				   }
				   key={item.id}
				   onClick={() =>
					toggleDescriptionBuildItemsItems(item.id, item.value)
				   }
				  >
					  <span>{item.text}</span>
				  </div>
				 ))}
			 </div>
		 </div>
	 </div>
	);
};

export default DescriptionBuilderComponent;