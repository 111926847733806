import { createSlice } from '@reduxjs/toolkit';

interface IRoleSliceState {
    isSave : boolean;
    isCancel: boolean;
    inputChanged : boolean;
    filterSortChange : boolean;
    isGridView: boolean | null;
}

const initialState: IRoleSliceState = {
    isSave: false,
    isCancel: false,
    inputChanged:false,
    isGridView: null,
    filterSortChange: false,
};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        setIsCancel : (state, action ) => {
            state.isCancel = action.payload
        },
        setIsSave : (state, action ) => {
            state.isSave = action.payload
        },
        setIsInputChanged : (state, action ) => {
            state.isSave = action.payload
        },
        setGridViewChecker : (state, action ) => {
            state.isGridView = action.payload
        },
        setFilterBySort : (state, action ) => {
            state.filterSortChange = action.payload
        }
    },
});

export const {
    setIsCancel,
    setIsSave,
    setIsInputChanged,
    setGridViewChecker,
    setFilterBySort,
} = catalogSlice.actions;
export default catalogSlice.reducer;