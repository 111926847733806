import { useState, useEffect } from 'react';

import jwt_decode from 'jwt-decode';
import getToken from '../../utils/getToken';


function useAppPrefix() {
  const [prefix, setPrefix] = useState<string>('app');
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    const token = getToken();

    if (token) {
      const decodedToken: any = jwt_decode(token);
      if (decodedToken?.data?.user?.roles) {
        setRoles(decodedToken?.data?.user?.roles);
      }
      if (decodedToken && decodedToken.iss.includes('apptest')) {
        setPrefix('apptest');
      } else if (decodedToken && decodedToken.iss.includes('app')) {
        setPrefix('app');
      }
    }
  }, []);

  return { prefix, roles };
}

export default useAppPrefix;
