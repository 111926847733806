import styles from './ExpressListerMain.module.scss';
import ExpressHeader from "../ExpressHeader";
import Tippy from "@tippyjs/react";
import {
	CustomizeIcon, LensIcon,
	Lightbulb,
	PopoverIcon,
	ProgressSaved, ResearchIcon,
	SavingIcon
} from "../../../../assets/icons";
import { InputCount } from "../InputCount";
import React, {useEffect, useState} from "react";
import getNameGenerator from "../../../../hooks/useNameGenerator";
import '../../express.css';
import {MoonLoader} from "react-spinners";
import ImageUploaderContainer from "../../../ImageUploader";
import { toast } from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
	setExpressListerNewActive,
	setExpressListerNewClicked, setExpressListingId, setIsUploaderOpenStatus
} from "../../../../redux/slices/NavigationSlice";
import jwt_decode from "jwt-decode";
import getToken from "../../../../utils/getToken";
import _, {debounce} from "lodash";
import {ListingAssistantModal} from "../../components/ListingAssistantModal";
import {RootState} from "../../../UI/Header";
import {TextareaWithGrowHeight} from "../../components/TextareaGrowHeight";
import {TextareaWithCount} from "../TextareaWithCount";
import {TextAreaTagify} from "../TextAreaTagify";
import TimeAgo from "react-timeago";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import Listings from "../../../../services/Listings";
import {v4 as uuidv4} from "uuid";
import {instanceAxios} from "../../../../services";
import _get from "lodash/get";
import {getFilenameFromUrl, replaceSuffixInFilename} from "../../../ImageUploader/common";
import useExtensionVersion from "@hooks/useExtensionVersion";
import {useNavigate} from "react-router-dom";

export const ExpressListerMain = ({
			configID,
			setCustomizeActivated,
			expressListerFields,
			setListFromMainLister,
			setMainListDataLister,
			customizeActivated,
			setExpressListerFields,
			backendDataFields,
			expressLoader,
			setExpressLoader,
			listingAssistantTone,
			setListingAssistantTone,
			listingAssistantContext,
			setListingAssistantContext,
			zipCode,
			allFieldsForListingAssistant,
			skuKeys,
			config,
			handleSKUitemClick,
			activeItemSKU,
			handleKeyPress,
			SKUInputValue,
			handleInputChangeSKU,
			SKUloader,
			setSKUloader,
			activeItemSKUValue,
			SKUprefix,
			SKUformat,
			skuGeneratorUniqueIdFormat
			} :any) => {
	const [isSKUgeneratorFinished, setIsSKUgeneratorFinished] = useState(false);
	const [hideSkuGenerator, setHideSkuGenerator] = useState(false);
	let entryId:any;
	const [uniqueID, setUniqueID] = useState({ 915: null });
	const [createActive, setCreateActive] = useState(true);
	const dispatch = useDispatch();
	const [isImagesUploaded, setIsImagesUploaded] = useState(true);
	const [expressListerCreated, setExpressListerCreated] = useState(false);
	const [expressListerSaved, setExpressListerSaved] = useState(false);
	const [listCreated, setListCreated] = useState(false);
	const [formChangedWhileCreating, setFormChangedWhileCreating] = useState(false);
	const [formChanged, setFormChanged] = useState(false);
	const newExpressClicked = useSelector(
	 (state: any) => state.navigation.expressListerNewClicked
	);
	const navigate = useNavigate();
	const [createdFailed, setCreatedFailed] = useState(false);
	const [listingAddRequestSend, setListingAddRequestSend] = useState(false);
	const [showRegenerate, setShowRegenerate] = useState(false);
	const [regenerateAttempsLeftCount, setRegenerateAttempsLeftCount] = useState(0);
	const [assistantTitle, setAssistantTitle] = useState('assistant');
	const [addNewChanger, setAddNewChanger] = useState<boolean>(false);
	const [imagesFromUploader, setImagesFromUploader] = useState([]);
	const [showListingAssistantModal, setShowListingAssistantModal] = useState(false);
	const [defaultTemplateMetdata, setDefaultTemplateMetadata] = useState(null);
	const [expressList, setExpressList] = useState<any>({
		newTitle: {
			text: '',
			changed: false,
		},
		newSku:{
			text: '',
			changed: false,
		},
		newDraft:{
			checked: true,
			changed: true,
		},
		newThumbnail: {
			text: '',
			changed: false,
		},
		newImagesArray: {
			text: '',
			changed: false,
		},
		uploaderImages:[],
		newCondition: {
			text: 'None',
			changed: false,
		},
		newSubCondition: {
			text: null,
			changed: false,
		},
		newPreOwnedCondition: {
			text: null,
			changed: false,
		},
		newConditionNotes: {
			text: '',
			changed: false,
		},
		newShippingWeightlbkg:{
			text: '',
			changed: false,
		},
		newShippingWeightozg: {
			text: '',
			changed: false,
		},
		newStyle: {
			text: '',
			changed: false,
		},
		newIntendedFor:{
			text: '',
			changed: false,
		},
		newDescBuilder: {
			text: '',
			changed: false,
		},
		newCOGS:{
			text: '',
			changed: false,
		},
		newMSRP: {
			text: '',
			changed: false,
		},
		newQuantity: {
			text: '',
			changed: false,
		},
		newNotes: {
			text: '',
			changed: false,
		},
		newMadein: {
			text: '',
			changed: false,
		},
		newShippingZipOrCityCode:  {
			text:'',
			changed: false,
		},
		newShippingPackageLength:  {
			text: '',
			changed: false,
		},
		newShippingPackageWidth: {
			text: '',
			changed: false,
		},
		newShippingPackageHeight:  {
			text: '',
			changed: false,
		},
		newPatternOrTexture: {
			text: '',
			changed: false,
		},
		newCare: {
			text: '',
			changed: false,
		},
		newSeasonOrWeather: {
			text: '',
			changed: false,
		}
	});
	const [activeCondition, setActiveCondition] = useState(0);
	const [conditionItems, setConditionItems] = useState([
		{ id: 1, value: 'New', text: 'New', active: false },
		{ id: 2, value: 'Pre-Owned', text: 'Pre-Owned', active: false },
		{ id: 3, value: null, text: 'None', active: false },
	]);
	const [newConditionItems, setNewConditionItems] = useState([
		{ id: 1, text: 'NWT', value: 'With Tags', active: false },
		{ id: 2, text: 'NWB', value: 'With Box', active: false },
		{ id: 3, text: 'NWOT', value: 'Without Tags', active: false },
		{ id: 4, text: 'NWD', value: 'Without Box', active: false },
		{ id: 5, text: 'NWD', value: 'With Defects', active: false },
		{ id: 6, text: 'None', value: null, active: false },
	]);
	const [preOwnedItems, setPreOwnedItems] = useState([
		{ id: 1, text: 'Like New', value: 'Like New', active: false },
		{ id: 2, text: 'Good', value: 'Good', active: false },
		{ id: 3, text: 'Fair', value: 'Fair', active: false },
		{ id: 4, text: 'Poor', value: 'Poor', active: false },
		{ id: 5, text: 'None', value: null, active: false },
	]);
	const [IntendedForItems, setIntendedItems] = useState([
		{ id: 1, value: 'Unisex', text: 'Unisex', active: false },
		{ id: 2, value: 'Mens', text: 'Men', active: false },
		{ id: 3, value: 'Womens', text: 'Women', active: false },
		{ id: 4, value: 'Unisex Kids', text: 'Unisex Kids', active: false },
		{ id: 5, value: 'Girls', text: 'Girls', active: false },
		{ id: 6, value: 'Boys', text: 'Boys', active: false },
		{ id: 7, value: 'Babys', text: 'Babies', active: false },
		{ id: 8, value: 'Maternity', text: 'Maternity', active: false },
		{ id: 9, value: 'Pets', text: 'Pets', active: false },
		{ id: 10, value: 'Cats', text: 'Cats', active: false },
		{ id: 11, value: 'Dogs', text: 'Dogs', active: false },
		{ id: 12, value: 'None', text: 'None', active: false },
	]);
	const [descriptionBuildItems, setDescriptionBuildItems] = useState([
		{ id: 1, value: 'normall', text: 'Expanded description', active: false },
		{ id: 2, value: 'shortenl', text: 'Short description only', active: false },
	]);
	const [googleLensUrl, setGoogleLensUrl] = useState(null)
	const {bdecode} = useBasisFuncs();
	const [skuGeneratorExist, setSKUGeneratorExist] = useState(false);
	const [serverTime, setServerTime] = useState<any>(null);
	const [isUploaderOpen, setIsUploaderOpen] = useState(false);
	const [createdListingId, setCreatedListingId] = useState(null);
	const [updatedItems, setUpdatedItems] = useState<any>({});
	const [updateButtonsDisabled, setUpdateButtonsDisabled] = useState(false);
	const [preventFirstRender, setPreventFirstRender] = useState(false);
	const token: any = getToken();
	const [regenerateId, setRegenerateId] = useState(null);
	const [regenerateTotal, setRegenerateTotal] = useState(0)
	const navigation = useSelector((state: RootState) => state.navigation.active);
	const decodedToken: any = jwt_decode(token);
	const roles = decodedToken?.data?.user?.roles;
	const is_role_allowed = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'corporate master' ||
		 role.toLowerCase() === 'corporate sub'
		);
	});

	const hasBetaTeamRole = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'beta team'
		);
	});

	const hasPro = roles
	.map((role: string) => role.toLowerCase())
	.includes('pro'.toLowerCase());
	const hasBusiness = roles
	.map((role: string) => role.toLowerCase())
	.includes('business'.toLowerCase());
	const hasSimple = roles
	.map((role: string) => role.toLowerCase())
	.includes('simple'.toLowerCase());
	const [hasExtensionInstall, setHasExtensionInstall] = useState(false);
	const isMobile = useSelector((state: any) => state.navigation.isMobile);
	const isPriceToolSupported = useExtensionVersion('0.33.0');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if(listCreated) {
			setFormChangedWhileCreating(true);
		}
		setFormChanged(true);

		const { name, type, value, checked } = event.target;
		const generatedName = getNameGenerator(name);
		setExpressList((prevState:any) => ({
			...prevState,
			[generatedName]: {
				...prevState[generatedName],
				[type === 'checkbox' ? 'checked' : 'text']: type === 'checkbox' ? checked : value,
				changed: true,
			}
		}));
		if (name === 'input_859.1') {
			setUpdatedItems((prevState : any) => ({
				...prevState,
				[generatedName]: checked ? 'Draft' : null,
			}));
		} else if (name === 'input_104' || name === 'input_851' || name === 'input_847' || name === 'input_531' || name === 'input_532' || name === 'input_533') {
			setUpdatedItems((prevState : any) => ({
				...prevState,
				[generatedName]: Number(value),
			}));
		} else {
			setUpdatedItems((prevState : any) => ({
				...prevState,
				[generatedName]:  value,
			}));
		}
	};

	useEffect(() => {
		const bodyElement = document.body;
		const handleAttributeChange = () => {
			const extensionVersion = bodyElement.getAttribute('lp_custom_chrome_extension_version');
			try {
				if (extensionVersion) {
					setHasExtensionInstall(true)
				}
				else {
					setHasExtensionInstall(false)
				}
			} catch (e) {
				console.log('error', e);
			}
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_version'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		try{
			if (backendDataFields) {
				// Create a map from backendDataFields for quick lookup
				const backendCodesMap = new Map(backendDataFields?.map((item:any, index:any) => [item.code, index]));

				// Mark the `checked` property for expressListerFields based on the map
				const updatedList = expressListerFields.map((item: any) => ({
					...item,
					checked: (item?.alwaysVisible && item?.allowed) || (backendCodesMap?.has(item.keyCode) && item?.allowed),
				}));

				// Sort `updatedList` based on the order of backendDataFields
				const sortedList = updatedList.sort((a:any, b:any) => {
					const indexA:any = backendCodesMap.get(a.keyCode);
					const indexB:any = backendCodesMap.get(b.keyCode);

					if (indexA === undefined) return 1;
					if (indexB === undefined) return -1;
					return indexA - indexB;
				});

				setExpressListerFields(sortedList);
			}
		}
		catch(error){
			console.log(error, 'error')
		}
	}, [backendDataFields]);

	useEffect(() => {
		if(expressListerFields) {
			const skuGeneratorItem = expressListerFields?.find((item : any) => item.title === 'SKU Generator');

			if (skuGeneratorItem && skuGeneratorItem.checked) {
				setSKUGeneratorExist(true);
			} else {
				setSKUGeneratorExist(false);
			}
		}
	}, [expressListerFields]);

	useEffect(() => {
		if (customizeActivated) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [customizeActivated]);



	const handleChangeUploaderInputs = (
	 thumbnailValue: string,
	 imagesArrayValue: string
	) => {
		let changes: any = {};
		if(thumbnailValue) {
			setFormChanged(true);
			changes['newThumbnail'] = thumbnailValue;
		}
		if(imagesArrayValue) {
			setFormChanged(true);
			changes['newImagesArray'] = imagesArrayValue;
		}
		if((thumbnailValue && thumbnailValue !== expressList?.newThumbnail?.text) || (imagesArrayValue  && imagesArrayValue !== expressList?.newImagesArray?.text)) {
			if(listCreated) {
				setFormChangedWhileCreating(true);
			}
		}

		setUpdatedItems((prevState : any) => ({
			...prevState,
			...changes,
		}));

		setExpressList((prevState:any) => {
			const newTableInfoUpdates = { ...prevState };
			newTableInfoUpdates['newThumbnail'] = {
				text: thumbnailValue,
				changed: true,
			};
			newTableInfoUpdates['newImagesArray'] = {
				text: imagesArrayValue,
				changed: true,
			};
			return newTableInfoUpdates;
		});
	};

	useEffect(() => {
		dispatch(setIsUploaderOpenStatus(isUploaderOpen));
	}, [isUploaderOpen]);

	const handleAddNewListing = () =>{
		setExpressLoader(true);
		setCreatedFailed(false);
		setListingAddRequestSend(false);
		setUniqueID({915: null});
		setActiveCondition(0);
		setExpressList({
			newTitle: {
				text: '',
				changed: false,
			},
			newSku: {
				text: '',
				changed: false,
			},
			newDraft: {
				checked: true,
				changed: true,
			},
			newThumbnail: '',
			newImagesArray: [],
			uploaderImages: [],
			newCondition:{
				text: '',
				changed: false,
			},
			newSubCondition: {
				text: null,
				changed: false,
			},
			newPreOwnedCondition: {
				text: null,
				changed: false,
			},
			newConditionNotes: {
				text: '',
				changed: false,
			},
			newShippingWeightlbkg:{
				text: '',
				changed: false,
			},
			newShippingWeightozg: {
				text: '',
				changed: false,
			},
			newStyle: {
				text: '',
				changed: false,
			},
			newIntendedFor:{
				text: '',
				changed: false,
			},
			newDescBuilder: {
				text: '',
				changed: false,
			},
			newCogs:{
				text: '',
				changed: false,
			},
			newMSRP: {
				text: '',
				changed: false,
			},
			newQuantity: {
				text: '',
				changed: false,
			},
			newNotes: {
				text: '',
				changed: false,
			},
			newMadein: {
				text: '',
				changed: false,
			},
			newShippingZipOrCityCode:  {
				text: '',
				changed: false,
			},
			newShippingPackageLength:  {
				text: '',
				changed: false,
			},
			newShippingPackageWidth: {
				text: '',
				changed: false,
			},
			newShippingPackageHeight:  {
				text: '',
				changed: false,
			},
			newPatternOrTexture: {
				text: '',
				changed: false,
			},
			newCare: {
				text: '',
				changed: false,
			},
			newSeasonOrWeather: {
				text: '',
				changed: false,
			}
		});
		setRegenerateAttempsLeftCount(0);
		setUpdatedItems({});
		setAssistantTitle('assistant');
		setShowRegenerate(false);
		setCreatedListingId(null);
		dispatch(setExpressListingId(null));
		setCreateActive(true);
		setListCreated(false);
		setFormChanged(false);
		setFormChangedWhileCreating(false);
		dispatch(setExpressListerNewClicked(false));
		setTimeout(()=>{
			setExpressLoader(false);
		},500)

	}

	const handleSaveListing = async (id?:any) => {
		const maxRetries = 2;
		let attempts = 0;
		const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

		const saveListingRequest = async () => {
			setCreateActive(false);
			setFormChanged(false);
			setFormChangedWhileCreating(false);
			setExpressListerSaved(true);

			try {
				const mapKeyToId: any = {
					newTitle: '133',
					newSku: '1',
					newDraft: '859.1',
					newThumbnail: '727',
					newImagesArray: '728',
					newColor: '209',
					newMaterial: '82',
					newSize: '213',
					newPrice: '22',
					newBrand: '206',
					newDescription: '505',
					newUpc: '704',
					newKeywords: '453',
					newCondition: '15',
					newSubCondition: '795',
					newPreOwnedCondition: '796',
					newConditionNotes: '17',
					newShippingWeightlbkg: '2',
					newShippingWeightozg: '3',
					newStyle: '205',
					newIntendedFor: '4',
					newDescBuilder: '166',
					newCogs: '847',
					newMSRP: '104',
					newQuantity: '851',
					newNotes: '726',
					newMadein: '211',
					newShippingZipOrCityCode: '837',
					newShippingPackageLength: '531',
					newShippingPackageWidth: '532',
					newShippingPackageHeight: '533',
					newPatternOrTexture: '210',
					newCare:'43',
					newSeasonOrWeather: '36'
				};
				let metadata: any = {};
				for (const [key, value] of Object.entries(updatedItems)) {
					if (key === 'newThumbnail' || key === 'newPrice') {
						metadata[mapKeyToId[key]] = Number(value);
					} else if (key === 'newImagesArray') {
						metadata[mapKeyToId[key]] = (value as string)?.split(',')?.map(Number);
					}
					else if(key === 'newTitle' || key ===  'newSeasonOrWeather' || key === 'newPatternOrTexture' || key === 'newCare' || key === 'newStyle') {
						const removeSpacesFromValue = (value as string)?.replace(/\n+/g, '');
						metadata[mapKeyToId[key]] = removeSpacesFromValue;
					}
					else if (mapKeyToId[key]) {
						metadata[mapKeyToId[key]] = value;
					}
				}
				let filter_by;
				if(id) {
					filter_by =  {id: id}
				}
				else {
					filter_by = createdListingId ? { id: createdListingId } : {}
				}
				const payload = {
					...metadata
				};
				const response: any = await Listings.metadata_update(filter_by, payload, token);
				if (response) {
					const {id} = response.data;
					entryId = id;
					const time = response?.data?.updated_at;
					if (time) {
						const parsedTime = new Date();
						setServerTime(parsedTime);
					}
				}
			} catch (e: any) {
				if (attempts < maxRetries) {
					attempts++;
					await delay(1000); // 1 second delay
					await saveListingRequest();
				} else {
					setServerTime(null);
					toast.error(`${e?.message}`, {
						position: 'bottom-right',
					});
				}
			} finally {
				setExpressListerSaved(false);
				setCreateActive(true);
			}
		};

		await saveListingRequest();
	};


	const generateUUID = () => {
		const uuid = uuidv4();
		setUniqueID((prevProperties : any) => ({
			...prevProperties,
			915: uuid
		}));
	};

	useEffect(() => {
		generateUUID();
		setHideSkuGenerator(false);
		setListingAssistantContext('');
	}, [addNewChanger, newExpressClicked]);

	const handleCreateList = async () => {
		const maxRetries = 0;
		let attempts = 0;
		const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

		const createListRequest = async () => {
			setListCreated(true);
			setFormChanged(false);
			setCreateActive(false);
			setExpressListerCreated(true);

			try {
				const mapKeyToId: any = {
					newTitle: '133',
					newSku: '1',
					newDraft: '859.1',
					newThumbnail: '727',
					newImagesArray: '728',
					newColor: '209',
					newMaterial: '82',
					newSize: '213',
					newPrice: '22',
					newBrand: '206',
					newDescription: '505',
					newUpc: '704',
					newKeywords: '453',
					newCondition: '15',
					newSubCondition: '795',
					newPreOwnedCondition: '796',
					newConditionNotes: '17',
					newShippingWeightlbkg: '2',
					newShippingWeightozg: '3',
					newStyle: '205',
					newIntendedFor: '4',
					newDescBuilder: '166',
					newCogs: '847',
					newMSRP: '104',
					newQuantity: '851',
					newNotes: '726',
					newMadein: '211',
					newShippingZipOrCityCode: '837',
					newShippingPackageLength: '531',
					newShippingPackageWidth: '532',
					newShippingPackageHeight: '533',
					newPatternOrTexture: '210',
					newCare:'43',
					newSeasonOrWeather: '36'
				};
				const metadata: any = {};
				for (const [key, value] of Object.entries(updatedItems)) {
					if (key === 'newThumbnail' || key === 'newPrice') {
						metadata[mapKeyToId[key]] = Number(value);
					} else if (key === 'newImagesArray') {
						metadata[mapKeyToId[key]] = (value as string)?.split(',')?.map(Number);
					}
					else if(key === 'newTitle' || key ===  'newSeasonOrWeather' || key === 'newPatternOrTexture' || key === 'newCare' || key === 'newStyle') {
						const removeSpacesFromValue = (value as string)?.replace(/\n+/g, '');
						metadata[mapKeyToId[key]] = removeSpacesFromValue;

					}
					else if (mapKeyToId[key]) {
						metadata[mapKeyToId[key]] = value;
					}
				}

				if (uniqueID) metadata['915'] = uniqueID['915'];

				const payload = {
					status: 'active',
					metadata: metadata
				};
				setListingAddRequestSend(true);
				const response: any = await Listings.add(token, payload);
				if (response) {
					const { id } = response.data;
					entryId = id;
					setCreatedListingId(id);
					dispatch(setExpressListingId(id));
				}
			} catch (e: any) {
				if (attempts < maxRetries) {
					attempts++;
					await delay(1000); // 1 second delay
					await createListRequest();
				} else {
					setCreatedFailed(true);
					toast.error(`${e?.message}`, {
						position: 'bottom-right',
					});
				}
			} finally {
				setExpressListerCreated(false);
				setCreateActive(true);
			}
		};

		await createListRequest();
	};

	const handleGenerateSKU = async() => {
		const defaultSkuFormat = ['sku_prefix',' ', 'location_type', 'location_number',' ', 'unique_id',' ','weight'];
		try {
			const payload:any = {
				location_number: SKUInputValue?.trim() === '' ? null :  Number(SKUInputValue),
				location_type: activeItemSKUValue,
				entry_id: entryId ? entryId : createdListingId,
				sku_prefix: SKUprefix,
				sku_format: SKUformat.length ? SKUformat : defaultSkuFormat,
				weight: (() => {
					const lbkg = expressList?.newShippingWeightlbkg?.text;
					const ozg = expressList?.newShippingWeightozg?.text;

					if (lbkg && ozg) {
						return `${lbkg}-${ozg}`;
					} else if (ozg) {
						return `0-${ozg}`;
					} else if (lbkg) {
						return `${lbkg}-0`;
					} else {
						return "";
					}
				})(),
				unique_id_format: skuGeneratorUniqueIdFormat,
			};

			const response : any = await Listings.generate_sku_for_listing(token,  payload);
			if (response) {
				const data = response.data;
				const skuId = _get(data, 'sku_id');
				if (skuId) {
					if(listCreated) {
						setFormChangedWhileCreating(true);
					}
					setFormChanged(true);
					// If sku_id exists, update the newSku text in expressList
					setExpressList((prevState: any) => ({
						...prevState,
						newSku: {
							text: skuId,
							changed: true,
						}
					}));

					setUpdatedItems((prevState : any) => ({
						...prevState,
						['newSku']: skuId,
					}));
				};
				setHideSkuGenerator(true);
			}
			setIsSKUgeneratorFinished(true);
			toast.success('SKU generated !', {
				autoClose: 2000, // Set the duration for auto close
				transitionDuration: 500, // Set the transition duration for this specific notification
				position: 'bottom-right'
			} as any); // Type cast the options object
		}
		catch (e) {
			toast.error('Something went wrong while Generating SKU please try again', {
				autoClose: 3000, // Set the duration for auto close
				transitionDuration: 500, // Set the transition duration for this specific notification
				position: 'bottom-right'
			} as any); // Type cast the options object
		}

	};

	const handleSKUGeneratorClick = async() => {
		setSKUloader(true);
		try {
			if (entryId || createdListingId) {
				await handleSaveListing();
			}
			else {
				await handleCreateList();
			}
			await handleGenerateSKU();
		}
		catch (error) {
			toast.error('An error occurred while creating the listing and generating the SKU. Please try again.', {
				position: 'bottom-right',
			});
		}
		finally {
			setSKUloader(false);
		}
	}


	useEffect(() => {
		setPreventFirstRender(true)
		if(preventFirstRender) {
			if(!isImagesUploaded) {
				setCreateActive(false);
			}
		}
	},[updateButtonsDisabled])

	useEffect(() => {
		setExpressList((prevState:any) => ({
			...prevState,
			newDescBuilder: {
				...prevState.newDescBuilder,
				text: 'shortenl',
				changed: true
			},
		}));

		setUpdatedItems((prevState : any) => ({
			...prevState,
			['newDescBuilder']: 'shortenl',
		}));
		setUpdatedItems((prevState : any) => ({
			...prevState,
			['newDraft']: 'Draft',
		}));
	},[addNewChanger, newExpressClicked]);

	useEffect(() => {
		if(zipCode) {
			setUpdatedItems((prevState : any) => ({
				...prevState,
				['newShippingZipOrCityCode']: zipCode,
			}));
			setExpressList((prevState : any) => ({
				...prevState,
				newShippingZipOrCityCode: {
					text: zipCode,  // replace 'newZipCode' with the actual new zip code
					changed: true,      // or set to false if you prefer
				}
			}));
		}
	}, [zipCode, addNewChanger, newExpressClicked]);


	useEffect(() => {
		dispatch(setExpressListerNewActive(createActive))
	}, [createActive]);

	useEffect(() => {
		if(newExpressClicked) {
			handleAddNewListing();
			setServerTime(null);
		}
	}, [newExpressClicked]);

	useEffect(() => {
		let debouncedSendRequest: any;

		const handleDebouncedSendRequest = async() => {
			if (formChanged && !listCreated) {
				if(isImagesUploaded) {
					await handleCreateList();
				}
			}
		};

		try {
			debouncedSendRequest = debounce(handleDebouncedSendRequest, 500);
			debouncedSendRequest();
		} catch (e) {
			console.log('error', e);
		}

		return () => {
			if (debouncedSendRequest) {
				debouncedSendRequest.cancel();
			}
		};
	}, [formChanged, handleCreateList, listCreated, isImagesUploaded]);

	const exponentialBackoff = async (
		fn: () => Promise<any>,
		retries: number = 4,
		delay: number = 1000
	): Promise<any> => {
		for (let i = 0; i < retries; i++) {
			try {
				const response = await fn();
				return response;
			} catch (error) {
				if (i < retries - 1) {
					await new Promise(resolve => setTimeout(resolve, delay));
					delay *= 2; // Exponential backoff
				} else {
					throw error;
				}
			}
		}
		throw new Error('Max retries reached');
	};


	const handleCheckListingCreation = async () => {
		const options = { include_thumbnail: true };
		const metadata = { offset: 0, limit: 10 };
		const filter_by = { 915: uniqueID['915'] };
		const token : any = getToken(); // Ensure you have the correct token

		const fetchListings = async () => {
			return await Listings.list(filter_by, metadata, options, null, token);
		};

		try {
			const response = await exponentialBackoff(fetchListings);
			return response; // Return success if fetchListings is successful
		} catch (error) {
			return error; // Return error if all retries fail
		} finally {
			setIsImagesUploaded(true);
		}
	};


	useEffect(() => {
		let debouncedSendRequest: any;

		const handleDebouncedSendRequest = async() => {
			if ((formChanged && createdListingId && formChangedWhileCreating) || isSKUgeneratorFinished) {
				if(isImagesUploaded){
					setIsSKUgeneratorFinished(false);
					await handleSaveListing();
				}
			}
			else if(formChanged && !createdListingId && listingAddRequestSend && createdFailed && formChangedWhileCreating){
					await handleCheckListingCreation().then(async (result) => {
						if (!(result instanceof Error)) {
							if(result?.data?.length){
								const id = result?.data[0]?.id;
								setCreatedListingId(id);
								dispatch(setExpressListingId(id));
								await handleSaveListing(id);
							}
							else{
								await handleCreateList();
							}

						}
						else{
							toast.error(`Next changes will not be Save Listing Details`, {
								position: 'bottom-right',
							});
						}
					}).catch(error => {
						// Handle any unexpected errors
						console.error('Unexpected error:', error);
					});
				}
		};

		try {
			debouncedSendRequest = debounce(handleDebouncedSendRequest, 1000);
			debouncedSendRequest();
		} catch (e) {
			console.log('error', e);
		}

		return () => {
			if (debouncedSendRequest) {
				debouncedSendRequest.cancel();
			}
		};
	}, [formChanged, handleSaveListing, createdListingId, formChangedWhileCreating, isImagesUploaded, isSKUgeneratorFinished]);

	const handleToggleListingAssistant = ()=>{
		setShowListingAssistantModal(!showListingAssistantModal)
	}


	const handleCustomizeClicked = () => {
		setCustomizeActivated(true);
		setListFromMainLister([...expressListerFields]);

		setMainListDataLister((prevMainListDataLister: any) => {
			return prevMainListDataLister.map((item: any) => {
				const matchingItem = expressListerFields?.find((field: any) => field.id === item.id);
				if (matchingItem && (matchingItem.checked && matchingItem.allowed)) {
					return {
						...item,
						checked: true
					};
				} else {
					return {
						...item,
						checked: false
					};
				}
			});
		});
	}


	const handleGetData = async() => {
		try {
			const tempMetadata = {offset: 0, limit: 30};
			const payload:any = {
				metadata: tempMetadata,
				filter_by: {},
				options:{include_assignee: true}
			};


			const response = await instanceAxios.post(
			 '/templates/list',
			 payload
			);
			if(response.status === 200) {
                const {data} = response.data
				const defaultTemplate = data.find((item:any) => item?.metadata?.default_template === true);
				if (defaultTemplate) {
					setDefaultTemplateMetadata(defaultTemplate.metadata);
					toast.success(`Default template applied`, {
						autoClose: 2500,
						position: 'bottom-right',
					});
				}
			}
		}
		catch (e){
			console.log(e, 'error')
		}
	}

	useEffect(() => {
		if(!newExpressClicked){
			handleGetData();
		}
	}, [addNewChanger, newExpressClicked]);

	useEffect(() => {
		if(defaultTemplateMetdata) {
			Object.entries(defaultTemplateMetdata).forEach(([key, value]) => {
				const newName = `input_${key}`;
				const generatedName = getNameGenerator(newName);
				const type = typeof value === 'boolean' ? 'checkbox' : 'text';
				const checked = value;

				let keyCodeToCheck:any;

				if (key === '15' || key === '795' || key === '796' || key === '17') {
					keyCodeToCheck = 'condition';
				} else if (key === '2' || key === '3') {
					keyCodeToCheck = 'weight';
				} else if (key === '531' || key === '532' || key === '533') {
					keyCodeToCheck = 'shipping_dimensions';
				} else {
					keyCodeToCheck = key;
				}

				const isChecked = keyCodeToCheck ? expressListerFields.some((item:any) => item.keyCode === keyCodeToCheck && item.checked) : false;

				if(isChecked){
					setExpressList((prevState:any) => ({
						...prevState,
						[generatedName]: {
							...prevState[generatedName],
							[type === 'checkbox' ? 'checked' : 'text']: type === 'checkbox' ? checked : value,
							changed: true,
						}
					}));

					if (newName === 'input_859.1') {
						setUpdatedItems((prevState : any) => ({
							...prevState,
							[generatedName]: checked ? 'Draft' : null,
						}));
					} else if (newName === 'input_104' || newName === 'input_851' || newName === 'input_847' || newName === 'input_531' || newName === 'input_532' || newName === 'input_533') {
						setUpdatedItems((prevState : any) => ({
							...prevState,
							[generatedName]: Number(value),
						}));
					} else {
						setUpdatedItems((prevState : any) => ({
							...prevState,
							[generatedName]:  value,
						}));
					}
				}
			});

		}
	}, [defaultTemplateMetdata, expressListerFields]);


	useEffect(() => {
		if(expressList?.newKeywords?.changed || expressList?.newColor?.changed) {
			if(listCreated) {
				setFormChangedWhileCreating(true);
			}
			if(listingAddRequestSend) {
				setFormChanged(true);
			}

		}
	}, [expressList?.newKeywords?.text, expressList?.newColor?.text]);


	const handleTagifyChange = (e:any, name:string) => {
		try {
			const value = JSON?.parse(e?.detail?.value);
			if (Array?.isArray(value)) {
				const result = value?.map(item => item?.value)?.join(', ');
				const nameField = getNameGenerator(name)
				setExpressList((prevState:any) => ({
					...prevState,
					[nameField]: {
						text: result,
						changed: true,
					},
				}));
				setUpdatedItems((prevState : any) => ({
					...prevState,
					[nameField]:  result,
				}));
			}
		}
		catch (e) {
			console.log(e, 'error')
		}
	}
	const toggleIntendedForItems = (id: number, value: any) => {
		if(listCreated) {
			setFormChangedWhileCreating(true);
		}
		setFormChanged(true);
		setExpressList((prevState:any) => ({
			...prevState,
			newIntendedFor: {
				...prevState.newIntendedFor,
				text: value,
				changed: true
			},
		}));

		setUpdatedItems((prevState : any) => ({
			...prevState,
			['newIntendedFor']: value === 'None' ? null : value,
		}));
	};

	const toggleDescriptionBuildItems = (id: number, value: any) => {
		if(listCreated) {
			setFormChangedWhileCreating(true);
		}
		setFormChanged(true);
		setExpressList((prevState:any) => ({
			...prevState,
			newDescBuilder: {
				...prevState.newDescBuilder,
				text: value,
				changed: true
			},
		}));

		setUpdatedItems((prevState : any) => ({
			...prevState,
			['newDescBuilder']: value,
		}));
	};

	const toggleConditionsItems = (id: number, value: any) => {
		if(listCreated) {
			setFormChangedWhileCreating(true);
		}
		setFormChanged(true);

		setExpressList((prevState:any) => ({
			...prevState,
			newCondition: {
				...prevState.newCondition,
				text: value,
				changed: true
			},
			newPreOwnedCondition: {
				...prevState.newPreOwnedCondition,
				text: null,
				changed: true
			},
			newSubCondition: {
				...prevState.newSubCondition,
				text: null,
				changed: true
			},
		}));

		setUpdatedItems((prevState:any) => {
			const updatedState = {
				...prevState,
				['newCondition']: value,
			};

			if (expressList?.newPreOwnedCondition?.text !== null) {
				updatedState['newPreOwnedCondition'] = null;
			}

			if (expressList?.newSubCondition?.text !== null) {
				updatedState['newSubCondition'] = null;
			}


			return updatedState;
		});
	};

	const togglePreOwnedItems = (id: number, value: any) => {
		if(listCreated) {
			setFormChangedWhileCreating(true);
		}
		setFormChanged(true);


		setExpressList((prevState:any) => ({
			...prevState,
			newPreOwnedCondition: {
				...prevState.newPreOwnedCondition,
				text: value,
				changed: true
			},
			newSubCondition: {
				...prevState.newSubCondition,
				text: null,
				changed: true
			},
		}));

		setUpdatedItems((prevState:any) => {
			const updatedState = {
				...prevState,
				['newPreOwnedCondition']: value,
			};
			if (expressList?.newSubCondition?.text !== null) {
				updatedState['newSubCondition'] = null;
			}

			return updatedState;
		});

	};
	const toggleNewConditionsItems = (id: number, value: any) => {

		if(listCreated) {
			setFormChangedWhileCreating(true);
		}
		setFormChanged(true);

		setExpressList((prevState:any) => ({
			...prevState,
			newSubCondition: {
				...prevState.newSubCondition,
				text: value,
				changed: true
			},
			newPreOwnedCondition: {
				...prevState.newPreOwnedCondition,
				text: null,
				changed: true
			}
		}));

		setUpdatedItems((prevState:any) => {
			const updatedState = {
				...prevState,
				['newSubCondition']: value,
			};
			if (expressList?.newPreOwnedCondition?.text !== null) {
				updatedState['newPreOwnedCondition'] = null;
			}

			return updatedState;
		});

	};

	const hasValidSkuLocationValue = () => {
		return skuKeys?.some((key:any) => config[key] !== undefined && config[key] !== '');
	}

	const hasLocationNumberInCustomSkuFormat = config?.sku_generator_format?.includes("location_number") ;
	const hasLocation = config?.sku_generator_format?.includes('location_type')

	useEffect(() => {
		if(imagesFromUploader?.length){
			let filename = getFilenameFromUrl(imagesFromUploader[0]);
			const newUrl:any = 'https://lens.google.com/uploadbyurl?url=' + encodeURIComponent('https://media.listperfectly.com/' + replaceSuffixInFilename(filename,'_LP', '_CI'))
			setGoogleLensUrl(newUrl);
		}else{
			setGoogleLensUrl(null)
		}
	}, [imagesFromUploader]);


	return (
	 <div className={styles.expressListerContainer} id='expressLister'>
		 {expressLoader ? <div className={styles.loadercontainerOverlay}> <MoonLoader /></div> : (
		  <>
			  {showListingAssistantModal
			   && <ListingAssistantModal
				navigation={navigation}
				setShowListingAssistantModal={setShowListingAssistantModal}
				imagesFromUploader={imagesFromUploader}
				setExpressList={setExpressList}
				setFormChanged={setFormChanged}
				setFormChangedWhileCreating={setFormChangedWhileCreating}
				setUpdatedItems={setUpdatedItems}
				backendDataFields={backendDataFields}
				expressListerFields={expressListerFields}
				listingAssistantTone={listingAssistantTone}
				setListingAssistantTone={setListingAssistantTone}
				listingAssistantContext={listingAssistantContext}
				setListingAssistantContext={setListingAssistantContext}
				configID={configID}
				setShowRegenerate={setShowRegenerate}
				setRegenerateAttempsLeftCount={setRegenerateAttempsLeftCount}
				assistantTitle={assistantTitle}
				setRegenerateId={setRegenerateId}
				regenerateId={regenerateId}
				regenerateAttempsLeftCount={regenerateAttempsLeftCount}
				regenerateTotal={regenerateTotal}
				setRegenerateTotal={setRegenerateTotal}
				allFieldsForListingAssistant={allFieldsForListingAssistant}
			   />
			  }
			  <ExpressHeader
			   serverTime={serverTime}
			   setServerTime={setServerTime}
			   expressListerCreated={expressListerCreated}
			   expressListerSaved={expressListerSaved}
			   handleCreateList={handleCreateList}
			   createActive={createActive}
			   isImagesUploaded={isImagesUploaded}
			   handleAddNewListing={handleAddNewListing}
			   setAddNewChanger={setAddNewChanger}
			   navigation={navigation}
			   createdListingId={createdListingId}
			   hasPro={hasPro}
			   isRoleAllowed={is_role_allowed}
			  />

			  <div className={styles.expressListerInner}>
				  <div className={styles.expressListerSwitcher}>
					  <button
						  className={`${styles.switcherItem} ${styles.expressListerForm}`}
						  disabled={!createActive}
						  style={!createActive ? {opacity: 0.7} : {opacity: 1}}
					  >Express Lister
					  </button>
					  <button
						  className={`${styles.switcherItem} ${styles.fullListingForm}`}
						  onClick={() => {
							  if (createdListingId) {
								  navigate(`/listings/edit/${createdListingId}`);
							  } else {
								  navigate(`/listings/add`);
							  }
						  }}
						  disabled={!createActive}
						  style={!createActive ? {opacity: 0.7} : {opacity: 1}}

					  >Full Listing Form
					  </button>
				  </div>
				  <div className={styles.expressTitlePart}>
					  <div className={styles.mobileTitle}>Express Lister</div>
					  <div className={styles.expressButtons}>
						  {
						   (showRegenerate && regenerateAttempsLeftCount !== 0) &&
						   <div className={styles.listingAssistentButton}
								style={{backgroundColor: `${createActive ? '#F2711C' : '#B6B6B6'}`}} onClick={() => {
							   if (createActive) {
								   setAssistantTitle('regenerate');
								   handleToggleListingAssistant();
							   }
						   }}><Lightbulb/> Regenerate
						   </div>
						  }
						  <div className={styles.listingAssistentButton}
							   style={{backgroundColor: `${createActive ? '#F2711C' : '#B6B6B6'}`}} onClick={() => {
							  if (createActive) {
								  setAssistantTitle('assistant');
								  handleToggleListingAssistant();
							  }
						  }}><Lightbulb/> Listing Assistant
						  </div>
						  <div className={styles.customizeButton} onClick={handleCustomizeClicked}>
							  <CustomizeIcon/>
							  <span>Customize</span>
						  </div>
					  </div>
				  </div>

				  <div className={styles.mobileNotifications}>
					  {(serverTime && !expressListerSaved) &&
					   <div className={styles.progressSavedContainer}>
						   <ProgressSaved/>
						   <div style={{color: '#767676', fontWeight: '600'}}>Progress Saved</div>
						   <TimeAgo className={styles.timeAgo} date={serverTime}/>
					   </div>
					  }
					  {expressListerCreated &&
					   <div style={{color: '#767676', fontWeight: '600'}}><SavingIcon/> Creating listing...</div>}
					  {expressListerSaved &&
					   <div style={{color: '#767676', fontWeight: '600'}}><SavingIcon/> Saving...</div>}
				  </div>
				  <div style={{visibility: 'hidden', position: 'absolute'}}>
					  <InputCount
					   placeholder=""
					   name="input_727"
					   handleChange={handleChange}
					   value={
						   expressList?.newThumbnail?.text ? expressList?.newThumbnail?.text : ''
					   }
					   limit={500}
					   height={40}
					  />
					  <InputCount
					   placeholder=""
					   name="input_728"
					   handleChange={handleChange}
					   value={
						   expressList?.newImagesArray?.text ? expressList?.newImagesArray?.text : ''
					   }
					   limit={500}
					   height={40}
					  />
				  </div>

				  <div className={styles.mainExpressListerList}>
					  <div className={styles.uploadImagesContainer}>
						  <div className={styles.uploadImagesSlider}>
							  <ImageUploaderContainer
							   setImagesFromUploader={setImagesFromUploader}
							   isUploaderOpen={(status: boolean) => setIsUploaderOpen(status)}
							   displayUploaderTitle={false}
							   displayBulkActions={false}
							   expressListerView={true}
							   handleImageEditComplete={() => setCreateActive(true)}
							   handleChangeUploaderInputs={handleChangeUploaderInputs}
							   allowListingSave={(status: boolean) => {
								   setUpdateButtonsDisabled(!updateButtonsDisabled)
								   setIsImagesUploaded(status)
							   }}
							   images={expressList.uploaderImages}
							  />
						  </div>
					  </div>
					  {expressListerFields?.map((item: any) => (item?.checked && item?.allowed) &&
					   <div>
						   {
							   item?.title === 'Listing Title' ? <div className={styles.listingTitleContainer}>
									<div className={styles.TitlesContainer}>
										<div className={styles.title}>
											Listing Title
											<Tippy
											 interactive={true}
											 arrow={false}
											 zIndex={9999}
											 trigger="click"
											 content={
												 <div className={`popover ${styles.popoverContent}`}>
													 <p className={styles.heading}>Title Character Count:</p>
													 <ul>
														 <li>
															 Etsy, Shopify & Kidizen: Title for these
															 marketplaces will be copied from main title field
															 and is limited to 140.
														 </li>
														 <li>Facebook: 99</li>
														 <li>eBay: 80</li>
														 <li>Grailed: 60</li>
														 <li>Poshmark: 80</li>
														 <li>Mercari: 80</li>
														 <li>Tradesy, Instagram & Depop: No title</li>
														 <div className={styles.line}></div>
														 <p>★ Crossposts to supported 'title' fields.</p>
													 </ul>
												 </div>
											 }
											>
												<PopoverIcon
												 className={styles.popoverIcon}
												 color={'#fff'}
												></PopoverIcon>
											</Tippy>
										</div>
										<div className={styles.titlesMain}>
											<TextareaWithGrowHeight
											 placeholder={'Title'}
											 name={"input_133"}
											 value={bdecode(expressList.newTitle.text)}
											 handleChangeEditInput={handleChange}
											 limit={140}
											 hasLimit={true}
											/>

										</div>
									</div>
								</div> :
								item?.title === 'SKU' ? <div className={`${styles.inputContainerDefault} ${styles.inputContainerDefaultSKU}`}>
									{
									 (!hideSkuGenerator && skuGeneratorExist) && <div className={styles.skuContainer}>
										 <div className={styles.title}>
											 SKU Generator
										 </div>
										 <div className={styles.skuInnerContainer}>
											 <div className={styles.skugeneratorFlex}>

												 {hasValidSkuLocationValue() && hasLocation
												  &&
												  <div className={styles.skuLocationExpress}>
													  <p>SKU Location</p>
													  <div className={styles.skuLocationExpressInner}>
														  {skuKeys.map((key: any) => (
														   (_.has(config, key) && config[key]?.length) ? (
															<button
															 key={key}
															 onClick={() => handleSKUitemClick(key)}
															 className={activeItemSKU === key ? styles.active : ''}
															>
																{config[key]}
															</button>
														   ) : null
														  ))}
													  </div>
												  </div>
												 }

												 {
												  hasLocationNumberInCustomSkuFormat &&
												  <div className={styles.skuLocationNumberExpress}>
													  <p>Location Number </p>
													  <input type="text" placeholder="Enter number"
															 onKeyPress={handleKeyPress}
															 value={SKUInputValue} onChange={handleInputChangeSKU}/>
												  </div>
												 }
											 </div>
											 <div style={{
												 display: 'flex',
												 alignItems: 'center',
												 gap: 5
											 }
											 }>
												 <button className={styles.GenerateButton}
														 onClick={handleSKUGeneratorClick}
														 disabled={SKUloader || !createActive}>
													 {SKUloader ? 'Generating SKU' : 'Generate SKU'}
												 </button>
												 <Tippy
												  interactive={true}
												  zIndex={9999}
												  trigger="click"
												  arrow={false}
												  content={
													  <div className={`popover ${styles.popoverContent}`}>
														  <p>
															  Utilize SKU generator to create a sequential customizable
															  SKU
															  system. <a
														   href={"https://help.listperfectly.com/en/articles/9660321 "}
														   target={"_blank"}>Learn more</a>.
														  </p>
													  </div>
												  }
												 >
													 <PopoverIcon
													  className={styles.popoverIcon}
													  color={'#fff'}
													 ></PopoverIcon>
												 </Tippy>
											 </div>


										 </div>
									 </div>
									}
									{
									 !hasSimple && <div className={styles.inpRightItem}>
										 <p className={styles.heading}>
											 SKU
											 <Tippy
											  interactive={true}
											  zIndex={9999}
											  trigger="click"
											  arrow={false}
											  content={
												  <div
												   className={`popover ${styles.popoverContent}`}
												  >
													  <ul>
														  <li>'Stock Keeping Unit'</li>
														  <li>
															  Used to track inventory movement and
															  locate inventory.
														  </li>
														  <li>
															  Example: G-Box32 = Located in
															  garage, in box #32.
														  </li>
														  <div className={styles.line}></div>
														  <p>
															  ★ Crossposts to supported 'SKU'
															  fields.
														  </p>
													  </ul>
												  </div>
											  }
											 >
												 <PopoverIcon
												  className={styles.popoverIcon}
												  color={'#fff'}
												 ></PopoverIcon>
											 </Tippy>
										 </p>
										 <InputCount
										  placeholder="SKU"
										  name="input_1"
										  handleChange={handleChange}
										  value={expressList.newSku.text}
										  limit={500}
										  height={40}
										  hideCount={true}
										 />
									 </div>
									}
								</div> : item?.title === 'Draft' ? <>
									{
									 (is_role_allowed || hasPro) && <div className={styles.markListing}>
										 <div className={styles.checkboxContainerDraft}>
											 <div
											  className={`${styles.draftCheckbox} ${
											   expressList.newDraft.checked ? styles.addBorder : ''
											  }`}
											 >
												 <label className={styles.checkBoxContainer}>
													 <input
													  type="checkbox"
													  name="input_859.1"
													  checked={expressList.newDraft.checked}
													  onChange={(e) => handleChange(e)}
													 />
													 <span
													  className={`${
													   expressList.newDraft.checked
														? styles.checkMark
														: styles.checkboxChecked
													  }`}
													 ></span>
												 </label>
												 <span>Draft</span>
											 </div>
											 <div className={styles.mobilePopover}>
												 <Tippy
												  interactive={true}
												  zIndex={9999}
												  trigger="click"
												  arrow={false}
												  content={
													  <div className={`popover ${styles.popoverContent}`}>
														  <ul>
															  <li>
																  Mark listing as as draft if your listing needs review
																  or is still in progress.
																  Listings marked as draft will be shown on the "Draft"
																  tab. See full guide
																  <a
																   href="https://help.listperfectly.com/en/articl­es/8912398-using-draft-listings-in-list-­perfectly "
																   target="_blank"
																  >
																	  <span> here</span>
																  </a>
															  </li>
														  </ul>
													  </div>
												  }
												 >
													 <PopoverIcon
													  className={styles.popoverIcon}
													  color={'#fff'}
													 ></PopoverIcon>
												 </Tippy>
											 </div>
										 </div>
									 </div>
									}
								</> : item?.title === 'Color' ?
								 <div className={styles.inputContainerDefault}>
									 <div className={styles.TitlesContainer}>
										 <div className={styles.title}>
											 {item?.title}
											 <Tippy
											  interactive={true}
											  arrow={false}
											  zIndex={9999}
											  trigger="click"
											  content={
												  <div className={`popover ${styles.popoverContent}`}>
													  <ul>
														  <li>Add up to two colors</li>
														  <ul>
															  <li>
																  (ex: Blue, White)
															  </li>
														  </ul>
													  </ul>
													  <p>★ Crossposts to supported 'color' fields.</p>
												  </div>
											  }
											 >
												 <PopoverIcon
												  className={styles.popoverIcon}
												  color={'#fff'}
												 ></PopoverIcon>
											 </Tippy>
										 </div>
										 <div className={styles.titlesMain}>
											 <TextAreaTagify placeholder="Color" value={bdecode(expressList.newColor?.text)}
															 name="input_209" handleTagifyChange={handleTagifyChange}/>
										 </div>
									 </div>
								 </div>
								 : item?.title === 'Material' ?
								  <div className={styles.inputContainerDefault}>
									  <div className={styles.TitlesContainer}>
										  <div className={styles.title}>
											  {item?.title}
											  <Tippy
											   interactive={true}
											   arrow={false}
											   zIndex={9999}
											   trigger="click"
											   content={
												   <div className={`popover ${styles.popoverContent}`}>
													   <div className="ui-tooltip-content">
														   <ul>
															   <li>Add one primary material</li>
														   </ul>

														   <p>★ Crossposts to supported 'material' fields.</p></div>
												   </div>
											   }
											  >
												  <PopoverIcon
												   className={styles.popoverIcon}
												   color={'#fff'}
												  ></PopoverIcon>
											  </Tippy>
										  </div>
										  <div className={styles.titlesMain}>
											  <InputCount
											   placeholder="Material"
											   name="input_82"
											   handleChange={handleChange}
											   value={bdecode(expressList.newMaterial?.text)}
											   limit={500}
											   height={40}
											   hideCount={true}
											  />

										  </div>
									  </div>
								  </div>
								  : item?.title === 'Size' ?
								   <div className={styles.inputContainerDefault}>
									   <div className={styles.TitlesContainer}>
										   <div className={styles.title}>
											   {item?.title}
											   <Tippy
												interactive={true}
												arrow={false}
												zIndex={9999}
												trigger="click"
												content={
													<div className={`popover ${styles.popoverContent}`}>
														<div className="ui-tooltip-content">
															<ul>
																<li>Add primary size.</li>
																<ul>
																	<li>ex. Standard letter sizing recommending (S, M,
																		L,
																		etc)
																	</li>
																</ul>

																<p>★ Crossposts to supported 'size' fields.</p>
																<p>♦ Added to Pro <a
																 href="/docs/topics/pro-plan/description-builder/"
																 target="_blank">Description Builder</a>.</p>
															</ul>
														</div>
													</div>
												}
											   >
												   <PopoverIcon
													className={styles.popoverIcon}
													color={'#fff'}
												   ></PopoverIcon>
											   </Tippy>
										   </div>
										   <div className={styles.titlesMain}>
											   <InputCount
												placeholder="Size"
												name="input_213"
												handleChange={handleChange}
												value={expressList.newSize?.text}
												limit={500}
												height={40}
												hideCount={true}
											   />
										   </div>
									   </div>
								   </div>
								   : item?.title === 'Price' ?
									<div className={styles.inputContainerDefault}>
										<div className={styles.TitlesContainer}>
											<div className={styles.title}>
												{item?.title}
												<Tippy
													interactive={true}
													arrow={false}
													zIndex={9999}
													trigger="click"
													content={
														<div className={`popover ${styles.popoverContent}`}>
															<div className="ui-tooltip-content">
																<ul>
																	<li>Enter number only, without currency symbols</li>
																</ul>

																<p>★ Crossposts to supported 'price' fields.</p></div>
														</div>
													}
												>
													<PopoverIcon
														className={styles.popoverIcon}
														color={'#fff'}
													></PopoverIcon>
												</Tippy>
											</div>
											<div className={`${styles.titlesMain} ${styles.titlesMainPrice}`}>
												<InputCount
													type="number"
													placeholder="Price"
													name="input_22"
													handleChange={handleChange}
													value={expressList.newPrice?.text}
													limit={500}
													height={40}
													hideCount={true}
												/>
												{
													(hasPro || (hasPro && is_role_allowed)) &&
													<div className={styles.researchLensContainer}>
														{
															<>
																{
																	googleLensUrl ?
																		<a href={googleLensUrl} target="_blank">
																			<button
																				className={`${styles.priceResearchButton} `}>
																				<LensIcon/>
																			</button>
																		</a>
																		: <Tippy
																			interactive={true}
																			arrow={false}
																			zIndex={9999}
																			trigger="click"
																			content={
																				<div
																					className={`popover ${styles.popoverContent}`}>
																					<p>
																						Add at least one image to search with Google Lens
																					</p>
																				</div>
																			}>
																			<button
																			    className={`${styles.priceResearchButton} ${styles.disabledResearch}`}>
																				<LensIcon/>
																			</button>
																		</Tippy>
																}
															</>
														}
														{
															!expressList?.newTitle?.text?.length ?
																<Tippy
																	interactive={true}
																	arrow={false}
																	zIndex={9999}
																	trigger="click"
																	content={
																		<div
																			className={`popover ${styles.popoverContent}`}>
																			<p>
																				Add listing title to use research button
																			</p>
																		</div>
																	}>
																	<button
																		className={`${styles.priceResearchButton} ${styles.disabledResearch}`}>
																		<ResearchIcon/>
																	</button>
																</Tippy> :
																<button
																	onClick={(e) => {
																		if (!expressList?.newTitle?.text?.length) {
																			e.preventDefault(); // Block the click
																			e.stopPropagation(); // Prevent further events
																		} else {
																			window.open(
																				`https://www.ebay.com/sh/research?marketplace=EBAY-US&keywords=${expressList?.newTitle?.text}&dayRange=365&endDate=1729187113959&startDate=1697651113959&categoryId=0&offset=0&limit=50&sorting=-avgsalesprice&tabName=SOLD&tz=Asia%2FTbilisi`,
																				'_blank'
																			);
																		}
																	}}
																	className={`${styles.priceResearchButton} ${expressList?.newTitle?.text?.length ? '' : styles.disabledResearch}`}>
																<ResearchIcon/>
																</button>
														}
													</div>
												}
											</div>
										</div>
									</div>
												: item?.title === 'Brand' ?
													<div className={styles.inputContainerDefault}>
														<div className={styles.TitlesContainer}>
															<div className={styles.title}>
																{item?.title}
																<Tippy
																	interactive={true}
																	arrow={false}
																	zIndex={9999}
																	trigger="click"
																	content={
																		<div
																			className={`popover ${styles.popoverContent}`}>
																			<div className="ui-tooltip-content">
																				<ul>
																					<li>Add one primary brand, maker,
																						label, artist.
																					</li>
																				</ul>
																				<p>★ Crossposts to supported 'brand'
																					fields.</p></div>
													  </div>
												  }
												 >
													 <PopoverIcon
													  className={styles.popoverIcon}
													  color={'#fff'}
													 ></PopoverIcon>
												 </Tippy>
											 </div>
											 <div className={styles.titlesMain}>
												 <InputCount
												  placeholder="Brand"
												  name="input_206"
												  handleChange={handleChange}
												  value={bdecode(expressList.newBrand?.text)}
												  limit={500}
												  height={40}
												  hideCount={true}
												 />
											 </div>
										 </div>
									 </div>
									 : item?.title === 'Short Description' ?
									  <div className={styles.inputContainerDefault}>
										  <div className={styles.TitlesContainer}>
											  <div className={styles.title}>
												  {item?.title}
												  <Tippy
												   interactive={true}
												   arrow={false}
												   zIndex={9999}
												   trigger="click"
												   content={
													   <div className={`popover ${styles.popoverContent}`}>
														   <div className="ui-tooltip-content"><strong>Description
															   Character Count Limits:</strong>
															   <ul>
																   <li>Etsy: 10000</li>
																   <li>eBay: 5000</li>
																   <li>Poshmark: 1500</li>
																   <li>Mercari: 1000</li>
															   </ul>
															   <p>★ Crossposts to supported 'description' fields.</p>
															   <p>♦ Pro <a
																href="/docs/topics/pro-plan/description-builder/"
																target="_blank">Description Builder</a> section</p>
														   </div>
													   </div>
												   }
												  >
													  <PopoverIcon
													   className={styles.popoverIcon}
													   color={'#fff'}
													  ></PopoverIcon>
												  </Tippy>
											  </div>
											  <div className={styles.titlesMain}>

												  <TextareaWithCount
												   placeholder={'Short Description'}
												   name={"input_505"}
												   value={bdecode(expressList.newDescription?.text)}
												   limit={5000}
												   height={150}
												   handleChange={handleChange}>
												  </TextareaWithCount>
											  </div>
										  </div>
									  </div>
									  : item?.title === 'Keywords' ?
									   <div className={styles.inputContainerDefault}>
										   <div className={styles.TitlesContainer}>
											   <div className={styles.title}>
												   {item?.title}
												   <Tippy
													interactive={true}
													arrow={false}
													zIndex={9999}
													trigger="click"
													content={
														<div className={`popover ${styles.popoverContent}`}>
															<div className="ui-tooltip-content">
																<ul>
																	<li>Enter your keywords</li>
																	<li>You can add spaces between words as well, spaces
																		between words will still be added as a single
																		keyword.
																	</li>
																</ul>
																<p>★ Crossposts to supported 'keywords and tags'
																	fields.</p>
															</div>
														</div>
													}
												   >
													   <PopoverIcon
														className={styles.popoverIcon}
														color={'#fff'}
													   ></PopoverIcon>
												   </Tippy>
											   </div>
											   <div className={styles.titlesMain}>
												   <TextAreaTagify placeholder="Keywords"
																   value={bdecode(expressList?.newKeywords?.text)}
																   name="input_453"
																   handleTagifyChange={handleTagifyChange}/>
											   </div>
										   </div>
									   </div>
									   : item?.title === 'UPC' ?
										<div className={styles.inputContainerDefault}>
											<div className={styles.TitlesContainer}>
												<div className={styles.title}>
													{item?.title}
													<Tippy
													 interactive={true}
													 arrow={false}
													 zIndex={9999}
													 trigger="click"
													 content={
														 <div className={`popover ${styles.popoverContent}`}>
															 <ul>
																 <li>'Universal Product Code'</li>
																 <li>Barcode number located on the scannable tag.</li>
															 </ul>
															 <p>★ Crossposts to supported 'UPC' fields.</p>
														 </div>
													 }
													>
														<PopoverIcon
														 className={styles.popoverIcon}
														 color={'#fff'}
														></PopoverIcon>
													</Tippy>
												</div>
												<div className={styles.titlesMain}>
													<InputCount
													 placeholder="UPC"
													 name="input_704"
													 handleChange={handleChange}
													 value={expressList.newUpc?.text}
													 limit={500}
													 height={40}
													 hideCount={true}
													/>

												</div>
											</div>
										</div>
										: item?.title === 'Condition' ?
											 <div className={styles.condition}>
													 <div className={styles.conditions}>
														 <div className={styles.intendedMenuCondition}>
															 <p className={styles.title}>
																 Condition
																 <Tippy
																  interactive={true}
																  zIndex={999}
																  trigger="click"
																  arrow={false}
																  content={
																	  <div
																	   className={`popover ${styles.popoverContent}`}>
																		  <ul>
																			  <li>Select your product's condition.</li>

																			  <div className={styles.line}></div>
																			  <p>★ Crossposts to supported 'condition'
																				  fields.</p>
																			  <p>
																				  ♦ Added to Pro{' '}
																				  <a
																				   href="https://app.listperfectly.com/docs/topics/pro-plan/description-builder/"
																				   target="_blank"
																				  >
																					  {' '}
																					  Description Builder
																				  </a>
																			  </p>
																		  </ul>
																	  </div>
																  }
																 >
																	 <PopoverIcon
																	  className={styles.popoverIcon}
																	  color={'#fff'}
																	 ></PopoverIcon>
																 </Tippy>
															 </p>
															 <div className={styles.conditionMenu}>
																 <div
																  className={`${styles.menuItems} ${styles.menuItemsConditions}`}
																 >
																	 {conditionItems?.map((item: any) => (
																	  <div
																	   data-name="input_15"
																	   className={
																		   expressList?.newCondition?.text === item?.value
																			? styles.itemActive
																			: styles.item
																	   }
																	   key={item?.id}
																	   onClick={() =>
																		toggleConditionsItems(item?.id, item?.value)
																	   }
																	  >
																		  <span>{item?.text}</span>
																	  </div>
																	 ))}
																 </div>
															 </div>
														 </div>
														 {expressList?.newCondition?.text === 'New' && (
														  <div className={styles.intendedMenuCondition}>
															  <p className={styles.title}>New Condition
																  <Tippy
																   interactive={true}
																   zIndex={5}
																   trigger="click"
																   arrow={false}
																   content={
																	   <div
																		className={`popover ${styles.popoverContent}`}>
																		   <p>
																			   These selections will be shown in your description without abbreviation as shown below:
																		   </p>
																		   <ul>
																			   <li>NWT = New With Tags</li>
																			   <li>NWB = New With Box</li>
																			   <li>NWOT = New Without Tags</li>
																			   <li>NWOB = New Without Box</li>
																			   <li>NWD = New With Defects</li>
																			   <ul>
																				   <li>
																					   Example: CONDITION: New With Tags
																				   </li>
																			   </ul>


																		   </ul>
																		   <p>♦ Added to Pro <a target="_blank" href="https://app.listperfectly.com/docs/topics/pro-plan/description-builder/">Description Builder.</a></p>
																		   <p>
																			   Coming soon - crossposting support for these selections where applicable.
																		   </p>
																	   </div>
																   }
																  >
																	  <PopoverIcon
																	   className={styles.popoverIcon}
																	   color={'#fff'}
																	  ></PopoverIcon>
																  </Tippy>
															  </p>

															  <div className={styles.menuItems}>
																  {newConditionItems?.map((item: any) => (
																   <div
																	data-name="input_795"
																	className={expressList?.newSubCondition?.text === item?.value ? styles.itemActive : styles.item}
																	key={item?.id}
																	onClick={() =>
																	 toggleNewConditionsItems(item?.id, item?.value)
																	}
																   >
																	   <span>{item?.text}</span>
																   </div>
																  ))}
															  </div>
														  </div>
														 )}
														 {expressList?.newCondition?.text === 'Pre-Owned' && (
														  <div className={styles.intendedMenuCondition}>
															  <p className={styles.title}>
																  Pre-Owned Condition
																  <Tippy
																   interactive={true}
																   zIndex={5}
																   trigger="click"
																   arrow={false}
																   content={
																	   <div
																		className={`popover ${styles.popoverContent}`}>
																		   <p>
																			   These selections will be shown in your
																			   description.
																		   </p>
																		   <p>Example: CONDITION: Pre-owned Like New</p>
																		   <p>
																			   Soon we will add marketplace support for
																			   these
																			   selections where applicable.
																		   </p>
																	   </div>
																   }
																  >
																	  <PopoverIcon
																	   className={styles.popoverIcon}
																	   color={'#fff'}
																	  ></PopoverIcon>
																  </Tippy>
															  </p>
															  <div className={styles.menuItems}>
																  {preOwnedItems?.map((item: any) => (
																   <div
																	data-name="input_796"
																	className={expressList?.newPreOwnedCondition?.text === item?.value ? styles.itemActive : styles.item}
																	key={item?.id}
																	onClick={() => togglePreOwnedItems(item?.id, item?.value)}
																   >
																	   <span>{item?.text}</span>
																   </div>
																  ))}
															  </div>
														  </div>
														 )}
													 </div>
													 <div className={styles.conditionNotes}>
														 <TextareaWithCount
														  placeholder={'Condition notes'}
														  name={"input_17"}
														  value={bdecode(expressList?.newConditionNotes?.text)}
														  limit={1000}
														  height={150}
														  handleChange={handleChange}>
														 </TextareaWithCount>
													 </div>
												 </div>
										 : item?.title === 'Weight' ?
										  <div className={`${styles.inputContainerDefault} ${styles.inputContainerDefaultWeight}`}>
											  <div className={styles.TitlesContainer}>
												  <div className={styles.title}>
													  Shipping Weight lb/kg
													  <Tippy
													   interactive={true}
													   arrow={false}
													   zIndex={9999}
													   trigger="click"
													   content={
														   <div className={`popover ${styles.popoverContent}`}>
															   <ul>
																   <li>
																	   Enter the weight.
																   </li>
															   </ul>
															   <p>★ Crossposts to supported 'weight' fields.</p>
														   </div>
													   }
													  >
														  <PopoverIcon
														   className={styles.popoverIcon}
														   color={'#fff'}
														  ></PopoverIcon>
													  </Tippy>
												  </div>
												  <div className={styles.titlesMain}>
													  <InputCount
													   placeholder="Shipping Weight lb/kg"
													   name="input_2"
													   type="number"
													   handleChange={handleChange}
													   value={expressList?.newShippingWeightlbkg?.text}
													   limit={3}
													   height={40}
													   hideCount={true}
													  />

												  </div>
											  </div>
											  <div className={styles.TitlesContainer}>
												  <div className={styles.title}>
													  Shipping Weight oz/g
													  <Tippy
													   interactive={true}
													   arrow={false}
													   zIndex={9999}
													   trigger="click"
													   content={
														   <div className={`popover ${styles.popoverContent}`}>
															   <ul>
																   <li>
																	   Enter the weight.
																   </li>
															   </ul>
															   <p>★ Crossposts to supported 'weight' fields.</p>
														   </div>
													   }
													  >
														  <PopoverIcon
														   className={styles.popoverIcon}
														   color={'#fff'}
														  ></PopoverIcon>
													  </Tippy>
												  </div>
												  <div className={styles.titlesMain}>
													  <InputCount
													   type="number"
													   placeholder="Shipping Weight oz/g"
													   name="input_3"
													   handleChange={handleChange}
													   value={expressList?.newShippingWeightozg?.text}
													   limit={2}
													   height={40}
													   hideCount={true}
													  />
												  </div>
											  </div>
										  </div>
										  :
										  item?.title === 'Style' ? <div className={styles.listingTitleContainer}>
											   <div className={styles.TitlesContainer}>
												   <div className={styles.title}>
													   {item?.title}
													   <Tippy
														interactive={true}
														arrow={false}
														zIndex={9999}
														trigger="click"
														content={
															<div className={`popover ${styles.popoverContent}`}>
																<ul>
																	<li>
																		Add primary style details.
																	</li>
																	<ul>
																		<li>
																			ex. When your buyer would use your item, or
																			the overall style like Western if clothing
																			or Soft Grip if a tool.
																		</li>
																	</ul>
																	<p>♦ Added to Pro <a target="_blank"
																						 href="https://app.listperfectly.com/docs/topics/pro-plan/description-builder/">Description
																		Builder.</a></p>
																</ul>
															</div>
														}
													   >
														   <PopoverIcon
															className={styles.popoverIcon}
															color={'#fff'}
														   ></PopoverIcon>
													   </Tippy>
												   </div>
												   <div className={styles.titlesMain}>
													   <TextareaWithGrowHeight
														   placeholder="Style"
														   name="input_205"
														   value={expressList.newStyle?.text}
														   handleChangeEditInput={handleChange}
														   limit={500}
														   hasLimit={false}
													   />
												   </div>
											   </div>
										   </div> :
										   item?.title === 'Intended For' ? <div className={styles.listingTitleContainer}>
												<div className={styles.TitlesContainer}>
													<div className={styles.title}>
														{item?.title}
														<Tippy
														 interactive={true}
														 arrow={false}
														 zIndex={9999}
														 trigger="click"
														 content={
															 <div className={`popover ${styles.popoverContent}`}>
																 <ul>
																	 <li>
																		 Selection will be displayed in your description
																		 next
																		 to Size{' '}
																	 </li>

																	 <div className={styles.line}></div>

																	 <p>
																		 ♦ Added to Pro{' '}
																		 <a
																		  href="https://app.listperfectly.com/docs/topics/pro-plan/description-builder/"
																		  target="_blank"
																		 >
																			 {' '}
																			 Description Builder.
																		 </a>
																	 </p>
																 </ul>
															 </div>
														 }
														>
															<PopoverIcon
															 className={styles.popoverIcon}
															 color={'#fff'}
															></PopoverIcon>
														</Tippy>
													</div>
													<div className={styles.titlesMain}>
														<div className={styles.intendedMenuIntendedFor}>
														<div className={styles.menuItems}>
															{IntendedForItems.map((item: any) => (
															 <div
															  data-name="input_4"
															  className={item.value === expressList?.newIntendedFor?.text ? styles.itemActive : styles.item}
															  key={item.id}
															  onClick={() =>
															   toggleIntendedForItems(item.id, item.value)
															  }
															 >
																 <span>{item.text}</span>
															 </div>
															))}
														</div>
														</div>
													</div>
												</div>
											</div> :
											   item?.title === 'Description Builder Settings' ? <div className={styles.listingTitleContainer}>
													   <div className={styles.TitlesContainer}>
														   <div className={styles.title}>
															   {item?.title}
															   <Tippy
																   interactive={true}
																   arrow={false}
																   zIndex={9999}
																   trigger="click"
																   content={
																	   <div
																		   className={`popover ${styles.popoverContent}`}>
																		   <ul>
																			   <li>
                              <span className={styles.heading}>
                                Normal Listing –
                              </span>{' '}
																				   This setting will add and display ALL
																				   fields that
																				   are included in the pro description
																				   builder.
																				   Including short description, feature
																				   list
																				   (bulleted list), size, measurements,
																				   condition,
																				   condition description, and bottom of
																				   description.
																			   </li>
																			   <li>
																				   {' '}
																				   <span className={styles.heading}>
                                Shorten Listing –
                              </span>
																				   This setting will display ONLY the
																				   details added
																				   to the specific “Short Description ”
																				   box in your
																				   LP Listing. *Please note that by
																				   default listings
																				   that are imported to your LP catalog
																				   will have a
																				   shorten listing setting applied as
																				   all of the
																				   details are added to only the “short
																				   description”
																				   box when importing to LP.
																			   </li>
																			   <li>
																				   Listings imported to List Perfectly
																				   will
																				   automatically be set to Shorten
																				   Listing. This is
																				   so that no extra text is added to
																				   your
																				   description.To use the description
																				   builder, set to
																				   Normal Listing.
																			   </li>
																			   <div className={styles.line}></div>
																			   <p>★ Crossposts to supported 'condition'
																				   fields.</p>
																			   <p>
																				   ♦ Pro{' '}
																				   <a
																					   href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
																					   target="_blank"
																				   >
																					   {' '}
																					   Description Builder Settings
																				   </a>
																			   </p>
																		   </ul>
																	   </div>
																   }
															   >
																   <PopoverIcon
																	   className={styles.popoverIcon}
																	   color={'#fff'}
																   ></PopoverIcon>
															   </Tippy>
														   </div>
														   <div className={styles.titlesMain}>
															   <div className={styles.intendedMenuIntendedFor}>
																   <div className={styles.menuItems}>
																	   {descriptionBuildItems.map((item: any) => (
																		   <div
																			   data-name="input_166"
																			   className={item.value === expressList?.newDescBuilder?.text ? styles.itemActive : styles.item}
																			   key={item.id}
																			   onClick={() =>
																				   toggleDescriptionBuildItems(item.id, item.value)
																			   }
																		   >
																			   <span>{item.text}</span>
																		   </div>
																	   ))}
																   </div>
															   </div>
														   </div>
													   </div>
												   </div> :
												   item?.title === 'COGS' ?
													   <div className={styles.listingTitleContainer}>
														   <div className={styles.TitlesContainer}>
															   <div className={styles.title}>
																   {item?.title}
																   <Tippy
																	   interactive={true}
																	   arrow={false}
																	   zIndex={9999}
																	   trigger="click"
																	   content={
																		   <div
																			   className={`popover ${styles.popoverContent}`}>
																			   <p>Cost of Goods - What you
																				   paid to buy or create the listing for
																				   sale.</p>

																		   </div>
																	   }
																   >
																	   <PopoverIcon
																		   className={styles.popoverIcon}
																		   color={'#fff'}
															 ></PopoverIcon>
														 </Tippy>
													 </div>
													 <div className={styles.titlesMain}>
														 <InputCount
														  placeholder="COGS"
														  name="input_847"
														  handleChange={handleChange}
														  value={expressList.newCogs?.text}
														  limit={500}
														  height={40}
														  hideCount={true}
														  type="number"
														 />

													 </div>
												 </div>
											 </div> :
											 item?.title === 'MSRP' ? <div className={styles.listingTitleContainer}>
												  <div className={styles.TitlesContainer}>
													  <div className={styles.title}>
														  {item?.title}
														  <Tippy
														   interactive={true}
														   arrow={false}
														   zIndex={9999}
														   trigger="click"
														   content={
															   <div className={`popover ${styles.popoverContent}`}>
																   <ul>
																	   <li>
																		   'Manufacturer's Recommended Sale Price'
																	   </li>
																	   <li>Original price as displayed on manufacturer tags</li>
																	   <p>★ Crossposts to supported 'MSRP' fields.</p>
																   </ul>
															   </div>
														   }
														  >
															  <PopoverIcon
															   className={styles.popoverIcon}
															   color={'#fff'}
															  ></PopoverIcon>
														  </Tippy>
													  </div>
													  <div className={styles.titlesMain}>
														  <InputCount
														   placeholder="MSRP"
														   name="input_104"
														   handleChange={handleChange}
														   value={expressList.newMSRP?.text}
														   limit={500}
														   height={40}
														   hideCount={true}
														   type="number"
														  />

													  </div>
												  </div>
											  </div> :
											  item?.title === 'Quantity' ? <div className={styles.listingTitleContainer}>
												   <div className={styles.TitlesContainer}>
													   <div className={styles.title}>
														   {item?.title}
														   <Tippy
															interactive={true}
															arrow={false}
															zIndex={9999}
															trigger="click"
															content={
																<div className={`popover ${styles.popoverContent}`}>
																	<ul>
																		<li>
																			Enter the quantity.
																		</li>
																		<p>★ Crossposts to supported 'Quantity' fields.</p>
																	</ul>
																</div>
															}
														   >
															   <PopoverIcon
																className={styles.popoverIcon}
																color={'#fff'}
															   ></PopoverIcon>
														   </Tippy>
													   </div>
													   <div className={styles.titlesMain}>
														   <InputCount
															placeholder="Quantity"
															name="input_851"
															handleChange={handleChange}
															value={expressList.newQuantity?.text}
															limit={500}
															height={40}
															hideCount={true}
															type="number"
														   />

													   </div>
												   </div>
											   </div> :
												  item?.title === 'Made In' ? <div className={styles.listingTitleContainer}>
													   <div className={styles.TitlesContainer}>
														   <div className={styles.title}>
															   {item?.title}
															   <Tippy
																interactive={true}
																arrow={false}
																zIndex={9999}
																trigger="click"
																content={
																	<div className={`popover ${styles.popoverContent}`}>
																		<ul>
																			<li>
																				What country, or if known, region, state
																				or city?
																			</li>
																			<li>
																				This is great information for your buyer and especially for rare and unique items is part of your item's story.
																			</li>
																		</ul>
																	</div>
																}
															   >
																   <PopoverIcon
																	className={styles.popoverIcon}
																	color={'#fff'}
																   ></PopoverIcon>
															   </Tippy>
														   </div>
														   <div className={styles.titlesMain}>
															   <InputCount
																placeholder="Made In"
																name="input_211"
																handleChange={handleChange}
																value={expressList.newMadein?.text}
																limit={500}
																height={40}
																hideCount={true}
															   />
														   </div>
													   </div>
												   </div> :
												  item?.title === 'Seller Notes' ? <div className={styles.listingTitleContainer}>
													   <div className={styles.TitlesContainer}>
														   <div className={styles.title}>
															   {item?.title}
														   </div>
														   <div className={styles.titlesMain}>
															   <TextareaWithCount
																placeholder={'Seller Notes'}
																name={"input_726"}
																value={bdecode(expressList.newNotes?.text)}
																limit={5000}
																height={150}
																handleChange={handleChange}>
															   </TextareaWithCount>

														   </div>
													   </div>
												   </div> :
													  item?.title === 'Shipping ZIP Or City Code' ? <div className={styles.listingTitleContainer}>
															  <div className={styles.TitlesContainer}>
																  <div className={styles.title}>
																	  {item?.title}
																	  <Tippy
																		  interactive={true}
																		  arrow={false}
																		  zIndex={9999}
																		  trigger="click"
																		  content={
																			  <div className={`popover ${styles.popoverContent}`}>
																				  <ul>
																					  <li>
																						  Enter the zip code for where the product is shipping from.

																					  </li>

																				  </ul>
																				  <p>★ Crossposts to supported 'zip code' fields.</p>
																			  </div>
																		  }
																	  >
																		  <PopoverIcon
																			  className={styles.popoverIcon}
																			  color={'#fff'}
																		  ></PopoverIcon>
																	  </Tippy>
																  </div>
																  <div className={styles.titlesMain}>
																	  <InputCount
																		  placeholder="Shipping ZIP Or City Code"
																		  name="input_837"
																		  type="number"
																		  handleChange={handleChange}
																		  value={expressList.newShippingZipOrCityCode?.text}
																		  limit={20}
																		  height={40}
																	  />
																  </div>
															  </div>
														  </div> :
													   item?.title === 'Care' ? <div className={styles.listingTitleContainer}>
															<div className={styles.TitlesContainer}>
																<div className={styles.title}>
																	{item?.title}
																	<Tippy
																	 interactive={true}
																	 arrow={false}
																	 zIndex={9999}
																	 trigger="click"
																	 content={
																		 <div className={`popover ${styles.popoverContent}`}>
																			 <ul>
																				 <li>
																					 This includes washing instructions, polishing, maintenance, or anything else your buyer should do to take care of this item.
																				 </li>
																			 </ul>
																		 </div>
																	 }
																	>
																		<PopoverIcon
																		 className={styles.popoverIcon}
																		 color={'#fff'}
																		></PopoverIcon>
																	</Tippy>
																</div>
																<div className={styles.titlesMain}>
																	<TextareaWithGrowHeight
																		placeholder="Care"
																		name="input_43"
																		value={expressList.newCare?.text}
																		handleChangeEditInput={handleChange}
																		limit={500}
																		hasLimit={false}
																	/>
																</div>
															</div>
														</div> :
														item?.title === 'Pattern or texture' ? <div className={styles.listingTitleContainer}>
															 <div className={styles.TitlesContainer}>
																 <div className={styles.title}>
																	 {item?.title}
																	 <Tippy
																	  interactive={true}
																	 arrow={false}
																	 zIndex={9999}
																	 trigger="click"
																	 content={
																		 <div className={`popover ${styles.popoverContent}`}>
																			 <ul>
																				 <li>
																					 ex. If your item is fabric
																				 </li>
																				 <ul>
																					 <li>
																						 What is the weave?
																					 </li>
																					 <li>
																						 If furniture, is it carved?
																					 </li>
																				 </ul>
																			 </ul>
																		 </div>
																	 }
																	 >
																		 <PopoverIcon
																		  className={styles.popoverIcon}
																		 color={'#fff'}
																		 ></PopoverIcon>
																	 </Tippy>
																 </div>
																 <div className={styles.titlesMain}>
																	 <TextareaWithGrowHeight
																		 placeholder="Pattern or texture"
																		 name="input_210"
																		 value={expressList.newPatternOrTexture?.text}
																		 handleChangeEditInput={handleChange}
																		 limit={500}
																		 hasLimit={false}
																	 />
																 </div>
															 </div>
														 </div> :
														 item?.title === 'Season or weather' ? <div className={styles.listingTitleContainer}>
															  <div className={styles.TitlesContainer}>
																  <div className={styles.title}>
																	  {item?.title}
																	  <Tippy
																	   interactive={true}
																	  arrow={false}
																	  zIndex={9999}
																	  trigger="click"
																	  content={
																		  <div className={`popover ${styles.popoverContent}`}>
																			  <ul>
																				  <li>
																					  ex. Is this item good for summer? Or for snow?
																				  </li>
																			  </ul>
																		  </div>
																	  }
																	  >
																		  <PopoverIcon
																		   className={styles.popoverIcon}
																		  color={'#fff'}
																		  ></PopoverIcon>
																	  </Tippy>
																  </div>
																  <div className={styles.titlesMain}>
																	  <TextareaWithGrowHeight
																		  placeholder="Season or weather"
																		  name="input_36"
																		  value={expressList.newSeasonOrWeather?.text}
																		  handleChangeEditInput={handleChange}
																		  limit={500}
																		  hasLimit={false}
																	  />

																  </div>
															  </div>
														  </div> :
														  item?.title === 'Shipping Dimensions' ?
															  <div>
																  <p className={styles.title}>Shipping Dimensions</p>
																  <div className={styles.shippingDimensions}>
																	  <div className={styles.listingTitleContainer}>
																		  <div className={styles.TitlesContainer}>
																			  <div className={styles.title}>
																				 	Length
																				  <Tippy
																					  interactive={true}
																					  arrow={false}
																					  zIndex={9999}
																					  trigger="click"
																					  content={
																						  <div
																							  className={`popover ${styles.popoverContent}`}>
																							  <ul>
																								  <li>
																									  Enter the package
																									  dimensions.
																								  </li>

																							  </ul>
																							  <p>★ Crossposts to
																								  supported
																								  'shipping dimension'
																								  fields</p>
																						  </div>
																					  }
																				  >
																					  <PopoverIcon
																						  className={styles.popoverIcon}
																						  color={'#fff'}
																					  ></PopoverIcon>
																				  </Tippy>
																			  </div>
																			  <div className={styles.titlesMain}>
																				  <InputCount
																					  placeholder="Shipping Package Length"
																					  name="input_531"
																					  type="number"
																					  handleChange={handleChange}
																					  value={expressList.newShippingPackageLength?.text}
																					  limit={500}
																					  height={40}
																					  hideCount={true}
																				  />
																			  </div>
																		  </div>
																	  </div>
																	  <div className={styles.listingTitleContainer}>
																		  <div className={styles.TitlesContainer}>
																			  <div className={styles.title}>
																				 Width
																				  <Tippy
																					  interactive={true}
																					  arrow={false}
																					  zIndex={9999}
																					  trigger="click"
																					  content={
																						  <div
																							  className={`popover ${styles.popoverContent}`}>
																							  <ul>
																								  <li>
																									  Enter the package
																									  dimensions.
																								  </li>
																							  </ul>
																							  <p>★ Crossposts to
																								  supported
																								  'shipping dimension'
																								  fields.</p>
																						  </div>
																					  }
																				  >
																					  <PopoverIcon
																						  className={styles.popoverIcon}
																						  color={'#fff'}
																					  ></PopoverIcon>
																				  </Tippy>
																			  </div>
																			  <div className={styles.titlesMain}>
																				  <InputCount
																					  placeholder="Shipping Package Width"
																					  name="input_532"
																					  type="number"
																					  handleChange={handleChange}
																					  value={expressList.newShippingPackageWidth?.text}
																					  limit={500}
																					  height={40}
																					  hideCount={true}
																				  />
																			  </div>
																		  </div>
																	  </div>
																	  <div className={styles.listingTitleContainer}>
																		  <div className={styles.TitlesContainer}>
																			  <div className={styles.title}>
																				   Height
																				  <Tippy
																					  interactive={true}
																					  arrow={false}
																					  zIndex={9999}
																					  trigger="click"
																					  content={
																						  <div
																							  className={`popover ${styles.popoverContent}`}>
																							  <ul>
																								  <li>
																									  Enter the package
																									  dimensions.
																								  </li>
																							  </ul>
																							  <p>★ Crossposts to
																								  supported
																								  'shipping dimension'
																								  fields.</p>
																						  </div>
																					  }
																				  >
																					  <PopoverIcon
																						  className={styles.popoverIcon}
																						  color={'#fff'}
																					  ></PopoverIcon>
																				  </Tippy>
																			  </div>
																			  <div className={styles.titlesMain}>
																				  <InputCount
																					  placeholder="Shipping Package Height"
																					  name="input_533"
																					  type="number"
																					  handleChange={handleChange}
																					  value={expressList.newShippingPackageHeight?.text}
																					  limit={500}
																					  height={40}
																					  hideCount={true}
																				  />
																			  </div>
																		  </div>
																	  </div>
																  </div>
															  </div> :

															  <></>
						   }
					   </div>
					  )}
				  </div>
			  </div>
		  </>
		 )}
	 </div>
	)
}