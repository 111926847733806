import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAppPrefix from '../../../hooks/UseAppPrefix';
import getToken from '../../../utils/getToken';

interface LayoutProps {
  children: JSX.Element[] | JSX.Element;
}

interface NavigationState {
  active: boolean;
  showIframe: boolean;
  isMobile: boolean;
}

interface RootState {
  navigation: NavigationState;
}

function Layout({ children }: LayoutProps) {
  const navigation = useSelector((state: RootState) => state.navigation.active);
  const showIframe = useSelector(
    (state: RootState) => state.navigation.showIframe
  );
  const token: any = getToken();
  const linkPrefix = useAppPrefix();
  const isMobile = useSelector((state: any) => state.navigation.isMobile);

  useEffect(() => {
    const decodedToken: any = jwt_decode(token);
    const roles = decodedToken?.data?.user?.roles;
    const is_role_allowed = roles.some((role: any) => {
      return (
        role.toLowerCase() === 'corporate master' ||
        role.toLowerCase() === 'corporate sub'
      );
    });

    if (!is_role_allowed) {
      window.location.href = `https://${linkPrefix?.prefix}.listperfectly.com/mainmenu/`;
    }
  }, []);

  return <div className="page-container">{children}</div>;
}

export default Layout;
