import pMap from 'p-map';
import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';

let token: string | undefined;

const tokenFromLocalStorage = localStorage.getItem('lp_authorization_token');
if (tokenFromLocalStorage) {
  token = tokenFromLocalStorage;
} else {
  const tokenFromCookies = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith('lp_authorization_token='));

  if (tokenFromCookies) {
    const tokenPrefix = 'lp_authorization_token=';

    token = tokenFromCookies.substring(tokenPrefix.length);
  } else {
    window.location.href = 'https://app.listperfectly.com/';
  }
}

let abortController = new AbortController();

const Giver = ({ token }: { token: string }) => {
  const axCfg: AxiosRequestConfig = {
    url: 'https://api.listperfectly.com/v1/orders/list',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };

  const getTotal = async (payload: any) => {
    payload.metadata = { offset: 0, limit: 1 };

    let modifiedPayload = { ...payload };

    const shippingStatus = _.get(payload, 'filter_by.shipping_status');

    if (!shippingStatus) {
      modifiedPayload = {
        ...payload,
        filter_by: {
          ...payload.filter_by,
          shipping_status: 'awaiting_shipment', // or whatever the default value should be
        },
      };
    }
    return await axios({ ...axCfg, data: modifiedPayload })
      .then(({ data }) => {
        return data?.metadata?.total;
      })
      .catch(({ message }) => {
        console.log('[getTotal] err', message);
        return false;
      });
  };

  const getAllDataByPayloadByPrint = async (
    initPayload: any,
    divider_number: number,
    max_concurrency: number,
    cancelToken?: boolean
  ) => {
    if (!initPayload || typeof initPayload !== 'object') return false;

    let modifiedPayload = { ...initPayload };

    const shippingStatus = _.get(initPayload, 'filter_by.shipping_status');

    if (!shippingStatus) {
      modifiedPayload = {
        ...initPayload,
        filter_by: {
          ...initPayload.filter_by,

          shipping_status: 'awaiting_shipment', // or whatever the default value should be
        },
      };
    }

    if (cancelToken) {
      abortController.abort();
      return;
    } else {
      abortController = new AbortController();
    }

    const mapper = async (alimit_offset: number) => {
      const data = {
        ...modifiedPayload,
        ...{ metadata: { offset: alimit_offset, limit: divider_number } },
      };

      return axios({ ...axCfg, data, signal: abortController.signal })
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          return { err: error.message };
        });
    };

    const total = await getTotal(initPayload);
    const limits = Array(((total / divider_number) | 0) + 1)
      .fill(1)
      .map((_, k) => k * divider_number);

    const result = await pMap(limits, mapper, { concurrency: max_concurrency });

    return result.flatMap(({ data, err }) => data || err);
  };

  return { getTotal, getAllDataByPayloadByPrint };
};

export const { getAllDataByPayloadByPrint } = Giver({ token: token! });
