import EditAndAddListingModal from "../../components/EditAddListingModal";
import Iframe from "../../components/UI/Iframe";
import {Helmet} from "react-helmet";
import React from "react";

const EditListingPage = () => {
    return(
        <>
            <Helmet>
                <title>
                    Edit Listing | List Perfectly
                </title>
            </Helmet>
            <Iframe />
            <EditAndAddListingModal />
        </>
    )
}

export default EditListingPage;