import React, {useContext} from 'react';
import {GlobalUploaderStateContext} from "../context/UploaderContext";

const useGlobalUploaderState = () => {
    const context = useContext(GlobalUploaderStateContext);
    if (!context) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }
    return context;
};

export {useGlobalUploaderState}