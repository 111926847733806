import React from 'react' ;
import styles from '../../CustomSkuFormat.module.scss'
import { useSortable } from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import {TagDragBtn, TagRemoveBtn} from "../../../../../../assets/icons";
interface ITag {
	id:number;
	label:string;
	handleDeleteSkuFormatTag:(id:number) => void;
	icon:any;
}
const Tag = (props:ITag) => {
	const {id,label,handleDeleteSkuFormatTag,icon} = props ;
	const { attributes, listeners, setNodeRef, transform,transition } = useSortable({ id });
	const style = {
		transition,
		transform: CSS.Transform.toString(transform),
		backgroundColor: '#f0f0f0',
		cursor: 'grab',
	};


	return (
	 <div
	  style={style}
	  className={styles.skuFormatItem}
	  ref={setNodeRef}
	  {...attributes}>
		 <div className={styles.dragHandle} {...listeners}>
			 <TagDragBtn/>
		 </div>
		 <div className={styles.tagLabelBlock}>{icon}
			 <div className={styles.tagLabel}>{label}</div>
		 </div>
		 <div className={styles.tagRemove}>
			 <TagRemoveBtn
			  onClick={() => handleDeleteSkuFormatTag(id)}/></div>
	 </div>
	)
}

export default Tag;