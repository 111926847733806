import React, { useRef, useState, useEffect } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { MoonLoader } from 'react-spinners';

const ScanComponent = ({ setScanShow, sendRequestForScan }: any) => {
    const [showLoader, setShowLoader] = useState(true);
    const [barcodeDetected, setBarcodeDetected] = useState(false);
    const beepSound = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        beepSound.current = new Audio('/sound/barcode.mp3');
        beepSound.current.load();
        // Set a timeout to hide the loader after 2 seconds
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        // Clear the timeout to avoid potential memory leaks
        return () => clearTimeout(timeoutId);
    }, []);

    const handleBarcodeDetection = (err: any, result: any) => {
        try{
            if (result && !barcodeDetected) {
                if (beepSound.current) {
                    beepSound.current.play().catch((error) => {
                        console.error("Failed to play sound:", error);
                    });
                }
                // Set barcodeDetected to true to prevent further requests
                setBarcodeDetected(true);

                // Call the sendRequestForScan function with the barcode value
                sendRequestForScan(result.text);

                // You can optionally hide the scanner after detecting the barcode
                setScanShow(false);
            }
        }
        catch(e){
            console.log('error', e)
        }
    };

    return (
        <div>
            {showLoader ? (
                <div
                    style={{
                        width: 600,
                        height: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <MoonLoader color="#1e3166" loading={true} size={48} />
                </div>
            ) : (
                <BarcodeScannerComponent
                    width={600}
                    height={300}
                    torch={true}
                    delay={0}
                    onUpdate={handleBarcodeDetection}
                />
            )}

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <button
                    onClick={() => setScanShow(false)}
                    style={{
                        background: '#1E3166',
                        fontSize: 18,
                        borderRadius: '8px',
                        marginTop: 10,
                        padding: '10px 20px',
                        color: '#fff',
                        border: 'none',
                    }}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ScanComponent