import ListingViewModal from "../../components/UI/ListingViewModal";
import Iframe from "../../components/UI/Iframe";
import React from "react";
import {Helmet} from 'react-helmet'

const ListingViewPage = () =>{
    return (
        <>
            <Helmet>
                <title>Listing View | List Perfectly</title>
            </Helmet>
            <ListingViewModal />
            <Iframe/>
        </>
    )
}

export default ListingViewPage;