import Cookies from 'js-cookie';

const getToken = (): string | undefined => {
	try {
		const token = localStorage.getItem('lp_authorization_token');
		const cookieToken = Cookies.get('lp_authorization_token');
		if (token) {
			return token;
		}
		else if (cookieToken) {
			return cookieToken;
		}
		else{
			window.location.href = 'https://app.listperfectly.com/'
		}

	} catch (error) {
		console.error(error)
	}
};

export default getToken;