import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import useBasicFuncs from "../../hooks/useBasisFuncs";
import { instanceAxios } from "../../services";
import './printQr.css';

export const PrintQRPage = () => {
    const [productInfo, setProductInfo] = useState<any>(null);
    const [id, setId] = useState<any>(null);
    const { bdecode } = useBasicFuncs();
    const printRef = useRef<any>();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    async function fetchData(): Promise<void> {
        try {
            const response = await instanceAxios.post('/listings/list', {
                metadata: { offset: 0, limit: 1 },
                filter_by: { id: id },
                options: {
                    include_thumbnail: true,
                    include_images: true,
                },
            });
            if (response.status === 200) {
                setProductInfo(response?.data?.data[0]);
                setTimeout(() => {
                    handlePrint();
                }, 100);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        const url = window.location.href;
        const id: any = url.split('/').pop();
        setId(+id);
    }, []);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);


    const origin = window.location.origin;
    const listingUrl = `${origin}/listings/view/${id}`;

    return (
     <>
         <div className="print-container" ref={printRef}>
             <div className="qrCode">
                 <div className="space"></div>
                 <div className="qr-code-container">
                     <QRCode
                      className="qr-code"
                      size={300}
                      value={listingUrl} // Set the value to the dynamic listing URL
                      viewBox="0 0 256 256"
                     />
                 </div>
                 <div className="space"></div>
                 <div className="title-container-print">
                     <div className="qr-title">{bdecode(productInfo?.metadata[133])}</div>
                     <div className="qr-sku">{bdecode(productInfo?.metadata[1])}</div>
                 </div>
             </div>
         </div>
     </>
    );
};
