export default function convertToFormattedString(inputString: string | null | undefined) {
  if (inputString == null) {
    return null;
  }

  const formattedWords = inputString.split('_').map((word) => {
    // Преобразование "sku" в "SKU"
    if (word.toLowerCase() === 'sku') {
      return 'SKU';
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  const formattedString = formattedWords.join(' ');

  return formattedString;
}