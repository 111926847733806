import React, {useEffect, useState} from 'react';
import styles from './MarketModalMobile.module.scss';
import {CloseSvg} from "../../../../../assets/icons";
import {PlatformIconShop} from "../../../../../assets/icons";
import ColorPicker from "../../ColorPicker";


const MarketModalMobile = ({
							   setOpenMarketModalMobile,
							   setIsEdit,
							   isEdit,
							   colorPickerColor,
							   customMarketNameValue,
							   setColorPickerColor,
							   setCustomMarketNameValue,
							   setCharCount,
							   updateCustomMarket,
							   setIsSaved,
							   setIsCanceled,
							   handleDelete,
							   handleCancel,
							   isMobile,
							   addNewCustom,
							   handleDeleteNewCustom,
							   setShowCustomObject,
							   setCustomMarketNameValueNew,
							   setColorPickerColorNew,
							   setAllowAdd,
							   handleSaveNewCustom,
							   colorPickerColorNew,
							   setCharCountNewCustom,
							   charCountNewCustom,
							   customMarketNameValueNew
						   }: any) => {

	const handleCloseMarketModal = ()=>{
		setOpenMarketModalMobile(false);
		if(!addNewCustom){
			setIsEdit(!isEdit);
			handleCancel();
		}else{
			setShowCustomObject(false);
			setCustomMarketNameValueNew('');
			setColorPickerColorNew('#000');
			setAllowAdd(true);
		}

	}
	const handleChangeCustomMarketValue = (value: string) => {
		if (addNewCustom && value.length <= 11) {
			setCustomMarketNameValueNew(value);
			setCharCountNewCustom(value.length);
		}else if(value.length <= 11 && !addNewCustom){
			setCustomMarketNameValue(value);
			setCharCount(value.length);
		}
	};
	const handleChangeColorPickerColor = (color: string) => {
		if(addNewCustom){
			setColorPickerColorNew(color);
		}else{
			setColorPickerColor(color);
		}

	};
	const handleSave = () => {
		if( addNewCustom){
			handleSaveNewCustom();
		}else{
			updateCustomMarket();
			setIsEdit(false);
			setIsSaved(true);
			setIsCanceled(false);
		}

	};

	return (
	 <div className={styles.customMarketModalContainer}>
		 <div className={styles.customMarketModal}>
			 <p className={styles.editCustomMarket}> Edit Custom Marketplace</p>
			 <div className={styles.imageContainer}>
				 <p>Image (optional)</p>
				 <PlatformIconShop width={40} height={40} fill={addNewCustom ? colorPickerColorNew : colorPickerColor} />
			 </div>
			 <CloseSvg className={styles.closeIcon} height={25} onClick={handleCloseMarketModal} />

			 <div className={styles.marketNameContainer}>
				 <p>Title*</p>
				 <div className={styles.platformInputWrapper}>
					 <input
					  type="text"
					  className="myInput"
					  value={addNewCustom ? customMarketNameValueNew : customMarketNameValue}
					  onChange={(e) => handleChangeCustomMarketValue(e.target.value)}
					 />
					 {
						 addNewCustom ? (<span>{customMarketNameValueNew?.length ? customMarketNameValueNew?.length : 0}/11</span>) : (<span>{customMarketNameValue?.length ? customMarketNameValue?.length : 0}/11</span>)
					 }

				 </div>
			 </div>
			 <div className={styles.colorContainer}>
				 <p>Color*</p>
				 <ColorPicker
				  defaultColor={colorPickerColor}
				  handleChangeColorPickerColor={handleChangeColorPickerColor}
				  isMobile={isMobile}

				 />
			 </div>
			 <div className={styles.buttonsContainer}>
				 <button onClick={handleSave} className="myBtn saveBulkBtn">
					 Save
				 </button>
				 {
					 addNewCustom ? (
					  <button className={`cancelBtn2 ${styles.cancelBtn2}`} onClick={handleDeleteNewCustom}>
						  {
							  <span>Delete</span>
						  }

					  </button>
					 ) : (
					  <button className={`cancelBtn2 ${styles.cancelBtn2}`} onClick={handleCancel}>
						  {
							  <span>Cancel</span>
						  }
					  </button>
					 )
				 }
			 </div>

		 </div>
	 </div>
	);
};

export default MarketModalMobile;