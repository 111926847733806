// sharedWorkerSlice.js

import { createSlice } from '@reduxjs/toolkit';

const sharedWorkerSlice = createSlice({
	name: 'sharedWorker',
	initialState: {
		token: null,
		messages: [],
	},
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload;
		},
		receiveMessage: (state, action) => {
			// Handle the received message here
			// console.log('Received message from shared worker:', action.payload);
			// You can update the state based on the message if needed
		},
	},
});

export const { setToken, receiveMessage } = sharedWorkerSlice.actions;

export default sharedWorkerSlice.reducer;