import { ConnectWizard, SettingsNavigation } from '../../assets/icons';
import { CloseModal } from '../../assets/icons';
import styles from './MarketplacesModal.module.scss';
import React, {useEffect, useRef, useState} from 'react';
import AvalaiblePlatformItem from './components/AvalaiblePlatformItem';
import ListItem from './components/ListItem';
import {MoonLoader} from "react-spinners";
const MarketplacesModal = (props: any) => {
	const {
		marketplacesList,
		availableMarketplacesList,
		setConnectedMarketplacesModal,
		setConnectionWizardActivate,
		setOpenSettingsModal,
		handleGetSettingsValue,
		includesError,
		setShowGeneralSettings,
		isShopifySupported
	} = props;
	const [settingsInfo, setSettingsInfo] = useState<any>('');
	const [reconnectionBtnLoading, setReconnectionBtnLoading] = useState(false);
	const reconectionButtonRef = useRef<any>(null);
	const handleCloseModal = () => {
		setConnectedMarketplacesModal(false);
	};
	const handleGoToWizardModal = () => {
		setConnectionWizardActivate(true);
	};
	const handleOpenSettingsModal = (marketName: any) => {
		setOpenSettingsModal(true);
		setSettingsInfo(marketName);
		handleGetSettingsValue(marketName);
	};

	useEffect(() => {
		const handleAttributeChange = () => {
			if (reconectionButtonRef.current) {
				const hasClass = reconectionButtonRef.current.classList.contains('lp_custom_connection_wizard_modal_reconnection_wizard_button_loading');
				setReconnectionBtnLoading(hasClass);
			}
		};

		const observer = new MutationObserver(handleAttributeChange);

		if (reconectionButtonRef.current) {
			observer.observe(reconectionButtonRef.current, {
				attributes: true,
				attributeFilter: ['class'],
			});
		}

		return () => {
			observer.disconnect();
		};
	}, [includesError, marketplacesList]);

	const generateRandomKey = () => {
		return Math.random().toString(36).substr(2, 9); // Generate a random string
	};


	return (
	 <div
	  className={`${styles.connectedMarketplacesModal} lp_custom_marketplaces_connected_modal`}
	 >
		 <>
			 <div className={styles.titles}>
				 <h1 className={styles.heading}>Connected Marketplaces</h1>
				 <span className={styles.subTitle}>
            <span>
              {marketplacesList?.length ? marketplacesList?.length : 0}
            </span>
            /11 connected
          </span>
			 </div>
			 <div className={styles.reconnectionWizardBtns}>
				 {marketplacesList?.length > 0 && includesError && (
				  <button ref={reconectionButtonRef} className={`lp_custom_connection_wizard_modal_reconnection_wizard_button ${styles.runReConnectionWizard}`}>
					  {reconnectionBtnLoading ? (
					   <div className={styles.loadingBtn}>
						   <MoonLoader size={20} />
						   <p>Loading</p>
					   </div>
					  ) : (
					   <>
						   <ConnectWizard />
						   Reconnection Wizard
					   </>
					  )}

				  </button>
				 )}

				 <button className={styles.viewAllSettings} onClick={()=> {
					 setShowGeneralSettings(true);
					 handleOpenSettingsModal('General');
				 }}>
					 <SettingsNavigation />
					 General Settings
				 </button>
			 </div>
			 {marketplacesList?.length <= 0 || marketplacesList === null ? (
			  <p className={styles.noMarketplaceList}>
				  You have no marketplaces connected, launch the connection wizard
				  below to get started.
			  </p>
			 ) : (
			  <div className={`${styles.marketplacesList}`}>
				  <div className={styles.marketplacesListHeader}>
					  <div className={styles.marketplacesListHeaderItem}>Name</div>
					  <div className={styles.marketplacesListHeaderItem}>Account</div>
					  <div
					   className={`${styles.marketplacesListHeaderItem} ${styles.actions}`}
					  >
						  Actions
					  </div>
				  </div>
				  <div className={styles.marketplacesListItems}>
					  {marketplacesList?.map((market: any) => (
					   <ListItem
						key={generateRandomKey()}
						marketName={market?.marketplace}
						account={market?.shop_id?.error ? market?.shop_id?.error?.substring(0, 3) : market?.shop_id}
						extensionActive={market?.connection_status_extension}
						extensionError={market?.connection_status_extension_error}
						onSettingsClick={handleOpenSettingsModal}
					   />
					  ))}
				  </div>
			  </div>
			 )}
			 <div className={styles.hr}></div>
			 <div className={styles.availablePlatformsContainer}>
				 <div>
					 <p className={styles.availablePlatformsTitle}>
						 Available Platforms
					 </p>
				 </div>

				 <div className={styles.availablePlatformsItemContainer}>
					 <div className={styles.avalaiblePlatforms}>
						 {availableMarketplacesList.map((market: any) => (
						  ((( !isShopifySupported && market?.marketName.toLowerCase() !== 'shopify') && market?.marketName.toLowerCase() !== 'instagram') || (isShopifySupported && market?.marketName.toLowerCase() !== 'instagram'))  &&
						  <AvalaiblePlatformItem marketName={market?.marketName} />
						 ))}
						 {/*{availableMarketplacesList.map((market: any) => (*/}
						 {/*    <AvalaiblePlatformItem marketName={market?.marketName} />*/}
						 {/*))}*/}
					 </div>

					 <button
					  className={styles.runConnectionWizard}
					  onClick={handleGoToWizardModal}
					 >
						 <ConnectWizard />
						 Connection Wizard
					 </button>
				 </div>
			 </div>
			 <div className={styles.closeModal} onClick={handleCloseModal}>
				 <CloseModal />
			 </div>
		 </>
	 </div>
	);
};

export default MarketplacesModal;