import ReactDOM from 'react-dom/client';
import './assets/styles/global.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { IntercomProvider } from 'react-use-intercom';
import SharedWorkerInitializer from "./components/SharedWorkerInitializer";

const INTERCOM_APP_ID = 's2df9k19';

const root = ReactDOM.createRoot(
 document.getElementById('root') as HTMLElement
);
root.render(
 <Provider store={store}>
	 <IntercomProvider appId={INTERCOM_APP_ID}>
		 <SharedWorkerInitializer />
		 <App />
	 </IntercomProvider>
 </Provider>
);

reportWebVitals();
