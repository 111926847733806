import React, { useEffect, useState } from 'react';
import styles from './TextAreaCount.module.scss'
export const TextareaWithCount = ({
                                      placeholder,
                                      name,
                                      value,
                                      limit,
                                      height,
                                      handleChange,
                                      textareaRef,
                                      showCount,
                                      verticallyResized
                                  }: any) => {
    const [characterCount, setCharacterCount] = useState(0);
    const id = Math.random().toString();

    useEffect(() => {
        if (value) {
            setCharacterCount(value.length);
        }
    }, [value]);

    return (
        <div style={{ width: '100%' }} className={`${verticallyResized ? styles.textareaResizeVertically : ''} `}>
		 <textarea
             className={styles.brandInfoTextarea}
             id={id}
             ref={textareaRef}
             value={value}
             name={name}
             style={{ height: height }}
             placeholder={placeholder}
             maxLength={limit}
             onChange={(e) => {
                 const newText = e.target.value;
                 if (newText.length <= limit) {
                     handleChange(e)
                     setCharacterCount(newText.length);
                 }
             }}
         />
            {
                showCount && 	<p className={styles.charactersQuantity}>
                    Character Count: {characterCount} / {limit}
                </p>
            }

        </div>
    );
};