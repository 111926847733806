import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISettingsState {
	settingsActiveIndex: number | null;
}

const initialState: ISettingsState = {
	settingsActiveIndex: 1,
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setSettingsActiveIndex: (state, action) => {
			state.settingsActiveIndex = action.payload;
		},
	},
});

export const { setSettingsActiveIndex } = settingsSlice.actions;
export default settingsSlice.reducer;