import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {DragIcon, TrashIcon} from "../../../../../assets/icons";
import styles from '../ExpressListerCustomize.module.scss';

export function SortableItem({skuGenerator, item, setMainListDataLister, setListFromMainLister}: any) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: item?.id });

	const style = {
		transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'none',
		transition,
	};

	const handleRemoveItem = (id:any) => {
		setMainListDataLister((prevList: any) =>
		 prevList.map((item:any) =>
		  item.id === id ? { ...item, checked: false } : item
		 )
		);
		setListFromMainLister((prevList: any) =>
		 prevList.map((item:any) =>
		  item.id === id ? { ...item, checked: false } : item
		 )
		);
	};

	return (
	 <div ref={setNodeRef} style={style} className={skuGenerator ? styles.skuGeneratorFieldSKU : ''}>
		 {skuGenerator && (
		  <div className={styles.selectedField}>
			  <div className={styles.leftSide}>
				  <p>SKU Generator</p>
			  </div>
			  <div className={styles.trashIcon} onClick={() => handleRemoveItem(skuGenerator?.id)}>
				  {!skuGenerator?.alwaysVisible && <TrashIcon/>}
			  </div>
		  </div>
		 )}
		 <div className={styles.selectedField}>
			 <div className={styles.leftSide}>
				 <div {...attributes} {...listeners}>
					 <div className={styles.dragButton}>
						 <DragIcon/>
					 </div>
				 </div>
				 <p>{item?.title}</p>
			 </div>
			 <div className={styles.trashIcon} onClick={() => handleRemoveItem(item?.id)}>
				 {!item?.alwaysVisible && <TrashIcon/>}
			 </div>
		 </div>

	 </div>
	);
}