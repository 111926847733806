import React, { useEffect } from 'react';

import styles from './TablePagination.module.scss';

import VectorActive from '../../../../../../assets/icons/svg/nextLeft.svg';
import DobleVector from '../../../../../../assets/icons/svg/2nextLEFT.svg';
import {setSecureModalWhenChangePage} from "../../../../../../redux/slices/NavigationSlice";
import {useDispatch} from "react-redux";
import {ArrowPgSvg, DoubleArrowPgSvg} from "@assets/icons";

interface ITablePagination {
  pageCount: number;
  pagiInputValue: string | number;
  setPagiInputValue: (value: string | number) => void;
  isBulkEdit:boolean;
}

const TablePagination = (props: ITablePagination) => {
  const {
    pageCount,
    isBulkEdit,
    pagiInputValue,
    setPagiInputValue,
  } = props;

  const dispatch = useDispatch();
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!isBulkEdit) {
      let value: any = e.target.value;
      if (+value > Math.ceil(pageCount) || +value < 0 || value === '0') {
        return;
      }
      if (value === '1') {
        value = +value;
      }

      setPagiInputValue(value);
    }
    else {
      dispatch(setSecureModalWhenChangePage(true));
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.charCode;

    if (charCode && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  const handleAddPage = () => {
    if(!isBulkEdit) {
      if (
          +pagiInputValue > Math.ceil(pageCount) - 1 ||
          +pagiInputValue < 0 ||
          pagiInputValue === '0'
      ) {
        return;
      }
      const addedValue = +pagiInputValue + 1;
      setPagiInputValue(addedValue.toString());
    }
    else {
      dispatch(setSecureModalWhenChangePage(true));
    }
  };

  const handleRemovePage = () => {
    if(!isBulkEdit) {
      if (+pagiInputValue < 1) {
        return;
      }
      const addedValue = +pagiInputValue - 1;
      setPagiInputValue(addedValue
          // .toString()
      );
    }
    else {
      dispatch(setSecureModalWhenChangePage(true));
    }
  };

  const handleLastPage = () => {
    if(!isBulkEdit) {
      setPagiInputValue(Math.ceil(pageCount).toString());
    }
    else {
      dispatch(setSecureModalWhenChangePage(true));
    }
  };

  const handleFirstPage = () => {
    if(!isBulkEdit) {
      setPagiInputValue(1);
    }
    else {
      dispatch(setSecureModalWhenChangePage(true));
    }
  };

  return (
    <div className={styles.reactPaginate}>
      <div className={styles.paginateInput}>
        <button
          className={styles.addPageVector}
          onClick={handleFirstPage}
          disabled={(pagiInputValue === '1' || pagiInputValue === 1 ) ? true : false}
        >
          <DoubleArrowPgSvg className={styles.rototateRemove} />
        </button>
        <button
          className={styles.addPageVector}
          onClick={handleRemovePage}
          disabled={(pagiInputValue === '1' || pagiInputValue === 1 ) ? true : false}
        >
          <ArrowPgSvg className={styles.rototateRemove} />
        </button>
        <input
          value={pagiInputValue}
          onChange={handleChangeValue}
          onKeyPress={handleKeyPress}
          id="paginateInput"
          type="text"
          className="myInput"
        />
        <div className={styles.paginatePageCount}>
          of {Math.ceil(pageCount)}
        </div>
        <button
          className={styles.addPageVector}
          onClick={handleAddPage}
          disabled={
            pagiInputValue === Math.ceil(pageCount).toString() ||
            Math.ceil(pageCount).toString() === '0'
              ? true
              : false
          }
        >
          <ArrowPgSvg />
        </button>
        <button
          className={styles.addPageVector}
          onClick={handleLastPage}
          disabled={
            pagiInputValue === Math.ceil(pageCount).toString() ||
            Math.ceil(pageCount).toString() === '0'
              ? true
              : false
          }
        >
          <DoubleArrowPgSvg />
        </button>
      </div>
    </div>
  );
};

export default TablePagination;
