import styles from './ShortDescriptionContainer.module.scss';
import Tippy from "@tippyjs/react";
import {PopoverIcon, SuggestDesc} from "../../../../assets/icons";
import React, {useEffect} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {TextareaWithCount} from "../../TextAreaCount";
import {InputCount} from "../../InputCount";
import {TextAreaTagify} from "../../TextAreaTagify";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import {MoonLoader} from "react-spinners";
import SuggestDescModal from "../../../UI/SuggestDescModal";
import {TextareaWithGrowHeight} from "../../TextareaGrowHeight";
import {AutoGrowHeightTextarea} from "../../AutoGrowHeightTextarea";


const ShortDescriptionContainer = ({hasBusiness, hasSimple, enabledButtons,suggestOnchange, setCommonState, commonState, setAssistantTitle, assistantTitle, generatedModal, setGeneratedModal, localAvalaibleCount, loaderForSuggest, sendRequestForSuggestDescription, formInfo, quotasData, unescapedRowMetadata, handleStandartInputChange, handleTagifiesChange, isModal ,  setAssistantFromTitle, assistantFromTitle}:any) => {
    const { bdecode } = useBasisFuncs();

    return (
        <div className={styles.shortDescriptionContainer}>
            {generatedModal && (
                <SuggestDescModal
                    assistantTitle={assistantTitle}
                    setAssistantTitle={setAssistantTitle}
                    setCommonState={setCommonState}
                    commonState={commonState}
                    setGeneratedModal={setGeneratedModal}
                    handleChangeEditInput={suggestOnchange}
                    isModal={isModal}
                    setAssistantFromTitle={setAssistantFromTitle}
                    assistantFromTitle={assistantFromTitle}
                />
            )}
            <div className={styles.brandInfoForm}>
                <div className={styles.leftSide}>
                    <div className={styles.headerBrandInfo}>
                        <div className={styles.title}>
                            Short Description
                            <Tippy
                                interactive={true}
                                zIndex={5}
                                trigger="click"
                                arrow={false}
                                content={
                                    <div className={`popover ${styles.popoverContent}`}>
                                        <p className={styles.heading}>
                                            Description Character Count Limits:
                                        </p>
                                        <ul>
                                            <li>Etsy: 10000</li>
                                            <li>eBay: 5000</li>
                                            <li>Poshmark: 1500</li>
                                            <li>Mercari: 1000</li>

                                            <div className={styles.line}></div>
                                            <p>
                                                ★ Crossposts to supported 'description' fields.
                                            </p>
                                            <p>
                                                ♦ Pro{' '}
                                                <a
                                                    href="https://app.listperfectly.com/docs/topics/pro-plan/description-builder/"
                                                    target="_blank"
                                                >
                                                    Description Builder
                                                </a>{' '}
                                                section
                                            </p>
                                        </ul>
                                    </div>
                                }
                            >
                                <PopoverIcon
                                    className={styles.popoverIcon}
                                    color={'#fff'}
                                ></PopoverIcon>
                            </Tippy>
                        </div>
                        {/*{*/}
                        {/*    (!hasSimple && !hasBusiness) && <>*/}
                        {/*        <div*/}
                        {/*            className={styles.suggestDescription}*/}
                        {/*            onClick={() => {*/}
                        {/*                if (enabledButtons) {*/}
                        {/*                    sendRequestForSuggestDescription('description')*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*            style={!enabledButtons ? {opacity: 0.7} : {opacity: 1}}*/}
                        {/*        >*/}
                        {/*            {loaderForSuggest ? (*/}
                        {/*                <MoonLoader color="#1e3166" loading={true} size={18}/>*/}
                        {/*            ) : (*/}
                        {/*                <div className={styles.suggestContainer}>*/}
                        {/*                    <SuggestDesc className={styles.suggestIcon}/>{' '}*/}
                        {/*                    <span>Suggest Description</span>*/}
                        {/*                </div>*/}
                        {/*            )}*/}
                        {/*        </div>*/}
                        {/*        <div className={styles.title}>*/}
                        {/*            {*/}
                        {/*                quotasData?.available ? quotasData?.available - localAvalaibleCount :*/}
                        {/*                    <div className={styles.quotasLoader}><MoonLoader color="#1e3166" loading={true}*/}
                        {/*                                                                     size={18}/></div>*/}
                        {/*            } of {quotasData?.total} available*/}
                        {/*        </div>*/}

                        {/*    </>*/}
                        {/*}*/}

                    </div>
                    <AutoGrowHeightTextarea
                        placeholder=""
                        name="input_505"
                        value={
                            formInfo.newDescription.changed
                                ? bdecode(formInfo.newDescription.text)
                                : unescapedRowMetadata[505]
                                    ? bdecode(unescapedRowMetadata[505])
                                    : ''
                        }
                        handleChange={handleStandartInputChange}
                        showCount={true}
                        hasLimit={true}
                        limit={5000}
                        height={322}
                    />
                </div>
                {
                    !hasSimple && <div className={styles.rightSide}>
                        <div>
                            <div className={styles.title}>
                                Brand / Maker / Label / Artist
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div className={`popover ${styles.popoverContent}`}>
                                            <ul>
                                                <li>
                                                    Add one primary brand, maker, label, artist.
                                                </li>

                                                <div className={styles.line}></div>
                                                <p>★ Crossposts to supported 'brand' fields.</p>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </div>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_206"
                                value={
                                    formInfo.newBrand.changed
                                        ? bdecode(formInfo.newBrand.text)
                                        : unescapedRowMetadata[206]
                                            ? bdecode(unescapedRowMetadata[206])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                hasLimit={false}
                            />
                        </div>
                        <div>
                            <div className={styles.title}>
                                Color Shade
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div className={`popover ${styles.popoverContent}`}>
                                            <ul>
                                                <li>
                                                    Add up to two colors separated by a comma.
                                                    <ul>
                                                        <li>(ex: Blue, White)</li>
                                                    </ul>
                                                </li>

                                                <div className={styles.line}></div>
                                                <p>★ Crossposts to supported 'brand' fields.</p>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </div>
                            <TextAreaTagify
                                placeholder=""
                                isTagify={true}
                                name="input_209"
                                value={
                                    formInfo.newColor.changed
                                        ? bdecode(formInfo.newColor.text)
                                        : unescapedRowMetadata[209]
                                            ? bdecode(unescapedRowMetadata[209])
                                            : ''
                                }
                                handleTagifyChange={handleTagifiesChange}
                                limit={250}
                                height={40}
                            />
                        </div>
                        <div>
                            <div className={styles.title}>
                                Material / Ingredient Composition
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div className={`popover ${styles.popoverContent}`}>
                                            <ul>
                                                <li>Add one primary material</li>
                                                <div className={styles.line}></div>
                                                <p>★ Crossposts to supported 'material' fields.</p>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </div>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_82"
                                value={
                                    formInfo.newMaterial.changed
                                        ? bdecode(formInfo.newMaterial.text)
                                        : unescapedRowMetadata[82]
                                            ? bdecode(unescapedRowMetadata[82])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                hasLimit={false}
                            />
                        </div>
                        {
                            !hasBusiness && <div>
                                <div className={styles.title}>
                                    Style / Features
                                    <Tippy
                                        interactive={true}
                                        zIndex={5}
                                        trigger="click"
                                        arrow={false}
                                        content={
                                            <div className={`popover ${styles.popoverContent}`}>
                                                <ul>
                                                    <li>
                                                        Add primary style details.
                                                        <ul>
                                                            <li>
                                                                ex. When your buyer would use your item, or
                                                                the overall style like Western if clothing or
                                                                Soft Grip if a tool.
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <div className={styles.line}></div>
                                                    <p>
                                                        ♦ Added to Pro{' '}
                                                        <a
                                                            href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
                                                            target="_blank"
                                                        >
                                                            {' '}
                                                            Description Builder.
                                                        </a>
                                                    </p>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <PopoverIcon
                                            className={styles.popoverIcon}
                                            color={'#fff'}
                                        ></PopoverIcon>
                                    </Tippy>
                                </div>
                                <TextareaWithGrowHeight
                                    placeholder=""
                                    name="input_205"
                                    value={
                                        formInfo.newStyle.changed
                                            ? bdecode(formInfo.newStyle.text)
                                            : unescapedRowMetadata[205]
                                                ? bdecode(unescapedRowMetadata[205])
                                                : ''
                                    }
                                    handleChange={handleStandartInputChange}
                                    limit={250}
                                    hasLimit={false}
                                />
                            </div>
                        }
                        <div>
                            <div className={styles.title}>
                                Size or Fit
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div className={`popover ${styles.popoverContent}`}>
                                            <ul>
                                                <li>
                                                    Add primary size.
                                                    <ul>
                                                        <li>
                                                            ex. Standard letter sizing recommending (S, M,
                                                            L, etc)
                                                        </li>
                                                    </ul>
                                                </li>

                                                <div className={styles.line}></div>
                                                <p>★ Crossposts to supported 'size' fields.</p>
                                                <p>
                                                    ♦ Added to Pro{' '}
                                                    <a
                                                        href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
                                                        target="_blank"
                                                    >
                                                        {' '}
                                                        Description Builder.
                                                    </a>
                                                </p>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </div>
                            <InputCount
                                placeholder=""
                                name="input_213"
                                value={
                                    formInfo.newSize.changed
                                        ? bdecode(formInfo.newSize.text)
                                        : unescapedRowMetadata[213]
                                            ? bdecode(unescapedRowMetadata[213])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                height={40}
                                hideCount={true}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ShortDescriptionContainer;