import React from 'react';
import {useGlobalUploaderState} from "../hooks/hooks";

const UploaderModalMainLoader = () => {
    const {state} = useGlobalUploaderState();
    const {showModalLoader, modalLoaderText} = state;
    return showModalLoader ? (
        <div id='react_image_uploader_modal_loader_container'>
            <div className="react_image_uploader_modal_loader">
                <svg className="circular-loader react_image_modal_uploader_loader" viewBox="25 25 50 50">
                    <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#234165"
                            strokeWidth="2"/>
                </svg>
                <span className='disable-select local-loading-text'>
                            {modalLoaderText}
                        </span>
            </div>
        </div>
    ): <></>
};

export default UploaderModalMainLoader;