import React, {useEffect,  useState} from 'react' ;
import styles from './CustomSkuFormat.module.scss' ;
import '@yaireo/dragsort/dist/dragsort.css';
import {instanceAxios} from "../../../../services";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import {
	closestCenter,
	DndContext,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors
} from "@dnd-kit/core";
import Tag from "./components/Tag";
import {QuestionIcon, SpaceIcon, UnderscoreIcon} from "../../../../assets/icons";
import Tippy from "@tippyjs/react";
import {useSelector} from "react-redux";

interface ICustomSkuFormatProps {
	skuFormatTags:any;
	setSkuFormatTags:(skuFormatTags:any) => void;
	configID:string | null;
	availableTags:any;
	setAvailableTags:(availableTags:any) => void;
	preview:any,
	setPreview:(preview:any) => void;
	setSkuLocationChanged:(skuLocationChanged:boolean) => void;
}

const CustomSkuFormat = (props:ICustomSkuFormatProps) => {
	const {skuFormatTags,setSkuFormatTags,configID,availableTags,setAvailableTags,preview,setPreview,setSkuLocationChanged} = props ;

	const [availableTagClicked,setAvailableTagClicked] = useState(false)
	const isMobile = useSelector((state: any) => state.navigation.isMobile);

	const dividers = [
		{
			id:5,
			value:'_',
			label:'Underscore',
			isDivide:true,
			icon:UnderscoreIcon
		},


		{
			id:8,
			value:' ',
			label:'Space',
			isDivide:true,
			icon:SpaceIcon

		},
	]

	const [isClickedQuestionIcon,setIsClickedQuestionIcon] = useState(false)

	const handleDeleteSkuFormatTag = (id:number) => {
		setSkuLocationChanged(false)
		setAvailableTagClicked(true)

		const filteredSkuFormatTags = skuFormatTags?.filter((tag:any)=> tag.id !== id)
		const findTag:any= skuFormatTags?.find((tag:any) => tag.id === id && !tag.isDivide)

		setSkuFormatTags((prevTags: any) =>
		  filteredSkuFormatTags
		);

		const filteredPreview = preview?.filter((pre:any) => pre.id !== id)

		setPreview((prevPreview: any) => filteredPreview);

		if(findTag ) {
			setAvailableTags((prevTags: any) => [...prevTags, findTag]);
		}
	}

	const sendRequest = async () => {
		try {

			const payload:any = {
				filter_by:{
					id:configID
				},
				data:{
					config:{
						sku_generator_format:[]
					}
				}
			}

			skuFormatTags.forEach((tag:any) => {
				payload.data.config.sku_generator_format.push(tag.value)

			})

			const response = await instanceAxios.put('/user-configs/website/update', payload)

		} catch(error) {
			console.error(error)
		}
	}

	const handleClickAvailableTag = (id:number) => {
		setSkuLocationChanged(false)

		const findTag:any= availableTags.find((tag:any) => tag.id === id)
		setAvailableTagClicked(true)
		const filteredAvailableTags = availableTags.filter((tag:any) => tag.id !== id)
		setAvailableTags(filteredAvailableTags);
		setSkuFormatTags((prevTags: any) => [...prevTags, findTag]);
		setPreview((prevPreview: any) => [...prevPreview, { id: findTag.id, text: findTag.label }]);
	}


	const handleClickDivider = (id:number) => {
		if(skuFormatTags?.length === 0) return
		setSkuLocationChanged(false)

		const findTag:any= dividers.find((tag:any) => tag.id === id)
		const newDivider = { ...findTag, id: Date.now() };

		setAvailableTagClicked(true)
		setSkuFormatTags((prevTags: any) => {
			let consecutiveDivideCount = 0;

			for (let i = prevTags.length - 1; i >= 0; i--) {
				if (prevTags[i].isDivide) {
					consecutiveDivideCount++;
				} else break
			}

			if (consecutiveDivideCount < 3) {
				return [...prevTags, newDivider];

			} else {
				return prevTags;
			}
		});
		setPreview((prevPreview: any) => {
			let consecutiveDivideCount = 0;

			for (let i = prevPreview.length - 1; i >= 0; i--) {
				if (prevPreview[i].text === findTag.value) {
					consecutiveDivideCount++;
				} else break
			}

			if (consecutiveDivideCount < 3) {
				return prevPreview
				 ? [...prevPreview, { id: newDivider.id, text: findTag.value }]
				 : [{ id: newDivider.id, text: findTag.value }];
			} else {
				return prevPreview;
			}
		});
	}

	useEffect(() => {
		if(availableTagClicked) {
			sendRequest()
		}
	}, [skuFormatTags]);

	const handleDragOver = (event: any) => {
		setSkuLocationChanged(false)

		const { active, over } = event;
		const oldFindTag = skuFormatTags.find((tag: any) => tag.id === active.id);
		const newFindTag = skuFormatTags.find((tag: any) => tag.id === over.id);


		if (active.id !== over.id) {
			setAvailableTagClicked(true)
			const oldIndex = skuFormatTags.findIndex((tag: any) => tag.id === active.id);
			const newIndex = skuFormatTags.findIndex((tag: any) => tag.id === over.id);

			if ((oldFindTag.isDivide && newIndex === 0) || (newFindTag.isDivide && oldIndex === 0)) return

			setSkuFormatTags((items: any) => arrayMove(items, oldIndex, newIndex));
			setPreview((items: any) => arrayMove(items, oldIndex, newIndex))
		}
	};

	const handleDragEnd = (event:any) => {
		setSkuLocationChanged(false)

		const { active, over } = event;
		const oldFindTag = skuFormatTags.find((tag: any) => tag.id === active.id);
		const newFindTag = skuFormatTags.find((tag: any) => tag.id === over.id);


		if (over && active.id !== over.id) {
			const oldIndex = skuFormatTags.findIndex((tag:any) => tag.id === active.id);
			const newIndex = skuFormatTags.findIndex((tag:any) => tag.id === over.id);

			if ((oldFindTag.isDivide && newIndex === 0) || (newFindTag.isDivide && oldIndex === 0)) return ;
			setSkuFormatTags((items:any) => arrayMove(items, oldIndex, newIndex));
			setPreview((items: any) => arrayMove(items, oldIndex, newIndex))
		}
	};

	const sensors = useSensors(
	 useSensor(PointerSensor),
	 useSensor(TouchSensor),
	 useSensor(KeyboardSensor),
	)

	const joinedPreviewString = preview?.map((pre:any) => pre.text).join('');

	return (
	 <div className={styles.customSkuFormat}>
		 <div className={styles.titleBlock}>
			 <div className={styles.title}>
				 Custom SKU Format
			 </div>
			 <Tippy
			  interactive={true}
			  zIndex={5}
			  arrow={false}
			  trigger="focus click"
			  content={
				  <p>Customize how your SKUs are formatted by selecting tags and dividers. This helps create a consistent and organized SKU system.</p>
			  }

			  placement={isMobile ? 'bottom-end' : 'right-end'}
			  className={styles.toolTip}
			  onClickOutside={() => setIsClickedQuestionIcon(false)}
			 >
				 <div>
					 <QuestionIcon
					  // className={`${styles.questionSvg} ${isClickedQuestionIcon ? styles.isClickedQuestionClass : ""}`}
					  onClick={() => setIsClickedQuestionIcon(!isClickedQuestionIcon)}
					  style={{
						  cursor:'pointer'
					  }}
					 />
				 </div>
			 </Tippy>
		 </div>
		 <div className={styles.skuFormatArea}>
			 <div className={styles.formatBlock}>
				 <DndContext
				  sensors={sensors}
				  collisionDetection={closestCenter}
				  onDragEnd={handleDragEnd}
				  onDragOver={handleDragOver}

				 >
					 <SortableContext items={skuFormatTags} strategy={verticalListSortingStrategy}>
						 {skuFormatTags?.map((skuFormat: any) => (
						  <Tag
						   key={skuFormat.id}
						   id={skuFormat.id}
						   label={skuFormat.label}
						   icon={skuFormat.icon && <skuFormat.icon />}
						   handleDeleteSkuFormatTag={handleDeleteSkuFormatTag}
						  />
						 ))}
					 </SortableContext>
				 </DndContext>

			 </div>
			 <div
			  style={{
				  justifyContent: availableTags.length > 0 ? 'space-between' : 'flex-start'
			  }}
			  className={styles.tagsAndDividers}>
				 <div className={styles.availableBlock}>
					 {availableTags.length > 0 && <div className={styles.availableTitle}>available tags</div>}
					 <div className={styles.availableTagsBlock}>
						 {availableTags?.map((tag: any) => (
						  <div
						   key={tag.id}
						   className={styles.tagItem}
						   onClick={() => handleClickAvailableTag(tag.id)}
						  >{tag.label}</div>
						 ))}
					 </div>
				 </div>
				 <div className={styles.dividersBlock}>
					 <div className={styles.dividersTitle}>dividers</div>
					 <div className={styles.dividers}>
						 {dividers?.map((tag: any) => (
						  <div
						   key={tag.id}
						   className={styles.tagItem}
						   onClick={() => handleClickDivider(tag.id)}
						  >
							  {tag?.icon && <tag.icon/>}
							  <div>{tag.label}</div>
						  </div>
						 ))}
					 </div>
				 </div>
			 </div>
			 <div className={styles.skuLine}></div>

			 <div className={styles.previewBlock}>
				 <div className={styles.previewTitle}>preview</div>
				 <div className={styles.previewText}>
					 {joinedPreviewString}
				 </div>
			 </div>
		 </div>

	 </div>
	)
}

export default CustomSkuFormat