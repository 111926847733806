import React, {useState} from 'react';
import styles from './ResultsFor.module.scss' ;
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import {SVGSmallX} from "../../../../assets/icons";


const ResultsFor = (props:any) => {
	const { filterBy, setFilterBy, listingTitle} = props ;
	const {bdecode} = useBasisFuncs()
	const [listingTitleView,setListingTitleView] = useState(listingTitle || "")


	const handleClearAll = () => {
		const localFilterBy:any = { ...filterBy };
		delete localFilterBy.order_ids;
		delete localFilterBy.listingTitle
		setFilterBy(localFilterBy);

		setListingTitleView("")

	};

	const resultsForTitleViewStyle = listingTitleView ? 'flex' : 'none'
	return (
	 <div
	  style={{display:resultsForTitleViewStyle}}
	  className={styles.resultsFor}>
		 <div>Results For: sales attached to</div>
		 <div className={styles.wrapper}>
			 <div
			  className={styles.tbDataItem}
			 >
				 <div>
					 {bdecode(listingTitleView) || ""}
				 </div>
				 <SVGSmallX
				  onClick={handleClearAll}
				 />
			 </div>
		 </div>
	 </div>
	)
}

export default ResultsFor;
