import styles from './SearchResultContainer.module.scss';
import {CloseSvg} from "../../../assets/icons";

export const SearchResultContainer = ({searchResult, setClearAllActive, setSearchResult, setFilterBy}:any) =>{

	const handleRemoveItem = (name:any) => {
		setSearchResult((prevState:any) => prevState.filter((item:any) => item.name !== name));

		if (name === 'title') {
			// Remove the item from filterBy that has the property 133
			setFilterBy((prevState:any) => {
				const updatedFilterBy = { ...prevState };
				delete updatedFilterBy[133];
				return updatedFilterBy;
			});
		}

	}

	const handleClearAll = ()=>{
		setClearAllActive(true);
		setSearchResult([]);
		setFilterBy({});
	}

	return(
	  <div className={styles.SearchResultContainer}>
		  <p>Results For:</p>
		  <ul className={styles.searchResultList}>
			  {
				  searchResult?.map((item:any)=>(
				   <>
					   {
						Boolean(item?.value?.length) && <li className={styles.searchItem}>
							<span className={styles.itemValue}>{item?.value}</span>
							<span className={styles.removeItem} onClick={() => handleRemoveItem(item?.name)}><CloseSvg/></span>
						</li>
					   }
				   </>
				  ))
			  }
			  <div className={styles.clearAllItem} onClick={handleClearAll}>Clear All</div>
		  </ul>
	  </div>
	)
}