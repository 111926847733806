import {PopoverIcon} from "../../../../assets/icons";
import Tippy from "@tippyjs/react";
import styles from './IntendedForComponent.module.scss';
import {useState} from "react";
import getNameGenerator from "../../../../hooks/useNameGenerator";

const IntendedForComponent = ({ itemData, setItemData, editIndex, IntendedForItems, setIntendedItems}:any) => {

	const toggleIntendedForItems = (id: number, value: any) => {
		let updatedItems: any = [...itemData];
		const generatedName = getNameGenerator('input_4');
		updatedItems[editIndex] = {
			...updatedItems[editIndex],
			[generatedName]: value,
			changed: true,
		};
		setItemData(updatedItems);
		setIntendedItems((prevItems:any) => {
			const updatedItems = prevItems.map((item:any) => ({
				...item,
				active: item.id === id && !item.active,
			}));

			const clickedItem = updatedItems.find((item:any) => item.id === id);
			if (clickedItem && clickedItem.active) {
				return updatedItems;
			}
			return updatedItems.map((item:any) => ({
				...item,
				active: item.id === id,
			}));
		});
	};

	return (
	 <div>
		 <div className={styles.intendedMenu}>
			 <p className={styles.title}>
				 Intended For
				 <Tippy
				  interactive={true}
				  zIndex={9991}
				  trigger="click"
				  arrow={false}
				  content={
					  <div className={`popover ${styles.popoverContent}`}>
						  <ul>
							  <li>
								  Selection will be displayed in your description next
								  to Size{' '}
							  </li>

							  <div className={styles.line}></div>

							  <p>
								  ♦ Added to Pro{' '}
								  <a
								   href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
								   target="_blank"
								  >
									  {' '}
									  Description Builder.
								  </a>
							  </p>
						  </ul>
					  </div>
				  }
				 >
					 <PopoverIcon
					  className={styles.popoverIcon}
					  color={'#fff'}
					 ></PopoverIcon>
				 </Tippy>
			 </p>
			 <div className={styles.menuItems}>
				 {IntendedForItems.map((item:any) => (
				  <div
				   data-name="input_4"
				   className={item.active ? styles.itemActive : styles.item}
				   key={item.id}
				   onClick={() =>
					toggleIntendedForItems(item.id, item.value)
				   }
				  >
					  <span>{item.text}</span>
				  </div>
				 ))}
			 </div>
		 </div>
	 </div>
	);
};


export default IntendedForComponent;