import {API_BASE, bucketUrl, WP_API_BASE} from "../const/const";
import {getActualCookie} from "../common";

class Service {
    async restoreOriginalImageByFilename(filename: string) {
        let myHeaders = new Headers();
        myHeaders.append("Authorization",  "Bearer " + getActualCookie());
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "key": filename,
            "image_handler": "image_handler_nodejs",
            "run_mode": "sync",
            "compress": true
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        return new Promise((resolve, reject) => {
            fetch(API_BASE + "/image-handler/restore-original-set", requestOptions)
                .then(response =>  response.json())
                .then(({data = []}) => {
                    if (data.length === 0) reject(null);
                    data.map(async ({key, suffix}: {key: string, suffix: string}) => {
                        if (suffix === 'lp'){
                            resolve(key)
                        }
                    })
                })
        })
    }
    async removeBackgroundByFilename(filename: string, bgColor: string = "#FFFFFF") {
        let myHeaders = new Headers();
        myHeaders.append("Authorization",  "Bearer " + getActualCookie());
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "key": filename,
            "bg_color": bgColor
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        return fetch(API_BASE + "/photoroom/remove-background", requestOptions).then((response) => {
            if(!response.ok) {
                alert("Error during background remove");
                throw new Error("Error during background remove")
            }else {
                return true;
            }
        });
    }
    async saveUserConfig(config: any) {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getActualCookie());
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "type": "website",
            "config": config
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        return fetch(API_BASE + "/user-configs/set", requestOptions)
    }
    async loadUserConfig() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " +  getActualCookie());
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "type": "website"
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        return fetch( API_BASE + "/user-configs/get", requestOptions)
            .then(response => response.json())
    }
    async loadRemoveBackgroundStats()  {
        let headers = new Headers();
        headers.append("Authorization",  "Bearer " + getActualCookie());
        return fetch(API_BASE + "/image-handler/get-my-quota", {method: "POST", headers})
            .then(response => response.json())
    };
    async getImageIdFromAdminAjax(FILENAME: string)  {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getActualCookie());
        let raw = JSON.stringify({
            key: FILENAME
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };
        const { data: {id} = {id: 0} } = await fetch(API_BASE + '/image-handler/files/add', requestOptions).then(response => response.json())
        return id
    };

    async fileGenerateSet(filename: string) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getActualCookie());

        let raw = JSON.stringify({
            "key": filename,
            "image_handler": "image_handler_nodejs",
            "source": "new_image_uploader_nodejs",
            "run_mode": "sync",
            "compress": true
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        try{
            const {data}: { data: Array<any> } = await fetch(API_BASE + "/image-handler/generate-set", requestOptions)
                .then(response => response.json())
            if (data.length > 0) {
                return {success: true}
            } else {
                return {success: false, message: 'generate data is null'}
            }
        }catch (e) {
            return {success: false, error: e}
        }
    };

    async uploadFileToS3(fields: any, file: any, filename: string) {
        let formdata = new FormData();
        Object.keys(fields).map((key, i) => {
            formdata.append(key, fields[key]);
        });

        formdata.append("file", file, filename);

        return new Promise((resolve, reject) => {
            fetch(bucketUrl, {
                method: 'POST',
                body: formdata,
                redirect: 'follow',
            }).then(result => {
                resolve(true)
            }).catch(error => {
                if (error.message.indexOf("pipeThrough") !==  -1){
                    console.log(error, "pipeThrough ERROR ==> IGNORE")
                    try{
                        resolve(true)
                    }catch (e) {
                        reject(e + " ERROR DURING GENERATE SET FOR pipeThrough")
                    }
                }else{
                    reject(error + " ERROR DURING UPLOAD")
                }
            });
        })
    };

    async getS3Policy(filename: string){
        return new Promise((resolve, reject) => {
            fetch(API_BASE + '/s3/create-presigned-post', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getActualCookie()
                },
                body: JSON.stringify({
                    "key": filename
                }),
            })
                .then(async (response)=> {
                    if (response.ok) return response.json();
                    const {message} = await response.json();
                    return reject(message || "Error during upload image")
                }).then(({url, fields}:{url: string, fields: {string: any}}) => {
                resolve({url, fields});
            }).catch(error => {
                const {error: message} = error || {}
                reject(message ||  "Error during image  upload")
            });
        })
    }
    async getListingImages(listing_id: number)  {
        try {
            const response = await fetch(API_BASE + "/listings/list", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getActualCookie()
                },
                body: JSON.stringify({
                    filter_by: {id: listing_id},
                    options: {"include_images": true,include_thumbnail: true},
                    metadata: {offset: 0, limit: 1}
                }),
            });

            if (!response.ok) {
                return {data: false, error: true, errorMessage: `HTTP error! Status: ${response.status}`}
            }
            const {data} = await response.json()
            return {data: data[0], error: false}
        } catch (error) {
            return {data: false, error: true, errorMessage: error}
        }
    }
}

const UploaderService = new Service()
export default UploaderService;