import _ from "lodash";

// Import config
import config from "../config";

// Import API client
import APIClient from './APIClient';


class UserConfigs {

    get(type: string, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/user-configs/get`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            _.set(params, 'data.type', type);

            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    extension_update(filter_by: any, payload: any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'PUT',
                url: `${config.base_url}/v1/user-configs/extension/update`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);
            if(payload) _.set(params, 'data.data', payload);


            // Return result
            APIClient(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

}

export default new UserConfigs();
