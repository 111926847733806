export default function formatString(inputString: string) {
  const words = inputString?.split('_');

  const formattedWords = words?.map((word) => {
    return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
  });

  const formattedString = formattedWords?.join(' ');

  return formattedString;
}
