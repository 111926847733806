import React, { ReactNode, MouseEventHandler } from 'react';
import styles from './ModalButton.module.scss';

interface ModalButtonProps {
  children: ReactNode;
  cb?: MouseEventHandler<HTMLButtonElement>;
  feature: 'primary' | 'light' | 'green' | 'red';
  disabled?: boolean;
}

const ModalButton: React.FC<ModalButtonProps> = ({
  children,
  cb = () => console.log('cb'),
  feature,
  disabled = false,
}) => {
  const classes = `${styles.modal__button} ${
    (feature === 'primary' && styles.primary__modal__button) ||
    (feature === 'light' && styles.light__modal__button) ||
    (feature === 'green' && styles.green__modal__button) ||
    (feature === 'red' && styles.red__modal__button)
  }`;

  return (
    <button className={classes} onClick={cb} disabled={disabled}>
      {children}
    </button>
  );
};

export default ModalButton;
