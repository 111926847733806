
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {receiveMessage, setToken} from "../../redux/slices/SharedWorker";
const SharedWorkerInitializer = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		try {
			// If SharedWorker is supported
			if (typeof SharedWorker !== 'undefined') {
				// Get hostname
				const hostname = window.location.hostname;
				// Init cookie function

				//site url

				const siteURL = `${window.location.protocol}//${window.location.host}`;

				//worker url
				const workerURL = new URL(`${siteURL}/shared-worker.js`);
				const getCookieByName = (cookieName : any) => {
					let name = cookieName + "=";
					let decodedCookie = decodeURIComponent(document.cookie);
					let ca = decodedCookie.split(';');
					for (let i = 0; i < ca.length; i++) {
						let c = ca[i];
						while (c.charAt(0) === ' ') {
							c = c.substring(1);
						}
						if (c.indexOf(name) === 0) {
							return c.substring(name.length, c.length);
						}
					}
				};

				// Init shared worker
				const sharedWorker = new SharedWorker(workerURL);
				const port = sharedWorker.port;

				// Start worker
				port.start();

				// Set token
				let pageToken = localStorage.getItem('lp_authorization_token') || getCookieByName('lp_authorization_token');

				// Send token to shared worker
				port.postMessage({ code: 'set_token', token: pageToken, hostname });
				dispatch(setToken(pageToken));

				// Event listener
				port.addEventListener('message', (event) => {
					dispatch(receiveMessage(event.data));
				});
			}
		} catch (error) {
			console.log(error);
		}
	}, [dispatch]);

	return null;
};

export default SharedWorkerInitializer;