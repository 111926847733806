import { useEffect, useState } from 'react';

interface TextAreaInreface {
  name: string;
  value: any;
  onChange: any;
}

export const TextareaWithCharacterCount = ({
  name,
  value,
  onChange,
}: TextAreaInreface) => {
  const [characterCount, setCharacterCount] = useState(0);
  const id = Math.random().toString();

  useEffect(() => {
    if (value) {
      setCharacterCount(value.length);
    }
  }, [value]);

  return (
    <div
      className="textAreaCounterContainer"
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '4px',
        border: '1px solid #393838',
        position: 'relative',
      }}
    >
      <textarea
        className="titleTextArea"
        id={id}
        value={value}
        name={name}
        maxLength={140}
        onChange={(e) => {
          const newText = e.target.value;
          if (newText.length <= 140) {
            onChange(e);
            setCharacterCount(newText.length);
          }
        }}
      />
      <p className="textAreaCounter">
        Character Count: {characterCount}/{140}
      </p>
    </div>
  );
};
