import React from 'react' ;
import styles from './UniqueIdFormat.module.scss' ;
import {PopoverIcon} from "../../../../assets/icons";
import Tippy from "@tippyjs/react";
const initialRadioValues = [
	{
		id:2,
		value:'AA000'
	},
	{
		id:1,
		value:'A000A'
	},

]
interface IUniqueIdFormat {
	skuGeneratorUniqueIdFormat:any;
	setSkuGeneratorUniqueIdFormat:(skuGeneratorUniqueIdFormat:any) => void;
	sendRequestUniqueIdFormat:(id:number) =>void;
}
const UniqueIdFormat = (props:IUniqueIdFormat) => {
	const {skuGeneratorUniqueIdFormat,setSkuGeneratorUniqueIdFormat,sendRequestUniqueIdFormat}  = props ;
	const handleClick = (id:number) => {
		setSkuGeneratorUniqueIdFormat(id)
		sendRequestUniqueIdFormat(id)
	};

	return (
	 <div className={styles.uniqueIdFormat}>
		 <div className={styles.header}>
			 <div className={styles.title}>Unique ID Format</div>
			 <Tippy

			  interactive={true}
			  arrow={false}
			  zIndex={9999}
			  trigger="click"
			  content={
				  <div className={`${styles.popoverContent}`}>
					  Use this setting for your preferred Unique ID format. Once set it should not be changed. To learn more about the importance and use of Unique IDs <a
				   target="_blank"
				  href="https://help.listperfectly.com/en/articles/9797069"
				  >
					  click here
				  </a>.
				  </div>
			  }
			 >
				 <PopoverIcon
				  className={styles.popoverIcon}
				  color={'#fff'}
				  style={{
					  cursor:'pointer'
				  }}
				 ></PopoverIcon>
			 </Tippy>
		 </div>
		 <div className={styles.radioWrapper}>
			 {initialRadioValues.map((radioValue) => (
			  <div
			   key={radioValue.id}
			   className={`${styles.radioValue} ${skuGeneratorUniqueIdFormat === radioValue.id ? styles.radioValueActive : ""}`}
			   onClick={() => handleClick(radioValue.id)}
			 >
				  <span>{radioValue.value}</span>
			  </div>
			 ))}
		 </div>
	 </div>
	)
}

export default UniqueIdFormat ;