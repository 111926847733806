import React, {useEffect, useState} from 'react';
import { isMobile} from "../const/const";
import {useGlobalUploaderState} from "../hooks/hooks";
import {updateFileProperty} from "../common";

const UploaderFilters = () => {
    const { state, dispatch } = useGlobalUploaderState();
    const {activeFile, files} = state;
    const [canvasHeight, setCanvasHeight] = useState(isMobile ?
        window.innerHeight - 205 - 40
        : window.innerHeight - 77 - 164 - 24)
    const [canvasWidth, setCanvasWidth] = useState(isMobile ? window.innerWidth : canvasHeight);
    useEffect(() => {
        const handleResize = () => {
            const canvasHeightNew = isMobile ?
                window.innerHeight - 205 - 40
                : window.innerHeight - 77 - 164 - 24
            setCanvasHeight(canvasHeightNew);
            setCanvasWidth(isMobile ? window.innerWidth : canvasHeightNew)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!activeFile) return <></>
    const {imageSmallPreviewUrl} = activeFile;

    const updateImageFilter = (filterName: string) => {
        dispatch({type: "SETACTIVEFILE", data: {...activeFile, filter: filterName}})
    }

    return  <div id="image-filters" >
        <div className="image-filter-container"  >
            <div className="image-filter-default" >
                <div className="disable-select image-filter-item  original-preview"  onClick={() => updateImageFilter('original')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Original</span>
                </div>
            </div>

            <div className="image-filter-list" style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: canvasWidth, overflow: 'auto', overflowY: "hidden"
            }} >

                <div className="disable-select image-filter-item preview-aden" onClick={() => updateImageFilter('aden')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}  >
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Aden</span>
                </div>
                <div className="disable-select image-filter-item preview-amaro" onClick={() => updateImageFilter('amaro')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Amaro</span>
                </div>
                <div className="disable-select image-filter-item  preview-1977" onClick={() => updateImageFilter('1977')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>1977</span>
                </div>

                <div className="disable-select image-filter-item  preview-brannan" onClick={() => updateImageFilter('brannan')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Brannan</span>
                </div>

                <div className="disable-select image-filter-item  preview-brooklyn" onClick={() => updateImageFilter('brooklyn')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Brooklyn</span>
                </div>
                <div className="disable-select image-filter-item  preview-clarendon" onClick={() => updateImageFilter('clarendon')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Clarendon</span>
                </div>

                <div className="disable-select image-filter-item  preview-earlybird" onClick={() => updateImageFilter('earlybird')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Earlybird</span>
                </div>
                <div className="disable-select image-filter-item  preview-gingham" onClick={() => updateImageFilter('gingham')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Gingham</span>
                </div>
                <div className="disable-select image-filter-item  preview-hudson" onClick={() => updateImageFilter('hudson')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Hudson</span>
                </div>


                <div className="disable-select image-filter-item  preview-inkwell" onClick={() => updateImageFilter('inkwell')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Inkwell</span>
                </div>
                <div className="disable-select image-filter-item  preview-lofi" onClick={() => updateImageFilter('lofi')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Lofi</span>
                </div>
                <div className="disable-select image-filter-item  preview-maven" onClick={() => updateImageFilter('maven')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Maven</span>
                </div>

                <div className="disable-select image-filter-item  preview-perpetua" onClick={() => updateImageFilter('perpetua')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Perpetua</span>
                </div>
                <div className="disable-select image-filter-item  preview-reyes" onClick={() => updateImageFilter('reyes')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Reyes</span>
                </div>
                <div className="disable-select image-filter-item  preview-stinson" onClick={() => updateImageFilter('stinson')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Stinson</span>
                </div>


                <div className="disable-select image-filter-item  preview-toaster" onClick={() => updateImageFilter('toaster')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Toaster</span>
                </div>
                <div className="disable-select image-filter-item  preview-walden" onClick={() => updateImageFilter('walden')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Walden</span>
                </div>
                <div className="disable-select image-filter-item  preview-valencia" onClick={() => updateImageFilter('valencia')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Valencia</span>
                </div>
                <div className="disable-select image-filter-item  preview-xpro2" onClick={() => updateImageFilter('xpro2')} style={{
                    backgroundImage: "url(" + imageSmallPreviewUrl + ")",
                }}>
                    <span style={{color: !isMobile ? "#000": "#fff"}}>Xpro2</span>
                </div>
            </div>
        </div>
    </div>
};

export default UploaderFilters;//