import React, {useEffect, useState} from 'react';
import styles from '../CustomMarketPlaces/CustomMarketPlaces.module.scss';
import {
	CloseSvg,
	EditPencilSvg,
	PlatformIconShop,
} from '../../../../assets/icons';
import ColorPicker from '../ColorPicker';
import { instanceAxios } from '../../../../services';
import {toast} from "react-toastify";
import MarketModalMobile from "./MarketModalMobile";
interface ICustomMarketplaceItemProps {
	id: string;
	platformColor: string;
	platformName: string;
	platformIcon: any;
	platformIndex: number;
	isMobile: boolean;
	indexCustom:number;
	// addActive:boolean;
	// setAddActive:any;
	customMarketplacesLocal:any;
	setCustomMarketPlacesLocal:any;
	configID:any;
	setOpenMarketModalMobile:any;
	openMarketModalMobile:any;
	setAllowAdd:any;
	edit:any;
	handleEditChangeParent: any;

}

const CustomMarketplaceItem = (props: ICustomMarketplaceItemProps) => {
	const { id, platformColor, platformName, platformIcon,edit, handleEditChangeParent, configID, platformIndex, setAllowAdd, isMobile, customMarketplacesLocal,  setCustomMarketPlacesLocal, indexCustom,  setOpenMarketModalMobile, openMarketModalMobile} = props;
	const [requestId, setRequestId] = useState(null);
	const [colorPickerColor, setColorPickerColor] = useState(platformColor);
	const [colorPickerCached, setColorPickerCached] = useState<any>(null);
	const [customMarketNameValue, setCustomMarketNameValue] = useState(platformName);
	const [customMarketNameValueCached, setCustomMarketNameValueCached] = useState<any>(null);
	const [isCanceled, setIsCanceled] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [charCount, setCharCount] = useState(platformName?.length);
	const [isEdit, setIsEdit] = useState(edit);


	const handleChangeColorPickerColor = (color: string) => {
		setColorPickerColor(color);
	};
	useEffect(() => {
		setIsEdit(edit); // Update local state when parent prop changes
	}, [edit]);
	const handleChangeEdit = () => {
		handleEditChangeParent();
		isMobile && setOpenMarketModalMobile(true);
		setIsEdit(true);
		setIsCanceled(false);
		setIsSaved(false);

	};
	const handleCancel =  () => {
		setCustomMarketPlacesLocal((prevState:any) => {
			const updatedCustomMarketPlacesLocal = [...prevState];
			const updatedItem = {
				...updatedCustomMarketPlacesLocal[indexCustom],
				isEdit: false
			};
			updatedCustomMarketPlacesLocal[indexCustom] = updatedItem;
			return updatedCustomMarketPlacesLocal;
		});
		setIsEdit(false);
		setIsCanceled(true);
		setIsSaved(false);
	}

	const handleChangeCustomMarketValue = (value: string) => {
		if (value.length <= 11) {
			setCustomMarketNameValue(value);
			setCharCount(value.length);
		}
	};

	const updateCustomMarket = async () => {
		try {
			setCustomMarketPlacesLocal((prevState:any) => {
				const updatedCustomMarketPlacesLocal = [...prevState];
				const updatedItem = {
					...updatedCustomMarketPlacesLocal[indexCustom],
					platformColor: colorPickerColor,
					platformName: customMarketNameValue,
					isEdit: false
				};
				updatedCustomMarketPlacesLocal[indexCustom] = updatedItem;
				return updatedCustomMarketPlacesLocal;
			});

			const dynamicPlatformName = `custom_marketplace_${platformIndex || indexCustom + 1}`;
			const dynamicPlatformColor = `custom_marketplace_${platformIndex || indexCustom + 1}_color`;
			const payload = {
				filter_by: { id: configID },
				data: {
					config: {
						[dynamicPlatformName]: customMarketNameValue,
						[dynamicPlatformColor]: colorPickerColor,
					},
				},
			};
			const response = await instanceAxios.put(
			 '/user-configs/website/update',
			 payload
			);
		} catch (error) {
			toast.error(`Oops! We encountered an error while attempting to save your changes.  Please try again.`, {
				position: 'bottom-right', // You can customize the notification position
			});
			console.error(error);
		}
	};

	const handleSave = () => {
		updateCustomMarket();
		setIsEdit(false);
		setIsSaved(true);
		setIsCanceled(false);
	};
	useEffect(() => {
		if((!colorPickerCached && !customMarketNameValueCached) || isSaved ) {
			setColorPickerCached(colorPickerColor)
			setCustomMarketNameValueCached(customMarketNameValue)
		}
	}, [colorPickerCached, customMarketNameValueCached, isSaved]);

	useEffect(() => {
		if(isCanceled) {
			setColorPickerColor(colorPickerCached)
			setCustomMarketNameValue(customMarketNameValueCached)
		}
	}, [isCanceled]);

	return (
	 <>
		 {
		  (openMarketModalMobile && isEdit) && (
		   <MarketModalMobile setOpenMarketModalMobile={setOpenMarketModalMobile}
							  setIsEdit={setIsEdit}
							  isEdit={isEdit}
							  colorPickerColor={colorPickerColor}
							  customMarketNameValue={customMarketNameValue}
							  setColorPickerColor={setColorPickerColor}
							  setCustomMarketNameValue={setCustomMarketNameValue}
							  setCharCount={setCharCount}
							  updateCustomMarket={updateCustomMarket}
							  setIsSaved={setIsSaved}
							  setIsCanceled={setIsCanceled}
							  handleCancel={handleCancel}
							  isMobile={isMobile}
		   />
		  )
		 }
		 <tr>
			 <td className={styles.platformIconTd}>
				 <div>
					 <PlatformIconShop width={40} height={40} fill={colorPickerColor} />
				 </div>
			 </td>
			 <td className={styles.platformNameTd}>
				 {isEdit && !isMobile  ? (
				  <>
					  <div className={styles.platformInputWrapper}>
						  <input
						   type="text"
						   className="myInput"
						   value={customMarketNameValue}
						   onChange={(e) => handleChangeCustomMarketValue(e.target.value)}
						  />
						  <span>{customMarketNameValue?.length ? customMarketNameValue?.length : 0}/11</span>
					  </div>
				  </>

				 ) :  (
				  <div>{customMarketNameValue}</div>
				 )}
			 </td>
			 <td className={styles.platformColorTd}>
				 {isEdit && !isMobile  ? (
				  <ColorPicker
				   defaultColor={colorPickerColor}
				   handleChangeColorPickerColor={handleChangeColorPickerColor}
				  />
				 ) : (
				  <div
				   style={{ background: colorPickerColor || '#000' }}
				   className={styles.colorDiv}
				  ></div>
				 )}
			 </td>
			 <td className={styles.actionsTd}>
				 <div>
					 {isEdit && !isMobile ? (
					  <>
						  <button onClick={handleSave} className="myBtn saveBulkBtn">
							  Save
						  </button>
						  <button className={`cancelBtn2 ${styles.cancelBtn2}`} onClick={handleCancel}>
							  {
								  isMobile ? <CloseSvg height={30} width={30} /> : <span>Cancel</span>
							  }

						  </button>
					  </>
					 ) : (
					  <button
					   className={`${styles.editBtn} myBtn bulkBtn`}
					   onClick={handleChangeEdit}
					  >
						  <EditPencilSvg />

						  <span>Edit</span>
					  </button>
					 )}
				 </div>
			 </td>
		 </tr>
	 </>
	);
};

export default CustomMarketplaceItem;