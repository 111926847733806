import _ from 'lodash';

const json_parse_safe_object = (str: string) => {
    try {
        let result = JSON.parse(str);
        if(_.isNull(result)) result = {};
        return result;
    } catch (e) {
        return {};
    }
};

export default json_parse_safe_object;
