import React, {useState} from 'react' ;
import styles from '../../SkuLocations.module.scss'

interface ISkuLocationItem {
	index:number;
	payloadKey:string;
	title:string;
	value:string;
	handleChangeSkuLocation:(index:number, event:any) => void;
}

const SkuLocationItem = (props:ISkuLocationItem) => {
	const {
		index,
		payloadKey,
		title, value, handleChangeSkuLocation} = props ;

	const [isFocused, setIsFocused] = useState(false)

	const limitExceeded = value.length > 5

	return (
	 <div className={styles.skuLocationItem}>
		 <div className={styles.locationTitle}>{title}</div>
		 <input
		  style={{
			  outlineColor:limitExceeded ? '#ff4d4d' : ""
		  }}
		  type="text"
		  value={value}
		  name={payloadKey}
		  onFocus={()=>setIsFocused(true)}
		  onBlur={() =>setIsFocused(false)}
		  onChange={(event) => {
			  handleChangeSkuLocation(index,event);
			  setIsFocused(true);
		  }}/>
		 {isFocused && <p className={limitExceeded ? styles.limitTextDanger : ""}>{value.length}/5
			 {limitExceeded ? ' - Limit Exceeded' : ""}
		 </p>}
	 </div>
	)
}

export default SkuLocationItem;