const bucketName = 'app1.listperfectly';
const bucketUrl = `https://s3.amazonaws.com/${bucketName}/`;
const WP_API_BASE = "https://apptest.listperfectly.com/wp-json/lpcustom/v2"
const uploader_version = '0.6.2'
const API_BASE = "https://api.listperfectly.com/v1"
const isMobile = window.innerWidth <= 900;
const imageUploadLimitCount = 30;

export {
    isMobile,
    imageUploadLimitCount,
    bucketName,
    bucketUrl,
    uploader_version,
    API_BASE,
    WP_API_BASE
}