import React, {useLayoutEffect, useState} from 'react' ;
import styles from "../../SuccessModal.module.scss";
import {FaImage} from "../../../../../../../../assets/icons";
import useBasisFuncs from "../../../../../../../../hooks/useBasisFuncs";

interface ISuccessModalListingProps {
	modalListing:any;
}

const SuccessModalListing = (props:ISuccessModalListingProps) => {
	const { modalListing } = props ;
	const { bdecode } = useBasisFuncs()
	const [dimensions, setDimensions] = useState<string | null>(null);

	useLayoutEffect(() => {
		const getImageDimensions = (src: string | undefined) => {
			try {
				if (src) {
					const img = new Image();
					img.src = src;

					img.onload = () => {
						if (img.width >= img.height) {
							setDimensions('width');
						} else {
							setDimensions('height');
						}
					};
				}
			} catch (e) {
				console.log('e');
			}
		};
		getImageDimensions(modalListing?.thumbnail);
	}, []);

	return (
	 <div
	  className={styles.successItem}
	 >
		 <div className={styles.thumbnail}>
			 {modalListing.thumbnail ? <img
			  style={{
				  width: dimensions === 'width' ? '60px' : 'auto',
				  height: dimensions === 'height' ? '60px' : 'auto',
			  }}
			  src={modalListing.thumbnail}/> : <FaImage/>}
		 </div>
		 <div className={styles.title}>{bdecode(modalListing?.metadata[133])}</div>
	 </div>
	)
}

export default SuccessModalListing