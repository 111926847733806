import React, { useEffect, useState } from 'react';
import App from './App';
import { GlobalUploaderStateProvider } from './state';
import './styles/index.css';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//         <GlobalUploaderStateProvider>
//             <App />
//         </GlobalUploaderStateProvider>
// );

const ImageUploaderContainer = ({
                                    images,
                                    isUploaderOpen,
                                    expressListerView,
                                    handleChangeUploaderInputs,
                                    allowListingSave,
                                    displayUploaderTitle = true,
                                    displayBulkActions = true,
                                    handleImageEditComplete = () => {},
                                    setImagesFromUploader,
                                    filesList
                                }: any) => {
    const [files, setFiles] = useState([]);
    useEffect(() => {
        setFiles(images);
    }, [images]);

    return (
        <GlobalUploaderStateProvider>
            <App
                isUploaderOpen={isUploaderOpen}
                handleImageEditComplete={handleImageEditComplete}
                displayUploaderTitle={displayUploaderTitle}
                displayBulkActions = {displayBulkActions}
                handleChangeUploaderInputs={handleChangeUploaderInputs}
                allowListingSave={allowListingSave}
                expressListerView={expressListerView}
                lpListingFiles={files}
                setImagesFromUploader={setImagesFromUploader}
                filesList={filesList}
            />
        </GlobalUploaderStateProvider>
    );
};

export default ImageUploaderContainer;