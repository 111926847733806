import { createSlice, PayloadAction } from '@reduxjs/toolkit';



interface CustomMarketplacesState {
	customMarketplaces:any;
	customMarketsLoading:boolean;
	finishedGetCustomMarkets: boolean;
}

const initialState: CustomMarketplacesState = {
	customMarketplaces: [],
	customMarketsLoading:true,
	finishedGetCustomMarkets: false,
};

const customMarketplacesSlice = createSlice({
	name: 'custom_marketplaces',
	initialState,
	reducers: {
		setCustomMarketplaces(state, action) {
			state.customMarketplaces = action.payload;
		},
		setCustomMarketsLoading:(state,action) => {
			state.customMarketsLoading = action.payload
		},
        setFinishedGetCustomMarkets:(state,action) => {
			state.finishedGetCustomMarkets = action.payload
		}
	},
});

export const {setCustomMarketplaces,setCustomMarketsLoading, setFinishedGetCustomMarkets } = customMarketplacesSlice.actions;
export default customMarketplacesSlice.reducer;
