function getNameGenerator(string: string): string {
    const inputMap: Record<string, string> = {
        input_726: 'newNotes',
        input_1: 'newSku',
        input_104: 'newMSRP',
        input_704: 'newUpc',
        input_852: 'newShippingCost',
        input_844: 'newFees',
        input_618: 'newPlatformIdByeBay',
        input_619: 'newPlatformIdByEtsy',
        input_620: 'newPlatformIdByPoshmark',
        input_621: 'newPlatformIdByMercari',
        input_836: 'newPlatformIdByAmazon',
        input_839: 'newPlatformIdByGrailed',
        input_840: 'newPlatformIdByFacebook',
        input_841: 'newPlatformIdByDepop',
        input_853: 'newPlatformIdByKidizen',
        input_855: 'newPlatformIdByShopify',
        input_857: 'newVariations',
        input_856: 'newPlatformIdByInstagram',
        input_885: 'newPlatformIdByVestiaire',
        input_17: 'newConditionNotes',
        input_847: 'newCogs',
        input_727: 'newThumbnail',
        input_728: 'newImagesArray',
        input_842: 'newSold_price',
        input_166: 'newDescBuilder',
        input_796: 'newPreOwnedCondition',
        input_795: 'newSubCondition',
        input_4: 'newIntendedFor',
        input_15: 'newCondition',
        input_205: 'newStyle',
        input_851: 'newQuantity',
        input_22: 'newPrice',
        'input_859.1': 'newDraft',
        input_133: 'newTitle',
        input_206: 'newBrand',
        input_209: 'newColor',
        input_82: 'newMaterial',
        input_213: 'newSize',
        input_453: 'newKeywords',
        input_505: 'newDescription',
        input_789: 'newMercaryTitle',
        input_786: 'newPoshmarkTitle',
        input_788: 'newGrailedTitle',
        input_791: 'newAmazonEbayTitle',
        input_869: 'newFacebookTitle',
        input_340: 'lineDetail1',
        input_341: 'lineDetail2',
        input_357: 'lineDetail3',
        input_629: 'lineDetail4',
        input_630: 'lineDetail5',
        input_188: 'lineDetail6',
        input_266: 'lineDetail7',
        input_397: 'lineDetail8',
        input_175: 'lineDetail9',
        input_628: 'lineDetail10',
        input_874: 'lineDetail11',
        input_875: 'lineDetail12',
        input_876: 'lineDetail13',
        input_877: 'lineDetail14',
        input_878: 'lineDetail15',
        input_210: 'newPatternOrTexture',
        input_36: 'newSeasonOrWeather',
        input_43: 'newCare',
        input_211: 'newMadein',
        input_750: 'newMeasuerements',
        input_48: 'newLength',
        input_46: 'newWidth',
        input_47: 'newHeight',
        input_380: 'newLengthMeasure',
        input_386: 'newWidthMeasure',
        input_391: 'newHeigthMeasure',
        input_722: 'newBottomOfDescription',
        input_837: 'newShippingZipOrCityCode',
        input_531: 'newShippingPackageLength',
        input_532: 'newShippingPackageWidth',
        input_533: 'newShippingPackageHeight',
        input_2: 'newShippingWeightlbkg',
        input_3: 'newShippingWeightozg',
        input_44: 'newBullet',
        input_54: 'newMeasure',
        'input_723.1': 'newBoth',
        input_854: 'copyDesc',
        input_378: 'measurementLabel4',
        input_384: 'measurementLabel5',
        input_389: 'measurementLabel6',
        input_70: 'measurementLabel7',
        input_641: 'measurementLabel8',
        input_642: 'measurementLabel9',
        input_643: 'measurementLabel10',
        input_185: 'measurementLabel11',
        input_186: 'measurementLabel12',
        input_187: 'measurementLabel13',
        input_192: 'measurementLabel14',
        input_194: 'measurementLabel15',
        input_331: 'measurementLabel16',
        input_405: 'measurementLabel17',
        input_407: 'measurementLabel18',
        input_411: 'measurementLabel19',
        input_602: 'measurementLabel20',
        input_88: 'measurementLabelInCm4',
        input_89: 'measurementLabelInCm5',
        input_90: 'measurementLabelInCm6',
        input_91: 'measurementLabelInCm7',
        input_617: 'measurementLabelInCm8',
        input_637: 'measurementLabelInCm9',
        input_639: 'measurementLabelInCm10',
        input_28: 'measurementLabelInCm11',
        input_30: 'measurementLabelInCm12',
        input_59: 'measurementLabelInCm13',
        input_84: 'measurementLabelInCm14',
        input_85: 'measurementLabelInCm15',
        input_86: 'measurementLabelInCm16',
        input_87: 'measurementLabelInCm17',
        input_92: 'measurementLabelInCm18',
        input_93: 'measurementLabelInCm19',
        input_94: 'measurementLabelInCm20',
        input_783: 'newSuggestTitle',
        input_883: 'newAssignee',
        'input_822.1': 'showAllTitlesCheckbox',
        'input_784.1': 'suggestTitleCheckbox',
        'input_879.1': 'addMoreLinesCheckbox',
        'input_377.1': 'moreMeasurementCheckbox1',
        'input_109.1': 'moreMeasurementCheckbox2',
        'input_122.1': 'newShowInDropDown',
        input_45: 'newEmoji'
    };
    return inputMap[string];
}

export default getNameGenerator;