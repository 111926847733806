import styles from './ConnectionWizardModal.module.scss';
import React, {useEffect, useRef, useState} from 'react';
import ConnectionPlatformsItem from './components/ConnectionPlatformsItem';
import { CloseModal } from '../../assets/icons';
import { MoonLoader } from 'react-spinners';
import './connectionWizard.css'

const ConnectionWizardModal = (props: any) => {
	const {
		connectionWizardActivate,
		availableMarketplacesList,
		setConnectionWizardActivate,
		setAvailableMarketplacesList,
		hasSimple,
		isShopifySupported
	} = props;
	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [wiizardBtnLoading, setWizardBtnLoading] = useState(false)
	const buttonRef = useRef<any>(null);
	const handleItemClick = (itemId: any) => {
		const updatedList = availableMarketplacesList?.map((item: any) =>
		 item?.id === itemId ? { ...item, selected: !item?.selected } : item,
		);
		setAvailableMarketplacesList(updatedList);
		const updatedSelectedItems = updatedList?.filter(
		 (item: any) => item?.selected,
		);
		setSelectedItems(updatedSelectedItems);
	};
	const handleGoToHomeModal = () => {
		setConnectionWizardActivate(false);
		const clearedList = availableMarketplacesList?.map((item: any) => ({
			...item,
			selected: false,
		}));
		setAvailableMarketplacesList(clearedList);
		setSelectedItems([]);
	};

	useEffect(() => {
		const buttonElement = document.querySelector('#connectWizardBtn');

		if (!buttonElement) return;

		const handleAttributeChange = () => {
			const hasClass = buttonElement.classList.contains('lp_custom_connection_wizard_modal_connect_loading');
			if (hasClass) {
				setWizardBtnLoading(true);
			} else {
				setWizardBtnLoading(false);
			}
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(buttonElement, {
			attributes: true,
			attributeFilter: ['class'],
		});

		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, []);


	return (
	 <div
	  className={`${styles.connectionWizardModal} ${
	   connectionWizardActivate ? styles.showModal : styles.hideModal
	  } lp_custom_connection_wizard_modal`}
	 >
		 <div className={styles.connectiopnWizardContainer}>
			 <div className={styles.titles}>
				 <h1 className={styles.heading}>Connection Wizard</h1>
			 </div>
			 <p
			  style={{
				  color: '#333',
				  fontSize: '16px',
				  marginTop: '15px',
				  paddingLeft: '30px',
				  paddingRight: '30px',
			  }}
			 >
				 Select the marketplaces you want to connect. Make sure you are logged
				 into the account you want to connect.
			 </p>
			 <p className={styles.notConnectedTitle}>Not connected</p>
			 <div
			  className={styles.connectionWizardList}
			  id="connectionWizardList"
			  style={{
				  display: 'grid',
				  gridTemplateColumns: '1fr 1fr 1fr',
				  gap: '15px 30px',
				  marginTop: '20px',
				  maxHeight: 'calc(100% - 250px)',
				  overflowY: 'auto',
				  marginBottom: '50px',
				  paddingLeft: '30px',
				  paddingRight: '30px',
				  flexGrow: 1,
				  msOverflowY: 'auto',
			  }}
			 >
				 {availableMarketplacesList?.map((market: any) => (
				  <ConnectionPlatformsItem
				   marketName={market?.marketName}
				   image={market?.image}
				   selected={market?.selected || false}
				   hasSimple={hasSimple}
				   isShopifySupported={isShopifySupported}
				   onChildClick={() => {
					   if( (!isShopifySupported && market?.marketName.toLowerCase() !== 'shopify') &&
						market?.marketName.toLowerCase() !== 'instagram'){
						   handleItemClick(market?.id);
					   }else if(isShopifySupported && market?.marketName.toLowerCase() !== 'instagram'){
						   handleItemClick(market?.id);
					   }
				   }}
				  />
				 ))}
			 </div>
			 <div className={styles.bottomButtons}>
				 <button
				  ref={buttonRef}
				  id="connectWizardBtn"
				  className={
					  'myBtn saveBulkBtn lp_custom_connection_wizard_modal_connect'
				  }
				 >
					 {
						 wiizardBtnLoading ? <div className={styles.loadingBtn}>
							 <MoonLoader size={20} />  <p>Loading</p>
						 </div> : <span>Connect</span>
					 }
				 </button>
				 <button
				  className={`myBtn lp_custom_connection_wizard_modal_cancel ${styles.cancelButton}`}
				  onClick={() => handleGoToHomeModal()}
				 >
					 Cancel
				 </button>
			 </div>
			 <div
			  className={`${styles.closeModal} lp_custom_connection_wizard_modal_cancel`}
			  onClick={() => handleGoToHomeModal()}
			 >
				 <CloseModal />
			 </div>
		 </div>
	 </div>
	);
};

export default ConnectionWizardModal;