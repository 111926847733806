import React, {useEffect, useMemo, useState} from "react";
import styles from './CreateTemplate.module.scss';
import {MoonLoader} from "react-spinners";
import {toast, ToastContainer} from "react-toastify";
import HeaderComponent from "./UI/HeaderComponent";
import EditTemplatesContainer from "./UI/EditTemplatesContainer";
import './TemplatePopover.css';
import ListingTitleContainer from "./UI/ListingTitleContainer";
import ShortDescriptionContainer from "./UI/ShortDescriptionContainer";
import DescriptionBuilderComponent from "./UI/DescriptionBuilderComponent";
import IntendedForComponent from "./UI/IntendedForComponent";
import ConditionsComponent from "./UI/ConditionsComponent";
import DropdownComponent from "./UI/DropdownComponent";
import {SellerNotesDraft} from "./UI/SellerNotesDraft";
import {FooterComponent} from "./UI/FooterComponent";
import _ from "lodash";
import getNameGenerator from "../../hooks/useNameGenerator";
import {instanceAxios} from "../../services";
import jwt_decode from "jwt-decode";
import getToken from "../../utils/getToken";
import Listings from "../../services/Listings";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setEditTemplateId, setListingTemplateId, setIsDuplicateTemplate, setShowEditTemplateModal} from "../../redux/slices/NavigationSlice";
import {CloseSvg} from "../../assets/icons";
import {v4 as uuidv4} from "uuid";

interface Variation {
	Size: string;
	Color: string;
	Custom: string;
	Quantity: string;
	SKU: string;
}

const option = {
	include_thumbnail: true,
	include_images: true,
	include_assignee: true,
}

const EditAddListingModal = () => {
	const editIndex = 0;
	const dispatch = useDispatch();
	const [uniqueID, setUniqueID] = useState({ 915: null });
	const showEditTemplateModal = useSelector((state: any) => state.navigation.showEditTemplateModal);
	const isDuplicateTemplate = useSelector((state: any) => state.navigation.isDuplicateTemplate);
	const [itemData, setItemData] = useState<any>([]);
	const [activeTemplateid, setActiveTemplateId] = useState(null);
	const [loader, setLoader] = useState(true);
	const [listingData, setListingData] = useState<any>(null)
	const token: any = getToken();
	const id:any = useSelector((state: any) => state.navigation.editTemplateId);
	const location = useLocation();
	const reduxListingId = useSelector((state: any) => state.navigation.listingTemplateId);
	const [listingId, setListingId] = useState<any>(reduxListingId ? reduxListingId : null);
	const [isSourceCatalog, setIsSourceCatalog] = useState<boolean>(false);
	const decodedToken: any = jwt_decode(token);
	const roles = decodedToken?.data?.user?.roles;

	const is_role_allowed = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'corporate master' ||
		 role.toLowerCase() === 'corporate sub'
		);
	});

	const has_master = roles.some((role: any) => {
		return (
			role.toLowerCase() === 'corporate master'
		);
	});

	const hasBusiness = roles
	.map((role: string) => role.toLowerCase())
	.includes('Business'.toLowerCase());
	const hasSimple = roles
	.map((role: string) => role.toLowerCase())
	.includes('simple'.toLowerCase());
	const hasPro = roles
	.map((role: string) => role.toLowerCase())
	.includes('pro'.toLowerCase());

	const onlyPro = hasPro && !is_role_allowed;

	const [isShowInDropDown, setIsShowInDropDown] = useState(true);
	const [formInfo, setFormInfo] = useState<any>({
		newTitle: {
			text: '',
			changed: false,
		},
		newMercaryTitle: {
			text: null,
			changed: false,
		},
		newPoshmarkTitle: {
			text: null,
			changed: false,
		},
		newGrailedTitle: {
			text: null,
			changed: false,
		},
		newAmazonEbayTitle: {
			text: null,
			changed: false,
		},
		newFacebookTitle: {
			text: null,
			changed: false,
		},
		newDescription: {
			text: '',
			changed: false,
		},
		newBrand: {
			text: '',
			changed: false,
		},
		newColor: {
			text: '',
			changed: false,
		},
		newBullet:{
			text: '',
			changed: false,
		},
		newMeasure:{
			text: '',
			changed: false,
		},
		newBoth:{
			text: '',
			changed: false,
		},
		copyDesc:{
			text: '',
			changed: false,
		},
		newSize: {
			text: '',
			changed: false,
		},
		newMaterial: {
			text: '',
			changed: false,
		},
		newStyle: {
			text: '',
			changed: false,
		},
		newCondition:{
			text: '',
			changed: false,
		},
		newConditionNotes:{
			text: '',
			changed: false,
		},
		newSubCondition: {
			text: '',
			changed: false,
		},
		newPreOwnedCondition:{
			text: '',
			changed: false,
		},
		newMeasuerements: {
			text: '',
			changed: false,
		},
		newLength: {
			text: '',
			changed: false,
		},
		newLengthMeasure: {
			text: '',
			changed: false,
		},
		newWidth: {
			text: '',
			changed: false,
		},
		newWidthMeasure: {
			text: '',
			changed: false,
		},
		newHeight: {
			text: '',
			changed: false,
		},
		newHeigthMeasure: {
			text: '',
			changed: false,
		},
		newBottomOfDescription: {
			text: '',
			changed: false,
		},
		lineDetail1: {
			text: '',
			changed: false,
		},
		lineDetail2: {
			text: '',
			changed: false,
		},
		lineDetail3: {
			text: '',
			changed: false,
		},
		lineDetail4: {
			text: '',
			changed: false,
		},
		lineDetail5: {
			text: '',
			changed: false,
		},
		lineDetail6: {
			text: '',
			changed: false,
		},
		lineDetail7: {
			text: '',
			changed: false,
		},
		lineDetail8: {
			text: '',
			changed: false,
		},
		lineDetail9: {
			text: '',
			changed: false,
		},
		lineDetail10: {
			text: '',
			changed: false,
		},
		lineDetail11: {
			text: '',
			changed: false,
		},
		lineDetail12: {
			text: '',
			changed: false,
		},
		lineDetail13: {
			text: '',
			changed: false,
		},
		lineDetail14: {
			text: '',
			changed: false,
		},
		lineDetail15: {
			text: '',
			changed: false,
		},
		newPatternOrTexture: {
			text: '',
			changed: false,
		},
		newSeasonOrWeather: {
			text: '',
			changed: false,
		},
		newCare: {
			text: '',
			changed: false,
		},
		newMadein: {
			text: '',
			changed: false,
		},
		newKeywords: {
			text: '',
			changed: false,
		},
		newCogs:{
			text: '',
			changed: false,
		},
		newPrice:{
			text: '',
			changed: false,
		},
		newMSRP:{
			text: '',
			changed: false,
		},
		newSku:{
			text: '',
			changed: false,
		},
		newUpc:{
			text: '',
			changed: false,
		},
		newQuantity:{
			text: '',
			changed: false,
		},
		newDraft:{
			text: '',
			changed: false,
		},
		newShowInDropDown:{
			text: '',
			changed: false,
		},
		newNotes:{
			text: '',
			changed: false,
		},
		newShippingWeightlbkg:{
			text: '',
			changed: false,
		},
		newShippingWeightozg: {
			text: '',
			changed: false,
		},
		newShippingPackageLength: {
			text: '',
			changed: false,
		},
		newShippingPackageWidth: {
			text: '',
			changed: false,
		},
		newShippingPackageHeight: {
			text: '',
			changed: false,
		},
		newShippingZipOrCityCode: {
			text: '',
			changed: false,
		},
		newVariations: {
			text: '',
			changed: false,
		},
		newImagesArray: {
			text: '',
			changed: false,
		},
		// newThumbnail: {
		// 	text: '',
		// 	changed: false,
		// },
		newDescBuilder: {
			text: '',
			changed: false,
		},
		newPlatformIdByeBay: {
			text: '',
			changed: false,
		},
		newPlatformIdByEtsy: {
			text: '',
			changed: false,
		},
		newPlatformIdByPoshmark: {
			text: '',
			changed: false,
		},
		newPlatformIdByMercari: {
			text: '',
			changed: false,
		},
		newPlatformIdByAmazon: {
			text: '',
			changed: false,
		},
		newPlatformIdByGrailed: {
			text: '',
			changed: false,
		},
		newPlatformIdByFacebook: {
			text: '',
			changed: false,
		},
		newPlatformIdByDepop: {
			text: '',
			changed: false,
		},
		newPlatformIdByKidizen: {
			text: '',
			changed: false,
		},
		newPlatformIdByShopify: {
			text: '',
			changed: false,
		},
		newPlatformIdByInstagram: {
			text: '',
			changed: false,
		},
		newPlatformIdByVestiaire: {
			text: '',
			changed: false,
		},
		newIntendedFor: {
			text: '',
			changed: false,
		},
		newUpdated: 1,
		newPlatform: {
			text: '',
			changed: false,
		},
		newPlatformIssue: {
			text: 'active',
			modalOpen: null,
			removed: [],
			platform: [],
			changed: false,
		},
		newSold_price: {
			text: '',
			changed: false,
		},
		newShippingCost: {
			text: '',
			changed: false,
		},
		newDateSold_date: {
			text: '',
			changed: false,
		},
		newFees: {
			text: '',
			changed: false,
		},
		measurementLabel1:{
			text: '',
			changed: false,
		},
		measurementLabel2:{
			text: '',
			changed: false,
		},
		measurementLabel3:{
			text: '',
			changed: false,
		},
		measurementLabel4:{
			text: '',
			changed: false,
		},
		measurementLabel5:{
			text: '',
			changed: false,
		},
		measurementLabel6:{
			text: '',
			changed: false,
		},
		measurementLabel7:{
			text: '',
			changed: false,
		},
		measurementLabel8:{
			text: '',
			changed: false,
		},
		measurementLabel9:{
			text: '',
			changed: false,
		},
		measurementLabel10:{
			text: '',
			changed: false,
		},
		measurementLabel11:{
			text: '',
			changed: false,
		},
		measurementLabel12:{
			text: '',
			changed: false,
		},
		measurementLabel13:{
			text: '',
			changed: false,
		},
		measurementLabel14:{
			text: '',
			changed: false,
		},
		measurementLabel15:{
			text: '',
			changed: false,
		},
		measurementLabel16:{
			text: '',
			changed: false,
		},
		measurementLabel17:{
			text: '',
			changed: false,
		},
		measurementLabel18:{
			text: '',
			changed: false,
		},
		measurementLabel19:{
			text: '',
			changed: false,
		},
		measurementLabel20:{
			text: '',
			changed: false,
		},
		measurementLabelInCm1:{
			text: '',
			changed: false,
		},
		measurementLabelInCm2:{
			text: '',
			changed: false,
		},
		measurementLabelInCm3:{
			text: '',
			changed: false,
		},
		measurementLabelInCm4:{
			text: '',
			changed: false,
		},
		measurementLabelInCm5:{
			text: '',
			changed: false,
		},
		measurementLabelInCm6:{
			text: '',
			changed: false,
		},
		measurementLabelInCm7:{
			text: '',
			changed: false,
		},
		measurementLabelInCm8:{
			text: '',
			changed: false,
		},
		measurementLabelInCm9:{
			text: '',
			changed: false,
		},
		measurementLabelInCm10:{
			text: '',
			changed: false,
		},
		measurementLabelInCm11:{
			text: '',
			changed: false,
		},
		measurementLabelInCm12:{
			text: '',
			changed: false,
		},
		measurementLabelInCm13:{
			text: '',
			changed: false,
		},
		measurementLabelInCm14:{
			text: '',
			changed: false,
		},
		measurementLabelInCm15:{
			text: '',
			changed: false,
		},
		measurementLabelInCm16:{
			text: '',
			changed: false,
		},
		measurementLabelInCm17:{
			text: '',
			changed: false,
		},
		measurementLabelInCm18:{
			text: '',
			changed: false,
		},
		measurementLabelInCm19:{
			text: '',
			changed: false,
		},
		measurementLabelInCm20:{
			text: '',
			changed: false,
		},
		newAssignee: {
			text: '',
			changed: false,
		},
		showAllTitlesCheckbox: {
			text: '',
			changed: false,
		},
		suggestTitleCheckbox: {
			text: '',
			changed: false,
		},
		addMoreLinesCheckbox: {
			text: '',
			changed: false,
		},
		moreMeasurementCheckbox1: {
			text: '',
			changed: false,
		},
		moreMeasurementCheckbox2: {
			text: '',
			changed: false,
		},
		newEmoji:{
			text: '',
			changed: false,
		}
	});
	const [enabledButtons, setEnabledButtons] = useState(true);
	const [savingProgress, setSavingProgress] = useState(false);
	const [showAllTitles, setShowAllTitles] = useState<any>(false);
	const [showSuggestTitles, setShowSuggestTitles] = useState<any>(false);
	const [listInfo, setListInfo] = useState<any>(null);
	const [templates, setTemplates] = useState<any>([]);
	const [assigneeId, setAssigneeId] = useState<any>(null)
	const [descriptionBuildItems, setDescriptionBuildItems] = useState([
		{ id: 1, value: 'normall', text: 'Expanded description', active: id ? false : true },
		{ id: 2, value: 'shortenl', text: 'Short description only', active: false },
	]);
	const [IntendedForItems, setIntendedItems] = useState([
		{ id: 1, value: 'Unisex', text: 'Unisex', active: false },
		{ id: 2, value: 'Mens', text: 'Men', active: false },
		{ id: 3, value: 'Womens', text: 'Women', active: false },
		{ id: 4, value: 'Unisex Kids', text: 'Unisex Kids', active: false },
		{ id: 5, value: 'Girls', text: 'Girls', active: false },
		{ id: 6, value: 'Boys', text: 'Boys', active: false },
		{ id: 7, value: 'Babys', text: 'Babies', active: false },
		{ id: 8, value: 'Maternity', text: 'Maternity', active: false },
		{ id: 9, value: 'Pets', text: 'Pets', active: false },
		{ id: 10, value: 'Cats', text: 'Cats', active: false },
		{ id: 11, value: 'Dogs', text: 'Dogs', active: false },
		{ id: 12, value: '', text: 'None', active: false },
	]);
	const [activeCondition, setActiveCondition] = useState(0);
	const [conditionItems, setConditionItems] = useState([
		{ id: 1, value: 'New', text: 'New', active: false },
		{ id: 2, value: 'Pre-Owned', text: 'Pre-Owned', active: false },
		{ id: 3, value: null, text: 'None', active: false },
	]);
	const [newConditionItems, setNewConditionItems] = useState([
		{ id: 1, text: 'NWT', value: 'With Tags', active: false },
		{ id: 2, text: 'NWB', value: 'With Box', active: false },
		{ id: 3, text: 'NWOT', value: 'Without Tags', active: false },
		{ id: 4, text: 'NWD', value: 'Without Box', active: false },
		{ id: 5, text: 'NWD', value: 'With Defects', active: false },
		{ id: 6, text: 'None', value: null, active: false },
	]);
	const [preOwnedItems, setPreOwnedItems] = useState([
		{ id: 1, text: 'Like New', value: 'Like New', active: false },
		{ id: 2, text: 'Good', value: 'Good', active: false },
		{ id: 3, text: 'Fair', value: 'Fair', active: false },
		{ id: 4, text: 'Poor', value: 'Poor', active: false },
		{ id: 5, text: 'None', value: null, active: false },
	]);
	const [bulletItems, setBulletItems] = useState([
		{ id: 1, text: 'Normal Bullets', value: 'normalbullet', active: (id || listingId) ? false : true },
		{ id: 2, text: 'Emoji', value: 'emoji', active: false },
		{ id: 3, text: 'No Bullets', value: 'nobullet', active: false },
	]);
	const [measureItems, setMeasureItems] = useState([
		{ id: 1, text: 'Inches', value: 'in', active: (id || listingId) ? false : true },
		{ id: 2, text: 'Centimeters', value: 'cm', active: false },
	]);
	const [measureBothItems, setMeasureBothItems] = useState([
		{ id: 1, value: 'in-cm', text: 'Yes', active: (id || listingId) ? false : true },
	]);
	const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
	const [variations, setVariations] = useState<Variation[]>([{
		Size: '',
		Color: '',
		Custom: '',
		Quantity: '',
		SKU: '',
	}]);
	const [addMoreInputs, setAddMoreInputs] = useState(false);
	const [moreMeasurementsPart1, setMoreMeasurementsPart1] = useState(false);
	const [moreMeasurementsPart2, setMoreMeasurementsPart2] = useState(false);
	const [subAccounts, setSubAccounts] = useState([]);
	const [selectedOptionAssignee, setSelectedOptionAssignee] = useState({
		value: 'Select Assignee Use',
		label: 'Select Assignee Use',
		id: null
	});
	const [optionsAssignee, setOptionsAssignee] = useState<any>([{ value: 'Select Assignee Use', label: 'Select Assignee Use', id: null }]);
	const getSubAccounts = async() => {
		try {
			if (!has_master) return;
			const payload: any = {filter_by: {}};
			const response = await instanceAxios.post('users/sub-accounts/list', payload);
			if(response.status === 200) {
				setSubAccounts(response.data.data);
			}
		}
		catch (e) {
			console.log('error', e)
		}
	}

	useEffect(() => {
		getSubAccounts();
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const source = searchParams.get('source');
		const hasSourceCatalog = source === 'catalog';
		setIsSourceCatalog(hasSourceCatalog);

		if(hasSourceCatalog){
			const urlListingId:any = hasSourceCatalog ? searchParams.get('listing_id') : null;

			// Use Redux listingId if it exists, otherwise fallback to URL listingId
			setListingId(Number(urlListingId));
		}
	}, [location.search]);

	useEffect(() => {
		const newOptions = subAccounts.map((acc: any) => ({
			value: `${acc?.first_name} ${acc?.last_name}`,
			label:`${acc?.first_name} ${acc?.last_name}`,
			id: `${acc?.id}`
		}));
		setOptionsAssignee([{ value: 'Select Assignee Use', label: 'Select Assignee Use', id: null }, ...newOptions]);

	}, [subAccounts]);
	const handleStandartInputChange = (e: any) => {
		const {name, value} = e.target;
		if(name === 'input_854'){
			return
		}
		const updatedItem = [...itemData];
		const generatedName = getNameGenerator(name);
		updatedItem[editIndex] = {
			...updatedItem[editIndex],
			[generatedName]: value,
			changed: true,
		};
		setItemData(updatedItem);
		setFormInfo({
			...formInfo,
			[generatedName]: { text: value, changed: true },
		});
	}

	useEffect(() => {
		const updatedItem = [...itemData];
		if(!id && !listingId){
			updatedItem[editIndex] = {
				...updatedItem[editIndex],
				['newShowInDropDown']: '1',
				changed: true,
			};
		}
		if(!hasSimple && !hasBusiness && !id && !listingId){
			updatedItem[editIndex] = {
				...updatedItem[editIndex],
				['newDescBuilder']: 'normall',
				['newBullet']: 'normalbullet',
				['newMeasure']: 'in',
				['newBoth']: 'in-cm',
				changed: true,
			};
		}
		setItemData(updatedItem);
	}, [hasSimple, hasBusiness]);

	const handleTagifiesChange = (
	 e:any,
	 tagifyVal: string,
	) => {
		try{
			const newValue = e.detail.value;
			let parsedValue :any;
			let emptyValue:any;
			if(tagifyVal === 'input_209') {
				if(newValue){
					const data = JSON.parse(newValue);
					parsedValue = data.map((item : any) => item.value).join(', ');
				}
				else {
					emptyValue = ''
				}
			}else{
				if(newValue) {
					parsedValue = JSON.parse(newValue);
				}
				else {
					emptyValue = []
				}
			}
			const generatedName = getNameGenerator(tagifyVal);
			setItemData((prevItemData: any) => {
				const updatedItems = [...prevItemData];
				updatedItems[editIndex] = {
					...updatedItems[editIndex],
					[generatedName]: parsedValue ? parsedValue : emptyValue,
					changed: true,
				};
				return updatedItems;
			});
			setFormInfo((prevTableInfo: any) => {
				const newTableInfoUpdates = { ...prevTableInfo };
				newTableInfoUpdates[generatedName] = {
					text: newValue,
					changed: true,
				};
				return newTableInfoUpdates;
			});
		}
		catch (e:any){
			console.log(e, 'error')
		}
	};

	const unescapedRowMetadata = useMemo(() => {
		const result: any = {};
		for (const key in listInfo) {
			if (listInfo.hasOwnProperty(key)) {
				result[key] = _.get(listInfo, key);
			}
		}
		return result;
	}, [listInfo]);


	// const getTemplate = async(id:any) => {
	// 	try {
	// 		const response = await instanceAxios.post('/templates/list', {
	// 			metadata: { offset: 0, limit: 1 },
	// 			filter_by: {id: +id},
	// 			options: { include_assignee: true },
	// 		});
	//
	// 		if(response) {
	// 			setListInfo(response.data.metadata)
	// 		}
	//
	// 	}
	// 	catch (e) {
	// 		console.log(e, 'error')
	// 	}
	// }
	//
	// useEffect(() => {
	// 	if(id) {
	// 		getTemplate(id)
	// 	}
	// }, [id]);


	const getTemplatesList = async () => {
		let allTemplates:any = [];
		let offset = 0;
		let hasMore = true;

		try {
			while (hasMore) {
				const response = await instanceAxios.post('/templates/list', {
					metadata: { offset: offset, limit: 100 },
					filter_by: {122.1:'1'},
					sort_by: {133:'asc'},
					options: { include_assignee: true },
				});

				if (response?.data && response?.data?.data?.length > 0) {
					allTemplates = [...allTemplates, ...response.data.data];
					offset += 100;
					if (response.data.data.length < 100) {
						hasMore = false;
					}
				} else {
					hasMore = false;
				}
			}
			setTemplates(allTemplates);
		} catch (error) {
			console.log(error, 'error')
		}
		finally {
			setLoader(false)
		}
	};

	useEffect(() => {
		getTemplatesList();
	}, []);

	const handleCreateTemplate = async () => {
		const maxRetries = 0;
		let attempts = 0;
		setSavingProgress(true);
		setEnabledButtons(false);
		const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

		const createTemplateRequest = async () => {

			try {
				setUniqueID({915: null});
				const mapKeyToId: any = {
					newTitle: '133',
					newSku: '1',
					newDraft: '859.1',
					// newThumbnail: '727',
					// newImagesArray: '728',
					newColor: '209',
					newMaterial: '82',
					newSize: '213',
					newPrice: '22',
					newBrand: '206',
					newDescription: '505',
					newUpc: '704',
					newKeywords: '453',
					newCondition: '15',
					newSubCondition: '795',
					newPreOwnedCondition: '796',
					newConditionNotes: '17',
					newShippingWeightlbkg: '2',
					newShippingWeightozg: '3',
					newStyle: '205',
					newIntendedFor: '4',
					newCogs: '847',
					newMSRP: '104',
					newQuantity: '851',
					newNotes: '726',
					newMadein: '211',
					newShippingZipOrCityCode: '837',
					newShippingPackageLength: '531',
					newShippingPackageWidth: '532',
					newShippingPackageHeight: '533',
					// newShippingCost: '852',
					// newFees: '844',
					// newSoldPrice: '842',
					newDescBuilder: '166',
					newSuggestTitle: '783',
					newAssignee: '883',
					newPatternOrTexture: '210',
					newSeasonOrWeather: '36',
					newCare: '43',
					newMeasuerements: '750',
					newLength: '48',
					newWidth: '46',
					newHeight: '47',
					newLengthMeasure: '380',
					newWidthMeasure: '386',
					newHeigthMeasure: '391',
					newBottomOfDescription: '722',
					newBullet: '44',
					newMeasure: '54',
					newBoth: '723.1',
					// copyDesc: '854',
					newVariations: '857',
					lineDetail1: '340',
					lineDetail2: '341',
					lineDetail3: '357',
					lineDetail4: '629',
					lineDetail5: '630',
					lineDetail6: '188',
					lineDetail7: '266',
					lineDetail8: '397',
					lineDetail9: '175',
					lineDetail10: '628',
					lineDetail11: '874',
					lineDetail12: '875',
					lineDetail13: '876',
					lineDetail14: '877',
					lineDetail15: '878',
					measurementLabel4: '378',
					measurementLabel5: '384',
					measurementLabel6: '389',
					measurementLabel7: '70',
					measurementLabel8: '641',
					measurementLabel9: '642',
					measurementLabel10: '643',
					measurementLabel11: '185',
					measurementLabel12: '186',
					measurementLabel13: '187',
					measurementLabel14: '192',
					measurementLabel15: '194',
					measurementLabel16: '331',
					measurementLabel17: '405',
					measurementLabel18: '407',
					measurementLabel19: '411',
					measurementLabel20: '602',
					measurementLabelInCm4: '88',
					measurementLabelInCm5: '89',
					measurementLabelInCm6: '90',
					measurementLabelInCm7: '91',
					measurementLabelInCm8: '617',
					measurementLabelInCm9: '637',
					measurementLabelInCm10: '639',
					measurementLabelInCm11: '28',
					measurementLabelInCm12: '30',
					measurementLabelInCm13: '59',
					measurementLabelInCm14: '84',
					measurementLabelInCm15: '85',
					measurementLabelInCm16: '86',
					measurementLabelInCm17: '87',
					measurementLabelInCm18: '92',
					measurementLabelInCm19: '93',
					measurementLabelInCm20: '94',
					showAllTitlesCheckbox: '822.1',
					suggestTitleCheckbox: '784.1',
					addMoreLinesCheckbox: '879.1',
					moreMeasurementCheckbox1: '377.1',
					moreMeasurementCheckbox2: '109.1',
					newShowInDropDown: '122.1',
					newEmoji: '45',
					newMercaryTitle: '789',
					newPoshmarkTitle: '786',
					newGrailedTitle: '788',
					newAmazonEbayTitle: '791',
					newFacebookTitle: '869'
				};
				const metadata: any = {};
				for (const [key, value] of Object.entries<any>(itemData[0])) {
					if (key === 'newPrice'
					 || key === 'measurementLabelInCm12' || key === 'measurementLabelInCm11'
					 || key === 'measurementLabelInCm14' || key === 'measurementLabelInCm18'
					 || key ===  'measurementLabelInCm20' || key === 'newMSRP'
					 || key === 'newLengthMeasure' || key === 'newWidthMeasure'
					 || key === 'newHeigthMeasure' || key === 'newShippingPackageLength'
					 || key === 'newShippingPackageWidth' || key === 'newShippingPackageHeight'
					 || key === 'newCogs' || key === 'newQuantity'
					 || key === 'measurementLabelInCm4'
					 || key === 'measurementLabelInCm5'
					 || key === 'measurementLabelInCm6'
					 || key === 'measurementLabelInCm7'
					 || key === 'measurementLabelInCm8'
					 || key === 'measurementLabelInCm9'
					 || key === 'measurementLabelInCm10'
					 || key === 'measurementLabelInCm11'
					 || key === 'measurementLabelInCm12'
					 || key === 'measurementLabelInCm13'
					 || key === 'measurementLabelInCm14'
					 || key === 'measurementLabelInCm15'
					 || key === 'measurementLabelInCm16'
					 || key === 'measurementLabelInCm17'
					 || key === 'measurementLabelInCm18'
					 || key === 'measurementLabelInCm19'
					 || key === 'measurementLabelInCm20'
					 || (key === 'newAssignee' && value !== null)
					) {
						metadata[mapKeyToId[key]] = Number(value);
					}
					else if(key === 'newKeywords' && Array.isArray(value)){
					    const newKeywordsString =  value?.map((item: any) => item?.value || item).join(', ');
					    metadata[mapKeyToId[key]] = newKeywordsString;
					}
					else if(key === 'newTitle' || key ===  'newSeasonOrWeather' || key === 'newPatternOrTexture' || key === 'newCare' || key === 'newStyle' || key === 'newBrand' || key === 'newMaterial' || key === 'newMadein' || key === 'newMercaryTitle' || key === 'newPoshmarkTitle' || key === 'newGrailedTitle' || key === 'newAmazonEbayTitle' || key === 'newFacebookTitle') {
						const removeSpacesFromValue = (value as string)?.replace(/\n+/g, '');
						metadata[mapKeyToId[key]] = removeSpacesFromValue;
					}
					else if (mapKeyToId[key]) {
						metadata[mapKeyToId[key]] = value;
					}
				}
				if (uniqueID) metadata['915'] = uniqueID['915'];

				const payload = {
					status: 'active',
					metadata: metadata
				};
				const response: any = await Listings.tempalates_add(token, payload);
				if (response) {
					setSavingProgress(false);
				}
			} catch (e: any) {
				setSavingProgress(false);
				if (attempts < maxRetries) {
					attempts++;
					await delay(1000); // 1 second delay
					await createTemplateRequest();
				} else {
					throw e;
					toast.error(`${e?.message}`, {
						position: 'bottom-right',
					});
				}
			}
			finally {
				setEnabledButtons(true);
			}
		};

		await createTemplateRequest();
	};
	const handleSaveTemplate = async () => {
		const maxRetries = 0;
		let attempts = 0;
		setSavingProgress(true);
		setEnabledButtons(false);
		const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

		const saveTemplateRequest = async () => {

			try {
				const mapKeyToId: any = {
					newTitle: '133',
					newSku: '1',
					newDraft: '859.1',
					// newThumbnail: '727',
					// newImagesArray: '728',
					newColor: '209',
					newMaterial: '82',
					newSize: '213',
					newPrice: '22',
					newBrand: '206',
					newDescription: '505',
					newUpc: '704',
					newKeywords: '453',
					newCondition: '15',
					newSubCondition: '795',
					newPreOwnedCondition: '796',
					newConditionNotes: '17',
					newShippingWeightlbkg: '2',
					newShippingWeightozg: '3',
					newStyle: '205',
					newIntendedFor: '4',
					newCogs: '847',
					newMSRP: '104',
					newQuantity: '851',
					newNotes: '726',
					newMadein: '211',
					newShippingZipOrCityCode: '837',
					newShippingPackageLength: '531',
					newShippingPackageWidth: '532',
					newShippingPackageHeight: '533',
					// newShippingCost: '852',
					// newFees: '844',
					// newSoldPrice: '842',
					newDescBuilder: '166',
					newSuggestTitle: '783',
					newAssignee: '883',
					newPatternOrTexture: '210',
					newSeasonOrWeather: '36',
					newCare: '43',
					newMeasuerements: '750',
					newLength: '48',
					newWidth: '46',
					newHeight: '47',
					newLengthMeasure: '380',
					newWidthMeasure: '386',
					newHeigthMeasure: '391',
					newBottomOfDescription: '722',
					newBullet: '44',
					newMeasure: '54',
					newBoth: '723.1',
					// copyDesc: '854',
					newVariations: '857',
					lineDetail1: '340',
					lineDetail2: '341',
					lineDetail3: '357',
					lineDetail4: '629',
					lineDetail5: '630',
					lineDetail6: '188',
					lineDetail7: '266',
					lineDetail8: '397',
					lineDetail9: '175',
					lineDetail10: '628',
					lineDetail11: '874',
					lineDetail12: '875',
					lineDetail13: '876',
					lineDetail14: '877',
					lineDetail15: '878',
					measurementLabel4: '378',
					measurementLabel5: '384',
					measurementLabel6: '389',
					measurementLabel7: '70',
					measurementLabel8: '641',
					measurementLabel9: '642',
					measurementLabel10: '643',
					measurementLabel11: '185',
					measurementLabel12: '186',
					measurementLabel13: '187',
					measurementLabel14: '192',
					measurementLabel15: '194',
					measurementLabel16: '331',
					measurementLabel17: '405',
					measurementLabel18: '407',
					measurementLabel19: '411',
					measurementLabel20: '602',
					measurementLabelInCm4: '88',
					measurementLabelInCm5: '89',
					measurementLabelInCm6: '90',
					measurementLabelInCm7: '91',
					measurementLabelInCm8: '617',
					measurementLabelInCm9: '637',
					measurementLabelInCm10: '639',
					measurementLabelInCm11: '28',
					measurementLabelInCm12: '30',
					measurementLabelInCm13: '59',
					measurementLabelInCm14: '84',
					measurementLabelInCm15: '85',
					measurementLabelInCm16: '86',
					measurementLabelInCm17: '87',
					measurementLabelInCm18: '92',
					measurementLabelInCm19: '93',
					measurementLabelInCm20: '94',
					showAllTitlesCheckbox: '822.1',
					suggestTitleCheckbox: '784.1',
					addMoreLinesCheckbox: '879.1',
					moreMeasurementCheckbox1: '377.1',
					moreMeasurementCheckbox2: '109.1',
					newShowInDropDown: '122.1',
					newEmoji: '45',
					newMercaryTitle: '789',
					newPoshmarkTitle: '786',
					newGrailedTitle: '788',
					newAmazonEbayTitle: '791',
					newFacebookTitle: '869'
				};
				const metadata: any = {};
				for (const [key, value] of Object.entries<any>(itemData[0])) {
					if (key === 'newPrice'
					 || key === 'measurementLabelInCm12' || key === 'measurementLabelInCm11'
					 || key === 'measurementLabelInCm14' || key === 'measurementLabelInCm18'
					 || key ===  'measurementLabelInCm20' || key === 'newMSRP'
					 || key === 'newLengthMeasure' || key === 'newWidthMeasure'
					 || key === 'newHeigthMeasure' || key === 'newShippingPackageLength'
					 || key === 'newShippingPackageWidth' || key === 'newShippingPackageHeight'
					 || key === 'newCogs' || key === 'newQuantity'
					 || key === 'measurementLabelInCm4'
					 || key === 'measurementLabelInCm5'
					 || key === 'measurementLabelInCm6'
					 || key === 'measurementLabelInCm7'
					 || key === 'measurementLabelInCm8'
					 || key === 'measurementLabelInCm9'
					 || key === 'measurementLabelInCm10'
					 || key === 'measurementLabelInCm11'
					 || key === 'measurementLabelInCm12'
					 || key === 'measurementLabelInCm13'
					 || key === 'measurementLabelInCm14'
					 || key === 'measurementLabelInCm15'
					 || key === 'measurementLabelInCm16'
					 || key === 'measurementLabelInCm17'
					 || key === 'measurementLabelInCm18'
					 || key === 'measurementLabelInCm19'
					 || key === 'measurementLabelInCm20'
					 || (key === 'newAssignee' && value !== null)
					) {
						metadata[mapKeyToId[key]] = Number(value);
					}
					else if(key === 'newKeywords' && Array.isArray(value)){
						const newKeywordsString =  value?.map((item: any) => item?.value || item).join(', ');
						metadata[mapKeyToId[key]] = newKeywordsString;
					}
					else if(key === 'newTitle' || key === 'newMercaryTitle' || key === 'newPoshmarkTitle' || key === 'newGrailedTitle' || key === 'newAmazonEbayTitle' || key === 'newFacebookTitle'){
						const removeSpacesFromValueTitles = value ? (value as string)?.replace(/\n+/g, '') : null;
						metadata[mapKeyToId[key]] = removeSpacesFromValueTitles;
					}
					else if(key ===  'newSeasonOrWeather' || key === 'newPatternOrTexture' || key === 'newCare' || key === 'newStyle' || key === 'newBrand' || key === 'newMaterial' || key === 'newMadein') {
						const removeSpacesFromValue = (value as string)?.replace(/\n+/g, '');
						metadata[mapKeyToId[key]] = removeSpacesFromValue;
					}
					else if (mapKeyToId[key]) {
						metadata[mapKeyToId[key]] = value;
					}
				}
                const filterBy = {
					id: +id,
				};
				const payload = {...metadata};
				const response: any = await Listings.tempalates_metadata_update(token, filterBy, payload);
				if (response) {
					setSavingProgress(false);
				}
			} catch (e: any) {
				setSavingProgress(false);
				if (attempts < maxRetries) {
					attempts++;
					await delay(1000); // 1 second delay
					await saveTemplateRequest();
				} else {
					throw e;
					toast.error(`${e?.message}`, {
						position: 'bottom-right',
					});
				}
			}
			finally {
				setEnabledButtons(true);
			}
		};

		await saveTemplateRequest();
	};

	function parsePhpSerializedString(serializedString:any) {
		// Match the entire array structure
		const outerArrayRegex = /a:\d+:{((?:i:\d+;a:\d+:{(?:s:\d+:"(.*?)";s:\d+:"(.*?)";)*}?)*)}/;
		const outerMatch = outerArrayRegex.exec(serializedString);
		if (!outerMatch) {
			throw new Error("Invalid serialized string");
		}

		const outerContent = outerMatch[1];

		// Regex to match each object in the array
		const objectRegex = /i:\d+;a:\d+:{((?:s:\d+:"(.*?)";s:\d+:"(.*?)";)*)}/g;
		const keyValuePairRegex = /s:\d+:"(.*?)";s:\d+:"(.*?)";/g;

		let objectMatch;
		const resultArray = [];

		// Iterate over each object match in the string
		while ((objectMatch = objectRegex.exec(outerContent)) !== null) {
			const objectContent = objectMatch[1];
			const resultObject:any = {};
			let keyValueMatch;

			// Extract key-value pairs for each object
			while ((keyValueMatch = keyValuePairRegex.exec(objectContent)) !== null) {
				const key = keyValueMatch[1];
				const value = keyValueMatch[2];
				resultObject[key] = value;
			}

			resultArray.push(resultObject);
		}

		return resultArray;
	}

	useEffect(() => {
		try{
			if(id){
				const template:any = templates.find((template:any) => Number(template?.id) === Number(id));
				setListInfo(template?.metadata)
			}
		}catch(e){
			console.log('error', e)
		}
	}, [templates, id]);

	const getListInfo = async (listingId:any) => {
		try {
			const options = {include_thumbnail: false};
			const metadata = {offset: 0, limit: 10};
			const filter_by = { id: listingId };
			const response:any = await Listings.list(filter_by, metadata, options, null,token)
			const { data } = response;
			setListingData(data[0]?.metadata)
		} catch (error) {
			console.error(error);
		}
	};


	useEffect(() => {
		try {
          if(listingId){
			  getListInfo(listingId)
		  }
		}
		catch(e){
           console.log(e, 'error')
		}
	}, [listingId]);


	useEffect(() => {
		try{
			if(id){
				const activeItemIntendedFor = unescapedRowMetadata[4];
				const indexToActivateIntendedFor = IntendedForItems?.findIndex(
				 (item) => item.value === activeItemIntendedFor
				);
				if (indexToActivateIntendedFor !== -1) {
					const updatedItems = [...IntendedForItems];
					updatedItems[indexToActivateIntendedFor] = {
						...updatedItems[indexToActivateIntendedFor],
						active: true,
					};
					setIntendedItems(updatedItems);
				}

				const activeBoth = unescapedRowMetadata[723.1];
				if(activeBoth){
					const indexToActivateActiveBoth = measureBothItems?.findIndex(
					 (item) => item.value === activeBoth
					);
					if (indexToActivateActiveBoth !== -1) {
						const updatedItems = [...measureBothItems];
						updatedItems[indexToActivateActiveBoth] = {
							...updatedItems[indexToActivateActiveBoth],
							active: true,
						};
						setMeasureBothItems(updatedItems);
					}
				}

				const activeBullets = unescapedRowMetadata[44];
				if(activeBullets){
					const indexToActivateActiveBullets = bulletItems?.findIndex(
					 (item) => item.value === activeBullets
					);
					if (indexToActivateActiveBullets !== -1) {
						const updatedItems = bulletItems.map((item, index) => ({
							...item,
							active: index === indexToActivateActiveBullets, // Only set the matched item to active: true
						}));
						setBulletItems(updatedItems);
					}
				}

				const activeMeasure = unescapedRowMetadata[54];
				if(activeMeasure){
					const indexToActivateActiveMeasure = measureItems?.findIndex(
					 (item) => item.value === activeMeasure
					);
					if (indexToActivateActiveMeasure !== -1) {
						const updatedItems = measureItems.map((item, index) => ({
							...item,
							active: index === indexToActivateActiveMeasure, // Only set the matched item to active: true
						}));
						setMeasureItems(updatedItems);
					}
				}

				const variations = unescapedRowMetadata[857];

				if(variations){
					const parsedVariations:any = parsePhpSerializedString(variations);
					setVariations(parsedVariations);
				}else {
					setVariations([
						{
							Size: '',
							Color: '',
							Custom: '',
							Quantity: '',
							SKU: '',
						},
					]);
				}

				const activeItemForCondition = unescapedRowMetadata[15];
				const indexToActivateCondition = conditionItems?.findIndex(
				 (item) => item.value === activeItemForCondition
				);
				if (activeItemForCondition) {
					if (activeItemForCondition === 'New') {
						setActiveCondition(1);
					} else if (activeItemForCondition === 'Pre-Owned') {
						setActiveCondition(2);
					} else {
						setActiveCondition(0);
					}
				}
				if (indexToActivateCondition !== -1) {
					const updatedItems = [...conditionItems];
					updatedItems[indexToActivateCondition] = {
						...updatedItems[indexToActivateCondition],
						active: true,
					};
					setConditionItems(updatedItems);
				}
				const activeItemForNewCondition = unescapedRowMetadata[795];
				const indexToActivateNewCondition = newConditionItems?.findIndex(
				 (item) => item.value === activeItemForNewCondition
				);

				if (indexToActivateNewCondition !== -1) {
					const updatedItems = [...newConditionItems];
					updatedItems[indexToActivateNewCondition] = {
						...updatedItems[indexToActivateNewCondition],
						active: true,
					};
					setNewConditionItems(updatedItems);
				}
				const activeItemForPreOwnedCondition = unescapedRowMetadata[796];
				const indexToActivatePreOwnedCondition = preOwnedItems?.findIndex(
				 (item) => item.value === activeItemForPreOwnedCondition
				);

				if (indexToActivatePreOwnedCondition !== -1) {
					const updatedItems = [...preOwnedItems];
					updatedItems[indexToActivatePreOwnedCondition] = {
						...updatedItems[indexToActivatePreOwnedCondition],
						active: true,
					};
					setPreOwnedItems(updatedItems);
				}
				const activeItemForBuilder = unescapedRowMetadata[166];
				if(activeItemForBuilder){
					const indexToActivateBuilder = descriptionBuildItems?.findIndex(
					 (item:any) => item.value === activeItemForBuilder
					);
					if (indexToActivateBuilder !== -1) {
						const updatedItems = descriptionBuildItems.map((item, index) => ({
							...item,
							active: index === indexToActivateBuilder, // Only set the matched item to active: true
						}));
						setDescriptionBuildItems(updatedItems);
					}
				}
				if(unescapedRowMetadata['122.1']){
					setIsShowInDropDown(true)
				}else{
					setIsShowInDropDown(false)
				}
				if (
				 unescapedRowMetadata[859.1]?.includes('Draft') &&
				 formInfo.newDraft.changed !== true
				) {
					setIsCheckBoxChecked(true);
				}
				if(unescapedRowMetadata['883']){
					setAssigneeId(`${unescapedRowMetadata['883']}`)
				}

				if (unescapedRowMetadata['879.1']) {
					setAddMoreInputs(true);
				}

				if (unescapedRowMetadata['109.1']) {
					setMoreMeasurementsPart2(true);
				}

				if (unescapedRowMetadata['377.1']) {
					setMoreMeasurementsPart1(true);
				}

				if(unescapedRowMetadata['784.1']){
					setShowSuggestTitles(true);
				}

				if(unescapedRowMetadata['822.1']){
					setShowAllTitles(true);
				}
			}
		}catch(e){
			console.log('error', e)
		}
	}, [listInfo]);


	useEffect(() => {
		try{
			if (activeTemplateid || isDuplicateTemplate || listingData) {
				const template:any = templates.find((template:any) => Number(template?.id) === Number(activeTemplateid || id));
				if (template || listingData) {
					let metadata = template?.metadata || listingData;
					const updatedFormInfo = { ...formInfo };
					const updatedItem = [...itemData];

					for (let key in metadata) {
						if (metadata?.hasOwnProperty(key)) {
							let inputName = 'input_' + key;
							let inputValue = metadata[key];

							const generatedName = getNameGenerator(inputName);

							if(key !== '883'){
								updatedFormInfo[generatedName] = { text: inputValue, changed: true };
							}

							if(key === '857' && metadata['857']){

								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newVariations']: Array.isArray(inputValue) ? inputValue : parsePhpSerializedString(inputValue),
									changed: true,
								};
							}
							else if(key !== '883'){
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									[generatedName]: inputValue,
									changed: true,
								};
							}
							if(key === '883' && metadata['883']){
								setAssigneeId(`${metadata['883']}`)
							}


							const activeItemForBuilder = metadata[166];
							if(activeItemForBuilder){
								const indexToActivateBuilder = descriptionBuildItems?.findIndex(
								 (item:any) => item.value === activeItemForBuilder
								);
								if (indexToActivateBuilder !== -1) {
									const updatedItems = descriptionBuildItems.map((item, index) => ({
										...item,
										active: index === indexToActivateBuilder, // Only set the matched item to active: true
									}));
									setDescriptionBuildItems(updatedItems);
								}
							}

							const activeItemIntendedFor = metadata[4];

							if(activeItemIntendedFor){
								const indexToActivateIntendedFor = IntendedForItems?.findIndex(
								 (item:any) => item.value === activeItemIntendedFor
								);
								if (indexToActivateIntendedFor !== -1) {
									const updatedItems = IntendedForItems.map((item, index) => ({
										...item,
										active: index === indexToActivateIntendedFor, // Only set the matched item to active: true
									}));
									setIntendedItems(updatedItems);
								}
							}

							const activeItemForCondition = metadata[15];

							if(activeItemForCondition){
								const indexToActivateCondition = conditionItems?.findIndex(
								 (item:any) => item.value === activeItemForCondition
								);
								if (activeItemForCondition) {
									if (activeItemForCondition === 'New') {
										setActiveCondition(1);
									} else if (activeItemForCondition === 'Pre-Owned') {
										setActiveCondition(2);
									} else {
										setActiveCondition(0);
									}
								}

								if (indexToActivateCondition !== -1) {
									const updatedItems = conditionItems.map((item, index) => ({
										...item,
										active: index === indexToActivateCondition, // Only set the matched item to active: true
									}));
									setConditionItems(updatedItems);
								}
							}

							const activeItemForNewCondition = metadata[795];
							if(activeItemForNewCondition){
								const indexToActivateNewCondition = newConditionItems?.findIndex(
								 (item:any) => item.value === activeItemForNewCondition
								);

								if (indexToActivateNewCondition !== -1) {
									const updatedItems = newConditionItems.map((item, index) => ({
										...item,
										active: index === indexToActivateNewCondition, // Only set the matched item to active: true
									}));
									setNewConditionItems(updatedItems);
								}
							}

							const activeItemForPreOwnedCondition = metadata[796];
							if(activeItemForPreOwnedCondition){
								const indexToActivatePreOwnedCondition = preOwnedItems?.findIndex(
								 (item:any) => item.value === activeItemForPreOwnedCondition
								);
								if (indexToActivatePreOwnedCondition !== -1) {
									const updatedItems = preOwnedItems.map((item, index) => ({
										...item,
										active: index === indexToActivatePreOwnedCondition, // Only set the matched item to active: true
									}));
									setPreOwnedItems(updatedItems);
								}
							}

							const activeBoth = metadata[723.1];
							if(activeBoth){
								const indexToActivateActiveBoth = measureBothItems?.findIndex(
								 (item) => item.value === activeBoth
								);
								if (indexToActivateActiveBoth !== -1) {
									const updatedItems = [...measureBothItems];
									updatedItems[indexToActivateActiveBoth] = {
										...updatedItems[indexToActivateActiveBoth],
										active: true,
									};
									setMeasureBothItems(updatedItems);
								}
							}


							const activeBullets = metadata[44];
							if(activeBullets){
								const indexToActivateActiveBullets = bulletItems?.findIndex(
								 (item) => item.value === activeBullets
								);
								if (indexToActivateActiveBullets !== -1) {
									const updatedItems = bulletItems.map((item, index) => ({
										...item,
										active: index === indexToActivateActiveBullets, // Only set the matched item to active: true
									}));
									setBulletItems(updatedItems);
								}
							}


							const activeMeasure = metadata[54];
							if(activeMeasure){
								const indexToActivateActiveMeasure = measureItems?.findIndex(
								 (item) => item.value === activeMeasure
								);
								if (indexToActivateActiveMeasure !== -1) {
									const updatedItems = measureItems.map((item, index) => ({
										...item,
										active: index === indexToActivateActiveMeasure, // Only set the matched item to active: true
									}));
									setMeasureItems(updatedItems);
								}
							}

							if (metadata[859.1] &&
							 metadata[859.1]?.includes('Draft') &&
							 formInfo.newDraft.changed !== true
							) {
								setIsCheckBoxChecked(true);
							}

							const variations = metadata[857];
							if(variations){
								const parsedVariations:any = Array.isArray(variations) ?  variations : parsePhpSerializedString(variations);
								setVariations(parsedVariations);
							}else {
								setVariations([
									{
										Size: '',
										Color: '',
										Custom: '',
										Quantity: '',
										SKU: '',
									},
								]);
							}
							if (metadata['879.1']) {
								setAddMoreInputs(true);
							}

							if (metadata['109.1']) {
								setMoreMeasurementsPart2(true);
							}

							if (metadata['377.1']) {
								setMoreMeasurementsPart1(true);
							}

							if(metadata['784.1']){
								setShowSuggestTitles(true);
							}

							if(metadata['822.1']){
								setShowAllTitles(true);
							}

							if (
								metadata[789] &&
								metadata[789] !== metadata['133']
							) {
								updatedFormInfo['newMercaryTitle'] = {text: metadata['789'], changed: true};
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newMercaryTitle']: metadata['789'],
									changed: true,
								};
							}
							else if (metadata['133'] && unescapedRowMetadata['789']) {
								updatedFormInfo['newMercaryTitle'] = { text: metadata['133'], changed: true };
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newMercaryTitle']: null,
									changed: true,
								};
							}
							else if(metadata['133'] && !unescapedRowMetadata['789']){
								updatedFormInfo['newMercaryTitle'] = { text: metadata['133'], changed: true };
							}

							if (
								metadata[786] &&
								metadata[786] !== metadata['133']
							) {
								updatedFormInfo['newPoshmarkTitle'] = {text: metadata['786'], changed: true};
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newPoshmarkTitle']: metadata['786'],
									changed: true,
								};
							}
							else if (metadata['133'] && unescapedRowMetadata['786']) {
								updatedFormInfo['newPoshmarkTitle'] = { text: metadata['133'], changed: true };
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newPoshmarkTitle']: null,
									changed: true,
								};
							}
							else if(metadata['133'] && !unescapedRowMetadata['786']){
								updatedFormInfo['newPoshmarkTitle'] = { text: metadata['133'], changed: true };
							}

							if (
								metadata[788] &&
								metadata[788] !== metadata['133']
							) {
								updatedFormInfo['newGrailedTitle'] = {text: metadata['788'], changed: true};
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newGrailedTitle']: metadata['788'],
									changed: true,
								};
							}
							else if (metadata['133'] && unescapedRowMetadata['788']) {
								updatedFormInfo['newGrailedTitle'] = { text: metadata['133'], changed: true };
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newGrailedTitle']: null,
									changed: true,
								};
							}
							else if(metadata['133'] && !unescapedRowMetadata['788']){
								updatedFormInfo['newGrailedTitle'] = { text: metadata['133'], changed: true };
							}

							if (
								metadata[791] &&
								metadata[791] !== metadata['133']
							) {
								updatedFormInfo['newAmazonEbayTitle'] = {text: metadata['791'], changed: true};
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newAmazonEbayTitle']: metadata['791'],
									changed: true,
								};
							}
							else if (metadata['133'] && unescapedRowMetadata['791']) {
								updatedFormInfo['newAmazonEbayTitle'] = { text: metadata['133'], changed: true };
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newAmazonEbayTitle']: null,
									changed: true,
								};
							}
							else if(metadata['133'] && !unescapedRowMetadata['791']){
								updatedFormInfo['newAmazonEbayTitle'] = { text: metadata['133'], changed: true };
							}

							if (
								metadata[869] &&
								metadata[869] !== metadata['133']
							) {
								updatedFormInfo['newFacebookTitle'] = {text: metadata['869'], changed: true};
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newFacebookTitle']: metadata['869'],
									changed: true,
								};
							}
							else if (metadata['133'] && unescapedRowMetadata['869']) {
								updatedFormInfo['newFacebookTitle'] = { text: metadata['133'], changed: true };
								updatedItem[editIndex] = {
									...updatedItem[editIndex],
									['newFacebookTitle']: null,
									changed: true,
								};
							}
							else if(metadata['133'] && !unescapedRowMetadata['869']){
								updatedFormInfo['newFacebookTitle'] = { text: metadata['133'], changed: true };
							}
						}

					}
					setFormInfo(updatedFormInfo);
					setItemData(updatedItem);
				}

			}
		}catch (e){
			console.log('error', e)
		}
	}, [activeTemplateid, isDuplicateTemplate, id, templates, listingData]);

	const handleResetAllTitles = () => {
		// Create a deep copy of itemData to ensure immutability
		const titlesToReset = [
			'newTitle',
			'newMercaryTitle',
			'newPoshmarkTitle',
			'newGrailedTitle',
			'newAmazonEbayTitle',
			'newFacebookTitle'
		];

		const updatedItem = [...itemData];

// Reset all titles and set `changed` to true in one go
		updatedItem[editIndex] = {
			...updatedItem[editIndex],
			changed: true,
			...titlesToReset.reduce((acc, title) => ({ ...acc, [title]: null }), {}),
		};

// Update itemData state
		setItemData(updatedItem);

		// Update formInfo state in one go
		setFormInfo((prev:any) => ({
			...prev,
			newTitle:{ text: '', changed: true },
			newMercaryTitle: { text: '', changed: true },
			newPoshmarkTitle: { text: '', changed: true },
			newGrailedTitle: { text: '', changed: true },
			newAmazonEbayTitle: { text: '', changed: true },
			newFacebookTitle: { text: '', changed: true },
		}));
	};


	useEffect(() => {
		return () => {
			dispatch(setShowEditTemplateModal(false));
			dispatch(setEditTemplateId(null));
			dispatch(setListingTemplateId(null));
			dispatch(setIsDuplicateTemplate(false))
		}
	}, []);

	useEffect(() => {
		if (showEditTemplateModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}

		return () => {
			document.body.style.overflow = '';
		};
	}, [showEditTemplateModal]);

	const generateUUID = () => {
		const uuid = uuidv4();
		setUniqueID((prevProperties : any) => ({
			...prevProperties,
			915: uuid
		}));
	};

	useEffect(() => {
		generateUUID();
	}, []);

	return(
	 <div className={`${styles.editAddModalContainer} ${(id || (listingId && !isSourceCatalog)) ? styles.editAddModalContainerFixed : ''}` } style={loader ? {height: '100vh'} : {}} id="templatesMainContainer">
		 {
			 loader ? <div className={styles.loadercontainer}><MoonLoader/></div>
			  : (
			   <>
				   {
					savingProgress && <div className={styles.loaderForSavingProgress}>
						<MoonLoader size={20}/>
						<p>Saving...</p>
					</div>
				   }
				   <div>
					   <p className={styles.headerTitle}>{(showEditTemplateModal && !isDuplicateTemplate && id && !listingId) ? <span>Edit Template</span> : isDuplicateTemplate ?
						<span>Duplicate Template</span> : <span>Create Template</span>}</p>
					   {
						((showEditTemplateModal && id) || (showEditTemplateModal && (listingId && !isSourceCatalog))) && <div className={styles.closeEditAddModalMobile} onClick={() => {
							dispatch(setShowEditTemplateModal(false));
						}}><CloseSvg/></div>
					   }
				   </div>

				   <div className={styles.editModalInner}>
					   <ToastContainer containerId="editToast"/>

					   <HeaderComponent
						handleCreateTemplate={handleCreateTemplate}
						enabledButtons={enabledButtons}
						id={id}
						listingId={listingId}
						handleSaveTemplate={handleSaveTemplate}
						isDuplicateTemplate={isDuplicateTemplate}
						isSourceCatalog={isSourceCatalog}
					   />

					   <EditTemplatesContainer
						isShowInDropDown={isShowInDropDown}
						setIsShowInDropDown={setIsShowInDropDown}
						templates={templates}
						setActiveTemplateId={setActiveTemplateId}
						formInfo={formInfo}
						setFormInfo={setFormInfo}
						itemData={itemData}
						editIndex={editIndex}
						setItemData={setItemData}/>

					   <ListingTitleContainer formInfo={formInfo}
											  setFormInfo={setFormInfo}
											  itemData={itemData}
											  editIndex={editIndex}
											  setItemData={setItemData}
											  unescapedRowMetadata={unescapedRowMetadata}
											  handleStandartInputChange={handleStandartInputChange}
											  hasSimple={hasSimple}
											  hasBusiness={hasBusiness}
											  showAllTitles={showAllTitles}
											  setShowAllTitles={setShowAllTitles}
											  showSuggestTitles={showSuggestTitles}
											  setShowSuggestTitles={setShowSuggestTitles}
											  handleResetAllTitles={handleResetAllTitles}
					   />
					   <ShortDescriptionContainer
						handleTagifiesChange={handleTagifiesChange}
						handleStandartInputChange={handleStandartInputChange}
						formInfo={formInfo}
						unescapedRowMetadata={unescapedRowMetadata}
						hasSimple={hasSimple}
						hasBusiness={hasBusiness}
					   />
					   {
						(!hasSimple && !hasBusiness) &&
						<DescriptionBuilderComponent itemData={itemData}
													 setItemData={setItemData}
													 editIndex={editIndex}
													 descriptionBuildItems={descriptionBuildItems}
													 setDescriptionBuildItems={setDescriptionBuildItems}
						/>
					   }

					   {
						(!hasSimple && !hasBusiness) &&
						<IntendedForComponent itemData={itemData}
											  setItemData={setItemData}
											  editIndex={editIndex}
											  IntendedForItems={IntendedForItems}
											  setIntendedItems={setIntendedItems}
						/>
					   }


					   {
						(!hasSimple && !hasBusiness) &&
						<ConditionsComponent itemData={itemData}
											 editIndex={editIndex}
											 setItemData={setItemData}
											 handleStandartInputChange={handleStandartInputChange}
											 listInfo={listInfo}
											 formInfo={formInfo}
											 unescapedRowMetadata={unescapedRowMetadata}
											 activeCondition={activeCondition}
											 setActiveCondition={setActiveCondition}
											 conditionItems={conditionItems}
											 setConditionItems={setConditionItems}
											 newConditionItems={newConditionItems}
											 setNewConditionItems={setNewConditionItems}
											 preOwnedItems={preOwnedItems}
											 setPreOwnedItems={setPreOwnedItems}
						/>
					   }
					   <DropdownComponent handleTagifiesChange={handleTagifiesChange}
										  handleStandartInputChange={handleStandartInputChange}
										  editIndex={editIndex}
										  itemData={itemData}
										  setItemData={setItemData}
										  listInfo={listInfo}
										  formInfo={formInfo}
										  unescapedRowMetadata={unescapedRowMetadata}
										  bulletItems={bulletItems}
										  setBulletItems={setBulletItems}
										  measureItems={measureItems}
										  setMeasureItems={setMeasureItems}
										  measureBothItems={measureBothItems}
										  setMeasureBothItems={setMeasureBothItems}
										  variations={variations}
										  setVariations={setVariations}
										  setFormInfo={setFormInfo}
										  addMoreInputs={addMoreInputs}
										  setAddMoreInputs={setAddMoreInputs}
										  moreMeasurementsPart1={moreMeasurementsPart1}
										  setMoreMeasurementsPart1={setMoreMeasurementsPart1}
										  moreMeasurementsPart2={moreMeasurementsPart2}
										  setMoreMeasurementsPart2={setMoreMeasurementsPart2}
										  assigneeId={assigneeId}
										  subAccounts={subAccounts}
										  setSubAccounts={setSubAccounts}
										  optionsAssignee={optionsAssignee}
										  setOptionsAssignee={setOptionsAssignee}
										  selectedOptionAssignee={selectedOptionAssignee}
										  setSelectedOptionAssignee={setSelectedOptionAssignee}
										  hasSimple={hasSimple}
										  hasBusiness={hasBusiness}
										  onlyPro={onlyPro}
					   />
					   <SellerNotesDraft itemData={itemData}
										 editIndex={editIndex}
										 setItemData={setItemData}
										 formInfo={formInfo}
										 setFormInfo={setFormInfo}
										 handleStandartInputChange={handleStandartInputChange}
										 listInfo={listInfo}
										 unescapedRowMetadata={unescapedRowMetadata}
										 isCheckBoxChecked={isCheckBoxChecked}
										 setIsCheckBoxChecked={setIsCheckBoxChecked}
										 hasSimple={hasSimple}
										 hasBusiness={hasBusiness}
					   />
				   </div>

				   <FooterComponent isSourceCatalog={isSourceCatalog} isDuplicateTemplate={isDuplicateTemplate} id={id} listingId={listingId} handleSaveTemplate={handleSaveTemplate} enabledButtons={enabledButtons} handleCreateTemplate={handleCreateTemplate}/>
			   </>
			  )
		 }
	 </div>
	)
}

export default EditAddListingModal;