import React, {useEffect, useRef} from 'react';
import styles from './ViewIssuesModal.module.scss';
import {ErrorPlusBig, ModalCloseSvg, QuestionIcon} from "@assets/icons";
import Tippy from "@tippyjs/react";

const modalWrapperItems = [
	{
		id: 1,
		value: 'Empty IDs - Status selected, no ID saved',
		label: 'Empty IDs - Status selected, no ID saved',
		placeholder:'issues_status_selected_empty_item_number',
		tooltipText:"A marketplace status is set in List Perfectly (like “active” or “sold”), but no marketplace ID is saved. This means the listing lacks a connection between your List Perfectly catalog and the actual marketplace listing."
	},
	{
		id: 2,
		value: 'Marketplace status selected, no ACTIVE listing found',
		label: 'Marketplace status selected, no ACTIVE listing found',
		placeholder: 'issues_status_selected_no_active_listing_found',
		tooltipText:"This indicates that you’ve marked a listing as active in List Perfectly, but no active listing is found on the marketplace for the saved ID. This might mean the listing was ended or deleted on the marketplace."
	},
	{
		id: 3,
		value: 'Marketplace status NOT selected, ACTIVE listing found',
		label: 'Marketplace status NOT selected, ACTIVE listing found',
		placeholder: 'issues_status_not_selected_active_listing_found',
		tooltipText:"This happens when no marketplace status is selected in List Perfectly, but an active listing is found using the saved ID. This could indicate that the listing was created on the marketplace without syncing back to List Perfectly."

	},
	{
		id: 4,
		value: 'Sold status selected, ACTIVE listing found',
		label: 'Sold status selected, ACTIVE listing found',
		placeholder: 'issues_sold_status_active_listing_found',
		tooltipText: "This shows up when you’ve marked a listing as sold in List Perfectly, but it remains active on the marketplace. You’ll need to manually end the listing on that platform."
	},
	{
		id: 5,
		value: 'Marketplace status selected, NO listing found',
		label: 'Unable to check status OR listing not found',
		placeholder: 'issues_status_selected_no_listing_found',
		tooltipText: "The Issue Finder cannot verify the status of this listing, or it cannot locate the listing at all. This may be due to a temporary issue or a marketplace glitch. You may need to check the listing manually."
	},
];

interface IViewIssuesModalProps {
	handleClickViewIssues:() => void;
	handleClickViewIssuesModalOption:(option:any) => void;
	updatedOptions:any;
}

const ViewIssuesModal = (props: IViewIssuesModalProps) => {
	const {  handleClickViewIssues,handleClickViewIssuesModalOption,updatedOptions } = props ;
	const getIssuesCount = (issueIndex:any) => {
		const issuesCount  = updatedOptions[issueIndex].issues_count ;
		return issuesCount
	}


	const viewIssuesModalRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
			 viewIssuesModalRef.current &&
			 !viewIssuesModalRef.current.contains(event.target as Node)
			) {
				handleClickViewIssues();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [viewIssuesModalRef, handleClickViewIssues]);

	const isHaveIssues = updatedOptions?.some((updateOption:any) => updateOption.issues_count > 0)

	return (
	 <div className={styles['modal__overlay']}>
		 <div ref={viewIssuesModalRef} className={styles.modal}>
			 <div className={styles['modal__header']}>
				 <div className={styles['modal__header-left']}>
					 <ErrorPlusBig/>
					 <span className={styles['modal__header-title']}>Filter Issues</span>
				 </div>
				 <ModalCloseSvg
				  className={styles['modal__closeButton']}
				  onClick={handleClickViewIssues}
				 />
			 </div>
			 <div className={styles['modal__text']}>
				 {isHaveIssues ? "Filter listings with issues by selecting issue type(s) below." : "No issues found!"}
			 </div>
			 <div className={styles['modal__line']}></div>
			 <div className={styles['modal__wrapper']}>
				 {modalWrapperItems?.map((modalItem: any, index: number) => (
				  <div
				   key={modalItem.id}
				   className={styles['modal__item']}
				  >
					  <div className={styles['modal__item-left']}>
						  <span
						   className={!getIssuesCount(index) ? `${styles['modal__item-count']} ${styles['modal__item-count--notActive']}` : index !== 0 ? styles['modal__item-count'] : `${styles['modal__item-count']} ${styles['modal__item-count--red']}`}>{getIssuesCount(index)}</span>
						  <div className={styles['modal__item-text']}>{modalItem.label}</div>
						  <Tippy
						   interactive={true}
						   arrow={false}
						   zIndex={9999}
						   trigger="click"
						   content={
							   <div className={styles.popoverContent}>
								   <p>{modalItem.tooltipText}</p>
							   </div>
						   }
						  >
							  <QuestionIcon
							   className={styles['modal__item-questionIcon']}
							  />
						  </Tippy>
					  </div>
					  <button
					   className={`myBtn ${styles['modal__item-button']}`}
					   onClick={() => handleClickViewIssuesModalOption(modalItem)}
					   disabled={!getIssuesCount(index)}
					  >
						  <span>Filter</span>
					  </button>
				  </div>
				 ))}
			 </div>
		 </div>
	 </div>

	);
};

export default ViewIssuesModal;
