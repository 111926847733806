import React, { useState} from 'react';
import styles from './DataSetRow.module.scss';

import moment from 'moment';

import useBasicFuncs from '../../../hooks/useBasisFuncs';
import defaultThumbnaiLPhoto from '../../../assets/icons/svg/defaultThumbnail.svg';
import {ReactComponent as EbayLogo} from '../../../assets/icons/svg/ebayIconNew.svg';
import {ReactComponent as GrailedLogo} from '../../../assets/icons/svg/GreiledIconUpdated.svg';
import {ReactComponent as EtsyLogo} from '../../../assets/icons/svg/EtsyIconUpdated.svg';
import {ReactComponent as DepopLogo} from '../../../assets/icons/svg/DepopIconUpdated.svg';
import {ReactComponent as PoshmarkLogo} from '../../../assets/icons/svg/poshmarkIconNew.svg';
import {ReactComponent as KitidzenLogo} from '../../../assets/icons/svg/KitidzenIconUpdated.svg';
import {ReactComponent as FacebookLogo} from '../../../assets/icons/svg/FacebookIconUpdated.svg';
import {ReactComponent as VestiaireLogo} from '../../../assets/icons/svg/VestiaireIconUpdated.svg';
import {ReactComponent as MercaryLogo} from '../../../assets/icons/svg/MercaryIconNew.svg';
import {ReactComponent as ShopifyLogo} from '../../../assets/icons/svg/ShopifyUpdatedICon.svg';



import {FaImage, PlatformIconShop} from "../../../assets/icons";
import {useSelector} from "react-redux";

interface ITableRowProps {
    item: any;
    columns: any;
    index: number;
}

const DataSetRow = (props: ITableRowProps) => {
    const {item, columns} = props;
    const {bdecode} = useBasicFuncs();
    const globalCustomMarketplaces = useSelector((state: any) => state.customMarketplaces.customMarketplaces)
    const [newTableInfo, setNewTableInfo] = useState({
        newTitle: {
            text: '',
            changed: false,
        },
        newDateSold: {
            text: '',
            changed: false,
        },
        newLp: {
            text: '',
            changed: false,
        },
        newCogs: {
            text: '',
            changed: false,
        },
        newQuantityAvailable: {
            text: '',
            changed: false,
        },
        newQuantitySold: {
            text: '',
            changed: false,
        },

        newSalesTax: {
            text: '',
            changed: false,
        },
        newFees: {
            text: '',
            changed: false,
        },
        newSoldOn: {
            text: '',
            changed: false,
        },
        newShippingCostBuyer: {
            text: '',
            changed: false,
        },
        newShippingStatus: {
            text: '',
            changed: false,
        },
        newPrice: {
            text: '',
            changed: false,
        },
        newShop: {
            text: '',
            changed: false,
        },
        newShippingCostSeller: {
            text: '',
            changed: false,
        },
        newLinked: {
            text: '',
            changed: false,
        },
        newSku: {
            text: '',
            changed: false,
        },
    });

    const isArrayLengthGreaterThanOne = item?.listings?.length > 1;


    const getCustomMarketplaces = (code:string) => {
        let customMarketplaces:any;

        globalCustomMarketplaces?.forEach((customMarket:any) => {
            if(customMarket.platform.code[0] === code) {
                customMarketplaces = {
                    color:customMarket.color,
                    title:customMarket.platform.title,
                    platformId:customMarket.platform.platformId
                }
            }
        })

        return customMarketplaces
    }

    const customMarketplace = getCustomMarketplaces(item && item?.marketplace)

    const imgPrefix = 'https://s3.amazonaws.com/listperfectly.orders/';

    return (
        <>
            <tr>
                <td>
                    <input
                        className={styles.myCheckbox}
                        type="checkbox"
                        checked={false}
                        name={item.id}
                    />
                </td>
                {columns.map((columnItem: any, index: any) =>
                    columnItem.value === 'image' ? (
                        <td className={styles.thumbnailTd}>
                            <div
                                className={styles.thumbnail}
                                key={columnItem.value}
                                style={{position: 'relative'}}
                            >
                                {item[`${'thumbnail'}`] ? (
                                    <img
                                        src={
                                            item[`${'thumbnail'}`].includes('undefined')
                                                ? defaultThumbnaiLPhoto
                                                : imgPrefix + item[`${'thumbnail'}`]
                                        }
                                        alt="thumbnailPhoto"
                                    />
                                ) : (
                                  <FaImage/>
                                )}
                                {isArrayLengthGreaterThanOne ||
                                    (Number(newTableInfo?.newQuantitySold?.text) > 1 && (
                                        <div
                                            style={{
                                                backgroundColor: '#4DB89E',
                                                borderRadius: '10px',
                                                height: '30px',
                                                width: '45px',
                                                border: '1px solid #fff',
                                                color: '#fff',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '16px',
                                                bottom: '-3px',
                                                right: '-6px',
                                                position: 'absolute',
                                            }}
                                        >
                        <span>
                          {newTableInfo.newQuantitySold.text
                              ? newTableInfo.newQuantitySold.text
                              : item?.quantity_sold}
                        </span>
                                        </div>
                                    ))}
                            </div>
                        </td>
                    ) : columnItem.value === 'title' ? (
                        <td>
                            <div style={{cursor: 'text'}}>
                                {
                                    bdecode(item[columnItem.value]) ||
                                    '-'}
                            </div>
                        </td>
                    ) : columnItem.value === 'date_sold' ? (
                        <td>
                            <div style={{cursor: 'text'}}>
                                {newTableInfo.newDateSold.text ||
                                    moment(item[columnItem.value]).format('MM/DD/YYYY')
                                 ||
                                    '-'}
                            </div>
                        </td>
                    ) : columnItem.value === 'sku' ? (
                        <td>
                            <div style={{cursor: 'text'}}>
                                {newTableInfo.newSku.text || item[columnItem.value] || '-'}
                            </div>
                        </td>
                    ) : columnItem.value === 'quantity_sold' ? (
                        <td>
                            {/* <div>{item[columnItem.value]}</div> */}
                            <div style={{cursor: 'text'}}>
                                {item.newQuantitySold?.length
                                    ? item.newQuantitySold
                                    : item[columnItem.value]
                                        ? item[columnItem.value]
                                        : '-'}
                            </div>
                        </td>
                    ) : columnItem.value === 'quantity_available' ? (
                        <td>
                            <div style={{cursor: 'text'}}>
                                {item.newQuantityAvailable?.length
                                    ? item.newQuantityAvailable
                                    : item[columnItem.value]
                                        ? item[columnItem.value]
                                        : '-'}
                            </div>
                        </td>
                    ) : columnItem.value !== 'sold_on' ? (
                        <td key={index}>
                            <div style={{cursor: 'text'}}>
                                {item[`${columnItem.value}`] || '-'}
                            </div>
                        </td>
                    ) : (
                        <td>
                            {item?.marketplace?.toLowerCase() === 'ebay' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/sh/ord/details?orderid=${item?.order_id}`}
                                    target="_blank"
                                    key={index}
                                >

                                    <EbayLogo/>
                                </a>
                            )}
                            {item?.marketplace?.toLowerCase() === 'shopify' && (
                             <a
                              // href={`https://${item?.marketplaces}.com/orders/${item?.order_id}`}
                              target="_blank"
                              key={index}
                             >
                                 <ShopifyLogo/>
                             </a>
                            )}

                            {item?.marketplace?.toLowerCase() === 'poshmark' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/order/sales/${item?.order_id}`}
                                    target="_blank"
                                    key={index}
                                >

                                    <PoshmarkLogo/>
                                </a>
                            )}

                            {item?.marketplace?.toLowerCase() === 'mercari' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/transaction/order_status/${item?.listing_id}`}
                                    target="_blank"
                                    key={index}
                                >

                                    <MercaryLogo/>
                                </a>
                            )}
                            {item?.marketplace?.toLowerCase() === 'etsy' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/your/orders/sold/completed?order_id=${item?.order_id}`}
                                    target="_blank"
                                    key={index}
                                >

                                    <EtsyLogo/>
                                </a>
                            )}

                            {item?.marketplace?.toLowerCase() === 'facebook' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/marketplace/you/shipping_orders/${item?.order_id}`}
                                    target="_blank"
                                    key={index}
                                >

                                    <FacebookLogo/>
                                </a>
                            )}
                            {item?.marketplace?.toLowerCase() === 'vestiare' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/${item?.order_id}.shtml`}
                                    target="_blank"
                                    key={index}
                                >

                                    <VestiaireLogo/>
                                </a>
                            )}

                            {item?.marketplace?.toLowerCase() === 'depop' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/sellinghub/sold-items/${item?.order_id}/`}
                                    target="_blank"
                                    key={index}
                                >

                                    <DepopLogo/>
                                </a>
                            )}

                            {item?.marketplace?.toLowerCase() === 'grailed' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/listings/${item?.order_id}/order`}
                                    target="_blank"
                                    key={index}
                                >

                                    <GrailedLogo/>
                                </a>
                            )}

                            {item?.marketplaces?.toLowerCase() === 'kidizen' && (
                                <a
                                    // href={`https://${item?.marketplaces}.com/orders/${item?.order_id}`}
                                    target="_blank"
                                    key={index}
                                >

                                    <KitidzenLogo/>

                                </a>
                            )}

                            {customMarketplace &&
                             <div
                              style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                 <div>
                                     <div
                                      style={{
                                          width: 40,
                                          height: 40,
                                          borderRadius: 4,
                                          padding: 5,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          border: `1px solid ${customMarketplace?.color ? customMarketplace.color : '#000'}`,
                                      }}
                                     >
                                         <PlatformIconShop
                                          width="20px"
                                          height="20px"
                                          fill={customMarketplace?.color ? customMarketplace.color : '#000'}
                                         />
                                     </div>
                                 </div>
                                 <div
                                  style={{
                                      fontSize: 15,
                                  }}
                                 >
                                     {customMarketplace.title}
                                 </div>
                             </div>
                            }
                        </td>
                    )
                )}
            </tr>
        </>
    );
};
export default DataSetRow;
