import { createSlice } from '@reduxjs/toolkit';

interface IRoleSliceState {
  roles: string[];
  hasPro: boolean;
  isRoleAllowed: boolean;
  hasSimple: boolean;
  hasBusiness: boolean;
}

const initialState: IRoleSliceState = {
  roles: [],
  hasPro: false,
  isRoleAllowed: false,
  hasSimple: false,
  hasBusiness: false,
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setHasPro: (state) => {
      state.hasPro = state.roles.includes('Pro');
    },
    setHasSimple: (state, action) => {
      state.hasSimple = action.payload;
    },
    setHasBusiness: (state, action) => {
      state.hasBusiness = action.payload;
    },
    setIsRoleAllowed: (state) => {
      state.isRoleAllowed = state.roles.some((role: any) => {
        return (
          role.toLowerCase() === 'corporate master' ||
          role.toLowerCase() === 'corporate sub'
        );
      });
    },
  },
});

export const {
  setRoles,
  setHasSimple,
  setHasBusiness,
  setHasPro,
  setIsRoleAllowed,
} = roleSlice.actions;
export default roleSlice.reducer;
