import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {MoonLoader} from 'react-spinners';
import {CloseSvg} from '../../assets/icons';
import {setHandleStopPrint} from '../../redux/slices/NavigationSlice';
import DataSetRow from './DataSetRow';
import styles from './DataSetTable.module.scss';
import './print.css';
import Button, {ModifierType} from "../../components/UI/Button";

function DataSetTable() {
  const dataForPrint = useSelector(
    (state: any) => state.navigation.dataForPrint
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<any>([
    {
      heading: 'Image',
      value: 'image',
      ref: useRef(),
    },
    {
      heading: 'Title',
      value: 'title',
      ref: useRef(),
    },
    {
      heading: 'Date Sold',
      value: 'date_sold',
      ref: useRef(),
    },
    {
      heading: 'Sold On',
      value: 'sold_on',
      ref: useRef(),
    },

    {
      heading: 'SKU',
      value: 'sku',
      ref: useRef(),
    },
    {
      heading: 'Qty sold',
      value: 'quantity_sold',
      ref: useRef(),
    },
    {
      heading: 'Qty available',
      value: 'quantity_available',
      ref: useRef(),
    },
  ]);

  const handlePrint = () => {
    setTimeout(() => {
      if (dataForPrint?.length) {
        window.print();
      }
    }, 1000);
  };

  useEffect(() => {
    if (!dataForPrint?.length) {
      navigate('/sales');
    } else {
      handlePrint();
    }
  }, []);

  return (
    <div className={styles.dataSetContainer}>
      <div className={styles.printHeader}>
        <Button handleClick={handlePrint} modifier={ModifierType.Primary} >Print</Button>
        <div
          className={styles.closeIcon}
          id="closePrint"
          onClick={() => {
            dispatch(setHandleStopPrint());
            navigate('/sales');
          }}
        >
          <CloseSvg />
        </div>
      </div>
      {dataForPrint?.length ? (
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <th
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              ></th>
              {columns?.map((col: any, i: any) => (
                <th
                  style={{
                    maxWidth:
                      col.value === 'title' ? 'none' : '500px !important',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ cursor: 'pointer' }}>{col.heading}</div>
                </th>
              ))}
            </thead>
            <tbody>
              {dataForPrint?.map((item: any, index: number) => (
                <DataSetRow
                  key={index}
                  item={item}
                  columns={columns}
                  index={index}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            width: '100%',
            position: 'absolute',
            top: '40%',
            gap: '5px',
          }}
        >
          <MoonLoader color="#1e3166" loading={true} />
          <div>Generate Table For Print</div>
        </div>
      )}
    </div>
  );
}

export default DataSetTable;
