import { useState, useEffect } from 'react';
import semver from 'semver';

const useExtensionVersion = (compareVersion:any) => {
    const [isExtensionSupport, setIsExtensionSupport] = useState<any>(null);

    useEffect(() => {
        const bodyElement = document.body;
        const handleAttributeChange = () => {
            const extensionVersionString = bodyElement.getAttribute('lp_custom_chrome_extension_version');
            try {
                if (extensionVersionString) {
                    const versionWithoutFirstPart = extensionVersionString.substring(extensionVersionString.indexOf('.') + 1);
                    const isSupported = semver.gte(versionWithoutFirstPart, compareVersion);
                    setIsExtensionSupport(isSupported);
                } else {
                    setIsExtensionSupport(false);
                }
            } catch (e) {
                console.log('error', e);
                setIsExtensionSupport(false);
            }
        };

        const observer = new MutationObserver(() => {
            handleAttributeChange();
        });
        observer.observe(bodyElement, {
            attributes: true,
            attributeFilter: ['lp_custom_chrome_extension_version'],
        });

        // Initial attribute check
        handleAttributeChange();

        return () => {
            observer.disconnect();
        };
    }, [compareVersion]);

    return isExtensionSupport;
};

export default useExtensionVersion;