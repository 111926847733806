const htmlUnescapes: { [key: string]: string } = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#39;': "'",
};

const reDoubleEncodedHtml = /&amp;(\w+);/g;
const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?39);/g;
const reHasEscapedHtml = RegExp(reEscapedHtml.source);

const mps: string[] = [
  'amazon',
  'depop',
  'ebay',
  'etsy',
  'facebook',
  'grailed',
  'instagram',
  'kidizen',
  'mercari',
  'poshmark',
  'shopify',
  'tradesy',
];
const img_placeholder = `${window.location.origin}/images/marketplaces/placeholder.png`;

const useBasicFuncs = () => {
  const bdecode = (s: string | undefined | null) =>
   s && reHasEscapedHtml.test(s)
    ? s.replace(reDoubleEncodedHtml, (m, p1) => htmlUnescapes[m] || htmlUnescapes[`&${p1};`] || "'")
    .replace(reEscapedHtml, (m) => htmlUnescapes[m] || "'")
    .replace(/&#39;/g, "'") // Заменяем кодированное апострофом (&#39;) на обычный апостроф
    : s || '';

  const getMpsImage = (mp: string) => {
    if (!mp || typeof mp !== 'string') return img_placeholder;

    mp = mp.toLowerCase();
    if (!mps.includes(mp)) return img_placeholder;

    return `${window.location.origin}/images/marketplaces/${mp}.png`;
  };

  const bakeRandomString = (length: number, encode = false) => {
    let result = '';
    const charSet = '!@#$%^&*(){}[]+_-~"№;:?';
    const charLen = charSet.length;
    for (let i = 0; i < length; i++)
      result += charSet.charAt(Math.floor(Math.random() * charLen));
    return encode ? encodeURIComponent(result) : result;
  };

  const cryptString = (v: string, tf = 0) =>
    v
      .split('')
      .map((a) => String.fromCharCode(a.charCodeAt(0) + (tf ? 1 : -1)))
      .join('');

  return { bdecode, getMpsImage, bakeRandomString, cryptString };
};

export default useBasicFuncs;
