import styles from './SuggestDescModal.module.scss';
import { NotificationClose } from '../../../assets/icons';
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
// @ts-ignore
import DragSort from '@yaireo/dragsort';
import '@yaireo/dragsort/dist/dragsort.css';
// @ts-ignore
import Tags, { TagifyRef } from '@yaireo/tagify/dist/react.tagify';
// @ts-ignore
import '@yaireo/tagify/dist/tagify.css';
import './tagify.css';
import useBasisFuncs from "../../../hooks/useBasisFuncs";
import {TextareaWithGrowHeight} from "./TextareaGrowHeight";
import {AutoGrowHeightTextarea} from "./AutoGrowHeightTextarea";
import Select from 'react-select';

const baseTagifySettings = {
    blacklist: ['xxx', 'yyy', 'zzz'],
    maxTags: 50,
    //backspace: "edit",
    placeholder: 'type something',
    dropdown: {
        enabled: 0, // a;ways show suggestions dropdown
    },
};

const initialCommonState = {
    brand: '',
    size: '',
    description: '',
    keywords: '',
    color: '',
    title: '',
};

const SuggestDescModal = ({
                              commonState,
                              setCommonState,
                              setGeneratedModal,
                              handleChangeEditInput,
                              assistantTitle,
                              setAssistantTitle,
                              isModal,
                              setAssistantFromTitle,
                              assistantFromTitle
                          }: any) => {
    const maxChars = 700; // Set your desired character limit here
    const tagifyRefDragSort: any = useRef();
    const tagifyRefColor: any = useRef();


    const [tagifySettings, setTagifySettings] = useState([]);
    const [tagifyProps, setTagifyProps] = useState({});
    const [tagValue, setTagValue] = useState('');
    const { bdecode } = useBasisFuncs();

    const highestPriceOption = commonState.stores ? commonState?.stores
        ?.filter((store: any) => store?.price) // Only include stores with a valid price
        .map((store: any) => ({
            value: parseFloat(store?.price?.replace('$', '')), // Extract numeric value from string
            label: store?.price, // Keep original string format for label
        }))
        ?.reduce((highest: any, current: any) => {
            return current.value > highest.value ? current : highest;
        }, { value: null, label: '' }) : [];

    const [selectedPrice, setSelectedPrice] = useState(null);

    const [priceOptions, setPriceOptions] = useState<any[]>(highestPriceOption ? [highestPriceOption] : []);

    const [price, setPrice] = useState({
        value: priceOptions ? priceOptions[0]?.value : null,
        accept: true,
    });

    const [IntendedForItems, setIntendedItems] = useState([
        { id: 1, value: 'Unisex', text: 'Unisex', active: false },
        { id: 2, value: 'Mens', text: 'Men', active: false },
        { id: 3, value: 'Womens', text: 'Women', active: false },
        { id: 4, value: 'Unisex Kids', text: 'Unisex Kids', active: false },
        { id: 5, value: 'Girls', text: 'Girls', active: false },
        { id: 6, value: 'Boys', text: 'Boys', active: false },
        { id: 7, value: 'Babys', text: 'Babies', active: false },
        { id: 8, value: 'Maternity', text: 'Maternity', active: false },
        { id: 9, value: 'Pets', text: 'Pets', active: false },
        { id: 10, value: 'Cats', text: 'Cats', active: false },
        { id: 11, value: 'Dogs', text: 'Dogs', active: false },
        { id: 12, value: '', text: 'None', active: false },
    ]);

    const intendedOptions = IntendedForItems.map(item => ({
        value: item.value,
        label: item.text,
    }));

    const [selectedIntended, setSelectedIntended] = useState<any>({ value: 'None', label:'None' });


    useEffect(() => {
      try{

          if(priceOptions){
              setSelectedPrice(priceOptions[0])
          }

          const intendedForValueExists = IntendedForItems?.some(
              item => (item.value)?.toLowerCase() === (commonState.gender)?.toLowerCase()
          );

          if (intendedForValueExists) {
              // Find the matching item from IntendedForItems
              const matchingItem = IntendedForItems.find(
                  item => (item.value)?.toLowerCase() === (commonState.gender)?.toLowerCase()
              );

              if(matchingItem){
                  // Set the matching item as selected in Select
                  setSelectedIntended({ value: matchingItem.value || 'None', label: matchingItem.text });

                  // Optionally, update gender as well
                  setGender({ value: matchingItem.value, accept: true });
              }

          }
      }
      catch (e){
          console.log(e, 'error')
      }

    }, []);


    // Handle the select change
    const handleChangePrice = (selectedOption:any) => {
        setSelectedPrice(selectedOption);
        setPrice((prevState) => ({
            ...prevState, // Preserve the 'accept' value and update 'value'
            value: selectedOption ? selectedOption.value : '',
        }));
    };

    const handleChangeIntended = (selectedOption:any) => {
        setSelectedIntended(selectedOption);
        setGender((prevState) => ({
            ...prevState, // Preserve the 'accept' value and update 'value'
            value: selectedOption ? selectedOption.value : '',
        }));
    };


    const [images, setImages] = useState({
        value: commonState?.images ? commonState?.images : [],
        accept: true,
    });

    const [shortDescription, setShortDescription] = useState({
        value: commonState.description ? commonState.description : '',
        accept: true,
        charCount: commonState?.description?.length,
    });
    const [brand, setBrand] = useState({
        value: commonState.brand ? commonState.brand : '',
        accept: true,
        charCount: commonState?.brand?.length,
    });
    const [keywordsText, setKeywordsText] = useState({
        value: commonState.keywords ? commonState.keywords : '',
        accept: true,
        charCount: commonState?.keywords?.length,
    });
    const [titleText, setTitleText] = useState({
        value: commonState.title ? commonState.title : '',
        accept: true,
        charCount: assistantTitle
            ? assistantTitle.length
            : commonState.title.length,
    });

    const [sizeText, setSizeText] = useState({
        value: commonState.size ? commonState.size : '',
        accept: true,
        charCount: commonState.size.length,
    });
    const [color, setColor] = useState({
        value: commonState.color ? commonState.color : '',
        accept: true,
        charCount: commonState?.color?.length,
    });

    const [material, setMaterial] = useState({
        value: commonState.material ? commonState.material : '',
        accept: true,
        charCount: commonState?.material?.length || 0,
    });

    const [upc, setUpc] = useState({
        value: commonState.barcode_number ? commonState.barcode_number : '',
        accept: true,
        charCount: commonState.upc?.length || 0,
    });

    const [condition, setCondition] = useState({
        value: commonState.condition ? commonState.condition : '',
        text: commonState.condition ? commonState.condition : '',
        accept: true,
    });

    const [style, setStyle] = useState({
        value: commonState.style ? commonState.style : '',
        accept: true,
        charCount: commonState.style?.length || 0,
    });

    const [madeIn, setMadeIn] = useState({
        value: commonState.madeIn ? commonState.madeIn : '',
        accept: true,
        charCount: commonState.madeIn?.length || 0,
    });

    const [shippingWeightlbkg, setShippingWeightlbkg] = useState({
        value: commonState.shippingWeightlbkg ? commonState.shippingWeightlbkg : '',
        accept: true,
        charCount: commonState.shippingWeightlbkg?.length || 0,
    });

    const [shippingWeightOzg, setShippingWeightOzg] = useState({
        value: commonState.shippingWeightOzg ? commonState.shippingWeightOzg : '',
        accept: true,
        charCount: commonState.shippingWeightOzg?.length || 0,
    });

    const [subCondition, setSubCondition] = useState({
        value: commonState.subCondition ? commonState.subCondition : '',
        text: commonState.subCondition ? commonState.subCondition : '',
        accept: true,
        charCount: commonState.subCondition?.length || 0,
    });

    const [preOwnedCondition, setPreOwnedCondition] = useState({
        value: commonState.preOwnedCondition ? commonState.preOwnedCondition : '',
        text: commonState.preOwnedCondition ? commonState.preOwnedCondition : '',
        accept: true,
        charCount: commonState.preOwnedCondition?.length || 0,
    });

    const [conditionNotes, setConditionNotes] = useState({
        value: commonState.conditionNotes ? commonState.conditionNotes : '',
        accept: true,
        charCount: commonState.conditionNotes?.length || 0,
    });

    const [patternOrTexture, setPatternOrTexture] = useState({
        value: commonState.patternOrTexture ? commonState.patternOrTexture : '',
        accept: true,
        charCount: commonState.patternOrTexture?.length || 0,
    });

    const [care, setCare] = useState({
        value: commonState.care ? commonState.care : '',
        accept: true,
        charCount: commonState.care?.length || 0,
    });

    const [seasonOrWeather, setSeasonOrWeather] = useState({
        value: commonState.seasonOrWeather ? commonState.seasonOrWeather : '',
        accept: true,
        charCount: commonState.seasonOrWeather?.length || 0,
    });

    const [gender, setGender] = useState(() => {
        const intendedForValueExists = IntendedForItems.some(
            item => (item.value)?.toLowerCase() === (commonState.gender)?.toLowerCase()
        );

        return {
            value: intendedForValueExists ? commonState.gender : '',
            accept: true,
        };
    });

    const customStylesSelect = {
        control: (provided: any) => ({
            ...provided,
            width: '100%',
            cursor: 'pointer'
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex: 9991,
        }),
    };
    

    const closeModal = () => {
        setAssistantTitle('');
        setAssistantFromTitle(false);
        setCommonState(initialCommonState);
        setGeneratedModal(false);
        setImages({
            value: [],
            accept: false,
        });
        setShortDescription({
            value: '',
            accept: false,
            charCount: 0,
        });
        setPrice({
            value: null,
            accept: false,
        });
        setKeywordsText({
            value: '',
            accept: false,
            charCount: 0,
        });
        setTitleText({
            value: '',
            accept: false,
            charCount: 0,
        });
        setBrand({
            value: '',
            accept: false,
            charCount: 0,
        });
        setSizeText({
            value: '',
            accept: false,
            charCount: 0,
        });
        setColor({
            value: '',
            accept: false,
            charCount: 0,
        });
    };

    const handleResetInputType = (inputName: string) => {
        if (inputName === 'images') {
            setImages({
                value: [],
                accept: false,
            });
        }
        else if (inputName === 'description') {
            setShortDescription({
                value: '',
                accept: false,
                charCount: 0,
            });
        }
        else if (inputName === 'price') {
            setPrice({
                value: null,
                accept: false,
            });
        }
        else if (inputName === 'keywords') {
            setKeywordsText({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'title') {
            setTitleText({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'brand') {
            setBrand({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'size') {
            setSizeText({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'color') {
            setColor({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'material'){
            setMaterial({
                value: '',
                accept: false,
                charCount: 0,
            })
        } else if (inputName === 'upc') {
            setUpc({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'condition') {
            setCondition({
                value: '',
                text: commonState.condition,
                accept: false,
            });
        } else if (inputName === 'style') {
            setStyle({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'madeIn') {
            setMadeIn({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'shippingWeightlbkg') {
            setShippingWeightlbkg({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'shippingWeightOzg') {
            setShippingWeightOzg({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'subCondition') {
            setSubCondition({
                value: '',
                text: commonState.subCondition,
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'preOwnedCondition') {
            setPreOwnedCondition({
                value: '',
                text: commonState.preOwnedCondition,
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'conditionNotes') {
            setConditionNotes({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'patternOrTexture') {
            setPatternOrTexture({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'care') {
            setCare({
                value: '',
                accept: false,
                charCount: 0,
            });
        } else if (inputName === 'seasonOrWeather') {
            setSeasonOrWeather({
                value: '',
                accept: false,
                charCount: 0,
            });
        }
        else if (inputName === 'gender') {
            setGender({
                value: '',
                accept: false,
            });
            setSelectedIntended({ value: 'None', label: 'None' });
        }
    };

    useEffect(() => {
        setTagifyProps((lastProps) => ({
            ...lastProps,
            showFilteredDropdown: 'a',
            loading: false,
        }));

        setTimeout(
            () =>
                setTagifyProps((lastProps) => ({
                    ...lastProps,
                    defaultValue: ['abc'],
                    showFilteredDropdown: false,
                })),
            5000
        );
    }, []);

    const settings = {
        ...baseTagifySettings,
        ...tagifySettings,
    };

    const onChange = useCallback((e: any) => {
        try {
            const parsedValue = JSON.parse(e.detail.value);
            setKeywordsText((prev: any) => ({
                ...prev,
                value: parsedValue,
                charCount: parsedValue.length,
            }));
            handleTextareaChange('keywords', parsedValue);
        } catch (e) {
            console.log('error', e);
        }
    }, []);

    const onChangeColor = useCallback((e: any) => {
        try {
            const parsedValue = JSON.parse(e.detail.value);
            setColor((prev: any) => ({
                ...prev,
                value: parsedValue,
                charCount: parsedValue.length,
            }));
            handleTextareaChange('color', parsedValue);
        } catch (e) {
            console.log('error', e);
        }
    }, []);


    useMemo(() => {
        if (tagifyRefDragSort.current)
            new DragSort(tagifyRefDragSort.current.DOM.scope, {
                selector: '.tagify__tag',
                callbacks: {
                    dragEnd: onDragEnd,
                },
            });
    }, [tagifyRefDragSort.current]);

    useMemo(() => {
        if (tagifyRefColor?.current)
            new DragSort(tagifyRefColor.current.DOM.scope, {
                selector: '.tagify__tag',
                callbacks: {
                    dragEnd: onDragEndColor,
                },
            });
    }, [tagifyRefColor?.current]);



    // must update Tagify's value according to the re-ordered nodes in the DOM
    function onDragEnd() {
        tagifyRefDragSort.current.updateValueByDOMTags();
    }
    function onDragEndColor() {
        tagifyRefColor.current.updateValueByDOMTags();
    }

    const handleTextareaChange = (
        type: 'description'
            | 'keywords'
            | 'title'
            | 'brand'
            | 'size'
            | 'color'
            | 'material'
            | 'upc'
            | 'condition'
            | 'style'
            | 'madeIn'
            | 'shippingWeightlbkg'
            | 'shippingWeightOzg'
            | 'subCondition'
            | 'preOwnedCondition'
            | 'conditionNotes'
            | 'patternOrTexture'
            | 'care'
            | 'seasonOrWeather'
            | 'gender',
        value: string
    ) => {
        if (type === 'description') {
            if (value.length <= maxChars) {
                setShortDescription({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'keywords') {
            if (value.length <= maxChars) {
                setKeywordsText({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'title') {
            if (value.length <= 250) {
                setTitleText({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'brand') {
            if (value.length <= 250) {
                setBrand({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'size') {
            if (value.length <= 250) {
                setSizeText({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'color') {
            if (value.length <= 250) {
                setColor({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        }
    else
        if (type === 'material') {
            if (value.length <= 250) {
                setMaterial({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'upc') {
            if (value.length <= 250) {
                setUpc({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'condition') {
            if (value.length <= 250) {
                setCondition({
                    value: value,
                    text: value,
                    accept: true,
                });
            }
        } else if (type === 'style') {
            if (value.length <= 250) {
                setStyle({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'madeIn') {
            if (value.length <= 250) {
                setMadeIn({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'shippingWeightlbkg') {
            if (value.length <= 250) {
                setShippingWeightlbkg({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'shippingWeightOzg') {
            if (value.length <= 250) {
                setShippingWeightOzg({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'subCondition') {
            if (value.length <= 250) {
                setSubCondition({
                    value: value,
                    text: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'preOwnedCondition') {
            if (value.length <= 250) {
                setPreOwnedCondition({
                    value: value,
                    text: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'conditionNotes') {
            if (value.length <= 250) {
                setConditionNotes({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'patternOrTexture') {
            if (value.length <= 250) {
                setPatternOrTexture({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'care') {
            if (value.length <= 250) {
                setCare({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        } else if (type === 'seasonOrWeather') {
            if (value.length <= 250) {
                setSeasonOrWeather({
                    value: value,
                    accept: true,
                    charCount: value.length,
                });
            }
        }
    };

    let resultArray:any;

    try {
        if(typeof keywordsText?.value === 'string') {
            const wordsArray = keywordsText?.value?.split(',');
            resultArray = wordsArray?.map((word:any) => ({ value: word }));
        }
        else {
            resultArray = null;
        }
    }
    catch (e) {
        console.log(e, 'error')
    }

    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => {
            document.body.classList.remove('overflow-hidden');
            setAssistantFromTitle(false);
        }
    }, []);


    return (
        <div className={`${styles.modalContainer} ${isModal ? styles.isModal : ''}`} id="descModal">
            <div className={styles.modal}>
                <div className={styles.closeIcon} onClick={closeModal}>
                    <NotificationClose/>
                </div>
                <h1>Confirm Listing Details</h1>
                <div className={styles.innerContainer}>
                    <div className={styles.scrollContainerDescModal}>
                        {(images?.value && images?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Images</p>
                                <div
                                    className={`${styles.detailSection} ${styles.detailsSectionUp}`}
                                >
                                    <div className={styles.suggestModalContainer}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '10px',
                                            }}
                                        >
                                            {images?.value?.map((image:any, index:any) => (
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`image-${index}`}
                                                    style={{
                                                        width: '150px',
                                                        height: '150px',
                                                        borderRadius: '8px',
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('images')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {Boolean(price?.value) && (
                            <>
                                <p className={styles.textAreaTitle}>Price</p>
                                <div
                                    className={`${styles.detailSection} ${styles.detailsSectionUp}`}
                                >
                                    <div className={styles.suggestModalContainer}>
                                        <Select
                                            value={selectedPrice} // Current selected value
                                            options={priceOptions} // Options from store prices
                                            onChange={handleChangePrice} // Handle selection
                                            styles={customStylesSelect}
                                        />
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('price')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {((shortDescription?.value && shortDescription?.value?.length > 0) || assistantFromTitle) && (
                            <>
                                <p className={styles.textAreaTitle}>Short Description</p>
                                <div
                                    className={`${styles.detailSection} ${styles.detailsSectionUp}`}
                                >
                                    <div className={styles.suggestModalContainer}>
                                        <AutoGrowHeightTextarea
                                            placeholder=""
                                            name="input_505"
                                            value={shortDescription.value ?  bdecode(shortDescription.value) : ''}
                                            title={'description'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={true}
                                            limit={5000}
                                        />
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('description')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {(titleText?.value && titleText?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Listing Title</p>
                                <div className={styles.detailSection}>
                                    <div className={styles.suggestModalContainer}>
                                        <TextareaWithGrowHeight
                                            placeholder=""
                                            name="input_133"
                                            value={bdecode(titleText.value)}
                                            title={'title'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={true}
                                            limit={140}
                                        />
                                    </div>
                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('title')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {((brand?.value && brand?.value?.length > 0) || assistantFromTitle) && (
                            <>
                                <p className={styles.textAreaTitle}>Brand</p>
                                <div
                                    className={`${styles.detailSection} ${styles.detailsSectionUp}`}
                                >
                                    <div className={styles.suggestModalContainer}>
                                        <TextareaWithGrowHeight
                                            placeholder=""
                                            name="input_206"
                                            value={brand.value ? bdecode(brand.value) : ''}
                                            title={'brand'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={false}
                                            limit={250}
                                        />

                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('brand')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {((sizeText?.value && sizeText?.value?.length > 0) || assistantFromTitle) && (
                            <>
                                <p className={styles.textAreaTitle}>Size</p>
                                <div
                                    className={`${styles.detailSection} ${styles.detailsSectionUp}`}
                                >
                                    <div className={styles.suggestModalContainer}>
                                        <input
                                            name="input_213"
                                            value={sizeText.value ? bdecode(sizeText.value) : ''}
                                            className={styles.input}
                                            height={100}
                                            onChange={(e) =>
                                                handleTextareaChange('size', e.target.value)
                                            }
                                        />
                                        <p className={styles.charactersCount}>
                                            Character Count: {sizeText.charCount}/{maxChars}
                                        </p>
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('size')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {(color?.value && color?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Color</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <Tags
                                            tagifyRef={tagifyRefColor} // you need a separate ref for color tags
                                            onChange={onChangeColor}   // handle changes for color tags
                                            defaultValue={bdecode(color.value)} // pass the color value here
                                            settings={{
                                                templates: {
                                                    tag: (tagData: any, {settings: _s}: any) => {
                                                        const valueName = (tagData.title || tagData.value);
                                                        const valueLength = bdecode(valueName).length;
                                                        const isLongValue = valueLength > 20;
                                                        return `<tag title="${tagData.title || tagData.value}"
                                                      contenteditable='false'
                                                      spellcheck='false'
                                                      tabIndex="${_s.a11y.focusableTags ? 0 : -1}"
                                                      class="${_s.classNames.tag} ${tagData.class || ''}  ${
                                                            isLongValue ? 'limited' : ''}"  ${tagData}>
                                                      <x title='' class="${_s.classNames.tagX}" role='button' aria-label='remove tag'></x>
                                                      <div>
                                                      <span class="${_s.classNames.tagText}">${tagData[_s.tagTextProp] || tagData.value}</span>
                                                      </div>
                                                      </tag>`;
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('color')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {((keywordsText?.value && keywordsText?.value?.length > 0) || assistantFromTitle) && (
                            <>
                                <p className={styles.textAreaTitle}>Keywords</p>
                                <p className={styles.keywordsCount}>
                                    Keywords with more than 20 characters will be highlighted red
                                </p>
                                <div className={styles.detailSection}>
                                    <div className={styles.suggestModalContainer}>
                                        <Tags
                                            tagifyRef={tagifyRefDragSort}
                                            onChange={onChange}
                                            defaultValue={keywordsText.value ? bdecode(keywordsText.value) : ''}
                                            value={keywordsText.value ? bdecode(keywordsText.value) : ''}
                                            settings={{
                                                templates: {
                                                    tag: (tagData: any, {settings: _s}: any) => {
                                                        const valueName = (tagData.title || tagData.value);
                                                        const valueLength = bdecode(valueName).length;
                                                        const isLongValue = valueLength > 20;
                                                        return `<tag title="${tagData.title || tagData.value}"
                                                        contenteditable='false'
                                                        spellcheck='false'
                                                        tabIndex="${_s.a11y.focusableTags ? 0 : -1}"
                                                        class="${_s.classNames.tag} ${tagData.class || ''}  ${
                                                            isLongValue ? 'limited' : ''}"  ${tagData}>
                                                          <x title='' class="${_s.classNames.tagX}" role='button' aria-label='remove tag'></x>
                                                            <div>
                                                                <span class="${_s.classNames.tagText}">${tagData[_s.tagTextProp] || tagData.value}</span>
                                                            </div>
                                                       </tag>`;
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('keywords')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {((material?.value && material?.value?.length > 0) || assistantFromTitle) && (
                            <>
                                <p className={styles.textAreaTitle}>Material</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <TextareaWithGrowHeight
                                            placeholder=""
                                            name="input_82"
                                            value={material.value ? bdecode(material.value) : ''}
                                            title={'material'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={false}
                                            limit={250}
                                        />
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('material')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {/*{(upc?.value && upc?.value?.length > 0 )&& (*/}
                        {/*    <>*/}
                        {/*        <p className={styles.textAreaTitle}>UPC</p>*/}
                        {/*        <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>*/}
                        {/*            <div className={styles.suggestModalContainer}>*/}
                        {/*                <input*/}
                        {/*                    name="input_704"*/}
                        {/*                    value={bdecode(upc.value)}*/}
                        {/*                    className={styles.input}*/}
                        {/*                    onChange={(e) => handleTextareaChange('upc', e.target.value)}*/}
                        {/*                />*/}
                        {/*                <p className={styles.charactersCount}>*/}
                        {/*                    Character Count: {upc.charCount}/{maxChars}*/}
                        {/*                </p>*/}
                        {/*            </div>*/}

                        {/*            <div className={styles.confirmationButtons}>*/}
                        {/*                <button*/}
                        {/*                    className={styles.confirmationButtonRefuce}*/}
                        {/*                    onClick={() => handleResetInputType('upc')}*/}
                        {/*                >*/}
                        {/*                    <NotificationClose/>*/}
                        {/*                </button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*)}*/}

                        {(condition?.text && condition?.text?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Condition</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        {/* Toggle button */}
                                        <button
                                            className={`${styles.toggleButtonCondition} ${condition.value ? styles.active : styles.inactive}`}
                                            onClick={() =>
                                                setCondition((prev) => ({
                                                    ...prev,
                                                    value: prev.value ? '' : commonState.condition, // toggle between clearing and restoring value
                                                }))
                                            }
                                        >
                                            {bdecode(condition.text)}
                                        </button>

                                    </div>
                                </div>
                            </>
                        )}

                        {(subCondition?.text && subCondition?.text?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Sub Condition</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <button
                                            className={`${styles.toggleButtonCondition} ${subCondition.value ? styles.active : styles.inactive}`}
                                            onClick={() =>
                                                setSubCondition((prev) => ({
                                                    ...prev,
                                                    value: prev.value ? '' : commonState.subCondition, // toggle between clearing and restoring value
                                                }))
                                            }
                                        >
                                            {bdecode(subCondition.text)}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {(preOwnedCondition?.text && preOwnedCondition?.text?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Pre-Owned Condition</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <button
                                            className={`${styles.toggleButtonCondition} ${preOwnedCondition.value ? styles.active : styles.inactive}`}
                                            onClick={() =>
                                                setPreOwnedCondition((prev) => ({
                                                    ...prev,
                                                    value: prev.value ? '' : commonState.preOwnedCondition, // toggle between clearing and restoring value
                                                }))
                                            }
                                        >
                                            {bdecode(preOwnedCondition.text)}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {(style?.value && style?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Style</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <TextareaWithGrowHeight
                                            placeholder=""
                                            name="input_205"
                                            value={bdecode(style.value)}
                                            title={'style'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={false}
                                            limit={250}
                                        />
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('style')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {(madeIn?.value && madeIn?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Made In</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <TextareaWithGrowHeight
                                            placeholder=""
                                            name="input_211"
                                            value={bdecode(madeIn.value)}
                                            title={'madeIn'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={false}
                                            limit={250}
                                        />
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('madeIn')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {(shippingWeightlbkg?.value && shippingWeightlbkg?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Shipping Weight (lb/kg)</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <input
                                            name="input_2"
                                            value={bdecode(shippingWeightlbkg.value)}
                                            className={styles.input}
                                            onChange={(e) => handleTextareaChange('shippingWeightlbkg', e.target.value)}
                                        />
                                        <p className={styles.charactersCount}>
                                            Character Count: {shippingWeightlbkg.charCount}/{maxChars}
                                        </p>
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('shippingWeightlbkg')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {(shippingWeightOzg?.value && shippingWeightOzg?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Shipping Weight (Oz/g)</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <input
                                            name="input_3"
                                            value={bdecode(shippingWeightOzg.value)}
                                            className={styles.input}
                                            onChange={(e) => handleTextareaChange('shippingWeightOzg', e.target.value)}
                                        />
                                        <p className={styles.charactersCount}>
                                            Character Count: {shippingWeightOzg.charCount}/{maxChars}
                                        </p>
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('shippingWeightOzg')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {(conditionNotes?.value && conditionNotes?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Condition Notes</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <AutoGrowHeightTextarea
                                            placeholder=""
                                            name="input_17"
                                            value={bdecode(conditionNotes.value)}
                                            title={'conditionNotes'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={false}
                                            limit={30000}
                                        />
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('conditionNotes')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {(patternOrTexture?.value && patternOrTexture?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Pattern or Texture</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <TextareaWithGrowHeight
                                            placeholder=""
                                            name="input_210"
                                            value={bdecode(patternOrTexture.value)}
                                            title={'patternOrTexture'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={false}
                                            limit={250}
                                        />
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('patternOrTexture')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {(care?.value && care?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Care</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <TextareaWithGrowHeight
                                            placeholder=""
                                            name="input_43"
                                            value={bdecode(care.value)}
                                            title={'care'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={false}
                                            limit={250}
                                        />

                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('care')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {(seasonOrWeather?.value && seasonOrWeather?.value?.length > 0) && (
                            <>
                                <p className={styles.textAreaTitle}>Season or Weather</p>
                                <div className={`${styles.detailSection} ${styles.detailsSectionUp}`}>
                                    <div className={styles.suggestModalContainer}>
                                        <TextareaWithGrowHeight
                                            placeholder=""
                                            name="input_36"
                                            value={bdecode(seasonOrWeather.value)}
                                            title={'seasonOrWeather'}
                                            handleChange={handleTextareaChange}
                                            hasLimit={false}
                                            limit={250}
                                        />

                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('seasonOrWeather')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {(Boolean(gender?.value) || assistantFromTitle) && (
                            <>
                                <p className={styles.textAreaTitle}>Intended For</p>
                                <div
                                    className={`${styles.detailSection} ${styles.detailsSectionUp}`}
                                >
                                    <div className={styles.suggestModalContainer}>
                                        <Select
                                            value={selectedIntended} // Current selected value
                                            options={intendedOptions} // Options from store prices
                                            onChange={handleChangeIntended} // Handle selection
                                            styles={customStylesSelect}
                                            menuPlacement="top"
                                        />
                                    </div>

                                    <div className={styles.confirmationButtons}>
                                        <button
                                            className={styles.confirmationButtonRefuce}
                                            onClick={() => handleResetInputType('gender')}
                                        >
                                            <NotificationClose/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}


                    </div>

                </div>
                <div className={styles.footerButtons}>
                    <input
                        name="accept"
                        type="button"
                        value="Accept"
                        className={styles.confirmationButtonAccept}
                        onClick={(e) => {
                            const keywrods = resultArray ? resultArray : keywordsText.value;
                            setCommonState(initialCommonState);
                            setAssistantTitle('');

                            handleChangeEditInput(
                                e,
                                shortDescription.value,
                                keywrods,
                                titleText.value,
                                brand.value,
                                sizeText.value,
                                color.value,
                                titleText.value,
                                material.value,
                                upc.value,
                                condition.value,
                                style.value,
                                madeIn.value,
                                shippingWeightlbkg.value,
                                shippingWeightOzg.value,
                                subCondition.value,
                                preOwnedCondition.value,
                                conditionNotes.value,
                                patternOrTexture.value,
                                care.value,
                                seasonOrWeather.value,
                                gender.value,
                                images.value,
                                price?.value
                            );
                            closeModal();
                        }}
                    />
                    <button
                        className={styles.confirmationButtonRefuce}
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SuggestDescModal;