export const formatWord = (word: string) => {
  const parts = word.split('_');

  const formattedParts = parts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );

  const formattedWord = formattedParts.join(' ');

  return formattedWord;
};
