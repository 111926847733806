const toSnakeToTitle = (input: string, mode: 'to_snake' | 'to_title'): any  => {
	if (mode === 'to_snake') {
		return input
		.replace(/&/g, '') // Удаление амперсандов
		.split(' ')
		.map(word => word.toLowerCase())
		.join('_');
	} else if (mode === 'to_title') {
		return input
		.split('_')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
	} else {
		throw new Error('Invalid mode. Use "to_snake" or "to_title".');
	}
}

export default toSnakeToTitle