import {Helmet} from "react-helmet";
import React from "react";

const NotFound = () => {
  return (
   <>
     <Helmet>
       <title>Not Found | List Perfectly</title>
     </Helmet>
     <div>page not found</div>;
   </>
  )
};

export default NotFound;
