import React, {ReactNode} from 'react' ;
import styles from './Button.module.scss' ;
import {MoonLoader} from "react-spinners";

export enum ModifierType {
	Primary = 'primary',
	Primary2 = 'primary2',
	Secondary = 'secondary',
	Error = 'error',
	Transparent = 'transparent'
}

interface IButtonProps {
	children: ReactNode;
	handleClick?: () => void;
	modifier?: ModifierType | undefined;
	disabled?:boolean;
	style?:React.CSSProperties;
	isLoading?:boolean;
}

const Button = (props:IButtonProps) => {
	const {children,handleClick, modifier,disabled,style,isLoading} = props;

	const className = `${styles['button']} ${modifier ? styles[`button--${modifier}`] : ''} ${disabled ? styles['button--disabled'] : ''}`;

	const onClickHandler = isLoading ? undefined : handleClick;


	return (
	 <button style={style} className={className} onClick={onClickHandler} aria-disabled={disabled}>
		 {isLoading && 	(<MoonLoader color="#fff" loading={true} size={16}/>)}
		 {children}
	 </button>
	)
}

export default Button