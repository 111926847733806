import React, { useState, forwardRef, useRef, useEffect } from 'react';

import { Popover } from 'react-tiny-popover';

import styles from '../../Table.module.scss';

import { ICatalogColumn } from '../../../../../../interfaces';
import {
  SVGClearSort,
  SVGNewAsc,
  SVGNewDesc,
} from '../../../../../../assets/icons';
import ColumnSearch from '../ColumnSearch';

interface ISortData {
  id: number;
  title: string;
}

const sortData: ISortData[] = [
  {
    id: Date.now(),
    title: 'ascending',
  },
  {
    id: Date.now(),
    title: 'descending',
  },
  {
    id: Date.now(),
    title: 'clear Sort',
  },
];

interface ItableResizeColumnProps {
  col: ICatalogColumn;
  id: string;
  handleChangeDirection: (
    columnId: string,
    direction: 'top' | 'bottom' | 'none'
  ) => void;
  resizableElementWidth: number;
  setResizableElementWidth: (width: number) => void;
  innerRef: any;
  activeIndex: number | null;
  i: number;
  mouseDown: (index: number) => void;
  directions: { [columnId: string]: 'top' | 'bottom' | 'none' };
  filterByArr: any;
  handleTogglePlatformsModalShow: any;
  activePlatforms: any;
  inputValues: any;
  handleInputChange: any;
  handleToggleDateRangeModalShow:any;
  dateRangeModalShow?:any;
  startChangedCreated?:any;
  endChangedCreated?:any;
  startDateCreated?:any;
  endDateCreated?:any;
  setStartChangedCreated?:any;
  setEndChangedCreated?:any;
  setStartDateCreated?:any;
  setEndDateCreated?:any;
  createdTippy?:any;
  setCreatedCloseClicked?:any;
}

const TableResizeColumn = forwardRef((props: ItableResizeColumnProps, ref) => {
  const {
    col,
    handleChangeDirection,
    id,
    resizableElementWidth,
    setResizableElementWidth,
    innerRef,
    activeIndex,
    i,
    mouseDown,
    directions,
    filterByArr,
    handleTogglePlatformsModalShow,
    activePlatforms,
    inputValues,
    handleInputChange,
    handleToggleDateRangeModalShow,
    dateRangeModalShow,
    startChangedCreated,
    endChangedCreated,
    startDateCreated,
    endDateCreated,
    setStartChangedCreated,
    setEndChangedCreated,
    setStartDateCreated,
    setEndDateCreated,
    createdTippy,
    setCreatedCloseClicked,
  } = props;

  const sortRef = useRef<HTMLDivElement | null>(null);

  const [sortTollTipShow, setSortToolTipShow] = useState<boolean>(false);

  const handleToggleSortToolTipShow = () => {
    setSortToolTipShow(!sortTollTipShow);
  };

  const direction = directions[id] || 'none';

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (sortRef.current && !sortRef.current?.contains(event.target as Node)) {
        setSortToolTipShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sortRef, setSortToolTipShow]);

  return (
    <>
      {col.value !== 'actions' ? (
        col.value === 'drafts' ? (
          <th
            ref={innerRef}
            className="sticky-element"
            style={{ position: 'sticky', top: '0' }}
          >
            draft
            <div
              style={{
                height: '100%',
                position: 'absolute',
                zIndex: 534534,
              }}
              onMouseDown={() => mouseDown(i)}
              className={`resize-handle ${
                activeIndex === i ? 'active' : 'idle'
              }`}
            />
          </th>
        ) : col.value === 'issues' ? (
          <th
            ref={innerRef}
            className="sticky-element"
            style={{ position: 'sticky', top: '0', background: '#F4F7FF' }}
          >
            {col.value}
            <div
              style={{
                height: '100%',
                position: 'absolute',
                zIndex: 534534,
              }}
              onMouseDown={() => mouseDown(i)}
              className={`resize-handle ${
                activeIndex === i ? 'active' : 'idle'
              }`}
            />
          </th>
        ) : (
          <th
            ref={innerRef}
            className="sticky-element"
            style={{
              position: 'sticky',
              top: '0',
              background: '#F4F7FF',
            }}
          >
            {col.value === 'keywords' ||
            col.value === 'platforms' ||
            col.value === 'description' ||
            col.value === 'date sold' ||
            col.value === 'fees' ||
            col.value === 'assignee' ||
            col.value === 'sold price' ||
            col.value === 'shipping cost' ||
            col.value === 'profit' ||
            col.value === 'cogs' ||
            col.value === 'upc' ||
            col.value === 'brand' ||
            col.value === 'color' ||
            col.value === 'size' ||
            col.value === 'material' ||
            col.value === 'condition' || col.value === 'notes' || col.value ==='sales' || col.value === 'zip' || col.value === 'shipping package length' || col.value === 'shipping package height'   || col.value === 'shipping package width'   || col.value === 'shipping weight oz/g' || col.value === 'shipping weight oz/g' || col.value === 'shipping weight lb/kg' ? (
              <span className={styles.flexHeaderOptimizer}>
                <p>{col.heading}</p>
                {col.value === 'platforms'
                  ? null
                  : direction !== 'none' && (
                      <div className={styles.selectedSortView}>
                        {direction === 'bottom' ? (
                          <div className={styles.caretUp}>
                            <SVGNewDesc />
                          </div>
                        ) : (
                          <SVGNewAsc />
                        )}
                      </div>
                    )}
              </span>
            ) : (
              <Popover
                isOpen={sortTollTipShow}
                positions={['bottom']}
                reposition={true}
                padding={15}
                containerStyle={{ zIndex: '999' }}
                content={
                  sortTollTipShow ? (
                    <div className="popover popoverCatalog" ref={sortRef}>
                      {sortData.map((item) => (
                        <div className={styles.popOverWrapper}>
                          <div
                            style={{
                              gap: item.title === 'clear Sort' ? 4 : 10,
                              marginBottom:
                                item.title === 'clear Sort' ? 0 : 10,
                            }}
                            className={styles.popoverSortItem}
                            key={item.id}
                            onClick={() => {
                              handleChangeDirection(
                                col.value,
                                item.title === 'clear Sort'
                                  ? 'none'
                                  : item.title === 'ascending'
                                  ? 'top'
                                  : 'bottom'
                              );

                              handleToggleSortToolTipShow();
                            }}
                          >
                            <div>
                              {item.title === 'clear Sort' ? (
                                <SVGClearSort />
                              ) : item.title === 'descending' ? (
                                <SVGNewDesc />
                              ) : (
                                <SVGNewAsc />
                              )}
                            </div>
                            <div>{item.title}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div></div>
                  )
                }
              >
                <span
                  onClick={handleToggleSortToolTipShow}
                  className={styles.flexHeaderOptimizer}
                >
                  <p>{col.heading}</p>

                  {col.value === 'platforms'
                    ? null
                    : direction !== 'none' && (
                        <div className={styles.selectedSortView}>
                          {direction === 'bottom' ? (
                            <div className={styles.caretUp}>
                              <SVGNewAsc />
                            </div>
                          ) : (
                            <SVGNewDesc />
                          )}
                        </div>
                      )}
                </span>
              </Popover>
            )}

            <div
              style={{
                height: '100%',
                position: 'absolute',
                zIndex: 534534,
              }}
              onMouseDown={() => mouseDown(i)}
              className={`resize-handle ${
                activeIndex === i ? 'active' : 'idle'
              }`}
            />
            {col.value !== 'image' &&
              col.value !== 'updated' &&
              col.value !== 'drafts' &&
              col.value !== 'actions' &&
              col.value !== 'keywords' &&
              col.value !== 'date sold' &&
              col.value !== 'size' &&
              col.value !== 'sold price' &&
              col.value !== 'profit' &&
              col.value !== 'assignee' &&
              col.value !== 'shipping cost' &&
              col.value !== 'brand' &&
              col.value !== 'material' &&
              col.value !== 'cogs' &&
              col.value !== 'color' &&
              col.value !== 'condition' && col.value !=='sales' && col.value !== 'shipping package width' && col.value !== 'shipping package length'
             && col.value !== 'shipping package height' && col.value !== 'shipping package height' && col.value !== 'shipping weight lb/kg' &&
             col.value !== 'shipping weight oz/g' &&
             col.value !== 'zip' &&
             (
                <ColumnSearch
                  filterByArr={filterByArr}
                  columnValue={col.value}
                  handleTogglePlatformsModalShow={
                    handleTogglePlatformsModalShow
                  }
                  activePlatforms={activePlatforms}
                  inputValues={inputValues}
                  handleInputChange={handleInputChange}
                  createdTippy={createdTippy}
                  startChangedCreated={startChangedCreated}
                  endChangedCreated={endChangedCreated}
                  startDateCreated={startDateCreated}
                  endDateCreated={endDateCreated}
                  setStartChangedCreated={setStartChangedCreated}
                  setEndChangedCreated={setEndChangedCreated}
                  setStartDateCreated={setStartDateCreated}
                  setEndDateCreated={setEndDateCreated}
                  dateRangeModalShow={dateRangeModalShow}
                  handleToggleDateRangeModalShow={handleToggleDateRangeModalShow}
                  setCreatedCloseClicked={setCreatedCloseClicked}
                />
              )}
          </th>
        )
      ) : (
        <th
          ref={innerRef}
          className="sticky-element"
          style={{ position: 'sticky', top: '0', background: '#F4F7FF' }}
        >
          {col.value}
          <div
            style={{
              height: '100%',
              position: 'absolute',
              zIndex: 534534,
            }}
            onMouseDown={() => mouseDown(i)}
            className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
          />
        </th>
      )}
    </>
  );
});

export default TableResizeColumn;
