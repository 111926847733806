import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './PerPageSelect.module.scss';
import { IMetadata } from '../../../interfaces';
import {
  setSecureModalWhenChangePage,
  setShowPerPageMessage
} from "../../../redux/slices/NavigationSlice";
import {useDispatch} from "react-redux";

const arrowUrl = `url("https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/chevron_down_light.svg")`;

const perPageNumbers = [
  {
    id: Date.now(),
    titleNum: 10,
  },
  {
    id: Date.now(),
    titleNum: 25,
  },
  {
    id: Date.now(),
    titleNum: 50,
  },
  {
    id: Date.now(),
    titleNum: 100,
  },
];

interface IPerPageSelect {
  handleGetDataPerPage: (num: number) => void;
  metadata?: IMetadata;
  type: string;
  saveActive?:boolean;
}

const PerPageSelect = (props: IPerPageSelect) => {
  const { handleGetDataPerPage, metadata, type, saveActive } = props;
  const [isOpenPerPageSelect, setIsOpenPerPageSelect] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const [perPageSelectTitle, setPerPageSelectTitle] = useState<number>(
    Number(localStorage.getItem(`${type}PerPage`)) ||
      (metadata && metadata.limit) ||
      10
  );

  const perPageSelectRef = useRef<HTMLDivElement>(null);

  const handleTogglePerPageSelect = useCallback(() => {
    setIsOpenPerPageSelect(!isOpenPerPageSelect);
    let toolbar: any = document.getElementById('salesElement');
    if (toolbar) {
      toolbar.style.zIndex = 999;
    }
  }, [isOpenPerPageSelect]);

  const handleChangePerPageSelectTitle = (titleNum: number) => {
    if(saveActive) {
      dispatch(setSecureModalWhenChangePage(true));
      dispatch(setShowPerPageMessage(true));
    }
    else {
      handleGetDataPerPage(titleNum);
      setPerPageSelectTitle(titleNum);
      localStorage.setItem(`${type}PerPage`, JSON.stringify(titleNum));
      setIsOpenPerPageSelect(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        perPageSelectRef.current &&
        !perPageSelectRef.current.contains(event.target as Node)
      ) {
        setIsOpenPerPageSelect(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [perPageSelectRef, handleTogglePerPageSelect]);

  return (
    <div
      ref={perPageSelectRef}
      className={styles.perPage}
      onClick={handleTogglePerPageSelect}
    >
      <div className={styles.perPageTitle}>
        <span>{perPageSelectTitle}</span>
        <div
          className={
            !isOpenPerPageSelect
              ? styles.down__icon
              : `${styles.dropdown__icon_rotate180}`
          }
          style={{
            backgroundImage: arrowUrl,
          }}
        ></div>
      </div>
      {isOpenPerPageSelect && (
        <ul className={styles.perPageNumbersWrapper}>
          {perPageNumbers.map((item) => (
            <li
              key={item.id}
              onClick={() => handleChangePerPageSelectTitle(item.titleNum)}
            >
              {item.titleNum}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PerPageSelect;
