import { ChangeEvent, useState, useEffect } from 'react';
import './columnSearch.css'
import _ from 'lodash';
import styles from './ColumnSearch.module.scss';
import { joinArrayValues } from '../../../../../../utils/joinArrayValues';
import { useLocation } from 'react-router-dom';
import {FaSearch, RangesCalendar, RangesCalendarGreen, CloseRanges,} from "../../../../../../assets/icons";
import 'react-datepicker/dist/react-datepicker.css';
import Tippy from "@tippyjs/react";
import DatePicker from "react-datepicker";
const titleIcon =
  'https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/shop_regular.svg';

const downUpIcon = `https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/chevron_down_light.svg`;

interface IColumnSearchProps {
  columnValue: string;
  handleTogglePlatformsModalShow: () => void;
  activePlatforms: string[];
  inputValues: Record<string, string | number>;
  handleInputChange: (col: string, value: string | number) => void;
  filterByArr: any;
  startChangedCreated:any;
  endChangedCreated:any;
  startDateCreated:any;
  endDateCreated:any;
  setStartChangedCreated:any;
  setEndChangedCreated:any;
  setStartDateCreated:any;
  setEndDateCreated:any;
  createdTippy?:any;
  setCreatedCloseClicked?:any;
  handleToggleDateRangeModalShow?:any;
  dateRangeModalShow?:any;
}

const ColumnSearch = (props: IColumnSearchProps) => {
  const {
    columnValue,
    handleTogglePlatformsModalShow,
    activePlatforms,
    inputValues,
    handleInputChange,
    filterByArr,
    startChangedCreated,
    endChangedCreated,
    startDateCreated,
    endDateCreated,
    setStartChangedCreated,
    setEndChangedCreated,
    setStartDateCreated,
    setEndDateCreated,
    createdTippy,
    setCreatedCloseClicked,
    handleToggleDateRangeModalShow,
    dateRangeModalShow,
  } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isFocused, setIsFocused] = useState(false);
  const [cacher, setCacher] = useState(true);
  const [selector, setSelector] = useState('');

  const isActive = startDateCreated || endDateCreated;
  const handleChange = ([newStartDate, newEndDate]: any) => {
    if (newStartDate !== startDateCreated ) {
      setStartDateCreated(newStartDate);
      setStartChangedCreated(true);
    }
    if (newEndDate !== endDateCreated) {
      setEndDateCreated(newEndDate);
      setEndChangedCreated(true);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (!event.target.value) {
      setIsFocused(false);
    }
  };

  const handleChangePlatformInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (columnValue === 'platforms') return;
    let value: string;
    if (columnValue === 'condition') {
      value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    } else {
      value = e.target.value;
    }
    handleInputChange(e.target.name, value);
    setCacher(false);
  };

  const handleChangeSelect = (event: any) => {
    setSelector(event.target.value);
    handleInputChange('condition', event.target.value);
  };

  const handlePlatformClick = () => {
    if (columnValue !== 'platforms') return;
    handleTogglePlatformsModalShow();
  };

  let decodedFilterBy: any;
  const functionGetValueFromUrl = (columnValue: string) => {
    const filterByParam = queryParams.get('filter_by');
    if (filterByParam) {
      decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));
    }
    if (columnValue === 'title') {
      return _.get(decodedFilterBy, '133');
    } else if (columnValue === 'SKU') {
      return _.get(decodedFilterBy, '1');
    } else if (columnValue === 'price') {
      return _.get(decodedFilterBy, '22');
    } else if (columnValue === 'quantity') {
      return _.get(decodedFilterBy, '851');
    } else if (columnValue === 'notes') {
      return _.get(decodedFilterBy, '726');
    } else if (columnValue === 'platforms') {
      return _.get(decodedFilterBy, 'platforms');
    } else {
      return '';
    }
  };
  useEffect(() => {
    functionGetValueFromUrl(columnValue);
  }, []);

  useEffect(() => {
    if(dateRangeModalShow) {
      setStartChangedCreated(false);
      setEndChangedCreated(false);
    }
  }, [dateRangeModalShow]);

  const inputType =
    columnValue === 'price'
      ? 'number'
      : columnValue === 'quantity'
      ? 'number'
      : columnValue === 'sold_price'
      ? 'number'
      : columnValue === 'cogs'
      ? 'number'
      : columnValue === 'updated'
      ? 'date'
      : columnValue === 'created'
      ? 'date'
      : 'text';

  return columnValue === 'platforms' ? (
    <div className={styles.platformsInput} onClick={handlePlatformClick}>
      <div className={styles.platformsContainer}>
        <div className={styles.titleContainer}>
          <div
            className={styles.titleIcon}
            style={{
              backgroundImage: `url(${titleIcon})`,
            }}
          ></div>
          <div className={styles.title} style={{textTransform:'initial'}}>
            {activePlatforms?.length
              ? joinArrayValues(activePlatforms)
              : 'Platforms'}
          </div>
          <div
            className={styles.downUpIcon}
            style={{
              backgroundImage: `url(${downUpIcon})`,
            }}
          ></div>
        </div>
      </div>
    </div>
  ) : columnValue === 'issues' ? (
    <div></div>
  ) : columnValue === 'fees' ? (
    <div></div>
  ) : columnValue === 'date_sold' ? (
    <div></div>
  )  : columnValue === 'created' ? (
   <div className={"createdRangesPicker"}>
     <Tippy
      appendTo={createdTippy.current}
      visible={dateRangeModalShow}
      interactive={true}
      zIndex={9991}
      arrow={false}
      trigger="focus click"
      content={
        <div
         className={styles.rangesModal}
         onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.rangesheader}>
            <p>Set Date Created Range</p>
            <div onClick={()=>{
              handleToggleDateRangeModalShow();
              setCreatedCloseClicked(true);
            }}
                 className={styles.closeRangesModal}>
              <CloseRanges/>
            </div>
          </div>

          <div className={'createdRange'}>
            <DatePicker
             selected={startDateCreated}
             onChange={handleChange}
             selectsRange
             startDate={startDateCreated}
             endDate={endDateCreated}
             dateFormat="MM/dd/yyyy"
             isClearable={true}
             open={true}
             placeholderText="Start Date - End Date"
             showDaysMonthYearPicker
             showYearDropdown
             showMonthDropdown
            />
            <div className={styles.rangesCalendar} >
              <RangesCalendarGreen />
            </div>
          </div>
        </div>
      }
     >
       <div className={`${styles.calendarIcon} ${isActive ? styles.calendarIconActive : ''}` } onClick={() => {
         if(dateRangeModalShow){
           setCreatedCloseClicked(true);
         }else{
           setCreatedCloseClicked(false);
         }
         handleToggleDateRangeModalShow();
       }}>
         <RangesCalendar/>
       </div>
     </Tippy>
   </div>
  ) : columnValue === 'condition' ? (
    <div>
      <select id="dropdown" value={selector} onChange={handleChangeSelect}>
        <option value="Pre-Owned">Pre-Owned</option>
        <option value="New">New</option>
        <option value=""></option>
      </select>
    </div>
  ) : (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        className="myInputCatalog no-spinner"
        type={inputType}
        onChange={handleChangePlatformInput}
        value={
          inputValues[columnValue] ||
          (cacher && functionGetValueFromUrl(columnValue)) ||
          ''
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={columnValue}
      />
      {!isFocused &&
        !inputValues[columnValue] &&
        !functionGetValueFromUrl(columnValue) && (
          <div className="myInputSearchIcon">
            <FaSearch/>
          </div>
        )}
    </div>
  );
};

export default ColumnSearch;
