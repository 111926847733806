import React, {Dispatch, useEffect, useState,useRef} from 'react';
import {useGlobalUploaderState} from "../hooks/hooks";
import {isMobile} from "../const/const";
import ReactCrop from 'react-image-crop'
// @ts-ignore
import loadImage from 'blueimp-load-image';
import {generateImageThumbnail, loadImageToBase64} from "../common";
import UploaderService from "../services";
const ModalBody = ({handleImageEditComplete}: any) => {
    const {state, dispatch} = useGlobalUploaderState();
    const { showUploaderCropGrid,showUploaderCropView,activeFile, files, saveImage,confirmCrop, showUploaderCropSquare}  = state;
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [scaledHeight, setScaledHeight] = useState(0);
    const [activeFlipHorizontalValue, setActiveFlipHorizontalValue] = useState(false);
    const [activeFlipVerticalValue, setActiveFlipVerticalValue] = useState(false);
    const [activeRotateValue, setActiveRotateValue] = useState(1);
    const [scaledWidth, setScaledWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(isMobile ?
        window.innerHeight - 205 - 40
        : window.innerHeight - 77 - 164 - 24)
    const [canvasWidth, setCanvasWidth] = useState(isMobile ? window.innerWidth : canvasHeight);
    const [yImage, setYImage] = useState(0);
    const [xImage, setXImage] = useState(0);
    const [image, setImage]: [any, Dispatch<any>] = useState(null);
    const [cropImageUrl, setCropImageUrl] = useState(null);
    const [previousLoadedImageFromUrl, setPreviousLoadedImageFromUrl] = useState('');
    const [crop, setCrop]: any = useState({
        unit: 'px',
        x: 0,
        y: 0
    });

    useEffect(() => {
        const handleResize = () => {
            const canvasHeightNew = isMobile ?
                window.innerHeight - 205 - 40
                : window.innerHeight - 77 - 164 - 24
            setCanvasHeight(canvasHeightNew);
            setCanvasWidth(isMobile ? window.innerWidth : canvasHeightNew)
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!activeFile || !saveImage) return;
        const resetModalSettings = () => {
            dispatch({type: "SETUPLOADERCROPVIEW", data: false})
            dispatch({type: "SETUPLOADERCROPGRIDVIEW", data: false})
            dispatch({type: "SETUPLOADERCROPSQUARE", data: false})
        }
        const saveImageInS3 = () => {
            const generationCanvas:any = document.getElementById('canvasForGenerateImage')
            const generationCtx = generationCanvas.getContext('2d');
            let canvas:any =  document.getElementById('image-uploader-body-item');
            drawImage(generationCanvas, generationCtx, true, image.width, image.height)
            generationCanvas.toBlob(function(blob:any) {
                const imageForDisplay = generationCanvas.toDataURL('image/jpeg')
                let activeFileFromList = files.find(file => file.index === activeFile.index);
                let fileS3Link, lp_id, uploaded;
                if (activeFileFromList) {
                    ({fileS3Link, lp_id, uploaded} = activeFileFromList);
                }
                dispatch({type: "SETNEWPROPERTYINFILE", data: { index: activeFile.index, properties:
                            {
                                ...activeFile,
                                fileS3Link,
                                lp_id,
                                uploaded,
                                imagePreviewUrl: imageForDisplay,
                                imageSmallPreviewUrl: imageForDisplay,
                                rotate: 1,
                                flipVertical: false,
                                flipHorizontal: false,
                                brightness: 0,
                                contrast: 0,
                                filter: null,
                            }
                    }});
                // @ts-ignore
                dispatch({type:'SETACTIVEFILE', data: null})
                dispatch({type: "INCREASEIMAGELOADINGQUEUE"})
                UploaderService.getS3Policy(activeFile.filename).then(async ({fields}: any) => {// @ts-ignore
                    await UploaderService.uploadFileToS3(fields, blob, activeFile.filename);
                    const {success} = await UploaderService.fileGenerateSet(activeFile.filename);
                    handleImageEditComplete();
                    dispatch({type: "DECREASEIMAGELOADINGQUEUE"})
                });
                resetModalSettings()
            }, 'image/jpeg')
        }
        saveImageInS3()
        dispatch({type: "SAVEIMAGE", data: false})
    }, [saveImage]);

    useEffect(() => {
        if (!activeFile || !confirmCrop) return;
        const url: any = generateNewImageBasedOnCrop();
        setCrop({
            ...crop,
            unit: 'px',
            x: 0,
            y: 0
        })
        setImage(null)
        setCropImageUrl(null)
        //reset confirm crop button
        dispatch({type: "CONFIRMCROP", data: false})
        generateImageThumbnail(url).then((imageSmallPreviewUrl) => {
            dispatch({type: "SETACTIVEFILE", data: {...activeFile, imagePreviewUrl:url, imageSmallPreviewUrl }})
        })
    }, [confirmCrop]);

    useEffect(() => {
        updateCropAspect(showUploaderCropSquare)
    }, [showUploaderCropSquare])


    if (!activeFile) return <>No Image</>
    const {imagePreviewUrl,rotate,flipHorizontal,contrast, brightness,flipVertical,filter}: any= activeFile

    const  addBackgroundForCanvas = (context: any, width: number, height: number) => {
        context.globalCompositeOperation = "lighter";  // AKA add / linear-dodge
        if (filter === 'aden') {
            context.fillStyle = "rgba(66, 10, 14, 0.2)";
        } else if (filter === 'amaro') {
            context.fillStyle = "rgba(255,255,255,0)";
        } else if (filter === '1977') {
            context.fillStyle = "rgba(243,106,188,0.3)";
        } else if (filter === 'brannan')  {
            context.fillStyle = "rgba(161, 44, 199, 0.31)";
        } else if (filter === 'brooklyn') {
            context.fillStyle = "rgba(168, 223, 193, 0.3)";
        } else if (filter === 'clarendon') {
            context.fillStyle = "rgba(127, 187, 227, 0.2)";
        } else if (filter === 'earlybird') {
            context.fillStyle = "rgba(208, 186, 142, 0.1)";
        } else if (filter === 'inkwell') {
            context.fillStyle = "rgba(0, 0, 0, 0)";
        } else if (filter === 'lofi') {
            context.fillStyle = "rgba(0, 0, 0, 0)";
        } else if (filter === 'maven') {
            context.fillStyle = "rgba(3, 230, 26, 0.2)";
        } else if (filter === 'perpetua') {
            context.fillStyle = "rgba(0, 91, 154, 0.6)";
        } else if (filter === 'hudson') {
            context.fillStyle = "rgba(255, 177, 166, 0.2)";
        } else if (filter === 'gingham') {
            context.fillStyle = "rgba(66, 10, 14, 0.2)";
        } else if (filter === 'reyes') {
            context.fillStyle = "rgba(173, 205, 239, 0.2)";
        } else if (filter === 'stinson') {
            context.fillStyle = "rgba(240, 149, 128, 0.2)";
        } else if (filter === 'toaster') {
            context.fillStyle = "rgba(15, 78, 128, 0.4)";
        } else if (filter === 'walden') {
            context.fillStyle = "rgba(204, 68, 0, 0.6)";
        } else if (filter === 'valencia') {
            context.fillStyle = "rgba(58, 3, 57, 0.4)";
        } else if (filter === 'xpro2') {
            context.fillStyle = "rgba(224, 231, 230, 0.2)";
        } else {
            context.fillStyle = "rgba(255,255,255,0)";
        }
        context.fillRect(0, 0, width, height);
        context.restore();
    }

    const clearCanvas = (canvas: any, context: any) => {
        context.fillStyle = "#ffffff";
        context.fillRect(0,0,canvas.width, canvas.height);
    }

    const updateCropAspect = (cropAspectOn: boolean) => {
        const {width, height} = crop;
        if(cropAspectOn){
            setCrop({
                ...crop,
                width: width > height ? height : width ,
                height: width > height ? height : width  ,
                x: 0,
                y: 0
            })
        }else{
            setCrop({
                ...crop,
                width: width ,
                height: height,
                x: 0,
                y: 0
            })
        }

        dispatch({type: "SETCROPBUTTONDISABLED", data: false})
    }

    const getImageUrl = (image: any) => {
        let url;
        //@ts-ignore
        if (image && !image.src) {
            //@ts-ignore
            url =  image.toDataURL("image/jpeg");
        } else {
            //@ts-ignore
            url = image.src
        }
        return url;
    }

    const  rotateImage = (context: CanvasRenderingContext2D) => {
        context.save();
        let orientation;
        if (activeFlipHorizontalValue !== flipHorizontal){
            orientation = 2;
            setActiveFlipHorizontalValue(flipHorizontal)
        }else if(activeFlipVerticalValue !== flipVertical){
            orientation = 4;
            setActiveFlipVerticalValue(flipVertical);
        }

        if ((activeRotateValue !== Math.abs(+rotate) && (activeRotateValue !== 1 || Math.abs(+rotate) !== 1) )) {
            let rotateLeft = activeRotateValue >  Math.abs(+rotate)
            //this need for correct displaying on rotate
            if (activeRotateValue === 4 && +rotate === 1) rotateLeft = false
            if(activeRotateValue === 1 && +rotate === 4) rotateLeft = true
            orientation = rotateLeft ? 8 : 6
            // fobv rceRotateReRender need for rerender image with correct rotate after remove background
            // if (forceRotateReRender){
            //     //reset forceRotateRerender for prevent loop
            //     forceRotateReRender = false;
            //
            //     switch (+rotate) {
            //         case 1:
            //             orientation = 1
            //             break;
            //         case 2:
            //             orientation = 6
            //             break;
            //         case 3:
            //             orientation = 3
            //             break;
            //         case 4:
            //             orientation = 8
            //             break;
            //     }
            //
            // }
        }

        if (!orientation) return;


        loadImage(getImageUrl(image), {
            orientation: orientation,
        }).then( (data: {image: any}) => {
            setActiveRotateValue(rotate);
            setImage(data.image)
            generateImageThumbnail(getImageUrl(data.image)).then((imageSmallPreviewUrl) => {
                dispatch({type: "SETACTIVEFILE", data: {...activeFile, imagePreviewUrl: getImageUrl(data.image), imageSmallPreviewUrl }})
            })
        })

    };


    const addFiltersToCanvas = (context: any) => {
        let additionalFilters = '';
        if (filter === 'aden'){
            additionalFilters = `saturate(85%) hue-rotate(20deg)`;
        } else if(filter === 'amaro') {
            additionalFilters = `saturate(150%) hue-rotate(-10deg)`;
        }else if(filter === '1977') {
            additionalFilters = `sepia(0%) saturate(130%) grayscale(0%) invert(0%) hue-rotate(0deg) blur(0px)`;
        } else if(filter === 'brannan') {
            additionalFilters = `sepia(50%)`;
        } else if(filter === 'clarendon') {
            additionalFilters = `saturate(125%)`;
        }else if(filter === 'earlybird') {
            additionalFilters = `sepia(20%)`;
        } else if(filter === 'inkwell') {
            additionalFilters = `sepia(30%) grayscale(100%)`;
        }else if(filter === 'lofi') {
            additionalFilters = `saturate(110%)`;
        }else if(filter === 'hudson') {
            additionalFilters = `saturate(110%)`;
        }   else if(filter === 'maven') {
            additionalFilters = `saturate(150%) sepia(25%)`;
        }else if(filter === 'reyes') {
            additionalFilters = `saturate(75%) sepia(22%)`;
        }  else if(filter === 'stinson') {
            additionalFilters = `saturate(85%)`;
        }else if(filter === 'walden') {
            additionalFilters = `saturate(160%) sepia(30%) hue-rotate(350deg)`;
        }else if(filter === 'valencia') {
            additionalFilters = `sepia(8%)`;
        }else if(filter === 'gingham') {
            additionalFilters = `hue-rotate(350deg)`;
        }else if(filter === 'xpro2') {
            additionalFilters = `sepia(30%)`;
        }
        context.filter = `${additionalFilters} contrast(${+contrast + 1}) brightness(${+brightness * 50 + 100}%)`;
    }

    const  drawImage = (canvas: any = null, ctx: any = null, originalSizes = false, actualWidth:number = 0, actualHeight:number = 0) => {
        if (!image) return console.error("DrawImage no image found")
        let myCanvas:any = canvas ? canvas : document.getElementById('image-uploader-body-item');
        let context =ctx ? ctx : myCanvas.getContext('2d');
        let iw = originalSizes ? actualWidth : scaledWidth;
        let ih = originalSizes ? actualHeight :  scaledHeight;
        myCanvas.width = originalSizes ? imageWidth : scaledWidth;
        myCanvas.height = originalSizes ? imageHeight : scaledHeight;
        //   console.log(scaledHeight, scaledWidth,image.width, image.height, 99394 )
        //clear rect is not supporting by old version of ios
        clearCanvas(myCanvas, context)
        //need apply filters after canvas size change
        addFiltersToCanvas(context)
        rotateImage(context);
        context.drawImage(image,
            iw/2 - iw/2 , ih/2 - ih/2,
            iw, ih
        );
        addBackgroundForCanvas(context, iw, ih)
        !originalSizes && setCropImageUrl(myCanvas)
    }

    const  getModalSettingBasedOnImage = (img: any) => {

        let activeHeight = img.height > canvasHeight ? canvasHeight : img.height;
        let activeWidth = img.width > canvasWidth ? canvasWidth : img.width;
        let imageScaledHeight;
        let imageScaledWidth;
        if(activeHeight === canvasHeight && activeWidth === canvasWidth && activeHeight !== activeWidth){
            let isHeightBigger = activeHeight > activeWidth;
            if ( img.height === img.width ){
                //if width and height is same need find smallest value based on canvas max size and apply for both width/height
                imageScaledWidth = activeHeight < activeWidth ? activeHeight : activeWidth
                imageScaledHeight = imageScaledWidth;
            }else if(!isHeightBigger){
                imageScaledWidth  = activeHeight * img.width / img.height

                //if scaled width is bigger than canvas need optimize the width based on canvas width
                if (imageScaledWidth > canvasWidth){
                    imageScaledHeight = canvasWidth * img.height / img.width
                }else{
                    imageScaledHeight = activeHeight
                }
            }else{
                imageScaledHeight  = activeWidth * img.height / img.width
                //if scaled height is bigger than canvas need optimize the width based on canvas height
                if (imageScaledHeight > canvasHeight){
                    imageScaledWidth = canvasHeight * img.width / img.height
                }else{
                    imageScaledWidth = activeWidth
                }

            }

        }else if(img.height === img.width ){
            //if width and height is same need find smallest value based on canvas max size and apply for both width/height
            imageScaledWidth = activeHeight < activeWidth ? activeHeight : activeWidth
            imageScaledHeight = imageScaledWidth;
        }else if (img.height > img.width ){
            imageScaledWidth = activeHeight * img.width / img.height;
            imageScaledHeight = imageScaledWidth * img.height / img.width;
        }else{
            // @ts-ignore
            imageScaledHeight = activeWidth * img.height / img.width;
            imageScaledWidth = imageScaledHeight * img.width / img.height;
        }
        imageScaledWidth = +imageScaledWidth.toFixed(0);
        imageScaledHeight = +imageScaledHeight.toFixed(0)
        let xPosition = (canvasWidth - imageScaledWidth) / 2
        let yPosition = (canvasHeight - imageScaledHeight) / 2
        //if image size is more then canvas set canvas width
        imageScaledWidth =  imageScaledWidth > canvasWidth ? canvasHeight : imageScaledWidth;
        //same for image height
        imageScaledHeight = imageScaledHeight > canvasHeight ? canvasHeight : imageScaledHeight;
        setCrop({
            ...crop,
            width:  imageScaledWidth,
            height: imageScaledHeight,
            x: crop.x,
            y: crop.y,
            unit: 'px'
        })
        setImageWidth(img.width);
        setImageHeight(img.height)
        setImage(img)
        setYImage(Math.trunc(yPosition))
        setXImage(Math.trunc(xPosition))
        setScaledWidth(imageScaledWidth)
        setScaledHeight(imageScaledHeight)
    }

    const generateNewImageBasedOnCrop = () => {
        const canvas = document.createElement('canvas');
        const scaleX = imageWidth / scaledWidth;
        const scaleY = imageHeight / scaledHeight;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY
        const ctx:any = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        return canvas.toDataURL("image/jpeg")
    }

    const displayImageInCanvas = () => {
        const blobToImage = new Image();
        blobToImage.onload = () => {
            getModalSettingBasedOnImage(blobToImage)
        };
        blobToImage.src = imagePreviewUrl;
    }

    const  onCropChange = (cropChanged: any) => {
        const {width, height} = cropChanged;
        if (cropChanged.y + cropChanged.height > scaledHeight)  crop.y = 0
        if (cropChanged.x + cropChanged.width > scaledWidth) crop.x = 0;
        if(width === 0 || height === 0) return
        setCrop({ ...crop,...cropChanged})
        //if image crop area is changed
        if (!(Math.round(scaledWidth) === Math.round(cropChanged.width) && Math.round(scaledHeight) === Math.round( cropChanged.height))){
            dispatch({type: "SETCROPBUTTONDISABLED", data: false})
        }else{
            dispatch({type: "SETCROPBUTTONDISABLED", data: true})
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (!image) return
        drawImage()
    }, [image,filter, activeFile]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (!imagePreviewUrl) return
        if (imagePreviewUrl.startsWith('data:image')) {
            displayImageInCanvas();
        } else {
            if (previousLoadedImageFromUrl === imagePreviewUrl) return;
            setPreviousLoadedImageFromUrl(imagePreviewUrl)
            //need load image for edit
            if (activeFile.preview){
                setCrop({
                    unit: 'px',
                    x: 0,
                    y: 0
                });
                setImage(null)
                setCropImageUrl(null)
                dispatch({type: "MODALLOADERACTION", data: {status: true, text: 'Image is loading for edit...'}})
            }
            if (activeFile.backgroundLoading){
                setCrop({
                    unit: 'px',
                    x: 0,
                    y: 0
                });
                setImage(null)
                setCropImageUrl(null)
                dispatch({type: "MODALLOADERACTION", data: {status: true, text: 'Imageds is loading for edit...'}})
                return;
            }
            loadImageToBase64(imagePreviewUrl).then((data:string) => {
                dispatch({type: "SETACTIVEFILE", data: {...activeFile, preview: false,imagePreviewUrl: data}})
                dispatch({type: "MODALLOADERACTION", data: {status: false, text: ''}})
            }).catch((e) => {
                // @ts-ignore
                dispatch({type:'SETACTIVEFILE', data: null})
                dispatch({type: "SETUPLOADERCROPVIEW", data: false})
                dispatch({type: "SETUPLOADERCROPGRIDVIEW", data: false})
                dispatch({type: "SETUPLOADERCROPSQUARE", data: false})
                alert(e)
            })
        }
    }, [imagePreviewUrl]);

    return <div className={ showUploaderCropGrid ? "uploader-body-canvas show-crop-grid" : "uploader-body-canvas" }>
        <div  style={{  height: canvasHeight, width: canvasWidth, background: "rgba(10, 10, 10, 0.3)"}} className="canvas-background">
            <div style={{ height: scaledHeight, width: scaledWidth, position: 'absolute'}} className="canvas-active-area">
            </div>
            <div style={{display: showUploaderCropView ?  "none" : "block"}} className="main-canvas-view-container">
                <canvas   id="image-uploader-body-item" />
            </div>
            <div className="crop-view-container">
                {
                    showUploaderCropView &&
                    <div style={{ width: canvasWidth, height: canvasHeight, display: 'flex', justifyContent: "center", alignItems: "center"}}>
                        <ReactCrop       minHeight={120}
                                         minWidth={120}
                                         aspect={showUploaderCropSquare ? 1 : undefined}
                                         style={{ zIndex: 999, overflow: "visible", cursor: 'default'}}
                                         onChange={onCropChange} ruleOfThirds={true} crop={crop} >
                            { cropImageUrl && <img  width={scaledWidth} height={scaledHeight} src={getImageUrl(cropImageUrl)}  alt="crop"/>}
                        </ReactCrop>
                    </div>
                }
            </div>
        </div>
        <canvas  id="canvasForGenerateImage"></canvas>
    </div>
};

export default ModalBody;