import CreateTemplate from "../../components/CreateTemplate";
import Iframe from "../../components/UI/Iframe";
import React from "react";

const EditTemplatesPage = () =>{
	return(
	 <>
		 <Iframe/>
		 <CreateTemplate />
	 </>
	)
}

export default  EditTemplatesPage;