import React from 'react';
import {useGlobalUploaderState} from "../hooks/hooks";
import UploaderService from "../services";
import {bucketUrl} from "../const/const";
import {getUniqueId, replaceSuffixInFilename} from "../common";

const ImageUploaderHeader = ({hasSimple}: any) => {
    const {state, dispatch} = useGlobalUploaderState();
    const {activeStatus, activeFile} = state;
    if (!activeFile) return <></>
    const {filename} = activeFile

    const restoreOriginal = async () => {
        dispatch({type: "MODALLOADERACTION", data:{status: true, text: 'Restoring original...'}})
        const LP_filename: any = await UploaderService.restoreOriginalImageByFilename(filename);
        await UploaderService.fileGenerateSet(LP_filename);
        const fileUrl = replaceSuffixInFilename(filename,'_LP', '_CI');
        dispatch({type: "SETACTIVEFILE", data: {...activeFile, preview: true, imagePreviewUrl:  bucketUrl + fileUrl + `?rnd=${getUniqueId()}`}})
    }

    const ImageUploaderHistory = () => {
        return  <div className="uploader-header-left-side">
            {activeStatus !== 0 && <div data-tooltip="Restore Original" className="history-container custom-tooltip" style={hasSimple ? {display: 'none'} : {}} onClick={() => restoreOriginal()}>
                <div className="history-image-icon" ></div>
            </div>}
        </div>
    }
    const DoneButton = () => {
        return <div>
            <button type="button" className="save-progress-btn upload-submit-btn disable-select"
                    onClick={() => dispatch({type: "SAVEIMAGE", data: true})}>
                Save
            </button>
        </div>
    }


    const MinimizeButton = () => {
        return <div data-tooltip="Minimize" className="minimize-modal-window custom-tooltip" onClick={() => {
            //    this.minimizeModalWindow(index)
        }} />
    }

    const ExitButton = () => {
        return <div data-tooltip="Discard Changes" className="custom-tooltip close-image-icon" onClick={ () => {
            // @ts-ignore
            dispatch({type:'SETACTIVEFILE', data: null})
            dispatch({type: "SETUPLOADERCROPVIEW", data: false})
            dispatch({type: "SETUPLOADERCROPGRIDVIEW", data: false})
            dispatch({type: "SETUPLOADERCROPSQUARE", data: false})
            // @ts-ignore
            //TODO do something with this
            let helpBubble:HTMLElement = document.querySelector('.lp_custom_help_bubble_button_container')
            if (helpBubble) helpBubble.style.zIndex = '88888';
        }} />

    }

    return (
        <div className="image-uploader-header">
            <ImageUploaderHistory/>
            <DoneButton/>
            <div className="modal-header-right">
                <MinimizeButton/>
                <ExitButton/>
            </div>
        </div>
    );
};

export default ImageUploaderHeader;